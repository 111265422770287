<template>
  <div>
    <chat-message
      v-if="chatEvent.type === 'msg'"
      :chat-message="chatEvent"
      :chat-log-id="chatLogId"
      :chat-log-platform="chatLogPlatform"
      :show-ssml="showSsml"
    />
    <chat-error
      v-if="chatEvent.type === 'error' || chatEvent.type === 'fallback'"
      :error="chatEvent"
    />
    <chat-action
      v-if="chatEvent.type === 'action' && showActions"
      :action="chatEvent"
    />
    <chat-message
      v-if="chatEvent.type === 'action' && isChatMacroMessage"
      :chat-message="chatMacroMessage"
      :chat-log-id="chatLogId"
    />
    <chat-traceback
      v-if="chatEvent.type === 'traceback'"
      :traceback="chatEvent"
    />
    <chat-interaction
      v-if="chatEvent.type === 'interaction'"
      :interaction="chatEvent"
    />
    <chat-upload
      v-if="chatEvent.type === 'upload'"
      :upload="chatEvent"
    />
    <chat-moved
      v-if="chatEvent.type === 'chat-moved'"
      :moved="chatEvent"
    />
  </div>
</template>

<script>
import ChatMessage from '@/pages/ChatLogs/ChatMessage.vue';
import ChatInteraction from '@/pages/ChatLogs/ChatInteraction.vue';
import ChatError from '@/pages/ChatLogs/ChatError.vue';
import ChatAction from '@/pages/ChatLogs/ChatAction.vue';
import ChatTraceback from '@/pages/ChatLogs/ChatTraceback.vue';
import ChatUpload from '@/pages/ChatLogs/ChatUpload.vue';
import ChatMoved from '@/pages/ChatLogs/ChatMoved.vue';

export default {
  name: 'ChatEvent',
  components: {
    ChatError,
    ChatInteraction,
    ChatMessage,
    ChatAction,
    ChatTraceback,
    ChatUpload,
    ChatMoved,
  },
  props: {
    chatEvent: {
      type: Object,
      required: true,
    },
    showActions: {
      type: Boolean,
      required: true,
    },
    chatLogId: {
      type: String,
      required: true,
    },
    chatLogPlatform: {
      type: String,
      required: true,
    },
    showSsml: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    isChatMacroMessage() {
      return this.chatEvent.data.endpoint === 'chatapi://chat.runMacro'
        && this.chatEvent?.data?.result?.payload?.ticket?.comment?.html_body;
    },
    chatMacroMessage() {
      return {
        data: {
          author: 'chatbot',
          query: this.chatEvent.data.result.payload.ticket.comment.html_body,
        },
        timestamp: this.chatEvent.timestamp,
      };
    },
  },
};
</script>

<style scoped>
</style>
