<template>
  <div>
    <h5>Interpretations of Nodes</h5>
    <b-card no-body>
      <p class="mb-2">
        Interpretation of different parts of the bot.<br>
        Node interpretations are also used to guide the AI predictions on Training page.
        <br>
        Click
        <b-link v-b-modal.node-interpretations-modal>
          here
        </b-link>
        for a detailed description on how to configure the node-interpretations.
      </p>
    </b-card>

    <h6 class="mt-2">
      Opt-out nodes
      <tooltipped-text
        value="If the conversation reaches an 'opt-out node' it means that the user actively
        decided to disband the bot."
      />
    </h6>
    <div>
      <chip-list
        class="mb-2 bg-white"
        :completions="allNodeNames"
        :value="optOutNodeNames"
        placeholder="Specify opt-out node"
        @input="setOptOutNodes"
        @click="goToNode"
      />
    </div>
    <h6>
      Flow nodes
      <tooltipped-text
        value="If the conversation reaches a 'flow node' it means that the conversation is
        'covered' by the bot."
      />
    </h6>
    <div>
      <chip-list
        class="mb-2 bg-white"
        :completions="allNodeNames"
        :value="flowNodeNames"
        placeholder="Specify flow node"
        @input="setFlowNodes"
        @click="goToNode"
      />
    </div>
    <h6>
      Transfer nodes
      <tooltipped-text
        value="Transfer nodes are nodes that transfer the conversation either via chat, phone,
        ticket creation, or other measures."
      />
    </h6>
    <div>
      <chip-list
        class="mb-2 bg-white"
        :completions="allNodeNames"
        :value="transferNodeNames"
        placeholder="Specify transfer node"
        @input="setTransferNodes"
        @click="goToNode"
      />
    </div>
    <b-modal
      id="node-interpretations-modal"
      title="Detailed description of node interpretations"
      size="lg"
      ok-only
    >
      <node-interpretations-description />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChipList from 'supwiz/components/ChipList.vue';
import TooltippedText from '@/components/TooltippedText.vue';
import NodeInterpretationsDescription from './NodeInterpretationsDescription.vue';

export default {
  name: 'NodeInterpretations',
  components: { TooltippedText, ChipList, NodeInterpretationsDescription },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
      'nodeByName',
      'allNodesAsList',
      'specialNodes',
    ]),
    ...mapGetters('nodeInterpretations', [
      'optOutNodes',
      'flowNodes',
      'transferNodes',
    ]),
    allNodeNames() {
      const allNodes = this.allNodesAsList;
      return allNodes.map((n) => n.name);
    },
    optOutNodeNames() {
      let nodes = this.optOutNodes.map((id) => this.nodeById(id));
      nodes = nodes.filter((node) => node !== undefined);
      return nodes.map((node) => node.name);
    },
    flowNodeNames() {
      let nodes = this.flowNodes.map((id) => this.nodeById(id));
      nodes = nodes.filter((node) => node !== undefined);
      return nodes.map((node) => node.name);
    },
    transferNodeNames() {
      let nodes = this.transferNodes.map((id) => this.nodeById(id));
      nodes = nodes.filter((node) => node !== undefined);
      return nodes.map((node) => node.name);
    },
  },
  methods: {
    nodeNames2Ids(nameArray) {
      // Note: This function is copied from NodeEditContexts.vue
      const output = [];
      let valid = true;
      for (let i = 0; i < nameArray.length; i += 1) {
        const tempNode = this.nodeByName(nameArray[i]);
        if (tempNode === undefined) {
          valid = false;
        } else {
          output.push(tempNode.id);
        }
      }
      return valid ? output : false;
    },
    setOptOutNodes(nameArray) {
      if (!nameArray) {
        return;
      }
      const nodeIds = this.nodeNames2Ids(nameArray);
      if (!nodeIds) return;
      this.$store.dispatch(
        'nodeInterpretations/updateOptOutNodes',
        { nodeIds },
      );
    },
    setFlowNodes(nameArray) {
      if (!nameArray) {
        return;
      }
      const nodeIds = this.nodeNames2Ids(nameArray);
      if (!nodeIds) return;
      this.$store.dispatch(
        'nodeInterpretations/updateFlowNodes',
        { nodeIds },
      );
    },
    setTransferNodes(nameArray) {
      if (!nameArray) {
        return;
      }
      const nodeIds = this.nodeNames2Ids(nameArray);
      if (!nodeIds) return;
      this.$store.dispatch(
        'nodeInterpretations/updateTransferNodes',
        { nodeIds },
      );
    },
    goToNode(name) {
      const node = this.nodeByName(name);
      const params = { botId: this.$route.params.botId, nodeId: node.id };
      this.$router.push({ name: 'edit-node', params });
    },
  },
};
</script>
