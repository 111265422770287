import { render, staticRenderFns } from "./GenerateReply.vue?vue&type=template&id=631e7deb&scoped=true&"
import script from "./GenerateReply.vue?vue&type=script&lang=js&"
export * from "./GenerateReply.vue?vue&type=script&lang=js&"
import style0 from "./GenerateReply.vue?vue&type=style&index=0&id=631e7deb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "631e7deb",
  null
  
)

export default component.exports