<template>
  <div>
    <b-row no-gutters>
      <b-col
        cols="6"
        xl="3"
        class="mb-3 pr-2"
      >
        <NewKPICard
          title="Impacted conversations"
          tooltip-text="Percent of conversations impacted by the bot"
          format-type="percent"
          :value="getKPICardValue('percent_impacted_chats', 'actual')"
          :previous-value="getKPICardValue('percent_impacted_chats', 'previous')"
          :is-loading="isLoading('percent_impacted_chats')"
        />
      </b-col>
      <b-col
        cols="6"
        xl="3"
        class="mb-3 pl-2 px-xl-2"
      >
        <NewKPICard
          tooltip-text="Average number of messages sent by users to the bot per conversation"
          title="Average user messages"
          format-type="rounded-number"
          :value="getKPICardValue('avg_user_msg_count', 'actual')"
          :previous-value="getKPICardValue('avg_user_msg_count', 'previous')"
          :is-loading="isLoading('avg_user_msg_count')"
        />
      </b-col>
      <b-col
        cols="6"
        xl="3"
        class="mb-3 pr-2 px-xl-2"
      >
        <NewKPICard
          title="Average bot messages"
          tooltip-text="Average number of messages sent by the bot to users per conversation"
          format-type="rounded-number"
          :value="getKPICardValue('avg_chatbot_msg_count', 'actual')"
          :previous-value="getKPICardValue('avg_chatbot_msg_count', 'previous')"
          :is-loading="isLoading('avg_chatbot_msg_count')"
        />
      </b-col>
      <b-col
        cols="6"
        xl="3"
        class="mb-3 pl-2"
      >
        <NewKPICard
          title="Avg. integrations used"
          tooltip-text="Average number of integrations used by the bot per conversation"
          format-type="rounded-number"
          :value="getKPICardValue('avg_action_count', 'actual')"
          :previous-value="getKPICardValue('avg_action_count', 'previous')"
          :is-loading="isLoading('avg_action_count')"
        />
      </b-col>
    </b-row>
    <b-card class="r-75 mb-3" body-class="p-3">
      <b-row>
        <b-col>
          <b-card-title>
            Node details
          </b-card-title>
        </b-col>
      </b-row>
      <template v-if="!isFetchingNodeDetailsGroups">
        <b-row class="my-2">
          <b-col class="my-auto">
            <div>
              <b-form-tags
                v-model="filterNodeNames"
                tag-variant="primary"
                size="sm"
                remove-on-delete
                placeholder="Type and press enter to search"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <min-max-input
              :min-value="nodesFilter.countMin"
              :max-value="nodesFilter.countMax"
              label="Conv. count"
              :config="convCountConfig"
              @input="v=>updateRange(v.key === 'min' ? 'countMin' : 'countMax', v)"
            />
          </b-col>
          <b-col>
            <min-max-input
              :min-value="nodesFilter.automationMin"
              :max-value="nodesFilter.automationMax"
              label="Auto. rate"
              :config="{ min: 0, max: 100, step: 1 }"
              @input="v=>updateRange(v.key === 'min' ? 'automationMin' : 'automationMax', v)"
            />
          </b-col>
          <b-col>
            <min-max-input
              :min-value="nodesFilter.selfServiceMin"
              :max-value="nodesFilter.selfServiceMax"
              label="Self-service"
              :config="{ min: 0, max: 100, step: 1 }"
              @input="v=>updateRange(v.key === 'min' ? 'selfServiceMin' : 'selfServiceMax', v)"
            />
          </b-col>
          <b-col>
            <min-max-input
              :min-value="nodesFilter.resolutionMin"
              :max-value="nodesFilter.resolutionMax"
              label="Partial res."
              :config="{ min: 0, max: 100, step: 1 }"
              @input="v=>updateRange(v.key === 'min' ? 'resolutionMin' : 'resolutionMax', v)"
            />
          </b-col>
          <b-col>
            <min-max-input
              :min-value="nodesFilter.ratingMin"
              :max-value="nodesFilter.ratingMax"
              label="Rating"
              :config="{ min: 0, max: 5, step: 1 }"
              @input="v=>updateRange(v.key === 'min' ? 'ratingMin' : 'ratingMax', v)"
            />
          </b-col>
        </b-row>
        <div class="scrollable-card">
          <b-collapse v-model="exportMode">
            <b-row>
              <b-col>
                <b-form-group label="Select columns for export">
                  <b-form-checkbox-group
                    v-model="selectedColumns"
                    buttons
                    button-variant="outline-primary"
                    :options="exportColumnsOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group label="Selection">
                  <b-button-group>
                    <b-button @click="selectExportNodes(true)">
                      All
                    </b-button>
                    <b-button @click="selectExportNodes(false)">
                      None
                    </b-button>
                  </b-button-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-collapse>
          <b-row class="px-3 bg-white" style="position: sticky; top: 0; z-index: 2">
            <b-col
              v-b-tooltip.hover.noninteractive.viewport="getSortingTooltip('name')"
              cols="auto"
              class="node-grid-col text-left cursor-pointer"
              :style="getNameWidth"
              @click="setSorting('name')"
            >
              Group/Node name
              <font-awesome-icon :icon="getSortingIcon('name')" />
            </b-col>
            <b-col
              v-b-tooltip.hover.noninteractive.viewport="getSortingTooltip('num_chats')"
              cols="auto"
              style="width: 8%;"
              class="node-grid-col text-center cursor-pointer"
              @click="setSorting('num_chats')"
            >
              Count
              <font-awesome-icon :icon="getSortingIcon('num_chats')" />
            </b-col>
            <b-col
              v-b-tooltip.hover.noninteractive.viewport="getSortingTooltip('automation_rate')"
              cols="auto"
              style="width: 9%;"
              class="node-grid-col text-center cursor-pointer"
              @click="setSorting('automation_rate')"
            >
              Auto. rate
              <font-awesome-icon :icon="getSortingIcon('automation_rate')" />
            </b-col>
            <b-col
              v-b-tooltip.hover.noninteractive.viewport="getSortingTooltip('self_service')"
              cols="auto"
              style="width: 10%;"
              class="node-grid-col text-center cursor-pointer"
              @click="setSorting('self_service')"
            >
              Self-service
              <font-awesome-icon
                :icon="getSortingIcon('self_service')"
              />
            </b-col>
            <b-col
              v-b-tooltip.hover.noninteractive.viewport="getSortingTooltip('percent_optout')"
              cols="auto"
              style="width: 10%;"
              class="node-grid-col text-center cursor-pointer"
              @click="setSorting('percent_optout')"
            >
              Opted out
              <font-awesome-icon :icon="getSortingIcon('percent_optout')" />
            </b-col>
            <b-col
              v-b-tooltip.hover.noninteractive.viewport="getSortingTooltip('partial_resolution')"
              cols="auto"
              style="width: 9%;"
              class="node-grid-col text-center cursor-pointer"
              @click="setSorting('partial_resolution')"
            >
              Partial res.
              <font-awesome-icon :icon="getSortingIcon('partial_resolution')" />
            </b-col>

            <b-col
              v-if="isExternalStatsEnabled()"
              v-b-tooltip.hover.noninteractive.viewport="getSortingTooltip('rating')"
              cols="auto"
              style="width: 9%;"
              class="node-grid-col text-center"
              @click="setSorting('rating')"
            >
              Rating
              <font-awesome-icon :icon="getSortingIcon('rating')" />
            </b-col>
            <b-col
              v-if="isExternalStatsEnabled()"
              v-b-tooltip.hover.noninteractive.viewport="getSortingTooltip('rating_count')"
              cols="auto"
              style="width: 9%;"
              class="node-grid-col text-center"
              @click="setSorting('rating_count')"
            >
              Rating#
              <font-awesome-icon :icon="getSortingIcon('rating_count')" />
            </b-col>
            <b-col cols="auto" style="width: 6%" class="node-grid-col" />
            <b-col
              v-if="exportMode"
              cols="auto"
              class="node-grid-col d-flex justify-content-center align-items-center"
              style="width: 5%"
            >
              <font-awesome-icon icon="download" />
            </b-col>
          </b-row>
          <b-row v-if="!hasNodeDetailsData" class="px-3">
            <b-col class="text-center py-5 node-grid-col">
              Recompute statistics to see data.
            </b-col>
          </b-row>
          <b-row v-else class="px-3">
            <node-grid-row
              v-for="(node, index) in
                sortedNodeDetailsChildren(nodeDetailsGroupData, nameOfId, activeSorting)"
              :key="index"
              :export-mode="exportMode"
              :path="[node.id]"
              :node="node"
              :indentation="0"
            />
          </b-row>
        </div>
        <b-row class="my-2">
          <b-col>
            <b-button
              class="px-3"
              variant="primary"
              @click="$bvModal.show('nodeDetailsGroupModal')"
            >
              <font-awesome-icon icon="plus" />
            </b-button>
          </b-col>
          <b-col
            v-if="!exportMode"
            cols="auto"
          >
            <b-button
              v-b-tooltip.hover.noninteractive.viewport="'Export section'"
              class="px-3"
              variant="primary"
              @click="exportClicked()"
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </b-col>
          <b-col v-else cols="auto">
            <b-button @click="exportMode = false">
              Cancel
            </b-button>
            <b-button class="ml-2" variant="primary" @click="exportNodeDetails">
              Export selected
            </b-button>
          </b-col>
        </b-row>
      </template>
      <div
        v-else
        class="text-center my-3"
      >
        <b-spinner style="width: 2rem; height: 2rem;" />
      </div>
    </b-card>
    <b-card
      no-body
      title="Node visits"
      class="r-75 p-3 mb-3"
    >
      <b-card-title>
        Node visits
        <tooltipped-text
          value="Shows for each node the number and percentage of conversations which go through it"
          style="font-size: 20px"
        />
      </b-card-title>
      <node-stats-node-table
        caption="All nodes"
        :node-visits="getNodeVisits"
        @row-clicked="KPINodeClicked"
      />

      <b-row
        align-h="center"
        class="mt-3"
      >
        <completion-input
          :placeholder="addNodeStatsNodeName"
          value=""
          :completions="nodeNamesForStatsAutoCompletion"
          @input="choseNodeStatsNodeName($event)"
        />
        <b-btn
          variant="success"
          @click.stop="proxyAddNodeStatsNode()"
        >
          Add node to overview
        </b-btn>
      </b-row>
      <b-row
        align-h="center"
      >
        <small class="text-muted">
          After adding a node you will need to recompute statistics for it to appear.
        </small>
      </b-row>
    </b-card>

    <b-card
      v-if="false"
      no-body
      title="Average ratings for nodes"
      class="r-75 p-3"
    >
      <b-card-title>
        Average rating for nodes
        <tooltipped-text
          value="Shows the average rating of conversations going through each node"
          style="font-size: 20px"
        />
      </b-card-title>
      <node-stats-node-table
        caption="All nodes"
        :node-statistics="getNodeRatings"
        :show-ratings="true"
        @row-clicked="KPINodeClicked"
      />

      <b-row
        align-h="center"
        class="mt-3"
      >
        <completion-input
          :placeholder="addNodeStatsNodeName"
          value=""
          :completions="nodeNamesForStatsAutoCompletion"
          @input="choseNodeStatsNodeName($event)"
        />
        <b-btn
          variant="success"
          @click.stop="proxyAddNodeStatsNode()"
        >
          Add node to overview
        </b-btn>
      </b-row>
      <b-row
        align-h="center"
      >
        <small class="text-muted">
          After adding a node you will need to recompute statistics for it to appear.
        </small>
      </b-row>
    </b-card>

    <b-overlay :show="isLoading('start_url_stats')">
      <b-card
        no-body
        title="Statistics by start URL"
        class="r-75 p-3"
      >
        <b-card-title>
          Statistics by start URL
          <tooltipped-text
            value="Shows statistics based on the URL a given chat was started on"
            style="font-size: 20px"
          />
        </b-card-title>
        <b-row>
          <b-col>
            <b-pagination
              v-model="startUrlPagination.currentPage"
              :total-rows="startUrlTableItemsFiltered.length"
              :per-page="startUrlPagination.itemsPerPage"
              size="sm"
            />
          </b-col>
          <b-col>
            <multi-select
              v-model="startUrls"
              :options="startUrlOptions"
              typename="url"
              :searchable="startUrlOptions.length > 5"
              :shown-options-limit="50"
              :selected-on-top="true"
              class="mb-2"
            />
          </b-col>
        </b-row>
        <b-table
          :items="startUrlTableItemsFiltered"
          :fields="startUrlTableFields"
          :tbody-tr-attr="styleForRow"
          sort-by="total"
          :sort-desc="true"
          :per-page="startUrlPagination.itemsPerPage"
          :current-page="startUrlPagination.currentPage"
          show-empty
          empty-text="No chats with a captured start url found"
          small
          hover
          details-td-class="non-hover-details"
          @row-clicked="toggleDetails"
        >
          <template #row-details="row">
            <div class="mx-5">
              <b-table
                :items="row.item.flowStats"
                :fields="innerUrlTableFields"
                sort-by="count"
                :sort-desc="true"
                show-empty
                empty-text="No flow nodes visited in the selected time period"
                hover
                small
                sticky-header
              >
                <template #cell(fraction)="data">
                  <b-progress max="100">
                    <b-progress-bar
                      :value="data.item.fraction"
                      :label="data.item.fraction >= 15 ? `${data.item.fraction.toFixed(0)}%` : ''"
                    />
                    <b-progress-bar
                      :value="100 - data.item.fraction"
                      variant="secondary"
                      :label="data.item.fraction < 15 ? `${data.item.fraction.toFixed(0)}%` : ''"
                    />
                  </b-progress>
                </template>
                <template #cell(viewConversations)="data">
                  <b-btn
                    v-b-tooltip.hover.noninteractive.viewport
                    title="View conversations in new tab"
                    size="sm"
                    class="mr-1"
                    variant="primary"
                    @click="viewNodeConversations(row.item.startUrl, data.item.nodeId)"
                  >
                    <font-awesome-icon icon="external-link-alt" />
                  </b-btn>
                </template>
              </b-table>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="startUrlPagination.currentPage"
          :total-rows="startUrlTableItemsFiltered.length"
          :per-page="startUrlPagination.itemsPerPage"
          size="sm"
        />
      </b-card>
    </b-overlay>

    <b-modal
      id="nodeDetailsGroupModal"
      :title="`${nodeDetailsGroupModal.isEdit ? 'Edit' : 'Add new'} node group`"
      @hide="clearModalVars"
    >
      <b-form-group label="Group name">
        <b-form-input
          v-model="nodeDetailsGroupModal.newGroupName"
          :state="$v.nodeDetailsGroupModal.newGroupName.$invalid ? false : null"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.nodeDetailsGroupModal.newGroupName.required">
            Group name is required.
          </div>
          <div v-else>
            Group name must be unique.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Node name"
        label-for="addKPINodeName"
        description="After adding a node group you will need to recompute statistics for it to appear."
      >
        <chip-list
          :completions="nodeNamesForAutoCompletion"
          :value="nodeDetailsGroupModal.nodeNames"
          placeholder="Add parent"
          @input="setGroupNodes"
        />
      </b-form-group>
      <template #modal-footer>
        <b-row no-gutters class="w-100">
          <b-col>
            <b-button v-if="nodeDetailsGroupModal.isEdit" variant="danger" @click="deleteNodeDetailsGroup(nodeDetailsGroupModal.id)">
              Delete group
            </b-button>
          </b-col>
          <b-col cols="auto">
            <b-button class="mr-3" @click="$bvModal.hide('nodeDetailsGroupModal')">
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="$v.nodeDetailsGroupModal.$invalid"
              @click="nodeDetailsGroupModalOkClicked"
            >
              {{ nodeDetailsGroupModal.isEdit ? 'Update' : 'Add' }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>

import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ChipList from 'supwiz/components/ChipList.vue';
import CompletionInput from 'supwiz/components/CompletionInput.vue';
import NewKPICard from 'supwiz/components/NewKPICard.vue';
import TooltippedText from '@/components/TooltippedText.vue';
import NodeStatsNodeTable from '@/pages/Statistics/NodeStatsNodeTable.vue';
import NodeGridRow from '@/pages/Statistics/NodeGridRow.vue';
import MinMaxInput from '@/components/MinMaxInput.vue';
import { sortedNodeDetailsChildren } from '@/js/utils';
import { isExternalStatsEnabled } from '@/js/featureFlags';
import botManipulationMixin from '@/mixins/BotManipulationMixin';
import MultiSelect from 'supwiz/components/MultiSelect.vue';

export default {
  name: 'StatisticsDetails',
  components: {
    NodeStatsNodeTable,
    TooltippedText,
    MinMaxInput,
    ChipList,
    CompletionInput,
    NewKPICard,
    NodeGridRow,
    MultiSelect,
  },
  mixins: [botManipulationMixin, validationMixin],
  data() {
    return {
      nodeDetailsGroupModal: {
        isEdit: false,
        newGroupName: '',
        nodeNames: [],
      },
      addNodeStatsNodeName: null,
      convCountConfig: { min: 0, max: 1, step: 1 },
      exportMode: false,
      selectedColumns: [
        'num_chats', 'automation_rate', 'self_service', 'partial_resolution',
        'percent_optout',
      ],
      exportColumnsOptions: [
        { text: 'Conv. count', value: 'num_chats' },
        { text: 'Auto. rate', value: 'automation_rate' },
        { text: 'Self-service', value: 'self_service' },
        { text: 'Opted out', value: 'percent_optout' },
        { text: 'Partial res.', value: 'partial_resolution' },

      ],
      startUrls: [],
      startUrlTableFields: [
        {
          key: 'startUrl',
          label: 'URL Name',
        },
        {
          key: 'total',
          label: 'Total',
        },
      ],
      innerUrlTableFields: [
        {
          key: 'nodeName',
          label: 'Node Name',
        },
        {
          key: 'count',
          label: 'Count',
        },
        {
          key: 'fraction',
          label: 'Fraction',
          thStyle: 'width: 200px',
        },
        {
          key: 'viewConversations',
          label: '',
          thStyle: 'width: 50px',
          tdClass: 'text-center',
        },
      ],
      startUrlPagination: {
        itemsPerPage: 20,
        currentPage: 1,
      },
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodesAsList',
      'specialNodes',
      'nameOfId',
    ]),
    ...mapGetters('statistics', [
      'getKPIData',
      'isLoaded',
      'isLoading',
      'getNodeStatsNodes',
      'getKPIDataInfo',
      'getKPICardValue',
      'getHighestChatCount',
      'getNodeDetailsData',
      'filterOutNode',
      'nodeDetailsLabels',
      'getNodeVisits',
      'getNodeDetailsGroups',
      'nodeDetailsGroupData',
    ]),
    ...mapState('statistics', ['isFetchingNodeDetailsGroups']),
    ...mapGetters('nodeInterpretations', ['flowNodes']),
    ...mapGetters('statistics/filters', [
      'statsFilter',
      'nodesFilter',
      'activeSorting',
    ]),
    ...mapGetters('chatlogs', ['availableStartUrls']),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    filterNodeNames: {
      get() {
        return this.nodesFilter.names;
      },
      set(value) {
        this.updateNodeFilter({ prop: 'names', value });
      },
    },
    nodeNamesForStatsAutoCompletion() {
      const allNodes = this.specialNodes.concat(this.nodesAsList);
      return allNodes.filter(
        (node) => !this.getNodeStatsNodes.find((entry) => entry.id === node.id),
      ).map((x) => x.name);
    },
    nodeRatingsReady() {
      return this.isLoaded('node_ratings');
    },
    getNodeRatings() {
      let nodeRatings;
      if (this.nodeRatingsReady) {
        const numChats = this.getKPIDataInfo('num_chats');
        const kpiData = this.getKPIDataInfo('node_ratings');
        nodeRatings = kpiData.filter(
          (elem) => this.getNodeStatsNodes.find((entry) => entry.id === elem.id),
        ).map((elem) => ({
          name: this.nameOfId(elem.id),
          id: elem.id,
          count: Math.min(elem.count, numChats.actual),
          rating: Math.min(elem.rating, 100),
          favorite: this.getNodeStatsNodes.find((entry) => entry.id === elem.id).favorite,
        }));
      } else {
        nodeRatings = this.getNodeStatsNodes.map(({ id, favorite }) => ({
          id,
          name: this.nameOfId(id),
          favorite,
        }));
      }
      return nodeRatings;
    },
    nodeNamesForAutoCompletion() {
      return this.nodesAsList.map((x) => x.name);
    },
    hasNodeDetailsData() {
      return !!Object.values(this.nodeDetailsGroupData || {}).length;
    },
    getNameWidth() {
      const baseWidth = this.isExternalStatsEnabled() ? '30%' : '48%';
      const exportWidth = this.isExternalStatsEnabled() ? '25%' : '43%';

      return `padding-left: ${this.indentation}px; width: ${this.exportMode ? exportWidth : baseWidth};`;
    },
    startUrlOptions() {
      return this.availableStartUrls ? this.availableStartUrls : [];
    },
    startUrlTableItemsAll() {
      const items = [];
      if (!this.isLoading('start_url_stats')) {
        for (const [url, data] of Object.entries(this.getKPIDataInfo('start_url_stats'))) {
          const flowStats = [];
          const flowTotal = Object.values(data.flow_stats).map((x) => x.count).reduce(
            (accumulator, currentValue) => accumulator + currentValue, 0,
          );
          for (const [nodeId, nodeFlowStats] of Object.entries(data.flow_stats)) {
            if (nodeFlowStats.count > 0) {
              flowStats.push({
                nodeId,
                nodeName: this.nodeById(nodeId).name,
                count: nodeFlowStats.count,
                fraction: (nodeFlowStats.count / flowTotal) * 100,
              });
            }
          }
          const flowStatsTop5 = flowStats.sort((a, b) => b.count - a.count).slice(0, 5);
          items.push({
            startUrl: url,
            total: data.num_chats,
            flowStats: flowStatsTop5,
          });
        }
      }
      return items;
    },
    startUrlTableItemsFiltered() {
      if (this.startUrls.length === 0) {
        return this.startUrlTableItemsAll;
      }
      return this.startUrlTableItemsAll.filter((x) => this.startUrls.includes(x.startUrl));
    },
  },
  watch: {
    getHighestChatCount(n) {
      this.convCountConfig = { min: 0, max: Math.max(n, 1), step: 1 };
      this.updateNodeFilter({ prop: 'countMin', value: 0 });
      this.updateNodeFilter({ prop: 'countMax', value: Math.max(n, 1) });
    },
  },
  mounted() {
    this.startUrls = this.startUrlOptions.map((x) => x.value);
  },
  created() {
    if (isExternalStatsEnabled()) {
      this.selectedColumns = this.selectedColumns.concat(['rating', 'rating_count']);
      this.exportColumnsOptions = this.exportColumnsOptions.concat([
        { text: 'Rating', value: 'rating' },
        { text: 'Rating count', value: 'rating_count' }]);
    }
    this.$root.$on('edit-node-group', (id) => {
      this.nodeDetailsGroupModal.id = id;
      this.nodeDetailsGroupModal.isEdit = true;
      this.nodeDetailsGroupModal.newGroupName = this.getNodeDetailsGroups[id].name;
      this.nodeDetailsGroupModal.nodeNames = this.getNodeDetailsGroups[id].node_ids
        .map((e) => this.nameOfId(e));
      this.$bvModal.show('nodeDetailsGroupModal');
    });
  },
  beforeDestroy() {
    this.$root.$off('edit-node-group');
  },
  methods: {
    sortedNodeDetailsChildren,
    isExternalStatsEnabled,
    ...mapActions('statistics', [
      'addNodeStatsNode',
      'addNodeDetailsGroup',
      'updateNodeDetailsGroup',
      'deleteNodeDetailsGroup',
    ]),
    ...mapMutations('statistics', ['setNodeChildDetailsData', 'setNodeDetailsGroupProperty']),
    ...mapActions('sidebar', ['showWarning']),
    ...mapMutations('statistics/filters', ['updateNodeFilter', 'setActiveSorting']),
    updateRange(prop, payload) {
      this.updateNodeFilter({ prop, value: payload.value });
    },
    clearModalVars() {
      this.nodeDetailsGroupModal = {
        id: null,
        isEdit: false,
        newGroupName: '',
        nodeNames: [],
      };
    },
    nodeDetailsGroupModalOkClicked() {
      if (this.nodeDetailsGroupModal.isEdit) {
        this.updateNodeDetailsGroup({
          id: this.nodeDetailsGroupModal.id,
          name: this.nodeDetailsGroupModal.newGroupName,
          node_ids: this.nodeNames2Ids(this.nodeDetailsGroupModal.nodeNames),
        });
      } else {
        this.addNodeDetailsGroup({
          name: this.nodeDetailsGroupModal.newGroupName,
          node_ids: this.nodeNames2Ids(this.nodeDetailsGroupModal.nodeNames),
        });
      }
    },
    setGroupNodes(names) {
      this.nodeDetailsGroupModal.nodeNames = names;
    },
    proxyAddNodeStatsNode() {
      this.addNodeStatsNode({ nodeName: this.addNodeStatsNodeName });
    },
    choseNodeStatsNodeName(nodeName) {
      this.addNodeStatsNodeName = nodeName;
    },
    KPINodeClicked(rowItem) {
      // Navigate to conversation logs page and pass these settings as queryparams
      this.$router.push({
        name: 'conversation-logs',
        query: {
          endDate: this.statsFilter.endDate.getTime(),
          startDate: this.statsFilter.startDate.getTime(),
          selectedLanguage: this.statsFilter.selectedLanguage,
          selectedVariant: this.statsFilter.selectedVariant,
          includeNodes: [rowItem.id],
        },
      });
    },
    exportClicked() {
      this.selectExportNodes(true);
      this.exportMode = true;
    },
    selectExportNodes(value, item = null) {
      const current = !item ? this.nodeDetailsGroupData : item;
      Object.values(current).forEach((node) => {
        if (node.isGroup) {
          this.setNodeDetailsGroupProperty({ id: node.id, field: 'selected', data: value });
        } else {
          this.setNodeChildDetailsData({ path: node.path, field: 'selected', data: value });
        }
        if (Object.keys(node.children).length) {
          this.selectExportNodes(value, node.children);
        }
      });
    },
    exportNodeDetails() {
      const rows = [['Group/Node name']];
      const selectedCols = this.exportColumnsOptions.filter((col) => this.selectedColumns
        .includes(col.value));
      selectedCols.forEach((col) => {
        this.nodeDetailsLabels.forEach((label) => {
          rows[0].push(`${col.text}: ${label}`);
        });
      });
      this.sortedNodeDetailsChildren(this.nodeDetailsGroupData, this.nameOfId, this.activeSorting)
        .forEach((item) => {
          if (!this.filterOutNode(item, item.isGroup ? item.name : this.nameOfId(item.id))
          && item.selected) {
            const row = [item.isGroup ? item.name : this.nameOfId(item.id)];
            selectedCols.forEach((col) => {
              for (let i = 0; i < this.nodeDetailsLabels.length; i++) {
                const value = item[col.value].info?.[i];
                if (col.value === 'num_chats') {
                  row.push(value);
                } else {
                  row.push(value ? value.toFixed(2).replace('.', ',') : '-');
                }
              }
            });
            rows.push(row);
            if (Object.values(item.children || {}).length && item.showChildren) {
              rows.push(...this.appendChildrenToRows(rows, item, selectedCols));
            }
          }
        });
      const DownloadHeader = 'data:text/csv;charset=utf-8,';
      const csv = `${DownloadHeader}\uFEFF${rows.map((x) => x.join(';')).join('\r\n')}`;
      const encodedUri = encodeURI(csv);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${'Node details statistics'}.csv`);
      document.body.appendChild(link);
      link.click();
    },
    appendChildrenToRows(rows, node, selectedCols) {
      const newRows = [];
      this.sortedNodeDetailsChildren(node.children, this.nameOfId, this.activeSorting)
        .forEach((item) => {
          if (!this.filterOutNode(item, item.isGroup ? item.name : this.nameOfId(item.id))
          && item.selected) {
            const row = [item.isGroup ? item.name : this.nameOfId(item.id)];
            selectedCols.forEach((col) => {
              for (let i = 0; i < this.nodeDetailsLabels.length; i++) {
                const value = item[col.value].info?.[i];
                if (col.value === 'num_chats') {
                  row.push(value);
                } else {
                  row.push(value ? value.toFixed(2).replace('.', ',') : '-');
                }
              }
            });
            newRows.push(row);
            if (Object.values(item.children || {}).length && item.showChildren) {
              newRows.push(...this.appendChildrenToRows(rows, item, selectedCols));
            }
          }
        });
      return newRows;
    },
    getSortingIcon(key) {
      if (this.activeSorting.key !== key) {
        return 'sort';
      }
      return this.activeSorting.order === 'desc' ? 'sort-down' : 'sort-up';
    },
    getSortingTooltip(key) {
      if (this.activeSorting.key !== key) {
        return 'Click to sort';
      }
      return this.activeSorting.order === 'desc' ? 'Descending' : 'Ascending';
    },
    setSorting(key) {
      if (this.activeSorting.key === key) {
        this.setActiveSorting({
          key,
          order: this.activeSorting.order === 'desc' ? 'asc' : 'desc',
        });
      } else {
        this.setActiveSorting({ key, order: 'desc' });
      }
    },
    toggleDetails(row) {
      this.$set(row, '_showDetails', !row._showDetails);
    },
    styleForRow(item, type) {
      if (type === 'row') {
        return {
          style: 'cursor:pointer',
        };
      }
      return null;
    },
    viewNodeConversations(startUrl, nodeId) {
      const query = {
        endDate: this.statsFilter.endDate.getTime(),
        startDate: this.statsFilter.startDate.getTime(),
        selectedVariant: this.statsFilter.selectedVariant,
        startUrls: [startUrl],
        includeNodes: [nodeId],
      };
      const routeData = this.$router.resolve({
        name: 'conversation-logs',
        query,
      });
      window.open(routeData.href, '_blank');
    },
  },
  validations: {
    nodeDetailsGroupModal: {
      newGroupName: {
        required,
        unique(value) {
          const otherNames = Object.values(this.getNodeDetailsGroups)
            .filter((e) => e.id !== this.nodeDetailsGroupModal.id).map((e) => e.name);
          return !otherNames.includes(value);
        },
      },
      nodeNames: {
        required,
      },
    },
  },
};

</script>
<style scoped>
::v-deep .card-header:first-child {
  border-radius: .75rem;
}
::v-deep .scrollable-card {
  overflow-y: auto;
  max-height: 400px;
  overflow-x:hidden;
}
.node-grid-col{
  border: 1px solid #eee;
  padding: 3px 3px;
}
::v-deep .node-grid-col{
  border: 1px solid #eee;
  padding: 3px 3px;
  line-height: 2;
}
::v-deep .non-hover-details {
  background: white;
}
</style>
