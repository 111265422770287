<template>
  <main
    role="main"
  >
    <b-card
      title="Classifiers"
      class="r-75"
      body-class="p-3"
    >
      <p>
        Classifiers are used for matching nodes to user queries. They may
        also be evoked on nodes for other actions such as classifying the
        best department to route.
      </p>

      <b-btn
        class="mt-3"
        variant="primary"
        @click.stop="setAddNLUModel()"
      >
        Add NLU model to bot
      </b-btn>
      <div class="text-muted small mt-1">
        Note: names of NLU models should be unique. The same name cannot be used in two
        different subflows or in both the main bot and a subflow.
      </div>
      <b-table
        hover
        style="max-height:80vh"
        responsive
        show-empty
        sticky-header
        :busy="classifiersLoading"
        empty-text="There are no classifiers assigned to this bot."
        :items="classifiersItems"
        :fields="classifierFields"
        class="mt-3 mb-0"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(version)="row">
          {{ row.item.version.description }}<br>
          {{ toDatetimeFilter(row.item.version.createdTime) }}
        </template>

        <template #cell(actions)="row">
          <b-btn-group>
            <b-btn
              v-b-tooltip.hover.noninteractive.viewport
              class="action-button"
              variant="primary"
              title="View NLU model in NLU module"
              @click="goToNLUVersion(row.item.modelId)"
            >
              <font-awesome-icon icon="external-link-alt" />
            </b-btn>
            <b-btn
              v-b-tooltip.hover.noninteractive.viewport
              class="action-button"
              title="Edit classifier"
              variant="primary"
              @click="setEditNLUModelIndex(row.item.modelId)"
            >
              <font-awesome-icon icon="edit" />
            </b-btn>
            <b-btn
              v-b-tooltip.hover.noninteractive.viewport
              title="Nodes"
              class="action-button"
              variant="info"
              @click="showNodes(row.item.modelId)"
            >
              <font-awesome-icon icon="dot-circle" />
            </b-btn>
            <b-btn
              v-b-tooltip.hover.noninteractive.viewport
              title="Remove classifiers"
              variant="danger"
              class="action-button"
              @click="removeNLUModel(row.item.modelId)"
            >
              <font-awesome-icon icon="trash-alt" />
            </b-btn>
          </b-btn-group>
        </template>
      </b-table>
    </b-card>

    <b-card
      v-if="isMainBot"
      title="Neutral examples"
      class="r-75 mt-3"
      body-class="p-3"
    >
      <p class="mb-2">
        Neutral examples are neutral pieces of text that the bot uses to find the right matches when
        it's using 'Use Examples' in the 'Text Matching' feature, instead of the 'Classifiers'
        approach.

        These neutral examples should capture the kind of everyday language that the users usually
        use when they're talking to the bot.
      </p>
      <hr>
      <b-list-group class="mt-2">
        <b-list-group-item
          v-for="(file, idx) in neutralData.files"
          :key="idx"
          button
          @click.prevent="showFileNeutralsModal(idx)"
        >
          <b-container>
            <b-row class="align-items-center">
              <b-col>
                <strong>
                  {{ toNameFilter(file) }}
                </strong>
              </b-col>
              <b-col md="auto">
                <b-button
                  variant="danger"
                  size="sm"
                  @click.stop="removeNeutralsFile(idx)"
                >
                  Remove
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-list-group-item>
      </b-list-group>
      <b-btn
        v-b-modal.upload-neutrals-modal
        variant="primary"
        class="mt-2"
      >
        Upload file
      </b-btn>
      <b-alert
        v-model="neutralsSuccess"
        variant="success"
        dismissible
      >
        Upload success!
      </b-alert>
      <b-alert
        v-model="neutralsAlert"
        variant="danger"
        dismissible
      >
        Upload failed. Make sure that the file is utf-8 encoded.
      </b-alert>
    </b-card>

    <b-modal
      id="addNLUModelModal"
      ref="addNLUModelModal"
      :title="(isEditing ? 'Edit' : 'Add') + ' NLU Model'"
      ok-title="Save"
      :ok-disabled="$v.modal.$invalid"
      @ok="addNLUModel"
      @shown="focusOnNluNameInput"
    >
      <b-form>
        <b-form-group
          label="Name"
          label-for="nameForm"
        >
          <b-input
            id="nameForm"
            ref="nluNameInput"
            v-model="modal.nluModelName"
            :disabled="nameDisabled"
            placeholder="Give the NLU model a name"
            :state="!$v.modal.nluModelName.$invalid"
          />
          <b-form-invalid-feedback id="nonUniqueNLUNameFeedback">
            <div v-if="!$v.modal.nluModelName.nameIsUnique">
              The name must be unique among the NLU models assigned to this bot.
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label="Classifier"
        >
          <b-form-select
            v-model="modal.nluModelId"
            :options="nluModelOptions"
            :state="!$v.modal.nluModelId.$invalid"
            :disabled="isEditing && nluModelInModalIsNodeClassifier"
          />
          <span
            v-if="nluModelInModalIsNodeClassifier"
            class="small text-muted"
          >
            This NLU model is bound to the node "{{ nameOfId(nluModelInModal.nodeId) }}".
          </span>
        </b-form-group>
        <b-form-group
          v-if="modal.nluModelId"
          label="Version"
        >
          <b-form-select
            v-model="modal.nluModelVersionId"
            :options="nluModelVersions"
            :state="!$v.modal.nluModelVersionId.$invalid"
          />
        </b-form-group>

        <b-form-group
          v-if="showAutoUpdate"
          label="Auto update"
          description="Automatically update version when a bettter one is trained. Automatic
            updates will not be made if labels have been removed from the classifier."
        >
          <b-form-checkbox
            v-model="modal.nluModelAutoUpdate"
            switch
          />
        </b-form-group>

        <b-card
          v-if="nodesToBeUpdated.length > 0"
          border-variant="warning"
        >
          Setting this classifier will change matching on the following nodes:
          <b-list-group class="mt-2">
            <b-list-group-item
              v-for="(nodeId, idx) in nodesToBeUpdated"
              :key="idx"
            >
              {{ nameOfId(nodeId) }}
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-form>
    </b-modal>

    <b-modal
      id="show-nodes-modal"
      title="Use of classifier on nodes"
      ok-only
    >
      <b-list-group v-if="nluModelNodes.length">
        <b-list-group-item
          v-for="node in nluModelNodes"
          :key="node.id"
          button
          @click="goToNode(node.id)"
        >
          {{ node.name }}
        </b-list-group-item>
      </b-list-group>
      <div v-else>
        The classifier is not in use on any nodes.
      </div>
    </b-modal>

    <b-modal
      id="upload-neutrals-modal"
      title="Upload neutrals"
      ok-title="Upload"
      @ok="uploadNeutrals"
      @shown="focusNeutralTitle"
    >
      <b-form-group
        label="Name"
        label-for="nameForm"
      >
        <b-form-input
          id="nameForm"
          ref="neutralTitleInput"
          v-model="neutralsName"
          placeholder="Some name..."
        />
      </b-form-group>
      <b-form-group
        label="Neutral text examples"
        label-for="neutralsFileForm"
        description="The file should be a utf-8 encoded text-file with one neutral
        example per line."
      >
        <b-form-file
          id="neutralsFileForm"
          v-model="neutralsFile"
          placeholder="Choose file..."
        />
      </b-form-group>
    </b-modal>

    <b-modal
      ref="neutrals-file-modal"
      size="xl"
      ok-only
    >
      <b-list-group>
        <b-list-group-item
          v-for="(text, ii) in neutralsShowObj.text"
          :key="ii"
        >
          {{ text }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </main>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import { percentageFormatter } from 'supwiz/util/formatters';
import { toNameFilter, toDatetimeFilter } from '@/js/vuefilters';
import { nluModelInUse } from '@/js/utils';
import endpoints from '@/js/urls';

export default {
  name: 'EditNLUs',
  mixins: [validationMixin],
  data() {
    return {
      modal: {
        nluModelName: '',
        nluModelId: null,
        nluModelVersionId: null,
        nluModelEditIndex: null,
        nluModelAutoUpdate: true,
      },
      nluModelNodes: [],
      classifierFields: [
        { key: 'name', sortable: true },
        { key: 'model', sortable: true },
        { key: 'version', sortable: true },
        { key: 'accuracy', sortable: true },
        { key: 'usage', sortable: true },
        { key: 'actions', tdClass: 'action-col', thClass: 'action-col' },
      ],
      classifiersItems: [],
      classifiersLoading: true,
      neutralsName: null,
      neutralsFile: null,
      neutralsAlert: false,
      neutralsSuccess: false,
      neutralsShowObj: { text: [], name: '' },
    };
  },
  computed: {
    ...mapState('botManipulation/activeBot/config', [
      'name',
      'multipleChoice',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nameOfId',
      'neutralData',
    ]),
    ...mapGetters('nlu/classifier', [
      'getGlobalNLUModels',
      'getGlobalNLUModelById',
      'getModelVersionForModel',
      'isModelsLoading',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'getNLUModels',
      'isMainBot',
    ]),
    activeBot() {
      return this.$store.state.botManipulation.activeBot;
    },
    nluModelNames() {
      return [''].concat(this.getNLUModels.map((model) => model.name));
    },
    nluModelOptions() {
      let models = Object.values(this.getGlobalNLUModels);
      models = models.filter((x) => !(x.botId && x.botId !== this.activeBot.id));
      if (this.isEditing) {
        if (this.nluModelInModalIsNodeClassifier) {
          models = models.filter((x) => x.id === this.nluModelInModal.id);
        } else {
          models = models.filter((x) => !x.nodeId);
        }
      } else {
        const currentModels = new Set(this.getNLUModels.map((x) => x.modelId));
        models = models.filter((x) => !currentModels.has(x.id));
      }
      const options = models.map((x) => ({ value: x.id, text: x.displayName }));
      // Adding null-value as a default value. Also required to make dropdown work in Safari.
      if (!this.isEditing) {
        options.unshift({
          value: null,
          text: 'Select NLU model',
        });
      }
      return options;
    },
    nluModelVersions() {
      if (!this.nluModelInModal) {
        return [];
      }
      const versionList = Object.values(this.nluModelInModal.versions).filter(
        (v) => !('task_success' in v && !v.task_success),
      );
      return versionList.map(
        (v) => ({
          value: v.id,
          text: `${v.description} - ${toDatetimeFilter(v.createdTime)}`,
        }),
      );
    },
    nodesToBeUpdated() {
      const model = this.nluModelInModal;
      const modelVersion = this.modal.nluModelVersionId;
      if (!(model && modelVersion && model.nodeId)) {
        return [];
      }
      const nodes = this.activeBot.nodes;
      const toUpdate = model.versions[modelVersion].labels.filter(
        (nodeId) => {
          if (nodeId in nodes) {
            const nodeModel = nodes[nodeId].match.nluModel;
            return nodeModel.name !== model.name || nodeModel.label !== nodeId;
          }
          return false;
        },
      );
      return toUpdate;
    },
    nluModelInModal() {
      return this.modal.nluModelId ? this.getGlobalNLUModels[this.modal.nluModelId] : null;
    },
    nluModelInModalIsNodeClassifier() {
      return this.nluModelInModal ? Boolean(this.nluModelInModal.nodeId) : null;
    },
    isEditing() {
      return this.modal.nluModelEditIndex !== null;
    },
    nameDisabled() {
      if (this.isEditing) {
        return true;
      }
      if (this.nluModelInModalIsNodeClassifier) {
        return true;
      }
      return false;
    },
    nameToModel() {
      const nameToModel = {};
      this.getNLUModels.forEach((x) => {
        nameToModel[x.name] = x;
      });
      return nameToModel;
    },
    showAutoUpdate() {
      if (this.modal.nluModelId) {
        return this.getGlobalNLUModels[this.modal.nluModelId].type === 'swml.bot';
      }
      return false;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'modal.nluModelId': function (newVal) {
      if (!this.isEditing && newVal) {
        const nodeId = this.getGlobalNLUModelById(newVal).nodeId;
        if (nodeId) {
          this.modal.nluModelName = `${this.name}: ${this.nameOfId(nodeId)}`;
        }
      }
    },
    getNLUModels() {
      this.fetchGlobalNLUModels();
    },
  },
  mounted() {
    this.fetchGlobalNLUModels();
    this.fetchClassifiers();
  },
  methods: {
    ...mapActions('botManipulation/activeBot/config', [
      'setNLUModels',
    ]),
    ...mapActions('nlu/classifier', ['fetchGlobalNLUModels']),
    ...mapActions('sidebar', ['showWarning']),
    toNameFilter,
    toDatetimeFilter,
    addNLUModel() {
      const modelsCopy = this.getNLUModels.slice(0);
      const modelName = this.modal.nluModelName;
      const modelId = this.modal.nluModelId;
      const modelVersion = this.modal.nluModelVersionId;
      const autoUpdate = this.modal.nluModelAutoUpdate;
      const modelType = this.nluModelInModal.type;
      const labels = this.nluModelInModal.versions[modelVersion].labels;
      if (!this.isEditing) {
        // Add new model
        modelsCopy.push({
          name: modelName,
          modelId,
          modelVersion,
          autoUpdate,
          type: modelType,
          labels,
        });
        this.setNLUModels({ models: modelsCopy, idUpdated: modelId });
      } else {
        const usedInNodes = nluModelInUse(this.activeBot.nodes, modelName);
        let success = true;
        const missingLabels = [];
        for (const node of usedInNodes) {
          // if model is used for matching on node, then check that the new
          // model has the relevant label
          if (node.match.nluModel.name === modelName
              && !labels.includes(node.match.nluModel.label)) {
            if (!missingLabels.includes(node.match.nluModel.label)) {
              let labelDisplayName;
              if (this.nluModelInModal.nodeId) {
                labelDisplayName = node.name;
              } else {
                labelDisplayName = node.match.nluModel.label;
              }
              missingLabels.push(labelDisplayName);
            }
            success = false;
          }
        }
        if (!success) {
          this.showWarning({
            title: 'An error occured',
            text: `The bot uses a label that is not supported by the
              new model. No changes have been made. Labels missing
              are: ${missingLabels.join(', ')}`,
            variant: 'danger',
          });
          return;
        }
        if (modelName === this.multipleChoice.yesNoModelName) {
          const yesLabel = this.multipleChoice.yesLabel;
          const noLabel = this.multipleChoice.noLabel;
          if (!labels.includes(yesLabel) || !labels.includes(noLabel)) {
            this.showWarning({
              title: 'An error occured',
              text: `The classifier is used as confirmation classifier,
              and the new version does not include the same "yes" and "no" labels.
              You must either ensure that the new version has the same labels or
              unattach the classifier under "Config/Smart nodes" before continuing.`,
              variant: 'danger',
            });
            success = false;
          }
        }
        if (success) {
          modelsCopy[this.modal.nluModelEditIndex] = {
            name: modelName,
            modelId,
            modelVersion,
            autoUpdate,
            type: modelType,
            labels,
          };
          this.setNLUModels({ models: modelsCopy, idUpdated: modelId });
        }
      }
      this.modal.nluModelName = '';
      this.modal.nluModelId = null;
      this.modal.nluModelVersionId = null;
      this.modal.nluModelAutoUpdate = true;
      this.fetchClassifiers();
    },
    async removeNLUModel(id) {
      const index = this.getModelIndex(id);
      const modelsCopy = this.getNLUModels.slice(0);
      const modelName = modelsCopy[index].name;
      const usedInNodes = nluModelInUse(this.activeBot.nodes, modelName);
      const confirmQuestion = 'Are you sure you want to remove this model from the bot?';
      if (usedInNodes.length > 0) {
        const usedInNodeNamesCropped = usedInNodes.slice(0, 3).map((x) => x.name);
        this.showWarning({
          title: 'An error occured',
          text: `The model you are trying to delete is used by the bot
            in ${usedInNodes.length} nodes including "${usedInNodeNamesCropped}" and
            can therefore not be removed.`,
          variant: 'danger',
        });
      } else if (modelName === this.multipleChoice.yesNoModelName) {
        this.showWarning({
          title: 'An error occured',
          text: `The classifier is used as confirmation classifier
            on smart nodes. You must first unattach the classifier under "Config/Smart
            nodes" before deleting. No changes have been made.`,
          variant: 'danger',
        });
      } else if (await this.$bvModal.msgBoxConfirm(confirmQuestion)) {
        modelsCopy.splice(index, 1);
        this.setNLUModels({ models: modelsCopy });
      }
      this.fetchClassifiers();
    },
    setEditNLUModelIndex(id) {
      const index = this.getModelIndex(id);
      const nluModelCopy = this.getNLUModels[index];
      this.modal.nluModelName = nluModelCopy.name;
      this.modal.nluModelId = nluModelCopy.modelId;
      this.modal.nluModelVersionId = nluModelCopy.modelVersion;
      this.modal.nluModelAutoUpdate = nluModelCopy.autoUpdate;
      this.modal.nluModelEditIndex = index;
      this.$bvModal.show('addNLUModelModal');
    },
    showNodes(id) {
      const index = this.getModelIndex(id);
      const modelName = this.getNLUModels[index].name;
      this.nluModelNodes = nluModelInUse(this.activeBot.nodes, modelName);
      this.$bvModal.show('show-nodes-modal');
    },
    setAddNLUModel() {
      this.modal.nluModelName = '';
      this.modal.nluModelId = null;
      this.modal.nluModelVersionId = null;
      this.modal.nluModelEditIndex = null;
      this.modal.nluModelAutoUpdate = false;
      this.$bvModal.show('addNLUModelModal');
    },
    getModelIndex(id) {
      return this.getNLUModels.indexOf(this.getNLUModels.find((e) => e.modelId === id));
    },
    getModelVersionForModelSafe(modelId, modelVersionId) {
      let model = this.getModelVersionForModel(modelId, modelVersionId);
      if (!model) {
        model = {
          name: undefined,
          description: undefined,
          createdTime: undefined,
        };
      }
      return model;
    },
    goToNode(nodeId) {
      this.$router.push({ name: 'edit-node', params: { nodeId } });
    },
    goToNLUVersion(id) {
      const index = this.getModelIndex(id);
      const modelId = this.getNLUModels[index].modelId;
      this.$router.push({ name: 'nlu-model-page', params: { modelId } });
    },
    focusOnNluNameInput() {
      this.$refs.nluNameInput.focus();
    },
    fetchClassifiers() {
      // this is used to unclogg the page renering and display loading properly
      setTimeout(() => {
        if (this.isModelsLoading) {
          setTimeout(this.fetchClassifiers, 100);
          return;
        }
        this.classifiersLoading = true;
        this.classifiersItems = [];
        try {
          this.getNLUModels.forEach(async (element) => {
            let version = null;
            if (element.modelVersion) {
              const resp = await axios.get(`${endpoints.classifierVersion}/${element.modelVersion}/`, {
                headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
              });
              version = resp.data;
            } else {
              this.showWarning({
                title: 'An error occured',
                text: `Classifier ${element.name} is missing model version`,
                variant: 'danger',
              });
            }
            const usedInNodes = nluModelInUse(this.activeBot.nodes, element.name);
            this.classifiersItems
              .push({
                name: element.name,
                model: toNameFilter(this.getGlobalNLUModelById(element.modelId)),
                modelId: element.modelId,
                version: {
                  description: this.getModelVersionForModelSafe(
                    element.modelId, element.modelVersion,
                  ).description,
                  createdTime: this.getModelVersionForModelSafe(
                    element.modelId, element.modelVersion,
                  ).createdTime,
                },
                modelVersion: element.modelVersion,
                accuracy: version ? percentageFormatter(version.classifierversionbot
                  ? version.classifierversionbot.accuracy : null) : null,
                usage: usedInNodes.length,
              });
          });
          this.classifiersLoading = false;
        } catch {
          this.classifiersLoading = false;
        }
      }, 0);
    },
    async uploadNeutrals() {
      // in case no name is given use provided filename
      if (!this.neutralsName) {
        this.neutralsName = this.neutralsFile.name.indexOf('.') > 0 ? this.neutralsFile.name.slice(0, this.neutralsFile.name.lastIndexOf('.'))
          : this.neutralsFile.name;
      }
      const formData = new FormData();
      formData.append('neutrals_name', this.neutralsName);
      formData.append('neutrals_file', this.neutralsFile);
      try {
        const resp = await axios.put(
          endpoints.botsBase + this.$store.state.botManipulation.activeBot.id,
          formData,
          {
            headers: {
              Authorization: `JWT ${this.$store.state.auth.jwt}`,
            },
          },
        );
        if (resp.data.success) {
          this.neutralsAlert = false;
          this.neutralsSuccess = true;
        } else {
          this.neutralsAlert = true;
          this.neutralsSuccess = false;
        }
      } catch {
        this.neutralsAlert = true;
        this.neutralsSuccess = false;
      }
      this.neutralsName = null; // set filename back to null
    },
    removeNeutralsFile(idx) {
      this.$store.dispatch('botManipulation/activeBot/removeNeutralsFile', idx);
    },
    showFileNeutralsModal(idx) {
      this.neutralsShowObj = this.neutralData.files[idx];
      this.$refs['neutrals-file-modal'].show();
    },
    focusNeutralTitle() {
      this.$refs.neutralTitleInput.focus();
    },
  },
  validations: {
    modal: {
      nluModelId: {
        required,
        existsInOptions(value) {
          return this.nluModelOptions.map((v) => v.value).includes(value);
        },
      },
      nluModelName: {
        required,
        nameIsUnique(value) {
          return this.isEditing || !this.nluModelNames.includes(value);
        },
      },
      nluModelVersionId: {
        required,
        existsInOptions(value) {
          return this.nluModelVersions.map((v) => v.value).includes(value);
        },
      },
    },
  },
};
</script>
<style scoped>
  ::v-deep .action-col{
    width:20px !important;
  }
  .action-button{
    padding-left:10px;
    padding-right:10px;
  }
</style>
