<template>
  <main
    role="main"
  >
    <b-card
      class="r-75"
      body-class="p-3"
    >
      <div v-if="!nodeExists">
        <b-row no-gutters>
          <b-col class="my-auto">
            The requested node does not exist.
          </b-col>
          <b-col>
            <b-btn
              variant="primary"
              class="float-right"
              @click="goBack"
            >
              <font-awesome-icon icon="arrow-left" />
              Back
            </b-btn>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col cols="12">
            <b-alert
              v-if="isUserLimited"
              show
              variant="warning"
            >
              Given your permissions, this page is view-only. Any changes you make here are not
              stored.<br>
              Contact your administrator if you need write-access to this page.
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="auto"
            class="my-auto pr-0"
          >
            <b-button
              v-b-tooltip.hover.noninteractive.viewport
              :variant="isFlowNode(nodeId) ? 'primary' : 'secondary'"
              :title="isFlowNode(nodeId) ? 'Remove node from flow nodes' : 'Add node to flow nodes'"
              @click="toggleFlowNode(nodeId)"
            >
              <font-awesome-icon icon="sitemap" />
            </b-button>
          </b-col>
          <b-col class="pr-0">
            <b-form-input
              v-model="nodeName"
              style="border: none; font-size: x-large;"
            />
          </b-col>
          <b-col
            cols="auto"
            class="my-auto"
          >
            <b-btn
              v-if="isSuperUser"
              v-b-tooltip.hover
              @click="showHistory"
            >
              Show history
            </b-btn>
            <b-btn
              v-b-tooltip.hover
              class="ml-1"
              title="Ctrl-Click to zoom"
              :disabled="isDisabled"
              @click.exact="showInGraphView(false)"
              @click.ctrl.exact="showInGraphView(true)"
            >
              Show in graph
            </b-btn>
            <b-btn
              v-b-tooltip.hover
              class="ml-1"
              title="Ctrl-Click to zoom"
              :disabled="isLoading || isDisabled"
              @click.exact="showInTreeView(false, false)"
              @click.ctrl.exact="showInTreeView(false, true)"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              {{ isLoading ? 'Loading tree' : 'Show in tree' }}
            </b-btn>
            <b-btn
              class="ml-1"
              variant="primary"
              @click="goBack"
            >
              <font-awesome-icon icon="arrow-left" />
              Back
            </b-btn>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span v-if="!isMainBot && activeNode.id === 'entrypoint'">
              <small style="color: #f50">This is the entry point node of the subflow</small>
            </span>
            <node-edit-comments
              class="mt-1 bg-white"
              :node-id="nodeId"
            />
            <b-alert
              :show="!hasUniqueNodeName"
              variant="warning"
              class="font-weight-bold mb-0"
            >
              Node name is not unique
            </b-alert>
            <b-alert
              :show="isDisabled"
              variant="warning"
              class="mb-0"
            >
              This node is currently disabled.
            </b-alert>

            <b-alert
              v-if="isSubflowNode"
              :show="true"
              variant="info"
              class="mb-0"
            >
              This node is an instance of the
              <router-link
                class="font-weight-bold"
                style="color:black"
                :to="{
                  name: 'flow',
                  params: { botId: `${activeBotId}-${activeNode.subFlowMap.subFlowID}` },
                }"
              >
                {{ getSubFlowName(activeNode.subFlowMap.subFlowID) }}
              </router-link>
              subflow
            </b-alert>
          </b-col>
        </b-row>

        <b-list-group class="mt-3">
          <node-edit-contexts :node-id="nodeId" />
          <node-edit-match
            v-if="!isSubflowNode && !isSpecialNode(activeNode) && !isRoot"
            :node-id="nodeId"
          />
          <b-list-group-item
            v-if="!isSubflowNode"
            class="p-2 border"
          >
            <node-edit-activities :node-id="nodeId" />
          </b-list-group-item>
          <b-list-group-item class="p-2 border mb-1">
            <node-edit-options :node-id="nodeId" />
          </b-list-group-item>
        </b-list-group>

        <b-button
          v-b-tooltip.hover.noninteractive.viewport
          class="mr-2"
          :disabled="!hasParents"
          :title="hasParents ? '' : 'This node cannot be disabled'"
          @click="toggleDisability"
        >
          {{ isDisabled ? 'Enable' : 'Disable' }} node
        </b-button>
        <b-button
          v-b-modal.delete-node-modal
          v-b-tooltip.hover.noninteractive.viewport
          variant="danger"
          :disabled="disableDelete"
          :title="!disableDelete ? '' : 'Greet and special nodes cannot be deleted'"
        >
          <font-awesome-icon
            icon="trash-alt"
            class="mr-1"
          />
          Delete
        </b-button>
      </div>
    </b-card>
    <delete-node-modal :node-id="nodeId" @delete="deleteNode" />
  </main>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import NodeEditContexts from '@/pages/EditNode/NodeEditContexts.vue';
import NodeEditMatch from '@/pages/EditNode/NodeEditMatch.vue';
import NodeEditOptions from '@/pages/EditNode/NodeEditOptions.vue';
import NodeEditComments from '@/pages/EditNode/NodeEditComments.vue';
import NodeEditActivities from '@/pages/EditNode/NodeEditActivities.vue';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import { nodeTypes } from '@/js/constants';
import DeleteNodeModal from '@/components/DeleteNodeModal.vue';

export default {
  name: 'EditNodePage',
  components: {
    DeleteNodeModal,
    NodeEditActivities,
    NodeEditOptions,
    NodeEditMatch,
    NodeEditContexts,
    NodeEditComments,
  },
  beforeRouteEnter(to, from, next) {
    // Some nodes are fake nodes, that you should not edit
    if (to.params.nodeId === 'init') {
      next({ name: 'config', params: { botId: to.params.botId }, hash: '#Initialization' });
      return;
    }
    if (to.params.nodeId === 'final') {
      next({ name: 'config', params: { botId: to.params.botId }, hash: '#Final-Activities' });
      return;
    }
    if (to.params.nodeId === 'elaboration') {
      next({ name: 'config', params: { botId: to.params.botId }, hash: '#fallback-behavior' });
      return;
    }
    if (to.params.nodeId === 'qa') {
      next({ name: 'Q&A', params: { botId: to.params.botId } });
      return;
    }
    next();
  },
  beforeRouteUpdate(to, from, next) {
    this.$bvModal.hide('delete-node-modal');
    // Some nodes are fake nodes, that you should not edit
    if (to.params.nodeId === 'init') {
      next({ name: 'config', params: { botId: to.params.botId }, hash: '#Initialization' });
      return;
    }
    if (to.params.nodeId === 'final') {
      next({ name: 'config', params: { botId: to.params.botId }, hash: '#Final-Activities' });
      return;
    }
    if (to.params.nodeId === 'elaboration') {
      next({ name: 'config', params: { botId: to.params.botId }, hash: '#fallback-behavior' });
      return;
    }
    next();
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapState('botManipulation', ['activeBotId']),
    ...applyThisArgs(
      mapGetters('botManipulation/activeBot', {
        activeNode: 'nodeById',
        hasUniqueNodeName: 'isNodeNameUnique',
        isDisabled: 'isDisabled',
        isRoot: 'isRoot',
        isGreetNode: 'isGreetNode',
      }),
      'nodeId',
    ),
    ...mapGetters('botManipulation/activeBot', ['isSpecialNode', 'getAllPaths']),
    ...mapGetters('botManipulation', ['getSubFlows']),
    ...mapGetters('auth', ['isUserLimited']),
    ...mapGetters('botManipulation/activeBot/config', ['isMainBot']),
    ...mapGetters('nodeInterpretations', ['isFlowNode']),
    ...mapGetters('auth', ['isSuperUser']),
    nodeId() {
      return this.$route.params.nodeId;
    },
    nodeExists() {
      return this.activeNode !== undefined;
    },
    activeBot() {
      return this.$store.state.botManipulation.activeBot;
    },
    isSubflowNode() {
      return this.activeNode.options.nodeType === nodeTypes.SUBFLOW;
    },
    disableDelete() {
      if (this.isGreetNode || this.isSpecialNode(this.activeNode)) {
        return true;
      }
      return false;
    },
    hasParents() {
      return !!this.activeNode.preds.length;
    },
    nodeName: {
      get() {
        return this.activeNode.name;
      },
      set(value) {
        this.$store.dispatch('botManipulation/activeBot/updateNames', { id: this.nodeId, value });
      },
    },
    getSubFlowName() {
      return (subFlowID) => this.getSubFlows.filter((x) => x.id === subFlowID)[0].config.name;
    },
  },
  methods: {
    ...mapActions('treeView', ['showPathsProxy']),
    ...addThisArgs(mapMutations('botManipulation/activeBot', ['setIsDisabled']), { id: 'nodeId' }),
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('nodeInterpretations', ['toggleFlowNode']),
    ...mapMutations('graphView', ['setHighlightEditNodeId', 'setTopNodeId', 'setPaths']),
    goBack() {
      this.$router.go(-1);
    },
    async deleteNode() {
      this.$store.dispatch('botManipulation/activeBot/removeNode', { id: this.nodeId });
      this.goBack();
    },
    showHistory() {
      this.$router.push({
        name: 'history',
        hash: `#${this.nodeId}`,
      });
    },
    showInGraphView(zoom) {
      if (this.activeNode.options.global && ![nodeTypes.QA, nodeTypes.SMALLTALK]
        .includes(this.activeNode.options.nodeType)) {
        this.setTopNodeId(this.nodeId);
        this.$router.push({ name: 'graph' });
      } else if (this.activeNode.preds.filter((e) => e !== this.nodeId).length === 0
        && !this.isRoot) {
        this.showWarning({
          title: 'An error occurred',
          text: 'The node is detached, so it cannot be shown in the graph.',
          variant: 'danger',
        });
      } else if (zoom) {
        this.setTopNodeId(this.nodeId);
        this.$router.push({ name: 'graph' });
      } else {
        const { paths, unfinished } = this.getAllPaths(this.nodeId);
        if (typeof paths === 'string') {
          this.setPaths([[paths]]);
        } else {
          this.setPaths(paths);
        }
        this.setHighlightEditNodeId(this.nodeId);
        this.$router.push({ name: 'graph' });
        if (unfinished) {
          this.showWarning({
            title: 'Maximum graph volume exceeded',
            text: 'Only some paths to the node will be shown.',
            variant: 'warning',
          });
        }
      }
    },
    showInTreeView(shortest = true, zoom = false) {
      this.isLoading = true;
      const { paths, unfinished } = this.getAllPaths(this.nodeId);
      if (paths.length === 0) {
        this.showWarning({
          title: 'An error occured',
          text: 'The node is detached from the tree, so it cannot be shown in the tree.',
          variant: 'danger',
        });

        this.isLoading = false;
      } else {
        this.showPathsProxy({ paths, shortest, zoom }).then(() => {
          this.isLoading = false;
          this.$store.commit('treeView/setHighlightEditNode', this.nodeId);
          this.$router.push({ name: 'flow' });
        });
        if (unfinished) {
          this.showWarning({
            title: 'Maximum tree volume exceeded',
            text: 'Only some paths to the node will be shown.',
            variant: 'warning',
          });
        }
      }
    },
    toggleDisability() {
      this.$store.commit('botManipulation/activeBot/toggleIsDisabled', { id: this.nodeId });
    },
  },
};
</script>
