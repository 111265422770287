import Vue from 'vue';
import axios from 'axios';
import endpoints from '@/js/urls';
import { cloneDeep } from 'lodash';
import { GENERATIVE_AI, SUP_WIZ_INTEGRATION } from '@/js/activity';

const initialState = {
  isSaving: false,
  isRefreshing: false,
  isTranslatingSingle: false,
  selectedFilterOptions: [],
  selectedVariant: null,
  variants: [],
  translationTasks: {},
  masterPhrases: {}, // Phrases from the master language
  masterOtherActivities: {}, // other elements from master language
  selectedVariantPhrases: {}, // Phrases from the selected variant
  selectedOtherActivities: {}, // Other acitivities from the selected variant
  selectedVariantConfig: {}, // Config of the selected variant
  selectedVariantInitActivities: {}, // activityId->code pairs for set variable activities in init.
  selectedVariantClassifierMapping: {}, // mapping of classifiers for the variant
  selectedVariantSecretMapping: {}, // mapping of secrets for the variant
  selectedVariantNodeExamples: {}, // object for storing translated node examples for the variant.
  selectedVariantPhraseLocaleId: null, // optional: Locale ID in phrase if enabled
  phraseIntegrationConfig: {}, // Configuration object for Phrase integration if used.
  generativeAIConfig: {
    gptPersona: null,
    allowedEmojis: [],
    personaConditions: {
      negative: null,
      neutral: null,
      positive: null,
    },
  },
};

const gettersDefinitions = {
  getNameFromId: (state) => (variantId) => {
    if (state.variants.length) {
      return state.variants.find((e) => e.id === variantId)?.name;
    }
    return null;
  },
  isVariantsRefreshing(state) {
    return state.isRefreshing;
  },
  isVariantsSaving(state) {
    return state.isSaving;
  },
  isTranslatingSingle(state) {
    return state.isTranslatingSingle;
  },
  selectedFilterOptions(state) {
    return state.selectedFilterOptions;
  },
  selectedVariant(state) {
    return state.selectedVariant;
  },
  hasSelectedLanguage(state) {
    return !!state.selectedVariantConfig?.config?.routing?.language;
  },
  selectedLanguage(state) {
    return state.selectedVariantConfig?.config?.routing?.language;
  },
  variantsList(state) {
    return state.variants;
  },
  masterPhrases(state) {
    return state.masterPhrases;
  },
  masterOtherActivities(state) {
    return state.masterOtherActivities;
  },
  getTranslationTasks(state) {
    return state.translationTasks;
  },
  selectedVariantPhrases(state) {
    return state.selectedVariantPhrases;
  },
  selectedOtherActivities(state) {
    return state.selectedOtherActivities;
  },
  selectedVariantConfig(state) {
    return state.selectedVariantConfig;
  },
  selectedVariantInitActivities(state) {
    return state.selectedVariantInitActivities;
  },
  selectedVariantClassifierMapping(state) {
    return state.selectedVariantClassifierMapping;
  },
  selectedVariantSecretMapping(state) {
    return state.selectedVariantSecretMapping;
  },
  selectedVariantNodeExamples(state) {
    return state.selectedVariantNodeExamples;
  },
  phraseIntegrationConfig(state) {
    return state.phraseIntegrationConfig;
  },
  selectedVariantPhraseLocaleId(state) {
    return state.selectedVariantPhraseLocaleId;
  },
  isSelectedVariantDirty(state) {
    return (key) => {
      if (state.selectedVariantPhrases[key] === undefined) {
        return false;
      }
      const elem = state.selectedVariantPhrases[key].elem;
      if ((elem.newText === undefined || elem.newText === (elem.text || null))
          && (elem.newApproved === undefined || elem.newApproved === (elem.approved || false))) {
        return false;
      }
      return true;
    };
  },
  isVariantOtherActivitiesDirty(state) {
    return (key) => {
      if (state.selectedOtherActivities[key] === undefined) {
        return false;
      }
      const params = Object.values(state.selectedOtherActivities[key].params);
      return params.some((e) => e.value?.newText && e.value?.newText !== e.value.text);
    };
  },
  generativeAIConfig(state) {
    return state.generativeAIConfig;
  },
};
function phraseTreeToDict(prependPath, phraseTree) {
  // This function is used to turn the tree-structure received by the backend into an
  // easier-to-work with key-value dict.
  if (phraseTree.text === null || typeof phraseTree.text === 'string') {
    const d = {};
    d[prependPath] = { prependPath, elem: phraseTree };
    return d;
  }
  if (phraseTree.constructor !== Object) {
    return {};
  }
  const dictList = Object.entries(phraseTree).map(
    ([key, val]) => phraseTreeToDict(prependPath.concat([key]), val),
  ).flat();
  return Object.assign({}, ...dictList);
}
function formatOtherActivity(activity) {
  const formattedObject = { params: {} };
  Object.entries(activity.params).forEach(([key, value]) => {
    // The following will split the string by the first | and produce an empty 3rd element.
    let newKey = key.split(/\|(.*)/s)[1];
    if (newKey === undefined) {
      // Keep support for old syntax to make sure we don't break bots.
      newKey = key.replace(/["'() ]/g, '').split(',')[1];
    }
    formattedObject.params[newKey] = { key: newKey, value: value.value || value };
  });
  return formattedObject;
}
// the purpose of this funciton is to separate responses text from other acitivites
function sortPhraseTree(nodesActivitiesDict, data) {
  const nodeTypes = cloneDeep({
    errorNode: data.errorNode,
    fallbackNode: data.fallbackNode,
    inactiveNode: data.inactiveNode,
    initNode: data.initNode,
    finalNode: data.finalNode,
    elaborationNode: data.elaborationNode,
    nodes: data.nodes,
    config: data.config,
  });
  let nodePhrases = {};
  const nodeOther = {};
  Object.keys(nodeTypes).forEach((type) => {
    // handle config separately
    if (type === 'config' && data.config) {
      const prependPath = ['config'];
      nodePhrases = {
        ...nodePhrases,
        ...phraseTreeToDict(prependPath, nodeTypes[type]),
      };
    } else if (data[type]) {
      const nodes = data[type];
      Object.keys(nodes).forEach((node) => {
        Object.keys(nodes[node].activities || {}).forEach((key) => {
          const prependPath = [type, node, 'activities', key];
          if (!(node in nodesActivitiesDict)) {
            return;
          }
          const masterActivities = nodesActivitiesDict[node];
          if (!(key in masterActivities) || !masterActivities[key]) {
            return;
          }
          if ([GENERATIVE_AI, SUP_WIZ_INTEGRATION].includes(masterActivities[key].type)) {
            nodeOther[key] = {
              prependPath,
              ...formatOtherActivity(nodes[node].activities[key]),
              type: masterActivities[key].type,
            };
          } else {
            nodePhrases = {
              ...nodePhrases,
              ...phraseTreeToDict(prependPath, nodes[node].activities[key]),
            };
          }
        });
      });
    }
  });
  return {
    nodePhrases,
    nodeOther,
  };
}

const mutations = {
  setIsVariantsRefreshing(state, newValue) {
    state.isRefreshing = newValue;
  },
  setIsVariantsSaving(state, newValue) {
    Vue.set(state, 'isSaving', newValue);
  },
  setIsTranslatingSingle(state, newValue) {
    state.isTranslatingSingle = newValue;
  },
  setSelectedFilterOptions(state, newOptions) {
    state.selectedFilterOptions = newOptions;
  },
  setSelectedVariant(state, newVariant) {
    state.selectedVariant = newVariant;
  },
  setVariantsList(state, newVariants) {
    state.variants = newVariants;
  },
  setMasterPhrases(state, newPhrases) {
    state.masterPhrases = newPhrases;
  },
  setMasterOtherActivities(state, newPhrases) {
    state.masterOtherActivities = newPhrases;
  },
  setIsTranslating(state, { variantId, translationTask, progress }) {
    if (translationTask) {
      Vue.set(state.translationTasks, variantId, { translationTask, progress });
    } else {
      Vue.delete(state.translationTasks, variantId);
    }
  },
  setSelectedVariantPhrases(state, newPhrases) {
    state.selectedVariantPhrases = newPhrases;
  },
  setSelectedOtherActivities(state, newPhrases) {
    state.selectedOtherActivities = newPhrases;
  },
  setSelectedVariantConfig(state, newConfig) {
    state.selectedVariantConfig = newConfig;
  },
  setSingleVariantPhraseToNewValues(state, { phraseKey }) {
    if (phraseKey in state.selectedVariantPhrases) {
      const elem = state.selectedVariantPhrases[phraseKey].elem;
      if (elem.newText !== undefined) {
        Vue.set(elem, 'text', elem.newText);
      }
      if (elem.newApproved !== undefined) {
        Vue.set(elem, 'approved', elem.newApproved);
      }
    }
  },
  setSingleVariantOtherActivityToNewValues(state, { id }) {
    if (id in state.selectedOtherActivities) {
      const elem = state.selectedOtherActivities[id].params;
      Object.keys(elem).forEach((key) => {
        if (elem[key].value.newText !== undefined) {
          Vue.set(elem[key], 'text', elem[key].value.newText);
          Vue.set(elem[key].value, 'text', elem[key].value.newText);
        }
      });
    }
  },
  setSingleVariantApproved(state, { phraseKey, path, approved }) {
    if (phraseKey in state.selectedVariantPhrases) {
      Vue.set(state.selectedVariantPhrases[phraseKey].elem, 'newApproved', approved);
    } else {
      Vue.set(
        state.selectedVariantPhrases, phraseKey, {
          prependPath: path,
          elem: {
            newApproved: approved,
          },
        },
      );
    }
  },
  setSingleVariantPhrase(state, { phraseKey, path, text }) {
    // If the key already exists we will update just the text.
    // Otherwise, we will add the entry, but not update the hashes
    //  - those are only updated once the user clicks save.
    if (phraseKey in state.selectedVariantPhrases) {
      Vue.set(state.selectedVariantPhrases[phraseKey].elem, 'newText', text);
    } else {
      Vue.set(state.selectedVariantPhrases, phraseKey, {
        prependPath: path,
        elem: {
          newText: text,
        },
      });
    }
  },
  setSingleOtherActivity(state, { payload, item }) {
    if (item.id in state.selectedOtherActivities
       && payload.param in state.selectedOtherActivities[item.id].params) {
      Vue.set(state.selectedOtherActivities[item.id].params[payload.param].value, 'newText', payload.value);
    } else {
      const params = { ...state.selectedOtherActivities[item.id]?.params || {} };
      params[payload.param] = { key: payload.param, value: { newText: payload.value } };
      Vue.set(state.selectedOtherActivities, item.id, {
        prependPath: item.prependPath,
        params,
      });
    }
  },
  setVariantPhraseHashToMaster(state, phraseKey) {
    if (phraseKey in state.masterPhrases && phraseKey in state.selectedVariantPhrases) {
      Vue.set(
        state.selectedVariantPhrases[phraseKey].elem,
        'masterHash',
        state.masterPhrases[phraseKey].elem.hash,
      );
    } else {
      console.error(`Warning: Asked to update masterHash for missing variant key: ${phraseKey}`);
      // We previously threw an error here, but then variants just completely broke if updating
      // a key that no longer exists.
      // However, keeping around old keys is not an issue since only existing ones are used.
      // throw Error(`Error updating master hash of phraseKey: ${phraseKey}`);
    }
  },
  setVariantOtherActivityHashToMaster(state, id) {
    if (id in state.masterOtherActivities && id in state.selectedOtherActivities) {
      Object.keys(state.selectedOtherActivities[id].params).forEach((key) => {
        Vue.set(
          state.selectedOtherActivities[id].params[key].value,
          'masterHash',
          state.masterOtherActivities[id].params[key].value.hash,
        );
      });
    } else {
      console.error(`Warning: Asked to update masterHash for missing variant activity: ${id}`);
      // We previously threw an error here, but then variants just completely broke if updating
      // a key that no longer exists.
      // However, keeping around old keys is not an issue since only existing ones are used.
      // throw Error(`Error updating master hash of phraseKey: ${phraseKey}`);
    }
  },
  setVariantConfigField(state, { indexList, value }) {
    // TODO: This function may have issues with reactivity.
    let obj = state.selectedVariantConfig;
    for (const k of indexList.slice(0, -1)) {
      if (!(k in obj)) {
        Vue.set(obj, k, {});
      }
      obj = obj[k];
    }
    Vue.set(obj, indexList[indexList.length - 1], value);
  },
  setSelectedVariantInitActivities(state, newActivities) {
    state.selectedVariantInitActivities = newActivities;
  },
  setSelectedVariantInitActivityCode(state, { activityId, code }) {
    if (code) {
      Vue.set(state.selectedVariantInitActivities, activityId, code);
    } else if (activityId in state.selectedVariantInitActivities) {
      delete state.selectedVariantInitActivities[activityId];
    }
  },
  setSelectedVariantClassifierMapping(state, newClassifiers) {
    state.selectedVariantClassifierMapping = newClassifiers;
  },
  setSelectedVariantSecretMapping(state, newSecrets) {
    state.selectedVariantSecretMapping = newSecrets;
  },
  removeSelectedVariantClassifier(state, { isSubflow, subflowId, modelName }) {
    if (!isSubflow && state.selectedVariantClassifierMapping.bot) {
      Vue.delete(state.selectedVariantClassifierMapping.bot, modelName);
    } else if (isSubflow && state.selectedVariantClassifierMapping.subflows
        && state.selectedVariantClassifierMapping.subflows[subflowId]) {
      Vue.delete(state.selectedVariantClassifierMapping.subflows[subflowId], modelName);
    }
  },
  setSelectedVariantClassifier(state, {
    isSubflow, subflowId, modelName, modelInfo,
  }) {
    // This mutation sets the mapping for the corresponding modelName
    // We also ensure that we also create the nested objects down to the specific modelName as
    // they may not already exist.
    if (!isSubflow) {
      if (!state.selectedVariantClassifierMapping.bot) {
        Vue.set(state.selectedVariantClassifierMapping, 'bot', {});
      }
      Vue.set(state.selectedVariantClassifierMapping.bot, modelName, modelInfo);
    } else {
      if (!state.selectedVariantClassifierMapping.subflows) {
        Vue.set(state.selectedVariantClassifierMapping, 'subflows', {});
      }
      if (!state.selectedVariantClassifierMapping.subflows[subflowId]) {
        Vue.set(state.selectedVariantClassifierMapping.subflows, subflowId, {});
      }
      Vue.set(state.selectedVariantClassifierMapping.subflows[subflowId], modelName, modelInfo);
    }
  },
  setSelectedVariantNodeExamples(state, newExamples) {
    state.selectedVariantNodeExamples = newExamples;
  },
  setPhraseIntegrationConfig(state, newConfig) {
    state.phraseIntegrationConfig = newConfig;
  },
  setSelectedVariantPhraseLocaleId(state, newId) {
    state.selectedVariantPhraseLocaleId = newId;
  },
  cleanUpVariantSpecificState(state) {
    state.selectedVariant = null;
    state.selectedVariantClassifierMapping = {};
    state.selectedVariantPhrases = {};
    state.selectedVariantInitActivities = {};
    state.selectedVariantConfig = {};
    state.selectedVariantNodeExamples = {};
    state.selectedVariantPhraseLocaleId = null;
    state.phraseIntegrationConfig = {};
    state.generativeAIConfig = {
      gptPersona: null,
      allowedEmojis: [],
      personaConditions: {},
    };
  },
  setGenerativeAIConfig(state, config) {
    state.generativeAIConfig.gptPersona = config.gptPersona || null;
    state.generativeAIConfig.allowedEmojis = config.allowedEmojis || [];
    state.generativeAIConfig.personaConditions = config.personaConditions || {};
  },
  setSingleGenerativeAIConfig(state, { key, value }) {
    Vue.set(state.generativeAIConfig, key, value);
  },
  setPersonaCondition(state, { sentiment, value }) {
    if (!state.generativeAIConfig.personaConditions) {
      state.generativeAIConfig.personaConditions = {};
    }
    Vue.set(state.generativeAIConfig.personaConditions, sentiment, value);
  },
};

const actions = {
  async refreshAllSelectedVariantData({ commit, dispatch }) {
    commit('setIsVariantsRefreshing', true);
    await dispatch('fetchMasterDataForVariants');
    await dispatch('fetchSelectedVariantData');
    commit('setIsVariantsRefreshing', false);
  },
  async fetchVariants({ rootState, commit }) {
    try {
      commit('setIsVariantsRefreshing', true);
      const result = await axios.get(endpoints.variants, {
        params: { bot_id: rootState.botManipulation.activeBotId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      const phraseResult = await axios.get(endpoints.phraseIntegrationConfig, {
        params: { bot_id: rootState.botManipulation.activeBotId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      commit('setVariantsList', result.data.variants);
      commit('setPhraseIntegrationConfig', {
        isEnabled: phraseResult.data.is_enabled,
        authTokenExists: phraseResult.data.auth_token_exists,
        projectId: phraseResult.data.project_id,
        masterLocaleId: phraseResult.data.master_locale_id,
      });
      commit('setIsVariantsRefreshing', false);
    } catch (e) {
      console.error('error occurred while refreshing variants list: ', e);
      throw e;
    }
  },
  async fetchMasterDataForVariants({
    rootState, commit, dispatch, rootGetters,
  }) {
    try {
      // We need to ensure that several types of data is properly sync'd for the variants page
      // to work.
      // 1. The phrase data (bot + subflows)
      const phraseResult = await axios.get(endpoints.phrases, {
        params: { bot_id: rootState.botManipulation.activeBotId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      // Filter phrase tree based on activity types
      const nodesAsList = rootGetters['botManipulation/activeBot/allNodesAsList'];
      const nodesActivitiesDict = {};
      nodesAsList.forEach((node) => {
        nodesActivitiesDict[node.id] = node.activities;
      });
      const sorted = sortPhraseTree(nodesActivitiesDict, phraseResult.data);
      commit('setMasterPhrases', sorted.nodePhrases);
      commit('setMasterOtherActivities', sorted.nodeOther);
      // 2. NLU model data so we can assign classifiers
      await dispatch('nlu/classifier/fetchGlobalNLUModels', null, { root: true });
      // 3. Subflow data to assign classifiers (for now).
      const subflowResult = await dispatch('botManipulation/getBackendSubFlows', null, { root: true });
      commit('botManipulation/setSubFlows', subflowResult.data, { root: true });
    } catch (e) {
      console.error('error occurred while fetching phrase tree: ', e);
      throw e;
    }
  },
  async fetchSelectedVariantData({
    rootState, state, commit, rootGetters,
  }) {
    if (!state.selectedVariant) {
      commit('setSelectedVariantConfig', {});
      commit('setSelectedVariantPhrases', {});
      commit('setSelectedVariantInitActivities', {});
      commit('setSelectedVariantClassifierMapping', {});
      commit('setSelectedVariantNodeExamples', {});
      commit('setSelectedVariantPhraseLocaleId', null);
      commit('setSelectedVariantSecretMapping', {});
      commit('setGenerativeAIConfig', {
        gptPersona: null,
        allowedEmojis: [],
        personaConditions: {
          negative: null,
          neutral: null,
          positive: null,
        },
      });
    } else {
      try {
        const result = await axios.get(endpoints.variantsSingle, {
          params: { bot_variant_id: state.selectedVariant },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
        // Filter phrase tree based on activity types
        const nodesAsList = rootGetters['botManipulation/activeBot/allNodesAsList'];
        const nodesActivitiesDict = {};
        nodesAsList.forEach((node) => {
          nodesActivitiesDict[node.id] = node.activities;
        });
        const sorted = sortPhraseTree(nodesActivitiesDict, result.data.phrase_tree);
        commit('setSelectedVariantPhrases', sorted.nodePhrases);
        commit('setSelectedOtherActivities', sorted.nodeOther);
        commit('setSelectedVariantConfig', result.data.config_tree);
        commit('setSelectedVariantInitActivities', result.data.init_activities);
        commit('setSelectedVariantClassifierMapping', result.data.classifier_mapping);
        commit('setSelectedVariantNodeExamples', result.data.node_examples);
        commit('setSelectedVariantPhraseLocaleId', result.data.phrase_locale_id);
        commit('setSelectedVariantSecretMapping', result.data.secret_mapping);
        commit('setGenerativeAIConfig', result.data.config_tree.config.generativeAIConfig || {});
      } catch (e) {
        console.error('error occurred while refreshing variants list: ', e);
        throw e;
      }
    }
  },
  async createVariant({ rootState, dispatch, commit }, {
    name,
    language,
    translateVariant,
    createClassifiers,
    doClassifierTranslation,
  }) {
    try {
      const { translation_task: translationTask, id: variantId } = (await axios.post(
        endpoints.variants,
        {
          bot_id: rootState.botManipulation.activeBotId,
          name,
          language,
          translateVariant,
          create_classifiers: createClassifiers,
          do_classifier_translation: doClassifierTranslation,
        },
        { headers: { Authorization: `JWT ${rootState.auth.jwt}` } },
      )).data;
      if (translationTask) {
        commit('setIsTranslating', { variantId, translationTask, progress: '0/?' });
        commit(
          'task/addTask',
          {
            celeryId: translationTask,
            callbackUpdate: ({ meta }) => {
              commit('setIsTranslating', {
                variantId,
                translationTask,
                progress: `${meta.has_translated_count}/${meta.to_translate_count}`,
              });
            },
            callbackDone: () => {
              commit('setIsTranslating', { variantId, translationTask: null });
            },
            callbackFailed: () => {
              dispatch('sidebar/showWarning', {
                title: 'Error occured',
                text: 'Backend ran into problems while translating the bot.',
                variant: 'danger',
              }, { root: true });
              commit('setIsTranslating', { variantId, translationTask: null });
            },
          },
          { root: true },
        );
      }
      // Above call returns id, but we just fetch entire list again.
      await dispatch('fetchVariants');
    } catch (e) {
      console.error('error occurred while fetching phrase tree: ', e);
      throw e;
    }
  },
  async saveVariantPhrases({
    rootState, state, commit, dispatch, getters,
  }, { phraseKey }) {
    // When updating the key we will store the master hash as well so we can easily identify
    // all entries that have been changed in master since last updating the entry in the
    // variant.
    let keys;
    if (phraseKey === undefined) {
      keys = Object.keys(state.selectedVariantPhrases).filter(getters.isSelectedVariantDirty);
    } else {
      keys = [phraseKey];
    }
    try {
      commit('setIsVariantsSaving', true);
      const changes = [];
      for (const key of keys) {
        const value = state.selectedVariantPhrases[key];
        commit('setVariantPhraseHashToMaster', key);
        let text = value.elem.newText;
        if (text === undefined) {
          text = value.elem.text;
        }
        changes.push({
          key_path: value.prependPath,
          value: {
            masterHash: value.elem.masterHash,
            text,
            approved: value.elem.newApproved,
          },
        });
      }
      await axios.put(
        endpoints.variantsSingle,
        {
          bot_variant_id: state.selectedVariant,
          changes,
          entry_type: 'phrase',
        },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        },
      );
      if (phraseKey === undefined) {
        await dispatch('refreshAllSelectedVariantData');
        return;
      }
      commit('setSingleVariantPhraseToNewValues', { phraseKey });
    } catch (e) {
      console.error('error occurred while updating variant key: ', e);
      throw e;
    } finally {
      commit('setIsVariantsSaving', false);
    }
  },
  async saveVariantOtherActivities({
    rootState, state, commit, dispatch, getters,
  }, { id }) {
    let keys;
    if (id === undefined) {
      keys = Object.keys(state.selectedOtherActivities)
        .filter(getters.isVariantOtherActivitiesDirty);
    } else {
      keys = [id];
    }
    try {
      commit('setIsVariantsSaving', true);
      const changes = [];
      for (const key of keys) {
        const item = state.selectedOtherActivities[key].params;
        commit('setVariantOtherActivityHashToMaster', key);
        Object.keys(item).forEach((prop) => {
          let text = state.selectedOtherActivities[key].params[prop].value.newText;
          if (text === undefined) {
            text = item[prop].value.text;
          }
          changes.push({
            key_path: state.selectedOtherActivities[key].prependPath.concat(['params', `key|${prop}`, 'value']),
            value: {
              masterHash: item[prop].value.masterHash,
              text,
            },
          });
        });
      }
      await axios.put(
        endpoints.variantsSingle,
        {
          bot_variant_id: state.selectedVariant,
          changes,
          entry_type: 'phrase',
        },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        },
      );
      if (id === undefined) {
        await dispatch('refreshAllSelectedVariantData');
        return;
      }
      commit('setSingleVariantOtherActivityToNewValues', { id });
    } catch (e) {
      console.error('error occurred while updating variant key: ', e);
      throw e;
    } finally {
      commit('setIsVariantsSaving', false);
    }
  },
  async translateVariantPhraseKey({
    rootState, state, rootGetters, commit, dispatch,
  }, phraseKey) {
    if (phraseKey in state.masterPhrases) {
      commit('setIsTranslatingSingle', true);
      try {
        const langFrom = rootGetters['botManipulation/activeBot/config/getRoutingLanguage'];
        const langTo = state.selectedVariantConfig.config.routing.language;
        const translation = await axios.post(
          endpoints.variantTranslation,
          {
            bot_variant_id: state.selectedVariant,
            key_path: state.masterPhrases[phraseKey].prependPath,
            from_language: langFrom,
            to_language: langTo,
          },
          {
            headers: {
              Authorization: `JWT ${rootState.auth.jwt}`,
            },
          },
        );
        commit('setSingleVariantPhrase', { phraseKey, path: state.masterPhrases[phraseKey].prependPath, text: translation.data.text });
        commit('setIsTranslatingSingle', false);
      } catch (e) {
        dispatch('sidebar/showWarning', {
          title: 'Translation failed',
          text: e,
          variant: 'danger',
        }, { root: true });
        commit('setIsTranslatingSingle', false);
        throw e;
      }
    }
  },
  async setAndPushVariantConfigField({ rootState, state, commit }, { value, indexList }) {
    try {
      commit('setVariantConfigField', { indexList, value });
      await axios.put(
        endpoints.variantsSingle,
        {
          bot_variant_id: state.selectedVariant,
          changes: [{
            key_path: indexList,
            value,
          }],
          entry_type: 'meta',
        },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        },
      );
    } catch (e) {
      console.error('error occurred while updating meta key: ', e);
      throw e;
    }
  },
  async uploadVariantNeutralsFile({ rootState, state, dispatch }, { fileName, fileObj }) {
    const formData = new FormData();
    formData.append('neutrals_name', fileName);
    formData.append('neutrals_file', fileObj);
    formData.append('entry_type', 'neutrals_file');
    formData.append('bot_variant_id', state.selectedVariant);
    try {
      await axios.put(
        endpoints.variantsSingle,
        formData,
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        },
      );
      await dispatch('refreshAllSelectedVariantData');
    } catch (e) {
      console.error('error occurred while uploading neutrals file for variant: ', e);
      throw e;
    }
  },
  async saveVariantConfig({ dispatch, commit }) {
    commit('setIsVariantsRefreshing', true);
    await dispatch('saveVariantInitActivities');
    await dispatch('saveVariantPersona');
    commit('setIsVariantsRefreshing', false);
  },
  async pushGenerativeAIConfig({ dispatch, commit }) {
    try {
      commit('setIsVariantsSaving', true);
      await dispatch('saveVariantPersona');
    } catch (e) {
      console.error('error occurred while updating variant GPT persona: ', e);
    } finally {
      commit('setIsVariantsSaving', false);
    }
  },
  async saveVariantInitActivities({ rootState, state, commit }) {
    try {
      commit('setIsVariantsRefreshing', true);
      await axios.put(
        endpoints.variantsSingle,
        {
          bot_variant_id: state.selectedVariant,
          value: state.selectedVariantInitActivities,
          entry_type: 'init_activities',
        },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        },
      );
    } catch (e) {
      console.error('error occurred while updating variant init activity code: ', e);
      throw e;
    }
  },
  async saveVariantPersona({ rootState, state }) {
    try {
      await axios.put(
        endpoints.variantsSingle,
        {
          bot_variant_id: state.selectedVariant,
          changes: [{
            key_path: ['config', 'generativeAIConfig'],
            value: state.generativeAIConfig,
          }],
          entry_type: 'meta',
        },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        },
      );
    } catch (e) {
      console.error('error occurred while updating variant GPT persona: ', e);
      throw e;
    }
  },
  async pushVariantSecrets({ dispatch }) {
    await dispatch('pushPartialVariant', 'secret');
  },
  async pushVariantClassifiers({ dispatch }) {
    await dispatch('pushPartialVariant', 'classifier');
  },
  async pushPartialVariant({ rootState, state, commit }, entryType) {
    let value = null;
    switch (entryType) {
      case 'secret':
        value = state.selectedVariantSecretMapping;
        break;
      case 'classifier':
        value = state.selectedVariantClassifierMapping;
        break;
      default:
        console.error(`Unsupported entry_type: ${entryType}`);
    }
    try {
      commit('setIsVariantsSaving', true);
      await axios.put(
        endpoints.variantsSingle,
        {
          bot_variant_id: state.selectedVariant,
          value,
          entry_type: entryType,
        },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        },
      );
    } catch (e) {
      console.error(`error occurred while updating ${entryType}`, e);
    } finally {
      commit('setIsVariantsSaving', false);
    }
  },
  async importVariantExamplesFile({ rootState, state, dispatch }, { fileObj }) {
    const formData = new FormData();
    formData.append('examples_file', fileObj);
    formData.append('entry_type', 'node_examples');
    formData.append('bot_variant_id', state.selectedVariant);
    try {
      await axios.put(
        endpoints.variantsSingle,
        formData,
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        },
      );
      await dispatch('refreshAllSelectedVariantData');
    } catch (e) {
      console.error('error occurred while uploading node examples file for variant: ', e);
      throw e;
    }
  },
  async savePhraseIntegrationConfig({ rootState, commit }, newConfig) {
    try {
      const phraseResult = await axios.put(
        endpoints.phraseIntegrationConfig,
        {
          bot_id: rootState.botManipulation.activeBotId,
          is_enabled: newConfig.isEnabled,
          auth_token: newConfig.authToken,
          project_id: newConfig.projectId,
          master_locale_id: newConfig.masterLocaleId,
        },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        },
      );
      commit('setPhraseIntegrationConfig', {
        isEnabled: phraseResult.data.is_enabled,
        authTokenExists: phraseResult.data.auth_token_exists,
        projectId: phraseResult.data.project_id,
        masterLocaleId: phraseResult.data.master_locale_id,
      });
    } catch (e) {
      console.error('error occurred while saving phrase integration config: ', e);
      throw e;
    }
  },
  async pushMasterToPhrase({ rootState }) {
    try {
      await axios.post(
        endpoints.phraseIntegration,
        { bot_id: rootState.botManipulation.activeBotId, type: 'master' },
        { headers: { Authorization: `JWT ${rootState.auth.jwt}` } },
      );
    } catch (e) {
      console.error('error occurred while pusher master to Phrase: ', e);
      throw e;
    }
  },
  async fetchSelectedVariantLocaleFromPhrase({ rootState, state }) {
    try {
      return await axios.post(
        endpoints.phraseIntegration,
        {
          bot_id: rootState.botManipulation.activeBotId,
          bot_variant_id: state.selectedVariant,
          locale_id: state.selectedVariantPhraseLocaleId,
          type: 'locale',
        },
        { headers: { Authorization: `JWT ${rootState.auth.jwt}` } },
      );
    } catch (e) {
      console.error('error occurred while downloading locale data from Phrase: ', e);
      throw e;
    }
  },
  async fetchAllVariantsFromPhrase({ rootState }) {
    try {
      const result = await axios.post(
        endpoints.phraseIntegration,
        {
          bot_id: rootState.botManipulation.activeBotId,
          type: 'locale_all',
        },
        { headers: { Authorization: `JWT ${rootState.auth.jwt}` } },
      );
      return result.data.data;
    } catch (e) {
      console.error('error occurred while downloading localeAll data from Phrase: ', e);
      throw e;
    }
  },
  async fetchBatchFromPhraseCommit({ rootState }, payload) {
    try {
      const promises = [];
      for (const [variantId, variantData] of Object.entries(payload)) {
        promises.push(axios.post(
          endpoints.phraseIntegration,
          {
            bot_id: rootState.botManipulation.activeBotId,
            bot_variant_id: variantId,
            phrase_tree: variantData.new_phrase_tree,
            type: 'locale_commit',
          },
          { headers: { Authorization: `JWT ${rootState.auth.jwt}` } },
        ));
      }
      await Promise.all(promises);
    } catch (e) {
      console.error('error occurred while downloading locale data from Phrase: ', e);
      throw e;
    }
  },
  async fetchSelectedVariantLocaleFromPhraseCommit({ rootState, state, dispatch }, phraseTree) {
    try {
      await axios.post(
        endpoints.phraseIntegration,
        {
          bot_id: rootState.botManipulation.activeBotId,
          bot_variant_id: state.selectedVariant,
          phrase_tree: phraseTree,
          type: 'locale_commit',
        },
        { headers: { Authorization: `JWT ${rootState.auth.jwt}` } },
      );
      await dispatch('refreshAllSelectedVariantData');
    } catch (e) {
      console.error('error occurred while downloading locale data from Phrase: ', e);
      throw e;
    }
  },
  async deleteVariant({ rootState, dispatch }, variant) {
    try {
      await axios.delete(endpoints.variants, {
        params: { bot_variant_id: variant.id },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      await dispatch('fetchVariants');
    } catch (e) {
      console.error('error occured while deleting the variant', e);
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state: () => initialState,
  getters: gettersDefinitions,
  mutations,
  actions,
};
