<template>
  <div class="alert-fixed">
    <!-- This alert is when a synchronization error has occurred! -->
    <b-alert
      variant="danger"
      :show="showSyncErrorStatus"
    >
      <h4>Synchronization error!</h4>
      <p class="font-weight-bold">
        There was an error while synchronizing your bot with the server.
      </p>
      <p>
        Your latest work might not have been saved.
      </p>
      <hr>
      <h4>
        Technical details
      </h4>
      <p>
        Please report this problem to Supwiz if this problem persists<br>
        {{ getSyncErrorStatus }} <br>
        Timestamp: {{ new Date().toISOString() }}
      </p>
      <hr>
      <b-list-group-item
        button
        class="text-center r-25 mb-1"
        @click="synchronizeBotManually()"
      >
        <h5 class="mb-1">
          Synchronize manually
        </h5>
        <p>
          Try synchronizing bot manually to save changes.
        </p>
      </b-list-group-item>

      <b-list-group-item
        button
        class="text-center r-25"
        @click="reloadBot"
      >
        <h5 class="mb-1">
          Reload from server
        </h5>
        <p>
          Reloading bot will dismiss your recent changes.
        </p>
      </b-list-group-item>
    </b-alert>
  </div>
</template>

<script>

import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';

export default {
  name: 'BotStudioToaster',
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    ...mapState('auth', ['expired']),
    ...mapGetters('botSynchronization', ['getSyncErrorStatus']),
    showSyncErrorStatus: {
      get() {
        if (this.expired) {
          // Session has expired; the "The current session is timed out" modal is shown already,
          // forcing user to sign in. It is redundant to state that the synchronization failed,
          // because that is fully expected once the session has expired.
          return false;
        }
        const errorState = this.getSyncErrorStatus;
        return !(errorState === null || errorState === false);
      },
    },
  },
  methods: {
    ...mapActions('botSynchronization', ['syncBot']),
    ...mapMutations('botSynchronization', [
      'setSyncError',
      'setManualSync',
    ]),
    async reloadBot() {
      // Determine where "we are" in BotStudio application - and reload accordingly
      const path = this.$route.path;
      this.setSyncError(null);
      if (path.startsWith('/nlu') || path.startsWith('/data-exploration')) {
        // Just reload the page
        document.location.reload();
      } else if (path.startsWith('/bot')) {
        const botId = this.$store.getters['botManipulation/getBotId'];
        const mainBot = this.$store.getters['botManipulation/activeBot/config/isMainBot'];
        const action = mainBot ? 'botManipulation/setBot' : 'botManipulation/editSubFlow';
        this.$store.commit('botManipulation/setActiveBotSet', { isSet: false });
        this.$router.push({ name: this.$route.name, params: { botId: this.$route.params.botId } });
        this.$store.dispatch(action, botId);
      }
    },
    synchronizeBotManually() {
      this.setSyncError(null);
      this.setManualSync(true);
      this.syncBot(true).then(() => {
        this.setManualSync(false);
        return null;
      });
    },
  },
};

</script>

<style scoped>
  .alert-fixed {
    position:fixed;
    top: 5px;
    right: 5px;
    width: 500px;
    z-index: 9999;
  }
</style>
