import axios from 'axios';
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import endpoints from '@/js/urls';
import { formatDateTime, generateNodeDetailsLabels, getDashboardTimeRange } from '@/js/utils';
import { isExternalStatsEnabled } from '@/js/featureFlags';
import filters from './filters';

// Declare what types of charts we want (must match what's accepted by backend)
const generalFields = ['automation_rate', 'estimated_cost_savings', 'num_chats', 'estimated_user_time_saved',
  'automation_distributions', 'distributions_over_time', 'chat_ratings', 'link_clicks',
  'language_stats'];
const detailsFields = ['percent_impacted_chats', 'avg_action_count', 'avg_user_msg_count', 'avg_chatbot_msg_count',
  'node_visits', 'node_ratings', 'start_url_stats'];
const nodeStatsFields = ['num_chats', 'automation_distributions', 'automation_rate', 'chat_ratings', 'percent_optout'];
const languageStatsFields = ['num_chats', 'automation_distributions', 'automation_rate', 'estimated_cost_savings', 'chat_ratings',
  'percent_optout'];
const dashboardFields = ['num_chats', 'automation_rate', 'estimated_cost_savings',
  'convs_with_errors_count', 'flow_stats'];
const startUrlFields = ['num_chats', 'flow_stats'];
const TIME_BETWEEN_STATISTICS_UPDATES_MS = 1000;

function getSelfService(data) {
  if (data.state !== 'SUCCESS') {
    return { state: data.state };
  }
  const formattedData = { state: data.state, ready: data.ready, info: [] };
  data.info.forEach((e) => {
    const info = e;
    const coverageSelfService = info.flow_coverage * info.flow_coverage_self_service;
    const notCoveredSelfService = info.not_covered * info.not_covered_self_service;
    if (coverageSelfService) {
      formattedData.info.push((notCoveredSelfService
        ? (coverageSelfService + notCoveredSelfService) : coverageSelfService) * 100);
    } else {
      formattedData.info.push(notCoveredSelfService * 100 || null);
    }
  });
  return formattedData;
}
function getResolution(data) {
  if (data.state !== 'SUCCESS') {
    return { state: data.state };
  }
  const formattedData = { state: data.state, ready: data.ready, info: [] };
  data.info.forEach((e) => {
    const info = e;
    const coverageResolution = info.flow_coverage * info.flow_coverage_other_partial_resolution;
    const notCoveredResolution = info.not_covered * info.not_covered_other_partial_resolution;
    if (coverageResolution) {
      formattedData.info.push((notCoveredResolution
        ? (coverageResolution + notCoveredResolution) : coverageResolution) * 100);
    } else {
      formattedData.info.push(notCoveredResolution * 100 || null);
    }
  });
  return formattedData;
}
function getRating(data) {
  if (data.state !== 'SUCCESS') {
    return { state: data.state };
  }
  const formattedData = { state: data.state, ready: data.ready, info: [] };
  data.info.forEach((e) => {
    const info = e || {};
    let totalRating = 0;
    let totalConversations = 0;
    for (const [rating, count] of Object.entries(info)) {
      const ratingNum = Number(rating);
      const countNum = Number(count);
      totalRating += ratingNum * countNum;
      totalConversations += countNum;
    }
    if (totalConversations === 0) {
      formattedData.info.push(0);
    } else {
      const averageRating = totalRating / totalConversations;
      formattedData.info.push(averageRating);
    }
  });
  return formattedData;
}
function getRatingCount(data) {
  if (data.state !== 'SUCCESS') {
    return { state: data.state };
  }
  const formattedData = { state: data.state, ready: data.ready, info: [] };
  data.info.forEach((e) => {
    const info = e || {};
    const ratedCount = Object.values(info).reduce(
      (accumulator, currentValue) => accumulator + currentValue, 0);
    formattedData.info.push(ratedCount);
  });
  return formattedData;
}
function getChildNames(data, nameOfId) {
  let current = [];
  if (Object.values(data.children || {})) {
    current = Object.keys(data.children).map((key) => nameOfId(key));
    Object.keys(data.children).forEach((childId) => {
      current = current.concat(getChildNames(data.children[childId], nameOfId));
    });
  }
  return current;
}
function getGroupValue(data, key) {
  const total = data.reduce((acc, obj) => {
    if (obj[key].state === 'SUCCESS') {
      obj[key].info.forEach((val, index) => {
      // eslint-disable-next-line no-param-reassign
        acc[index] = (acc[index] || 0) + val;
      });
    }
    return acc;
  }, []);
  return total;
}
function getAvgGroupValue(data, key) {
  let counter = 0;

  const total = data.reduce((acc, obj) => {
    if (obj[key].state === 'SUCCESS') {
      const copy = cloneDeep(obj[key].info).filter((e) => e > 0);
      copy.forEach((val, index) => {
      // eslint-disable-next-line no-param-reassign
        acc[index] = (acc[index] || 0) + val;
      });
      if (copy.length) {
        counter += 1;
      }
    }
    return acc;
  }, []);
  return counter > 0 ? total.map((v) => v / counter) : total;
}
function getAvgGroupRating(data) {
  const rating = {};
  data.forEach((node) => {
    if (node.rating.state === 'SUCCESS' && node.rating_count.state === 'SUCCESS') {
      node.rating.info.forEach((periodValue, index) => {
        if (!rating[index]) {
          rating[index] = { value: 0, count: 0 };
        }
        const count = node.rating_count.info[index] || 0;
        rating[index].value += periodValue * count;
        rating[index].count += count;
      });
    }
  });
  return Object.values(rating).map((v) => (v.count > 0 ? v.value / v.count : 0));
}

function getInitialState() {
  return {
    // KPIs
    KPIMeta: {},
    nodeDetailsGroups: {},
    isFetchingNodeDetailsGroups: false,
    KPIData: {},
    nodeStatsNodes: [],
    generalFields,
    detailsFields,
    runningKPIid: null,
    runningLabelingKPIid: null,
    // persistent node details
    nodeDetailsData: {},
    runningNodeDetailsTaskIds: [],
    nodeDetailsLabels: [],
    isFetchingSettings: false,
    settings: {},
  };
}

const kpiGetters = {
  getNodeStatsNodes(state) {
    return state.nodeStatsNodes;
  },
  isLoaded: (state) => (field) => {
    const entryForField = state.KPIData[field];
    if (entryForField !== undefined && entryForField !== null) {
      const stateForField = entryForField.state;
      const infoForField = entryForField.info;
      if (stateForField !== undefined && stateForField !== null) {
        return stateForField === 'SUCCESS'
          && (infoForField !== undefined && infoForField !== null);
      }
      return false;
    }
    return false;
  },
  anyLoading: (state) => Object.values(state.KPIData).filter((e) => e && !e.ready).length !== 0,
  isLoading: (state) => (field) => {
    /**
     * Disclaimer: Use this function wisely! It _only_ states whether
     * the field is currently loading.
     * There are basically three consecutive states for data-retrieval:
     * 1. Haven't attempted to fetch data yet
     * 2. Currently awaiting response from server <- What this method state!
     * 3. Data is loaded
     */
    const entryForField = state.KPIData[field];

    if (entryForField !== undefined && entryForField !== null) {
      const stateForField = entryForField.state;
      if (stateForField !== undefined && stateForField !== null) {
        return stateForField === 'RUNNING' || stateForField === 'PENDING';
      }
      return false;
    }
    return false;
  },
  getKPIMeta: (state) => (field) => state.KPIMeta[field],
  getKPIData: (state) => (field) => state.KPIData[field],
  getKPICardValue: (state) => (field, type) => (state.KPIData[field]?.info
    ? state.KPIData[field]?.info[type] : 0),
  getKPIDataInfo: (state) => (field) => {
    if (state.KPIData[field] === undefined
      || !state.KPIData[field]) {
      return {};
    }
    return state.KPIData[field].info;
  },
  getKPIDataPlot: (state) => (id, plot) => {
    const field = `${id}_${plot}`;
    if (state.KPIData[field] !== undefined && state.KPIData[field]
      && state.KPIData[field].state === 'SUCCESS') {
      return state.KPIData[field].info;
    }
    return null;
  },
  getKPIDataDistributionPlot: (state, getters) => (id) => getters.getKPIDataPlot(id, 'distribution'),
  getKPIDataResolutionPlot: (state, getters) => (id) => getters.getKPIDataPlot(id, 'resolution'),
  topNodeCountLoading: (state) => Object.values(state.nodeDetailsData).some((e) => e.num_chats.state === 'PENDING'),
  getHighestChatCount(state, getters) {
    let highest = 0;
    Object.values(getters.nodeDetailsGroupData).forEach((item) => {
      const totalCount = (item?.num_chats?.info || [])
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      if (parseInt(totalCount, 10) > highest) {
        highest = parseInt(totalCount, 10);
      }
    });
    return highest;
  },
  nodeDetailsGroupData: (state) => {
    const dict = cloneDeep(state.nodeDetailsGroups);
    Object.values(dict).forEach((group) => {
      const groupData = group.node_ids.map((id) => state.nodeDetailsData[id]);
      dict[group.id].num_chats.info = getGroupValue(groupData, 'num_chats');
      dict[group.id].automation_rate.info = getAvgGroupValue(groupData, 'automation_rate');
      dict[group.id].partial_resolution.info = getAvgGroupValue(groupData, 'partial_resolution');
      dict[group.id].percent_optout.info = getAvgGroupValue(groupData, 'percent_optout');
      dict[group.id].rating.info = getAvgGroupRating(groupData);
      dict[group.id].rating_count.info = getGroupValue(groupData, 'rating_count');
      dict[group.id].self_service.info = getAvgGroupValue(groupData, 'self_service');
      group.node_ids.forEach((id) => {
        dict[group.id].children[id] = state.nodeDetailsData[id];
      });
    });
    return dict;
  },
  getNodeDetailsData: (state) => state.nodeDetailsData,
  nodeDetails: (state) => (path) => {
    let current = state.nodeDetailsData[path[0]];
    path.slice(1).forEach((element) => {
      current = current.children[element];
    });
    return current;
  },
  filterOutNode: (state, getters, rootState, rootGetters) => (node, name) => {
    const nodeData = node.isGroup ? getters.nodeDetailsGroupData[node.id]
      : getters.nodeDetails(node.path);
    let copy;
    if (state.filters.nodesFilter.names.length) {
      if (!state.filters.nodesFilter.names
        .some((str) => name.toLowerCase().includes(str.toLowerCase()))) {
        const allChildNames = getChildNames(nodeData,
          rootGetters['botManipulation/activeBot/nameOfId']);
        if (state.filters.nodesFilter.names.some((str1) => allChildNames
          .some((str2) => str2.toLowerCase().includes(str1.toLowerCase())))) {
          return false;
        }
        return true;
      }
    }
    const totalCount = nodeData.num_chats.info
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    if (state.filters.nodesFilter.countMin > totalCount
    || state.filters.nodesFilter.countMax < totalCount) {
      return true;
    }
    // assumption that if % value is 0 or null, it is actually n/a so it shouldnt be counted
    copy = cloneDeep(nodeData.automation_rate.info).filter((e) => e > 0);
    let actualLength = copy.length;
    let totalAutomation = copy
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    if (actualLength) {
      totalAutomation /= actualLength;
    }
    if (state.filters.nodesFilter.automationMin > totalAutomation
    || state.filters.nodesFilter.automationMax < totalAutomation) {
      return true;
    }

    copy = cloneDeep(nodeData.self_service.info).filter((e) => e > 0);
    actualLength = copy.length;
    let totalSelfService = copy
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    if (actualLength) {
      totalSelfService /= actualLength;
    }
    if (state.filters.nodesFilter.selfServiceMin > totalSelfService
     || state.filters.nodesFilter.selfServiceMax < totalSelfService) {
      return true;
    }

    copy = cloneDeep(nodeData.partial_resolution.info).filter((e) => e > 0);
    actualLength = copy.length;
    let totalResolution = copy
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    if (actualLength) {
      totalResolution /= actualLength;
    }
    if (state.filters.nodesFilter.resolutionMin > totalResolution
    || state.filters.nodesFilter.resolutionMax < totalResolution) {
      return true;
    }
    copy = cloneDeep(nodeData.rating.info).filter((e) => e > 0);
    actualLength = copy.length;
    let totalRating = copy
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    if (actualLength) {
      totalRating /= actualLength;
    }
    if (state.filters.nodesFilter.ratingMin > totalRating
    || state.filters.nodesFilter.ratingMax < totalRating) {
      return true;
    }

    return false;
  },
  getRunningNodeTaskIds: (state) => state.runningNodeDetailsTaskIds,
  nodeDetailsLabels: (state) => state.nodeDetailsLabels,
  getNodeVisits(state, getters, rootState, rootGetters) {
    let nodeStats;
    if (getters.isLoaded('node_visits')) {
      const numChats = getters.getKPIDataInfo('num_chats');
      const kpiData = getters.getKPIDataInfo('node_visits');
      nodeStats = kpiData.filter(
        (elem) => getters.getNodeStatsNodes.find((entry) => entry.id === elem.id),
      ).map((elem) => ({
        name: rootGetters['botManipulation/activeBot/nameOfId'](elem.id),
        id: elem.id,
        count: Math.min(elem.count, numChats.actual),
        fraction: Math.min(elem.fraction, 1),
        favorite: getters.getNodeStatsNodes.find((entry) => entry.id === elem.id).favorite,
      }));
    } else {
      nodeStats = getters.getNodeStatsNodes.map(({ id, favorite }) => ({
        id,
        name: rootGetters['botManipulation/activeBot/nameOfId'](id),
        favorite,
      }));
    }
    return nodeStats;
  },
  statsSettings: (state) => state.settings,
  getNodeDetailsGroups: (state) => state.nodeDetailsGroups,
};

const kpiMutations = {
  setRunningKPIid(state, taskId) {
    state.runningKPIid = taskId;
  },
  setRunningLabelingKPIid(state, taskId) {
    state.runningLabelingKPIid = taskId;
  },
  setKPIData(state, { field, data }) {
    Vue.set(state.KPIData, field, data);
  },
  setKPIMeta(state, { field, data }) {
    Vue.set(state.KPIMeta, field, data);
  },
  setNodeStatsNodes(state, payload) {
    state.nodeStatsNodes = payload.data;
  },
  setNodeStatsNodeFavorite(state, { nodeId, favorite }) {
    state.nodeStatsNodes.filter((element) => element.id === nodeId)[0].favorite = favorite;
  },
  clearStatistics(state) {
    Object.assign(state, getInitialState());
  },
  addRunningNodeDetailsTaskId(state, taskId) {
    state.runningNodeDetailsTaskIds.push(taskId);
  },
  removeRunningNodeDetailsTaskId(state, taskId) {
    state.runningNodeDetailsTaskIds.pop(taskId);
  },
  setNodeDetailsData(state, { nodeId, field, data }) {
    if (field === 'automation_distributions') {
      Vue.set(state.nodeDetailsData[nodeId], 'self_service', getSelfService(data));
      Vue.set(state.nodeDetailsData[nodeId], 'partial_resolution', getResolution(data));
    } else if (field === 'chat_ratings') {
      Vue.set(state.nodeDetailsData[nodeId], 'rating', getRating(data));
      Vue.set(state.nodeDetailsData[nodeId], 'rating_count', getRatingCount(data));
    } else {
      Vue.set(state.nodeDetailsData[nodeId], field, data);
    }
    if (field === 'showChildren' && data === false) {
      Vue.set(state.nodeDetailsData[nodeId], 'children', {});
    }
  },
  setNodeDetailsEmpty(state, { nodeId }) {
    Vue.set(state.nodeDetailsData, nodeId, {
      children: {}, showChildren: false, selected: true, path: [nodeId],
    });
  },
  setChildDetailsEmpty(state, { path }) {
    let current = state.nodeDetailsData[path[0]];
    path.slice(1).forEach((element) => {
      if (!current.children[element]) {
        current.children[element] = {};
      }
      current = current.children[element];
    });
    Vue.set(current, 'showChildren', current.showChildren || false);
    Vue.set(current, 'path', path);
    Vue.set(current, 'children', current.children || {});
    Vue.set(current, 'selected', true);
  },
  setNodeChildDetailsData(state, { path, field, data }) {
    const copy = cloneDeep(state.nodeDetailsData);
    let current = copy[path[0]];
    path.slice(1).forEach((element) => {
      if (!current.children[element]) {
        current.children[element] = {
          children: {}, showChildren: false, selected: true, path,
        };
      }
      current = current.children[element];
    });
    if (field === 'automation_distributions') {
      Vue.set(current, 'self_service', getSelfService(data));
      Vue.set(current, 'partial_resolution', getResolution(data));
    } else if (field === 'chat_ratings') {
      Vue.set(current, 'rating', getRating(data));
      Vue.set(current, 'rating_count', getRatingCount(data));
    } else {
      Vue.set(current, field, data);
    }
    if (field === 'showChildren' && data === false) {
      Vue.set(current, 'children', {});
    }
    state.nodeDetailsData = copy;
  },
  updateNodeDetailsLabels(state, payload) {
    state.nodeDetailsLabels = payload;
  },
  setIsLoadingProgressAndFeatures(state, payload) {
    state.isLoadingProgressAndFeatures = payload;
  },
  setIsFetchingSettings(state, payload) {
    state.isFetchingSettings = payload;
  },
  setSettings(state, payload) {
    state.settings = payload;
  },
  setIsFetchingNodeDetailsGroups(state, payload) {
    state.isFetchingNodeDetailsGroups = payload;
  },
  setNodeDetailsGroupProperty(state, { id, field, data }) {
    state.nodeDetailsGroups[id][field] = data;
  },
  setNodeDetailsGroups(state, payload) {
    const dict = {};
    payload.forEach((group) => {
      dict[group.id] = {
        ...group,
        num_chats: { info: [] },
        automation_rate: { info: [] },
        partial_resolution: { info: [] },
        percent_optout: { info: [] },
        rating: { info: [] },
        rating_count: { info: [] },
        self_service: { info: [] },
        children: {},
        path: [group.id],
        selected: false,
        showChildren: false,
        isGroup: true,
      };
    });
    state.nodeDetailsGroups = dict;
  },
};

const kpiActions = {
  async addNodeStatsNode(
    {
      rootState, rootGetters, dispatch,
    }, payload,
  ) {
    const nodeName = payload.nodeName;
    const node = rootGetters['botManipulation/activeBot/nodeByName'](nodeName);
    if (node) {
      try {
        await axios.post(endpoints.nodeStatsNodes,
          { bot_id: rootState.botManipulation.activeBotId, node_id: node.id },
          {
            headers: {
              Authorization: `JWT ${rootState.auth.jwt}`,
            },
          });
        await dispatch('fetchNodeStatsNodes');
      } catch (e) {
        console.log('Adding NodeStatsNode failed', e);
      }
    }
  },
  async deleteNodeStatsNode({ rootState, dispatch }, nodeId) {
    try {
      await axios.delete(endpoints.nodeStatsNodes,
        {
          params: {
            bot_id: rootState.botManipulation.activeBotId,
            node_id: nodeId,
          },
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        });
      await dispatch('fetchNodeStatsNodes');
    } catch (e) {
      console.log('Adding NodeStatsNode failed', e);
    }
  },
  async setNodeStatsNodeFavorite({ rootState, commit, dispatch }, { nodeId, favorite }) {
    commit('setNodeStatsNodeFavorite', { nodeId, favorite }); // For enduser to see immediate effect
    try {
      await axios.patch(endpoints.nodeStatsNodes,
        {
          favorite,
        },
        {
          params: {
            bot_id: rootState.botManipulation.activeBotId,
            node_id: nodeId,
          },
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        });
      await dispatch('fetchNodeStatsNodes');
    } catch (e) {
      console.log(favorite ? 'Favoriting NodeStatsNode failed' : 'Unfavoriting NodeStatsNode failed', e);
    }
  },
  async fetchNodeStatsNodes({ rootState, commit }) {
    try {
      const { data } = await axios.get(endpoints.nodeStatsNodes,
        {
          params: { bot_id: rootState.botManipulation.activeBotId },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      commit('setNodeStatsNodes', { data: data.result });
    } catch (e) {
      console.log('Fetching NodeStatsNodes failed', e);
    }
  },
  async fetchKPIData({
    state, commit, dispatch, rootState, rootGetters,
  }, computationType) {
    let startDateTime;
    let endDateTime;
    if (computationType === 'dashboard') {
      const timeRange = getDashboardTimeRange();
      startDateTime = timeRange.startDateTime.toISOString();
      endDateTime = timeRange.endDateTime.toISOString();
    } else {
      startDateTime = formatDateTime(state.filters.statsFilter.startDate,
        state.filters.statsFilter.startTime);
      endDateTime = formatDateTime(state.filters.statsFilter.endDate,
        state.filters.statsFilter.endTime);
    }
    const variantId = state.filters.statsFilter.selectedVariant;
    const includeOngoing = state.filters.statsFilter.includeOngoing;
    const language = state.filters.statsFilter.selectedLanguage;
    const startDate = startDateTime;
    const endDate = endDateTime;
    const selectedOrigins = state.filters.selectedOrigins;

    if (rootState.botManipulation.activeBotSet) {
      commit('setKPIMeta', { field: 'start_date', data: startDate });
      commit('setKPIMeta', { field: 'end_date', data: endDate });
      commit('setKPIMeta', { field: 'language', data: language });
      commit('setKPIMeta', { field: 'selected_origins', data: selectedOrigins });
      commit('setKPIMeta', { field: 'variant', data: variantId });
      commit('setKPIMeta', { field: 'include_ongoing', data: includeOngoing });
    }
    const rawSelectedOrigins = selectedOrigins.map((x) => x.rawValue);

    // create parameters to be sent to backend
    const data = {
      bot_id: rootState.botManipulation.activeBotId,
      variant_id: variantId,
      include_ongoing: includeOngoing,
      language,
      start_date: startDate,
      end_date: endDate,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      selected_data_origins: rawSelectedOrigins,
      kpi_specs: {},
    };

    const type2Fields = {
      general: generalFields,
      details: detailsFields,
      dashboard: dashboardFields,
    };
    const fields = type2Fields[computationType];
    if (computationType === 'general') {
      data.kpi_specs.automation_distributions = { kpi_type: 'automation_distributions' };
      data.kpi_specs.distributions_over_time = { kpi_type: 'distributions_over_time', time_metric: state.filters.statsFilter.selectedGroupBy, statistic: undefined };
    } else if (computationType === 'details') {
      dispatch('fetchNodeDetailsData');
      data.kpi_specs.num_chats = { kpi_type: 'num_chats' };
    }
    // Prepare tasks related to "fact-boxes" / "fact-tiles"
    for (const field of fields) {
      // Before API-invocation: Update state to let it know we're in a "loading" state
      if (rootState.botManipulation.activeBotSet) {
        // I do this to set everything to loading, even though the task state may be PENDING
        commit('setKPIData', {
          field,
          data: {
            state: 'PENDING',
          },
        });
      }
      data.kpi_specs[field] = { kpi_type: field };
      if (field === 'distributions_over_time') {
        data.kpi_specs[field].time_metric = state.filters.statsFilter.selectedGroupBy;
      }
      if (field === 'language_stats') {
        const languageKPISpec = {
          kpi_type: 'group_by',
          group_field: 'language_normalized',
          group_kpi_specs: {},
          groups: rootGetters['chatlogs/availableLanguages'].map((x) => x.value),
        };
        for (const groupField of languageStatsFields) {
          languageKPISpec.group_kpi_specs[groupField] = { kpi_type: groupField };
        }
        data.kpi_specs.language_stats = languageKPISpec;
      }
      if (field === 'start_url_stats') {
        const startUrlKPISpec = {
          kpi_type: 'group_by',
          group_field: 'start_url_trimmed',
          group_kpi_specs: {},
          groups: rootGetters['chatlogs/availableStartUrls'].map((x) => x.value),
        };
        for (const groupField of startUrlFields) {
          startUrlKPISpec.group_kpi_specs[groupField] = { kpi_type: groupField };
        }
        data.kpi_specs.start_url_stats = startUrlKPISpec;
      }
    }

    // Request backend to start tasks
    const response = await axios.post(endpoints.kpis, data, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
    if (state.runningKPIid != null) {
      await axios.delete(endpoints.kpis,
        {
          params: { task_id: state.runningKPIid },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
    }
    commit('setRunningKPIid', response.data.id);
    if (response.status === 200 && rootState.botManipulation.activeBotSet) {
      dispatch('updateKPIStatus', response.data.id);
    }
  },
  async updateKPIStatus(
    {
      commit, state, dispatch, rootState,
    }, taskId,
  ) {
    // if the task we're getting the status on has been replaced with a newer task
    if (taskId !== state.runningKPIid) {
      return;
    }
    const response = await axios.get(endpoints.kpis,
      {
        params: { task_id: taskId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
    if (response.data.info !== null && response.data.info !== undefined) {
      for (const [field, data] of Object.entries(response.data.info)) {
        commit('setKPIData', { field, data });
      }
    }
    if (response.data.state === 'RUNNING' || response.data.state === 'PENDING') {
      setTimeout(() => {
        dispatch('updateKPIStatus', taskId);
      }, TIME_BETWEEN_STATISTICS_UPDATES_MS);
    } else {
      commit('setRunningKPIid', null);
    }
  },
  async fetchLabelingData({
    state, commit, dispatch, rootState,
  }) {
    const endDateTime = formatDateTime(new Date(), 24);
    const pastDate = new Date();
    pastDate.setDate(new Date().getDate() - 7);
    const startDateTime = formatDateTime(pastDate, 0);
    const rawSelectedOrigins = state.filters.selectedOrigins.map((x) => x.rawValue);

    const data = {
      bot_id: rootState.botManipulation.activeBotId,
      include_ongoing: state.filters.statsFilter.includeOngoing,
      variant_id: state.filters.statsFilter.selectedVariant,
      start_date: startDateTime,
      end_date: endDateTime,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      kpi_specs: {},
      date_filter_model: 'query_label',
      selected_data_origins: rawSelectedOrigins,
    };

    let fields = ['labeled_transfer_count', 'labeled_count'];
    fields = isExternalStatsEnabled ? fields.concat('labeled_negative_feedback_count') : fields;

    for (const field of fields) {
      if (rootState.botManipulation.activeBotSet) {
        commit('setKPIData', {
          field,
          data: {
            state: 'PENDING',
          },
        });
      }
      data.kpi_specs[field] = { kpi_type: field };
    }
    const response = await axios.post(endpoints.kpis, data, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
    if (state.runningLabelingKPIid != null) {
      await axios.delete(endpoints.kpis,
        {
          params: { task_id: state.runningLabelingKPIid },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
    }
    commit('setRunningLabelingKPIid', response.data.id);
    if (response.status === 200 && rootState.botManipulation.activeBotSet) {
      dispatch('updateLabelingKPIStatus', response.data.id);
    }
  },
  async updateLabelingKPIStatus(
    {
      commit, state, dispatch, rootState,
    }, taskId,
  ) {
    // if the task we're getting the status on has been replaced with a newer task
    if (taskId !== state.runningLabelingKPIid) {
      return;
    }
    const response = await axios.get(endpoints.kpis,
      {
        params: { task_id: taskId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
    if (response.data.info !== null && response.data.info !== undefined) {
      for (const [field, data] of Object.entries(response.data.info)) {
        commit('setKPIData', { field, data });
      }
    }
    if (response.data.state === 'RUNNING' || response.data.state === 'PENDING') {
      setTimeout(() => {
        dispatch('updateLabelingKPIStatus', taskId);
      }, TIME_BETWEEN_STATISTICS_UPDATES_MS);
    } else {
      commit('setRunningLabelingKPIid', null);
    }
  },
  async fetchNodeDetailsData({
    state, commit, dispatch, rootState,
  }) {
    const allNodes = Object.values(state.nodeDetailsGroups).flatMap((e) => e.node_ids);
    const selectedNodes = [...new Set(allNodes)];
    const rawSelectedOrigins = state.filters.selectedOrigins.map((x) => x.rawValue);

    // create parameters to be sent to backend
    const data = {
      bot_id: rootState.botManipulation.activeBotId,
      variant_id: state.filters.statsFilter.selectedVariant,
      include_ongoing: state.filters.statsFilter.includeOngoing,
      language: state.filters.statsFilter.selectedLanguage,
      start_date: formatDateTime(state.filters.statsFilter.startDate,
        state.filters.statsFilter.startTime),
      end_date: formatDateTime(state.filters.statsFilter.endDate,
        state.filters.statsFilter.endTime),
      time_metric: state.filters.statsFilter.selectedGroupBy,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      selected_data_origins: rawSelectedOrigins,
      selected_nodes: selectedNodes,
    };
    commit('updateNodeDetailsLabels', generateNodeDetailsLabels(
      state.filters.statsFilter.startDate,
      state.filters.statsFilter.endDate,
      state.filters.statsFilter.selectedGroupBy));
    for (const node of selectedNodes) {
      commit('setNodeDetailsEmpty', { nodeId: node });
      for (const field of nodeStatsFields) {
      // Before API-invocation: Update state to let it know we're in a "loading" state
        if (rootState.botManipulation.activeBotSet) {
          commit('setNodeDetailsData', {
            nodeId: node,
            field,
            data: {
              state: 'PENDING',
            },
          });
        }
      }
    }

    // Request backend to start tasks
    const response = await axios.post(endpoints.nodeLevelStats, data, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
    commit('addRunningNodeDetailsTaskId', response.data.id);
    if (response.status === 200 && rootState.botManipulation.activeBotSet) {
      dispatch('updateNodeDetailsStatus');
    }
  },
  async updateNodeDetailsStatus({
    commit, state, dispatch, rootState,
  }) {
    const taskIds = state.runningNodeDetailsTaskIds;
    const response = await axios.get(endpoints.nodeLevelStats,
      {
        params: { task_ids: taskIds },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
    for (const taskId of taskIds) {
      if (response.data[taskId].info !== null && response.data[taskId].info !== undefined) {
        for (const [nodeId, info] of Object.entries(response.data[taskId].info)) {
          for (const [field, data] of Object.entries(info)) {
            commit('setNodeDetailsData', { nodeId, field, data });
          }
        }
      }
      if (response.data[taskId].state === 'SUCCESS') {
        commit('removeRunningNodeDetailsTaskId', taskId);
      }
    }
    if (state.runningNodeDetailsTaskIds.length !== 0) {
      setTimeout(() => {
        dispatch('updateNodeDetailsStatus');
      }, TIME_BETWEEN_STATISTICS_UPDATES_MS);
    }
  },
  async fetchNodeChildDetailsData({
    state, commit, dispatch, rootState,
  }, payload) {
    const selectedNodes = payload.children;
    const rawSelectedOrigins = state.filters.selectedOrigins.map((x) => x.rawValue);

    // create parameters to be sent to backend
    const data = {
      bot_id: rootState.botManipulation.activeBotId,
      variant_id: state.filters.statsFilter.selectedVariant,
      include_ongoing: state.filters.statsFilter.includeOngoing,
      language: state.filters.statsFilter.selectedLanguage,
      start_date: formatDateTime(state.filters.statsFilter.startDate,
        state.filters.statsFilter.startTime),
      end_date: formatDateTime(state.filters.statsFilter.endDate,
        state.filters.statsFilter.endTime),
      time_metric: state.filters.statsFilter.selectedGroupBy,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      selected_data_origins: rawSelectedOrigins,
      selected_nodes: selectedNodes,
      node_path: payload.path,
    };
    for (const node of selectedNodes) {
      const path = payload.path.concat([node]);
      commit('setChildDetailsEmpty', { path });
      for (const field of nodeStatsFields) {
      // Before API-invocation: Update state to let it know we're in a "loading" state
        if (rootState.botManipulation.activeBotSet) {
          commit('setNodeChildDetailsData', {
            path,
            field,
            data: {
              state: 'PENDING',
            },
          });
        }
      }
    }

    // Request backend to start tasks
    const response = await axios.post(endpoints.nodeLevelStats, data, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
    commit('addRunningNodeDetailsTaskId', response.data.id);
    if (response.status === 200 && rootState.botManipulation.activeBotSet) {
      dispatch('updateNodeChildDetailsStatus', payload.path);
    }
  },
  async updateNodeChildDetailsStatus({
    commit, state, dispatch, rootState,
  }, path) {
    const taskIds = state.runningNodeDetailsTaskIds;
    const response = await axios.get(endpoints.nodeLevelStats,
      {
        params: { task_ids: taskIds },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
    for (const taskId of taskIds) {
      if (response.data[taskId].info !== null && response.data[taskId].info !== undefined) {
        for (const [nodeId, info] of Object.entries(response.data[taskId].info)) {
          for (const [field, data] of Object.entries(info)) {
            commit('setNodeChildDetailsData', {
              path: path.concat([nodeId]), field, data,
            });
          }
        }
      }
      if (response.data[taskId].state === 'SUCCESS') {
        commit('removeRunningNodeDetailsTaskId', taskId);
      }
    }
    if (state.runningNodeDetailsTaskIds.length !== 0) {
      setTimeout(() => {
        dispatch('updateNodeChildDetailsStatus', path);
      }, TIME_BETWEEN_STATISTICS_UPDATES_MS);
    }
  },
  async clearStatistics({ commit }) {
    commit('clearStatistics');
  },
  async fetchStatisticsSettings({ commit, rootState, dispatch }) {
    commit('setIsFetchingSettings', true);
    try {
      const response = await axios.get(
        endpoints.statisticsConfig,
        {
          params: { bot_id: rootState.botManipulation.activeBotId },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        },
      );
      commit('setSettings', response.data);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch statistics settings',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setIsFetchingSettings', false);
    }
  },
  async fetchNodeDetailsGroups({
    rootState, dispatch, commit, rootGetters,
  }) {
    commit('setIsFetchingNodeDetailsGroups', true);
    try {
      const resp = await axios.get(endpoints.nodeDetailsGroups, {
        params: {
          bot_id: rootState.botManipulation.activeBotId,
        },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      commit('setNodeDetailsGroups', [{
        id: 'flow',
        name: 'Flow nodes',
        node_ids: rootGetters['nodeInterpretations/flowNodes'],
      }].concat(...resp.data));
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch node details groups',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setIsFetchingNodeDetailsGroups', false);
    }
  },
  async addNodeDetailsGroup({ rootState, commit, dispatch }, payload) {
    try {
      const data = {
        bot: rootState.botManipulation.activeBotId,
        ...payload,
      };
      await axios.post(endpoints.nodeDetailsGroups,
        data,
        {
          params: { bot_id: rootState.botManipulation.activeBotId },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      commit('statistics/filters/setNeedsRefresh', { key: 'details', value: true }, { root: true });
      dispatch('fetchNodeDetailsGroups');
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to add node details group',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  async updateNodeDetailsGroup({ rootState, commit, dispatch }, group) {
    try {
      await axios.put(`${endpoints.nodeDetailsGroups}${group.id}/`,
        {
          bot: rootState.botManipulation.activeBotId,
          ...group,
        },
        {
          params: { bot_id: rootState.botManipulation.activeBotId },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      commit('statistics/filters/setNeedsRefresh', { key: 'details', value: true }, { root: true });
      dispatch('fetchNodeDetailsGroups');
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to update node details group',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  async deleteNodeDetailsGroup({ rootState, commit, dispatch }, id) {
    try {
      await axios.delete(`${endpoints.nodeDetailsGroups}${id}/`,
        {
          params: { bot_id: rootState.botManipulation.activeBotId },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      commit('statistics/filters/setNeedsRefresh', { key: 'details', value: true }, { root: true });
      dispatch('fetchNodeDetailsGroups');
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to delete node details group',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: getInitialState(),
  getters: kpiGetters,
  mutations: kpiMutations,
  actions: kpiActions,
  modules: {
    filters,
  },
};
