import { isGenerativeAiEnabled, isSupSearchEnabled } from '@/js/featureFlags';

export const ACTION = 'action';
export const CALL_MODEL = 'callModel';
export const CHAT_ACTION = 'chatAction';
export const COMPOUND_RESPONSE = 'compoundResponse';
export const CONTROL_FLOW = 'controlFlow';
export const ENCRYPT = 'encrypt';
export const GENERATIVE_AI = 'generativeAi';
export const METRIC_SIGNAL = 'metricSignal';
export const RESPONSE = 'response';
export const SET_VARIABLE = 'setVariable';
export const SUP_WIZ_INTEGRATION = 'supWizIntegration';

export const ALL_ACTIONS = [
  ACTION,
  CALL_MODEL,
  CHAT_ACTION,
  COMPOUND_RESPONSE,
  CONTROL_FLOW,
  ENCRYPT,
  METRIC_SIGNAL,
  RESPONSE,
  SET_VARIABLE,
];

if (isGenerativeAiEnabled()) {
  ALL_ACTIONS.push(GENERATIVE_AI);
}
if (isSupSearchEnabled()) {
  ALL_ACTIONS.push(SUP_WIZ_INTEGRATION);
}
export function nodeHasResponse(node) {
  for (const activity of Object.values(node.activities)) {
    if (activity.type === RESPONSE || activity.type === COMPOUND_RESPONSE) {
      return true;
    }
  }
  return false;
}
