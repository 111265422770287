import Vue from 'vue';

const demopanelState = {
  autoplayMessages: false,
  messageList: [],
  currentMessage: '',
};

const getters = {
  autoplayMessages: (state) => state.autoplayMessages,
  messageList: (state) => state.messageList,
  currentMessage: (state) => state.currentMessage,
};

const mutations = {
  toggleAutoplayMessages(state) {
    state.autoplayMessages = !state.autoplayMessages;
  },
  clearMessages(state) {
    state.messageList = [];
  },
  appendMessage(state, payload) {
    state.messageList.push(payload);
  },
  setCurrentMessage(state, payload) {
    state.currentMessage = payload;
  },
  resetDemoPanelState(state) {
    state.messageList = [];
    state.currentMessage = '';
  },
  setRunningSTTid(state, taskId) {
    state.runningSTTid = taskId;
  },
  setSpeechToTextResponse(state, response) {
    Vue.set(state, 'speechToTextResponse', response);
  },
};

export default {
  namespaced: true,
  state: demopanelState,
  getters,
  mutations,
};
