<template>
  <b-card
    class="r-75"
    body-class="p-3"
    title="Versions"
  >
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="versionsPagination.count"
          :per-page="versionsPagination.perPage"
          aria-controls="versions-table"
        />
      </b-col>
      <b-col>
        <template v-if="!isSWML">
          <b-button
            class="float-right"
            :variant="trainingButtonData.variant"
            :disabled="!activeModel.config.language_model || trainingButtonData.showSpinner"
            @click="startTrain"
          >
            {{ trainingButtonData.text }}
            <b-spinner
              v-if="trainingButtonData.showSpinner"
              small
            />
          </b-button>
        </template>
        <b-button
          v-else
          v-b-modal.version-upload-modal
          class="float-right"
          variant="primary"
        >
          Upload new version
        </b-button>
      </b-col>
    </b-row>
    <b-table
      id="versions-table"
      :caption="versionsTableCaption"
      class="mb-0"
      :tbody-tr-attr="{ style: 'cursor:pointer' }"
      :items="versionList"
      :fields="versionFields"
      sort-by="created_time"
      sort-desc
      hover
      @row-clicked="onRowClicked"
    />
    <version-upload />
  </b-card>
</template>
<script>

import axios from 'axios';
import {
  mapGetters, mapState, mapActions, mapMutations,
} from 'vuex';
import moment from 'moment';
import { percentageFormatter } from 'supwiz/util/formatters';
import VersionUpload from '@/pages/NLU/Versions/VersionUpload.vue';
import endpoints from '@/js/urls';

function trainStateFormatter(val) {
  if (val === null) {
    return 'Unknown';
  }
  return val;
}

export default {
  name: 'VersionsOverview',
  components: { VersionUpload },
  data() {
    return {
      intervalId: null,
      nodes: null,
      lastUpdated: null,
    };
  },
  computed: {
    ...mapState('nlu/classifier', ['labels', 'versions', 'versionsPagination', 'isFetchingVersions', 'activeTraining']),
    ...mapGetters('nlu/classifier', ['activeModel', 'globalIsTraining']),
    currentPage: {
      get() {
        return this.versionsPagination.currentPage;
      },
      set(v) {
        this.setVersionsPaginationPage(v);
        this.fetchClassifierVersions({
          clfId: this.activeModel.id,
          page: this.currentPage,
        });
      },
    },
    versionsTableCaption() {
      return `Last updated: ${this.lastUpdated ? this.lastUpdated.toLocaleString() : '-'}`;
    },
    trainingButtonData() {
      if (this.globalIsTraining || this.activeTraining) {
        return { text: 'Training', variant: 'primary', showSpinner: true };
      }
      if (this.$store.state.nlu.classifier.isFetchingLabels) {
        return { text: 'Fetching label data...', variant: 'primary', showSpinner: true };
      }
      return { text: 'New training', variant: 'primary', showSpinner: false };
    },
    versionList() {
      return Object.values(this.versions);
    },
    isSWML() {
      return this.activeModel.type === 'swml';
    },
    versionFields() {
      if (this.isSWML) {
        return ['created_time', 'description'];
      }
      return [
        { key: 'created_time', formatter: (x) => moment(x).format('YYYY-MM-DD HH:mm:ss') },
        { key: 'classifierversionbot.train_task.status', label: 'Training state', formatter: trainStateFormatter },
        { key: 'classifierversionbot.accuracy', label: 'accuracy', formatter: percentageFormatter },
      ];
    },
  },
  created() {
    this.lastUpdated = new Date();
    this.fetchClassifierVersions({
      clfId: this.activeModel.id,
      page: this.currentPage,
    });
    this.intervalId = setInterval(() => {
      if (!this.isFetchingVersions) {
        this.lastUpdated = new Date();
        this.fetchClassifierVersions({
          clfId: this.activeModel.id,
          page: this.currentPage,
        });
      }
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapMutations('nlu/classifier', ['setGlobalIsTrainingTmp', 'setVersionsPaginationPage']),
    ...mapActions('nlu/classifier', ['fetchClassifierVersions', 'fetchTrainingTask']),
    ...mapActions('sidebar', ['showWarning']),
    onRowClicked(item) {
      this.$router.push({ name: 'nlu-version-details', params: { versionId: item.id } });
    },
    async startTrain() {
      const msg = 'You need a minimum of two label categories and 100 datapoints to train a model.';
      const labelVals = Object.values(this.labels);
      if (labelVals.length < 2) {
        this.showWarning({
          title: 'An error occured',
          text: msg,
          variant: 'danger',
        });
        return;
      }
      const rows = labelVals.reduce((a, b) => a + b.rows, 0);
      if (rows >= 100) {
        this.setGlobalIsTrainingTmp(true);
        const data = { clfId: this.activeModel.id };
        await axios.post(endpoints.trainTask, data, {
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
        await this.fetchClassifierVersions({
          clfId: this.activeModel.id,
          page: this.currentPage,
        });
        this.fetchTrainingTask(false);
        this.setGlobalIsTrainingTmp(false);
      } else {
        this.showWarning({
          title: 'An error occured',
          text: msg,
          variant: 'danger',
        });
      }
    },
  },
};
</script>
