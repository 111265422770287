<template>
  <div>
    <div
      v-for="child in children"
      :key="`${child.id}ChildOfMC`"
      class="mb-3"
    >
      <b-row
        :id="'GroupDisplayName' + child.id"
        no-gutters
      >
        <b-col
          :cols="alwaysOpen ? '12' : '4'"
          :class="alwaysOpen ? '' : 'text-right'"
          class="my-auto pr-2"
        >
          <label
            class="m-0"
            :for="'displayName' + child.id"
          >
            <b-link :to="linkToNode(child.id)">{{ child.name }} </b-link>
            <voicebot-audio
              v-if="voicebotPlatformSelected"
              v-b-tooltip.noninteractive
              :text="getDisplayNameForId(child.id) || getDefaultDisplayName(child.id)"
              small
              title="Play this message"
              class="border-0 bg-transparent shadow-none ml-1"
            />
          </label>
        </b-col>
        <b-col
          :cols="alwaysOpen ? '12' : '8'"
          class="my-auto"
        >
          <b-input-group>
            <b-form-input
              :id="'displayName' + child.id"
              :ref="'input' + child.id"
              :disabled="getDirectMatchOnlyForId(child.id)"
              :value="getDisplayNameForId(child.id)"
              :placeholder="getDefaultDisplayName(child.id)"
              type="text"
              required
              @focus="autoFill(child.id)"
              @input="displayName =>
                setDisplayNameForId({ childId: child.id, displayName: displayName })"
            />
            <display-name-enhance
              v-if="enableResponseEnhancement"
              :text-element="() => $refs['input' + child.id][0]"
              :default-name="child.name"
              class="position-absolute mt-1 mb-1"
              variant="link"
              size="sm"
              :style="{
                right: '0',
                top: '0',
                zIndex: '3',
                marginRight: !isStrictMpc ? '4rem' : '2rem',
              }"
              @result="selected => replaceSelected(child.id, selected)"
            />
            <template #append>
              <b-button
                v-b-tooltip.hover.noninteractive.viewport
                :title="getDirectMatchOnlyForId(child.id) ? 'Click to show when presenting multiple choice options' : 'Click to hide when presenting multiple choice options'"
                @click="setDirectMatchOnlyForId({
                  childId: child.id,
                  isDirectMatch: !getDirectMatchOnlyForId(child.id),
                })"
              >
                <font-awesome-icon :icon="getDirectMatchOnlyForId(child.id) ? 'eye-slash' : 'eye'" />
              </b-button>
              <b-button
                v-if="!isStrictMpc"
                v-b-tooltip.hover.noninteractive.viewport
                :title="getAutoThresholdEnabledForId(child.id) ? 'Remove jump threshold override' : 'Override jump threshold'"
                @click="toggleAutoThreshold(child.id)"
              >
                <font-awesome-icon :icon="getAutoThresholdEnabledForId(child.id) ? 'times' : 'sliders'" />
              </b-button>
            </template>
          </b-input-group>
          <div v-if="longNameWarning(child.id)">
            <small class="text-warning">
              <font-awesome-icon icon="circle-exclamation" />
              We recommend using short and concise display names.
              {{ aiRecommendation }}
            </small>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="isSupchatPlatform"
        :id="'GroupDescription' + child.id"
        class="pt-1"
        no-gutters
      >
        <b-col
          :cols="alwaysOpen ? '12' : '4'"
          :class="alwaysOpen ? 'text-left' : 'text-right'"
          class="my-auto pr-2"
        >
          <label
            class="m-0"
            :for="'description' + child.id"
          >
            <small>
              Button description
            </small>
          </label>
        </b-col>
        <b-col class="my-auto">
          <b-form-input
            :id="'description' + child.id"
            :disabled="getDirectMatchOnlyForId(child.id)"
            :value="getDescriptionForId(child.id)"
            placeholder=""
            type="text"
            @input="description => setDescriptionForId({ childId: child.id, description })"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="getAutoThresholdEnabledForId(child.id) && !isStrictMpc"
        no-gutters
        class="pt-2"
      >
        <b-col
          :cols="alwaysOpen ? '12' : '4'"
          :class="alwaysOpen ? 'text-left' : 'text-right'"
          class="my-auto pr-2"
        >
          <label
            class="m-0 pb-1"
            :for="'threshold' + child.id"
          >
            Override direct jump threshold
          </label>
        </b-col>
        <b-col class="my-auto">
          <input
            :id="'threshold' + child.id"
            :aria-labelledby="'threshold' + child.id"
            type="range"
            class="custom-range"
            :class="alwaysOpen ? '' : 'pt-3 mt-1'"
            :value="getAutoThresholdValueForId(child.id)"
            @input="(event) => setAutoThresholdValueForId({
              childId: child.id,
              threshold: event.target.value,
            })"
          />
          <small v-if="Number(getAutoThresholdValueForId(child.id)) < 20" class="text-warning">
            <font-awesome-icon icon="circle-exclamation" />
            Very low threshold override values may cause undesired
            behavior where we jump to this node too often.
          </small>
          <div style="float:right">
            <div v-if="Number(getAutoThresholdValueForId(child.id)) === 100">
              Never go directly to {{ child.name }}
            </div>
            <div v-else>
              {{ getAutoThresholdValueForId(child.id) }}%
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { applyThisArgs, addThisArgs } from '@/js/storeHelpers';
import VoicebotAudio from '@/components/VoicebotAudio.vue';
import DisplayNameEnhance from '@/pages/EditNode/SmartNode/DisplayNameEnhance.vue';

export default {
  name: 'EditChildNodeDisplayName',
  components: {
    VoicebotAudio,
    DisplayNameEnhance,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      arrow: 'angle-down',
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'getPlatforms',
    ]),
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      activeNode: 'nodeById',
      isStrictMpc: 'isStrictMpc',
      getDisplayNameForId: 'getDisplayNameForId',
      getDescriptionForId: 'getDescriptionForId',
      getDirectMatchOnlyForId: 'getDirectMatchOnlyForId',
      getAutoThreshold: 'getAutoThreshold',
      getAutoThresholdEnabledForId: 'getAutoThresholdEnabledForId',
      getAutoThresholdValueForId: 'getAutoThresholdValueForId',
    }), 'nodeId'),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    ...mapGetters('userSettings', ['isVoicebotPlatform', 'showAIFeatures']),
    children() {
      return this.activeNode.children.map((id) => this.nodeById(id));
    },
    voicebotPlatformSelected() {
      return this.isVoicebotPlatform(this.getPlatforms);
    },
    isSupchatPlatform() {
      return this.getPlatforms.includes('supchat');
    },
    enableResponseEnhancement() {
      return this.showAIFeatures;
    },
    aiRecommendation() {
      return this.enableResponseEnhancement ? 'Consider using the AI button to generate a short display name.' : '';
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', [
      'setDisplayNameForId',
      'setDescriptionForId',
      'setDirectMatchOnlyForId',
      'setAutoThresholdValueForId',
      'setAutoThresholdStateForId',
    ]), { id: 'nodeId' }),
    getDefaultDisplayName(id) {
      for (const child of this.children) {
        if (child.id === id) {
          return child.name;
        }
      }
      return '';
    },
    linkToNode(nodeId) {
      return { name: 'edit-node', params: { botId: this.$route.params.botId, nodeId } };
    },
    autoFill(id) {
      if (this.getDisplayNameForId(id).length === 0) {
        this.setDisplayNameForId({ childId: id, displayName: this.getDefaultDisplayName(id) });
      }
    },
    async replaceSelected(id, txtToReplace) {
      this.setDisplayNameForId({ childId: id, displayName: txtToReplace });
    },
    longNameWarning(id) {
      return this.getDisplayNameForId(id).length > 30;
    },
    toggleAutoThreshold(id) {
      const newState = !this.getAutoThresholdEnabledForId(id);
      this.setAutoThresholdStateForId({
        childId: id,
        value: newState,
      });
      if (newState) {
        if (!this.getAutoThresholdValueForId(id) && this.getAutoThresholdValueForId(id) !== 0) {
          this.setAutoThresholdValueForId({
            childId: id,
            threshold: this.getAutoThreshold,
          });
        }
      }
    },
  },
};
</script>
