import axios from 'axios';
import Vue from 'vue';
import isEqual from 'lodash/isEqual';
import { deepCopyJson } from 'supwiz/util/data';
import endpoints from '@/js/urls';
import { actionMutations, actionGetters, apiActionActions } from '@/store/botManipulation/activeBot/actions';
import activeBot from './activeBot/index';

const initialState = {
  activeBotSet: false,
  activeBotId: null,
  activeSubFlowId: null,
  activePhraseId: null,

  botsList: [],
  botTemplates: [],
  isBotsLoading: false,
  subFlows: null,
  customNode: null,
  runningTasks: { info: [] },
  stagedBots: null,

  diffTwoBots: null,

  botHistoryLoading: false,
  botHistorySize: 0,
  botHistory: null,
};

export const botManipulationGetters = {
  ...actionGetters,
  // Functions for dealing with the config of the current bot
  getRunningTasks(state) {
    return state.runningTasks;
  },
  getSubFlows(state) {
    return state.subFlows ? state.subFlows : [];
  },
  getSubFlowNameFromId: (state) => (id) => {
    if (state.subFlows) {
      return state.subFlows.find((e) => e.id === id).config.name;
    }
    return null;
  },
  getBotId(state) {
    return state.activeBotSet ? state.activeBot.id : null;
  },

  getStagedBots(state) {
    return state.stagedBots ? state.stagedBots : [];
  },
  lastStagedBot(state) {
    if (!state.stagedBots || state.stagedBots.length === 0) {
      return null;
    }
    return state.stagedBots.reduce(
      // eslint-disable-next-line no-confusing-arrow
      (bLast, b) => bLast.timestamp < b.timestamp ? b : bLast,
      state.stagedBots[0],
    );
  },
  getBotNameFromIdInSubflow: (state) => (botId) => {
    const botIndex = state.botsList.findIndex((bot) => bot.id === botId);
    if (botIndex >= 0) {
      return state.botsList[botIndex].name;
    }
    return `bot${botId}`;
  },
  getBotNameFromId: (state) => (botId) => {
    if (state.activeBotSet && state.activeBotId === botId) {
      return state.activeBot.config.name;
    }
    const botIndex = state.botsList.findIndex((bot) => bot.id === botId);
    if (botIndex >= 0) {
      return state.botsList[botIndex].name;
    }
    return `bot${botId}`;
  },
  getOtherBotNames(state) {
    const activeBotId = state.activeBot.id;
    return state.botsList.filter((bot) => bot.id !== activeBotId).map((bot) => bot.name);
  },
  customNode(state) {
    return state.customNode;
  },
  activeBotId(state) {
    return state.activeBotId;
  },
  botsNotHidden(state) {
    return state.botsList ? state.botsList.filter((bot) => !(bot.hidden)) : [];
  },
  botHistoryLoading(state) {
    return state.botHistoryLoading;
  },
  botHistorySize(state) {
    return state.botHistorySize;
  },
  botHistory(state) {
    return state.botHistory;
  },
  getDiffTwoBots(state) {
    return state.diffTwoBots;
  },
  getActiveSubFlowId(state) {
    return state.activeSubFlowId;
  },
  botsList(state) {
    return state.botsList;
  },
  activePhrase: (state) => (state.activePhraseId
    ? state.activeBot.phrases[state.activePhraseId] : null),
};

export const mutations = {
  ...actionMutations,
  /**
   * Set the value of activeBotId
   * @param state
   * @param {string} payload: the name of activeBot from web
   */
  setActiveBotId(state, payload) {
    state.activeBotId = payload;
  },
  setActiveSubFlowId(state, payload) {
    state.activeSubFlowId = payload;
  },
  setBotsList(state, payload) {
    state.botsList = payload;
  },
  setBotTemplates(state, payload) {
    state.botTemplates = payload;
  },
  setCustomNode(state, payload) {
    state.customNode = payload;
  },
  setSubFlows(state, payload) {
    state.subFlows = payload;
  },
  addNodeToNodeList(state, newNode) {
    state.activeBot.nodeIds.push(newNode.id);
    Vue.set(state.activeBot.nodes, newNode.id, newNode);
  },
  setRunningTasks(state, data) {
    state.runningTasks = data;
  },
  setRootNode(state, payload) {
    Vue.set(state.activeBot, 'root', payload);
  },
  setStagedBots(state, payload) {
    state.stagedBots = payload;
  },
  setActiveBotSet(state, { isSet }) {
    state.activeBotSet = isSet;
  },
  setDiffTwoBots(state, payload) {
    state.diffTwoBots = payload;
  },
  setIsBotsLoading(state, val) {
    state.isBotsLoading = val;
  },
  setBotHistoryLoading(state, val) {
    state.botHistoryLoading = !!val;
  },
  setBotHistorySize(state, val) {
    state.botHistorySize = val;
  },
  setBotHistory(state, payload) {
    state.botHistory = payload;
  },
  setActivePhraseId(state, id) {
    state.activePhraseId = id;
  },
};

function isDict(x) {
  return x && x.constructor === Object;
}

function tryMerge(a, b, c, path = []) {
  const allDicts = isDict(a) && isDict(b) && isDict(c);
  const noDicts = !isDict(a) && !isDict(b) && !isDict(c);
  if (!allDicts && !noDicts) {
    throw new Error('a, b, c should either all be objects or none of them should');
  }
  if (noDicts) {
    if (!isEqual(a, b) && !isEqual(b, c) && !isEqual(c, a)) {
      throw new Error('Cannot merge');
    }
    return isEqual(a, b) ? c : b;
  }
  const allKeys = {};
  for (const key of Object.keys(a)) {
    allKeys[key] = true;
  }
  for (const key of Object.keys(b)) {
    allKeys[key] = true;
  }
  for (const key of Object.keys(c)) {
    allKeys[key] = true;
  }
  const d = {};
  for (const key of Object.keys(allKeys)) {
    if (a[key] !== undefined) {
      if (b[key] !== undefined && c[key] !== undefined) {
        // If key is in a, b, and c => merge recursively
        d[key] = tryMerge(a[key], b[key], c[key], path.concat([key]));
      } else if (c[key] !== undefined) { // if key in a and c but not b
        if (!isEqual(a[key], c[key])) {
          // b deleted the key but c changed it. This is a merge error.
          throw new Error('Cannot merge');
        }
        // If b deleted the key and a and c has the same key. We consider it a deletion.
      } else if (b[key] !== undefined) { // If key in a and b but not in c
        if (!isEqual(a[key], b[key])) {
          // c deleted the key and b changed its value. This is a merge error.
          throw new Error('Cannot merge');
        }
        // c deleted the key while b did nothing. It should therefore be deleted.
      }
    } else if (b[key] !== undefined && c[key] !== undefined) {
      if (!isEqual(b[key], c[key])) {
        // If both b and c added the key but not the same, this is a merge error.
        throw new Error('Cannot merge');
      }
      d[key] = b[key];
    } else if (b[key] !== undefined) {
      d[key] = b[key]; // Only b contains the key
    } else {
      d[key] = c[key]; // Only c contains the key
    }
  }
  return d;
}

export const actions = {
  ...apiActionActions,
  resetBotState({ commit, dispatch }) {
    commit('setActiveBotSet', { isSet: false });
    commit('setActiveBotId', null);
    commit('setActiveSubFlowId', null);
    commit('setActivePhraseId', null);
    commit('setStagedBots', null);
    commit('setBotHistorySize', 0);
    commit('setBotHistory', null);
    dispatch('activeBot/resetBotState');
  },
  addNode({ dispatch, commit }, newNode) {
    const newNodeCp = { ...newNode };
    commit('addNodeToNodeList', newNodeCp);
    if ('preds' in newNodeCp) {
      dispatch('handleParentChange');
    } else if ('children' in newNodeCp) {
      dispatch('handleChildChange');
    }
  },
  setActiveBot({ commit, dispatch, rootGetters }, bot) {
    commit('activeBot/setBot', bot);
    // If we loaded a mailbot, make sure to set the default stats filter to include ongoing
    // conversations. This will make sure it defaults correctly on the dashboards and stats page.
    if (rootGetters['botManipulation/activeBot/config/includeOngoingByDefault']) {
      commit(
        'statistics/filters/updateStatsFilter',
        { key: 'includeOngoing', newValue: true },
        { root: true },
      );
    } else {
      commit(
        'statistics/filters/updateStatsFilter',
        { key: 'includeOngoing', newValue: false },
        { root: true },
      );
    }
    commit('setActiveBotSet', { isSet: true });
    dispatch('treeView/init', { rootId: bot.root }, { root: true });
  },
  handleParentChange({ commit, dispatch }) {
    commit('activeBot/recomputeChildren');
    dispatch('treeView/normalize', undefined, { root: true });
    dispatch('graphView/normalize', undefined, { root: true });
  },
  handleChildChange({ commit, dispatch }) {
    commit('activeBot/recomputeParents');
    dispatch('treeView/normalize', undefined, { root: true });
    dispatch('graphView/normalize', undefined, { root: true });
  },
  async updateBot({
    commit, state, dispatch, rootState,
  }, { botReceived, ETag }) {
    commit('botSynchronization/setLastBotReceived', botReceived, { root: true });
    commit('botSynchronization/setLastBotETagReceived', { ETag }, { root: true });
    try {
      const bot = deepCopyJson(rootState.botSynchronization.lastBotSent
        ? tryMerge(rootState.botSynchronization.lastBotSent, botReceived, state.activeBot)
        : botReceived);
      commit('setActiveBotSet', { isSet: true });
      commit('activeBot/setBot', bot);
      await dispatch('treeView/normalize', undefined, { root: true });
      dispatch('graphView/normalize', undefined, { root: true });
      commit('botSynchronization/setSyncError', null, { root: true });
    } catch (e) {
      console.log(`Received sync error ${e}`);
      commit('botSynchronization/setSyncError', true, { root: true });
    }
  },
  async newBot({ rootState }, data) {
    return axios.post(endpoints.botsBase, data, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
  },

  async newSubFlow({
    state, commit, dispatch, rootState,
  }) {
    const resp = await axios.post(
      endpoints.subFlows,
      {
        bot_id: state.activeBotId,
        mode: 'new',
      },
      {
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      },
    );

    const response = await dispatch('getBackendSubFlows');
    commit('setSubFlows', response.data);
    return resp.data.id;
  },

  async deleteSubFlow({
    state, commit, dispatch, rootState,
  }, id) {
    await axios.post(
      endpoints.subFlows,
      {
        bot_id: state.activeBotId,
        mode: 'delete',
        subflow_id: id,
      },
      {
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      },
    );

    const response = await dispatch('getBackendSubFlows');
    commit('setSubFlows', response.data);
  },

  async updateBotList({ rootState, commit, dispatch }) {
    commit('setIsBotsLoading', true);
    try {
      const resp = await axios.get(endpoints.botsBase,
        { headers: { Authorization: `JWT ${rootState.auth.jwt}` } });
      commit('setBotsList', resp.data);
      commit('setIsBotsLoading', false);
    } catch (err) {
      dispatch('sidebar/showWarning', {
        title: 'An error occured',
        text: 'Failed to load the list of bots.',
        variant: 'danger',
      }, { root: true });
      throw err;
    }
  },

  async fetchBotTemplates({ rootState, commit }) {
    const resp = await axios.get(endpoints.botTemplates, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
    commit('setBotTemplates', resp.data.templates);
  },

  async fetchBotHistory({
    rootState, state, commit, dispatch,
  }, {
    pageSize = 20, page = 1, text0 = '', text1 = '',
  }) {
    try {
      commit('setBotHistoryLoading', true);
      commit('setBotHistory', []);
      const modelName = state.activeBot.config.type || 'bot';
      const modelId = state.activeSubFlowId ? state.activeBot.id : state.activeBotId;
      const url = `${endpoints.jsonHistory}${modelName}/${modelId}`;
      const resp = await axios.get(url, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        params: {
          pageSize, page, text0, text1,
        },
      });
      commit('setBotHistory', resp.data.history);
      commit('setBotHistorySize', resp.data.total);
    } catch (err) {
      dispatch('sidebar/showWarning', {
        title: 'A server error occurred',
        text: 'Failed to load bot history.',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setBotHistoryLoading', false);
    }
  },

  async restoreBotHistory({ rootState, state, dispatch }, restoreId) {
    try {
      const modelName = state.activeBot.config.type || 'bot';
      const modelId = state.activeSubFlowId ? state.activeSubFlowId : state.activeBotId;
      const url = `${endpoints.jsonHistory}${modelName}/${modelId}`;
      await axios.put(url,
        { history_id: restoreId },
        {
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      dispatch('sidebar/showWarning', {
        title: 'Bot is restored',
        text: 'The bot is now restored to a previous state.',
        variant: 'warning',
      }, { root: true });
    } catch (err) {
      dispatch('sidebar/showWarning', {
        title: 'A server error occurred',
        text: 'Failed to restore bot.',
        variant: 'danger',
      }, { root: true });
    }
  },

  async fetchBotFromHistory({ rootState, state, dispatch }, historyId) {
    try {
      const modelName = state.activeBot.config.type || 'bot';
      const modelId = state.activeSubFlowId ? state.activeSubFlowId : state.activeBotId;
      const url = `${endpoints.jsonHistory}${modelName}/${modelId}`;
      const resp = await axios.post(url,
        { history_id: historyId },
        {
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      return resp.data;
    } catch (err) {
      dispatch('sidebar/showWarning', {
        title: 'A server error occurred',
        text: 'Failed to find bot definition.',
        variant: 'danger',
      }, { root: true });
      return null;
    }
  },

  getCustomNode({ commit, rootState }) {
    return axios.get(endpoints.customNode,
      {
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      }).then((response) => {
      if (response.status === 200) {
        commit('setCustomNode', response.data);
      }
    });
  },

  async getBackendSubFlows({
    state, rootState, dispatch,
  }) {
    try {
      const resp = await axios.get(endpoints.subFlows,
        {
          params: { bot_id: state.activeBotId },
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        });
      if (resp.status === 200) {
        return resp;
      }
    } catch (err) {
      if (err.response.status === 403) {
        dispatch('sidebar/showWarning', {
          title: 'Permission denied',
          text: 'You do not have permissions to this bot. You must ask a superuser to grant you access.',
          variant: 'warning',
        }, { root: true });
      } else if (err.response.status === 404) {
        dispatch('sidebar/showWarning', {
          title: 'An error occurred',
          text: "This bot's subflow does not exists.",
          variant: 'danger',
        }, { root: true });
      }
      throw err;
    }
    return null;
  },

  /**
   * Load the bot from backend
   * because this function is always called after mutations function,
   * authentication is skipped here
   */
  async loadBot({
    state, dispatch, rootState, commit,
  }) {
    let url = endpoints.botsBase + state.activeBotId;
    const params = {};
    if (state.activeSubFlowId) {
      url = endpoints.subFlows + state.activeSubFlowId;
      params.bot_id = state.activeBotId;
    }
    try {
      const resp = await axios.get(url, {
        params,
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      });
      // Also fetch the list of variants. Otherwise the demo panel will break.
      await dispatch('variants/fetchVariants', null, { root: true });
      if (resp.status === 200) {
        commit('botSynchronization/setLastBotSent', null, { root: true });
        commit('botSynchronization/setLastBotReceived', resp.data, { root: true });
        commit('botSynchronization/setLastBotETagReceived', { ETag: resp.headers.etag }, { root: true });
        dispatch('setActiveBot', resp.data);
      }
    } catch (err) {
      if (err.response.status === 403) {
        dispatch('sidebar/showWarning', {
          title: 'Permission denied',
          text: 'You do not have permissions to this bot. You must ask a superuser to grant you access.',
          variant: 'warning',
        }, { root: true });
      } else if (err.response.status === 404) {
        dispatch('sidebar/showWarning', {
          title: 'An error occurred',
          text: 'This bot does not exists.',
          variant: 'danger',
        }, { root: true });
      } else {
        throw err;
      }
    }
  },

  // This should be called when the activeBotId (and optionally the activeSubFlowId) has been set
  // and you wish to load NLU models, unittests, etc., which are necessary in order to edit the
  // bot. Some of these things (e.g. unittests) are only necessary when editing the main bot, but
  // it will fetched regardless.
  async initializeEditing({ commit, dispatch }) {
    // stuff to load before the bot
    await dispatch('nlu/classifier/fetchGlobalNLUModelsNoVersions', null, { root: true });

    const subFlowsResponse = await dispatch('getBackendSubFlows');
    commit('setSubFlows', subFlowsResponse.data);

    const unitTestResponse = await dispatch('unitTest/getBackendUnitTests', null, { root: true });
    commit('unitTest/setUnitTests', unitTestResponse.data, { root: true });

    // Clear this first to avoid race-condition
    await dispatch('statistics/clearStatistics', {}, { root: true });
    // fetch stuff from backend
    await Promise.all([
      dispatch('synchronizeStagedBots'),
      dispatch('statistics/fetchNodeStatsNodes', null, { root: true }),
      dispatch('nodeInterpretations/fetchNodeInterpretations', null, { root: true }),
      dispatch('botSecrets/fetchSecrets', null, { root: true }),
      dispatch('statistics/fetchStatisticsSettings', null, { root: true }),
    ]);
  },

  async setBot({ commit, dispatch }, id) {
    commit('setActiveBotSet', false);
    commit('setActiveBotId', id);

    await dispatch('initializeEditing');

    // load the bot
    await dispatch('loadBot');
  },

  async editSubFlow({ commit, dispatch }, id) {
    commit('setActiveSubFlowId', id);

    await dispatch('initializeEditing');

    const subFlowsResponse = await dispatch('getBackendSubFlows');
    commit('setSubFlows', subFlowsResponse.data);
    return dispatch('loadBot');
  },

  async exitSubFlowEdit({ commit, dispatch }) {
    commit('setActiveSubFlowId', null);
    const subFlowsResponse = await dispatch('getBackendSubFlows');
    commit('setSubFlows', subFlowsResponse.data);
    return dispatch('loadBot');
  },

  updateInactivity({ commit }, payload) {
    const inactivity = payload.inactivity.slice(0);
    commit('activeBot/config/setInactivity', inactivity);
  },

  async synchronizeStagedBots({ state, rootState, commit }) {
    const stagedBotsResponse = await axios.get(endpoints.stagedBots,
      {
        params: { bot_id: state.activeBotId, include_variants: false },
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      });
    commit('setStagedBots', stagedBotsResponse.data);
  },

  async newStagedBot({
    state, dispatch, rootState,
  }, { name, description }) {
    if (state.activeBotSet) {
      await axios.post(endpoints.stagedBots,
        { bot_id: state.activeBotId, name, description },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        });
      await dispatch('synchronizeStagedBots');
    }
  },

  async deleteStagedBot({
    state, dispatch, rootState,
  }, { stagedBotId }) {
    if (state.activeBotSet) {
      // TODO: Currently, there is no check of the response in this function.
      await axios.delete(endpoints.stagedBots,
        {
          params: {
            staged_bot_id: stagedBotId,
          },
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        });
      await dispatch('synchronizeStagedBots');
    }
  },

  async changeStagedBotDescription({
    state, dispatch, rootState,
  }, { stagedBotId, description }) {
    if (state.activeBotSet) {
      // TODO: Currently there is no error handling.
      await axios.put(endpoints.stagedBots,
        { staged_bot_id: stagedBotId, description },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        });
      await dispatch('synchronizeStagedBots');
    }
  },

  async doDiffTwoBots({ state, rootState, commit }, { botid1, botid2 }) {
    const diffTwoBotsResponse = await axios.get(endpoints.diffTwoBots,
      {
        params: { current_bot_id: state.activeBotId, first_bot_id: botid1, second_bot_id: botid2 },
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      });
    commit('setDiffTwoBots', diffTwoBotsResponse.data);
  },

  async getAllResponses({ rootState, dispatch }, { botId, exportFormat }) {
    try {
      const response = await axios.get(
        endpoints.phrases, {
          responseType: exportFormat === 'excel' ? 'blob' : 'arraybuffer',
          params: {
            bot_id: botId,
            export_format: exportFormat,
          },
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        });
      return response.data;
    } catch (err) {
      await dispatch('sidebar/showWarning', {
        title: 'An error occurred',
        text: "Failed to export the bot's responses",
        variant: 'danger',
      }, { root: true });
      throw err;
    }
  },
};

export default {
  namespaced: true,
  modules: {
    activeBot,
  },
  state: initialState,
  getters: botManipulationGetters,
  mutations,
  actions,
};
