import axios from 'axios';
import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import endpoints from '@/js/urls';

const initialState = {
  unitTests: null,
  runningTasks: { info: [] },
  runningUnitTests: null,
  runningUnitTestStatus: null,
};

function testsStillRunning(status) {
  if (!status) {
    return false;
  }

  if ('ready' in status) {
    return !status.ready;
  }

  // Backward compatibility; some states are also missing
  return status.state === 'RUNNING' || status.state === 'PENDING';
}

function failRemainingTest(status, tests, serverMsg = 'Unknown error') {
  const newStatus = status ? cloneDeep(status) : {};
  const errorMsg = `Test Server responded: ${serverMsg}`;

  newStatus.state = 'FAILURE';
  newStatus.ready = true;
  if (!newStatus.info) {
    // Seen in responses too!
    newStatus.info = { total: 0, info: {} };
  }
  newStatus.info.current = null;

  // Fail remaining tests
  for (const info of Object.values(newStatus.info.info)) {
    if (!info.finished) {
      info.failed = true;
      info.finished = true;
      info.running = false;
      info.msgs.push(errorMsg);
    }
  }

  // If failing at once, no tests are reported, so add all of them, and let the gui sort it out.
  for (const test of tests) {
    if (!newStatus.info.info[test.key]) {
      newStatus.info.info[test.key] = {
        failed: true,
        finished: true,
        running: false,
        msgs: [errorMsg],
      };
      newStatus.info.total++;
    }
  }

  return newStatus;
}

const getters = {
  getUnitTests(state) {
    return state.unitTests ? state.unitTests : [];
  },
  getRunningUnitTests(state) {
    return state.runningUnitTests;
  },
  getRunningUnitTestStatus(state) {
    return state.runningUnitTestStatus;
  },
  totalRunningTests(state) {
    if (state.runningUnitTestStatus !== null && state.runningUnitTestStatus.info !== null) {
      return state.runningUnitTestStatus.info.total;
    }
    return null;
  },
  totalFinishedTests(state) {
    if (state.runningUnitTestStatus !== null && state.runningUnitTestStatus.info !== null) {
      return Object.values(state.runningUnitTestStatus.info.info)
        .filter((element) => element.finished).length;
    }
    return null;
  },
  totalFailedTests(state) {
    if (state.runningUnitTestStatus !== null && state.runningUnitTestStatus.info !== null) {
      return Object.values(state.runningUnitTestStatus.info.info)
        .filter((element) => element.failed)
        .length;
    }
    return null;
  },
  areTestsRunning(state) {
    return !!(state.runningUnitTests && state.runningUnitTestStatus
      && state.runningUnitTestStatus.info);
  },
};

const mutations = {
  setRunningUnitTests(state, payload) {
    // This should be null or an id returned by the backend.
    // The id is used to check the status of the tests.
    state.runningUnitTests = payload;
  },
  setRunningUnitTestStatus(state, payload) {
    state.runningUnitTestStatus = payload;
  },
  setUnitTests(state, payload) {
    state.unitTests = payload;
  },
  deleteUnitTestMutation(state, payload) {
    Vue.delete(state.unitTests, payload); // Assume payload is index
  },
  editUnitTestMutation(state, payload) {
    for (const [key, val] of Object.entries(payload)) {
      Vue.set(state.unitTests[payload.index], key, val);
    }
    // Test result is probably no longer valid:
    if (state.runningUnitTestStatus && state.runningUnitTestStatus.info) {
      Vue.delete(state.runningUnitTestStatus.info.info, state.unitTests[payload.index].key);
      state.runningUnitTestStatus.info.total -= 1;
    }
  },
  toggleUnitTestDetailsMutation(state, payload) {
    if (state.unitTests) {
      // eslint-disable-next-line no-underscore-dangle
      if (state.unitTests[payload.index]._showDetails) {
        Vue.set(state.unitTests[payload.index], '_showDetails', false);
      } else {
        Vue.set(state.unitTests[payload.index], '_showDetails', true);
      }
    }
  },

};

const actions = {
  async newUnitTest({ commit, dispatch, rootState }, payload) {
    await axios.post(endpoints.unitTests,
      {
        bot_id: rootState.botManipulation.activeBotId,
        ...payload,
      },
      {
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      });
    const unitTestResponse = await dispatch('getBackendUnitTests');
    commit('setUnitTests', unitTestResponse.data);
  },

  deleteUnitTest({
    state, commit, rootState,
  }, payload) {
    if (rootState.botManipulation.activeBotSet) {
      axios.delete(endpoints.unitTests,
        {
          params: {
            bot_id: rootState.botManipulation.activeBotId,
            key: state.unitTests[payload.index].key,
          },
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        }).then((response) => {
        if (response.status === 200) {
          commit('deleteUnitTestMutation', payload.index);
          commit('setRunningUnitTestStatus', null);
        }
      });
    }
  },

  editUnitTest({
    state, commit, rootState,
  }, payload) {
    if (rootState.botManipulation.activeBotSet) {
      const req = {
        bot_id: rootState.botManipulation.activeBotId,
        key: state.unitTests[payload.index].key,
      };
      for (const attr of ['name', 'path', 'content', 'enabled', 'test_path', 'test_content']) {
        if (attr in payload) {
          req[attr] = payload[attr];
        }
      }
      axios.put(
        endpoints.unitTests,
        req,
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        }).then((response) => {
        if (response.status === 200) {
          commit('editUnitTestMutation', payload);
        }
      });
    }
  },

  async runUnitTests({
    commit, rootState,
  }) {
    if (rootState.botManipulation.activeBotSet) {
      // Clear the data of any currently running test
      // TODO: There's possibly a race condition here if an updateUnitTestStatus call is running.
      await axios.post(endpoints.unitTestRunner,
        {
          bot_id: rootState.botManipulation.activeBotId,
        },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        }).then((response) => {
        if (response.status === 200) {
          commit('setRunningUnitTests', response.data.id);
        }
      });
    }
  },

  async runSpecificUnitTest({
    commit, rootState, dispatch,
  }, payload) {
    if (rootState.botManipulation.activeBotSet) {
      // Clear the data of any currently running test
      // TODO: There's possibly a race condition here if an updateUnitTestStatus call is running.
      dispatch('clearRunningTests', payload);

      await axios.post(endpoints.unitTestRunner,
        {
          bot_id: rootState.botManipulation.activeBotId,
          test_key: payload.key,
        },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        }).then((response) => {
        if (response.status === 200) {
          commit('setRunningUnitTests', response.data.id);
        }
      });
    }
  },

  clearRunningTests({ commit, rootState, state }, payload) {
    const taskId = state.runningUnitTests;
    commit('setRunningUnitTests', 'temp');
    const enabledTests = state.unitTests.filter((e) => e.enabled);
    const testStatus = {
      state: 'RUNNING',
      ready: false,
      info: {
        current: null,
        total: payload ? 1 : enabledTests.length,
        info: {},
      },
    };
    if (payload) {
      testStatus.info.info[payload.key] = {
        running: true, finished: false, msgs: [], failed: false,
      };
    } else {
      enabledTests.forEach((element) => {
        testStatus.info.info[element.key] = {
          running: true, finished: false, msgs: [], failed: false,
        };
      });
    }
    commit('setRunningUnitTestStatus', testStatus);
    if (taskId) {
      axios.post(endpoints.unitTestTermination,
        {
          id: taskId,
        },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        });
    }
  },

  terminateRunningTest({
    commit, rootState, state,
  }) {
    const taskId = state.runningUnitTests;
    commit('setRunningUnitTests', null);
    commit('setRunningUnitTestStatus', null);
    if (taskId) {
      axios.post(endpoints.unitTestTermination,
        {
          id: taskId,
        },
        {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        });
    }
  },

  updateUnitTestStatus({
    state, commit, dispatch, rootState,
  }) {
    if (state.runningUnitTests) {
      axios.get(endpoints.unitTestRunner,
        {
          params: {
            task_id: state.runningUnitTests,
          },
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        }).then((response) => {
        if (response.status === 200 && state.runningUnitTests) {
          if (testsStillRunning(response.data)) {
            // Update of tests, but could also be a pending with no data!
            // There is no easy way of telling between 1) tests done and forgotten,
            // 2) or tests not started yet, or 3) between unit tests.
            // 3) is more likely on stressed cpu, so not your developer machine!
            // 3) will still have ready attribute.
            if (response.data && response.data.info) {
              commit('setRunningUnitTestStatus', response.data);
            }
            setTimeout(() => {
              dispatch('updateUnitTestStatus');
            }, 2000);
          } else if ('TaskException' in response.data) {
            // Framework raised exception. Nothing will happen gain
            const exception = response.data.TaskException;
            const msg = exception.errorDescription || exception.exceptionType || 'Unknown';
            const newStatus = failRemainingTest(state.runningUnitTestStatus, state.unitTests, msg);
            commit('setRunningUnitTestStatus', newStatus);
          } else {
            // Test done
            commit('setRunningUnitTestStatus', response.data);
          }
        }
      }).catch((error) => {
        let msg = 'Test Server did not respond';
        if (error && error.response && error.response.data) {
          msg = error.response.data;
        }
        const newStatus = failRemainingTest(state.runningUnitTestStatus, state.unitTests, msg);
        commit('setRunningUnitTestStatus', newStatus);
      });
    }
  },

  getBackendUnitTests({ rootState }) {
    return axios.get(endpoints.unitTests,
      {
        params: { bot_id: rootState.botManipulation.activeBotId },
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
