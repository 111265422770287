<template>
  <main
    role="main"
    class="h-100"
  >
    <template v-if="objectsLoading">
      <b-row class="h-100 align-items-center">
        <b-col class="text-center">
          <b-spinner
            style="width: 5rem; height: 5rem;"
          />
        </b-col>
      </b-row>
    </template>
    <router-view v-else />
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AdminUsersGroups',
  computed: {
    ...mapState('administration', ['objectsLoading']),
  },
  async created() {
    await this.fetchUsers();
    await this.fetchGroups();
  },
  methods: {
    ...mapActions('administration', ['fetchUsers']),
    ...mapActions('administration', ['fetchGroups']),
  },
};
</script>
