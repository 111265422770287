<template>
  <base-activity
    ref="baseActivity"
    :node-id="nodeId"
    :activity-id="activityId"
    :indent="indent"
    bs-variant="success"
    :allow-expand="shouldAllowExpansion"
    :advanced-icon="advancedIcon"
    @shown="focusInput"
    @toggleExtraContents="toggleExtraContents"
  >
    <template #icon>
      <font-awesome-icon icon="cloud" />
    </template>
    <template #header>
      {{ cappedActionName }} {{ actionId === 'sleep'
        ? `(${filteredParams[0].value} seconds)` : '' }}
    </template>
    <b-card :body-class="graphView ? 'p-0 pr-2' : 'p-3'">
      <b-row
        v-for="(param, index) in filteredParams"
        :key="param.key"
        show
        variant="secondary"
        class="mt-1"
      >
        <template v-if="graphView">
          <b-col cols="12" class="font-weight-bold">
            <label>
              {{ param.key }}:
            </label>
          </b-col>
          <b-col cols="12">
            <chat-form
              v-if="actionId === 'chat.promptForm' && param.key === 'fields'"
              :node-id="nodeId"
              :activity-id="activityId"
            />
            <botscript-validation
              v-else
              :value="param.value"
              :expanded="multilineParam[param.key]"
              :placeholder="placeholders[param.key]"
              :validations="getValidations(param.key)"
              @onChange="text => setParamText(text, param.key, index)"
            />
          </b-col>
        </template>
        <template v-else>
          <b-col
            class="text-right font-weight-bold mt-2"
            :cols="actionId === 'chat.promptForm' ? 2 : 5"
          >
            <label>
              {{ param.key }}:
            </label>
          </b-col>
          <b-col :cols="actionId === 'chat.promptForm' ? 10 : 7">
            <chat-form
              v-if="actionId === 'chat.promptForm' && param.key === 'fields'"
              :node-id="nodeId"
              :activity-id="activityId"
            />
            <botscript-validation
              v-else
              :value="param.value"
              :expanded="multilineParam[param.key]"
              :placeholder="placeholders[param.key]"
              :validations="getValidations(param.key)"
              @onChange="text => setParamText(text, param.key, index)"
            />
          </b-col>
        </template>
      </b-row>
      <hr
        v-if="filteredParams.length > 0 && target !== null"
        class="separator"
      >
      <b-row
        v-if="target !== null"
        class="mt-1"
      >
        <b-col class="text-right font-weight-bold mt-2">
          Variable to save response in
        </b-col>
        <b-col cols="7">
          <VariableName
            :value="target"
            @input="target => setTarget({ target })"
          />
        </b-col>
      </b-row>
    </b-card>
  </base-activity>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';
import BaseActivity from '@/pages/EditNode/activity/BaseActivity.vue';
import VariableName from '@/components/VariableName.vue';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import {
  truncateString, includeOrRemoveParams, changeOrAddParam,
} from '@/js/utils';
import chatActions from '@/js/chatActions';
import BotscriptValidation from '@/components/BotscriptValidation.vue';
import { platforms } from '@/js/constants';
import ChatForm from '@/pages/EditNode/activity/ChatForm.vue';

export default {
  name: 'ChatActionActivity',
  components: {
    BaseActivity,
    VariableName,
    BotscriptValidation,
    ChatForm,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showExtraContents: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', ['getPlatforms']),
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      target: 'activityTarget',
      name: 'activityName',
      params: 'activityParams',
      actionId: 'activityId',
    }), 'nodeId', 'activityId'),
    graphView() {
      return this.$route.name === 'graph';
    },
    defaultAction() {
      const defaultAction = chatActions.find((a) => a.id === this.actionId);
      if (!defaultAction) {
        return chatActions.find((a) => a.id === 'MISSING_CHAT_ACTION');
      }
      return defaultAction;
    },
    cappedActionName() {
      if (!this.target) {
        return truncateString(this.name, 40);
      }
      return truncateString(`${this.target} = ${this.name}`, 40);
    },
    advancedIcon() {
      if (this.defaultAction.extraParams) {
        return this.showExtraContents ? 'angle-up' : 'angle-down';
      }
      return '';
    },
    normalParams() {
      // Old bots may be missing added attributes
      if (!this.defaultAction) {
        return this.params;
      }
      const allParams = this.params.slice();
      for (const pDefault of this.defaultAction.params) {
        if (!allParams.some((p) => p.key === pDefault.name)) {
          allParams.push({ key: pDefault.name, value: pDefault.default });
        }
      }
      return allParams;
    },
    shouldAllowExpansion() {
      return this.defaultAction.allowsExpansion;
    },
    multilineParam() {
      const ret = {};
      for (const item of this.defaultAction.params) {
        ret[item.name] = Boolean(item?.multiline);
      }
      return ret;
    },
    placeholders() {
      const ret = {};
      for (const item of this.defaultAction.params) {
        ret[item.name] = item.placeholder;
      }
      for (const item of this.defaultAction.extraParams || []) {
        ret[item.name] = item.placeholder;
      }
      return ret;
    },
    filteredParams() {
      return includeOrRemoveParams(this.normalParams,
        this.showExtraContents,
        this.defaultAction.extraParams);
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', {
      setTarget: 'setActivityTarget',
      setParams: 'setActivityParams',
    }), { nodeId: 'nodeId', activityId: 'activityId' }),
    setParamText(value, paramKey, indexHint) {
      const newParams = changeOrAddParam(this.params, paramKey, value, indexHint);
      this.setParams({ params: newParams });
    },
    focusInput() {
      const overlayElement = this.$refs.baseActivity?.$children[4]?.$children[0]?.$children[0];
      if (overlayElement && Object.prototype.hasOwnProperty.call(overlayElement, 'focus')) {
        overlayElement.focus();
      }
    },
    focusActivity() {
      this.$refs.baseActivity.focusActivity();
    },
    toggleExtraContents() {
      this.showExtraContents = !this.showExtraContents;
    },
    getValidations(key) {
      const validations = ['empty', 'typecheck-nonbool'];
      if (key === 'tag') {
        validations.push('typecheck-tag');
        if (this.getPlatforms.includes(platforms.ZENDESK)) {
          validations.push('zendeskTag');
        }
      }
      return validations;
    },
  },
};

</script>

<style scoped>

</style>
