<template>
  <main>
    <b-card
      title="Bot training"
      class="r-75"
      body-class="p-3"
    >
      <p class="mb-2">
        Here you can evaluate the predictions of the bot to help it get better.
      </p>
      <p
        v-if="!isUserLimited"
        class="mb-2"
      >
        Go to <b-link :to="{ name: 'classifiers' }">
          classifiers
        </b-link> page to see the classifiers used for predictions.
      </p>
      <div
        class="text-muted mt-1"
        style="font-size: x-small"
      >
        Note: Your evaluations will first have an effect when a new classifier
        version is trained and the classifier version on the bot is updated. You can
        set this up to happen automatically.
      </div>
      <hr>
      <b-tabs v-model="currentTab" content-class="mt-3" @input="v=>tabChanged(v)">
        <b-tab
          v-for="tab in [0, 1]"
          :key="tab"
          :title="tab ? 'Select dataset' : 'Filter data'"
        >
          <b-row>
            <b-col class="pr-1">
              <b-form-group
                label="Variant"
                label-class="pb-0"
                class="mb-1"
              >
                <b-overlay :show="variantOptions === null">
                  <b-form-select
                    v-model="selectedVariant"
                    :options="variantOptions"
                  />
                </b-overlay>
              </b-form-group>
            </b-col>
            <b-col class="pl-1">
              <b-form-group
                label="Language"
                label-class="pb-0"
                class="mb-0"
              >
                <b-dropdown
                  no-flip
                  class=""
                  block
                  toggle-class="bg-white text-dark text-left font-weight-normal"
                  menu-class="bg-white"
                  @hide="languageFilter = ''"
                >
                  <template
                    #button-content
                  >
                    <span class="btn-content">
                      {{ selectedLanguage }}
                    </span>
                  </template>
                  <b-dropdown-item @click.native.capture.stop>
                    <b-form-input
                      v-model="languageFilter"
                      placeholder="Type to search"
                      class="bg-white"
                    />
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item
                    v-for="(item, index) in filteredLanguageOptions"
                    :key="index"
                    class="text-dark"
                    @click="selectedLanguage = item.value"
                  >
                    {{ item.text }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-form-group>
            </b-col>
          </b-row>
          <div v-if="tab === 0">
            <date-time-lang-picker
              date
              class="my-2"
              shortcuts
              :getter="$store.state.nodeLabels.filters"
              :setter="updateTrainingFilter"
            />
            <b-row>
              <b-col class="pr-1">
                <b-form-textarea
                  v-model="text"
                  class="mt-1"
                  placeholder="Text in visitor messages to search for"
                  rows="5"
                  max-rows="5"
                />
              </b-col>
              <b-col class="pl-1">
                <chip-list
                  class="mb-1 bg-white"
                  :completions="nodeNames"
                  :value="selectedNodeNames"
                  placeholder="Require visit to nodes"
                  @input="setRequireNode"
                />
                <b-form-radio-group
                  v-model="selfServedFilter"
                  class="mx-1 mt-1 d-inline-block"
                >
                  <b-form-radio value="self-served">
                    Self-served chats
                  </b-form-radio>
                  <b-form-radio value="not self-served">
                    Not self-served chats
                  </b-form-radio>
                  <b-form-radio value="all">
                    Don't filter by self-served
                  </b-form-radio>
                </b-form-radio-group>
                <chip-list
                  class=" mb-1 bg-white"
                  :completions="nodeNames"
                  :value="ignoreNodeNames"
                  placeholder="Exclude visit to nodes"
                  @input="setIgnoreNodes"
                />
              </b-col>
            </b-row>
          </div>

          <div v-else>
            <b-row class="my-2">
              <b-col>
                <b-form-input v-model="datasetKeyword" size="sm" placeholder="Type to search datasets" />
              </b-col>
            </b-row>
            <b-table
              ref="datasetTable"
              sticky-header="400px;"
              :items="filteredDataset"
              :fields="fields"
              show-empty
              :sort-compare="sortCompare"
              selectable
              hover
              select-mode="single"
              row-class="cursor-pointer"
              :busy="isFetchingDatasets"
              @row-clicked="rowClicked"
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle" />
                </div>
              </template>
              <template #cell(timeRange)="row">
                <span v-if="row.item.start_time && row.item.end_time">
                  {{ new Date(row.item.start_time).toLocaleDateString() }} -
                  {{ new Date(row.item.end_time).toLocaleDateString() }}
                </span>
                <span v-else>N/A</span>
              </template>
              <template #cell(labeled)="row">
                {{ row.item.label_count }}/{{ row.item.labelable_count }}
              </template>
              <template #cell(delete)="row">
                <b-button v-b-tooltip.hover.noninteractive.viewport="'Delete dataset'" size="sm" @click="deleteDataset(row.item.id)">
                  <font-awesome-icon icon="trash-alt" />
                </b-button>
              </template>
            </b-table>
          </div>
        </b-tab>
      </b-tabs>
      <b-row class="mt-3 mb-2">
        <b-col>
          <b-input-group>
            <b-input-group-prepend is-text>
              <span style="width:100px;"> Table view
                <tooltipped-text
                  class="ml-2"
                  value="Display multiple chats in a table instead of viewing single chat."
                /></span>
            </b-input-group-prepend>
            <b-input-group-append>
              <b-button style="width:150px" :variant="tableView ? 'success' : ''" @click="tableView = !tableView">
                {{ tableView ? 'Enabled' : 'Disabled' }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row v-if="tableView">
        <b-col cols="6" class="pr-1">
          <b-input-group class="w-100">
            <b-input-group-prepend is-text>
              <span style="width:100px;">Focus node</span>
            </b-input-group-prepend>
            <b-input-group-append>
              <b-dropdown toggle-class="focus-node-btn" menu-class="focus-node-dropdown" @hide="focusNodeKeyword = ''">
                <template #button-content>
                  {{ focusNode ? nameOfId(focusNode) : 'Select a node' }}
                </template>
                <b-dropdown-form>
                  <b-input v-model="focusNodeKeyword" placeholder="Type to search" />
                </b-dropdown-form>
                <b-dropdown-divider />
                <b-dropdown-item-button
                  v-for="(node, index) in focusNodeOptions"
                  :key="node.value + index"
                  class="focus-node-item"
                  @click="focusNode = node.value"
                >
                  {{ node.text }}
                </b-dropdown-item-button>
              </b-dropdown>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-btn
            variant="primary"
            :disabled="isFetching"
            @click="$root.$emit('refreshTraining', null)"
          >
            Refresh
          </b-btn>
          <div class="d-inline">
            <b-dropdown
              id="dropdown-1"
              class="ml-2"
              text="Choose data sources"
            >
              <b-dropdown-form>
                <b-form-checkbox
                  v-for="source in availableDataOrigins"
                  :key="source.rawValue"
                  v-model="selectedOrigins"
                  :value="source"
                  class="text-nowrap align-middle"
                >
                  {{ source.displayName }}
                </b-form-checkbox>
              </b-dropdown-form>
            </b-dropdown>
            <span
              v-if="selectedOrigins.length === 0"
              class="ml-2 text-warning"
            >
              <font-awesome-icon
                icon="exclamation-circle"
              />
              Choose sources
            </span>
          </div>
          <b-dropdown
            id="dropdown-1"
            class="ml-2"
            :text="chatRatings.length ? `Chat ratings (${chatRatings.length})` : 'Chat ratings'"
          >
            <b-dropdown-form>
              <b-form-checkbox-group
                v-model="chatRatings"
                :options="ratingsOptions"
                stacked
                class="text-nowrap align-middle"
              />
            </b-dropdown-form>
          </b-dropdown>
          <div class="d-inline">
            <b-dropdown
              id="dropdown-1"
              class="ml-2"
            >
              <template #button-content>
                Filter conversations <strong>({{ currentNodeLabelFilter }})</strong>
              </template>
              <b-dropdown-form>
                <b-form-radio-group
                  v-for="option in nodeLabelFilterOptions"
                  :key="option.value"
                  v-model="nodeLabelFilter"
                  class="text-nowrap align-middle"
                >
                  <b-form-radio :value="option.value">
                    {{ option.text }}
                  </b-form-radio>
                </b-form-radio-group>
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <label-conversation :node-label-filter="nodeLabelFilter" />
  </main>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapState,
  mapMutations,
} from 'vuex';
import { validationMixin } from 'vuelidate';
import ChipList from 'supwiz/components/ChipList.vue';
import DateTimeLangPicker from '@/components/DateTimeLangPicker.vue';
import LabelConversation from '@/pages/Training/LabelConversation.vue';
import getLanguageName from '@/js/languageMap';
import TooltippedText from '@/components/TooltippedText.vue';

export default {
  name: 'TrainingPage',
  components: {
    DateTimeLangPicker,
    LabelConversation,
    TooltippedText,
    ChipList,
  },
  mixins: [validationMixin],
  data() {
    return {
      nodeLabelFilterOptions: [
        { text: 'Show all', value: null },
        { text: 'Only unlabeled', value: false },
        { text: 'Only submitted', value: true },
      ],
      nodeLabelFilter: false,
      focusNodeKeyword: '',
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'timeRange', label: 'Time range', sortable: true },
        { key: 'labeled', label: 'Labeled/Total', sortable: true },
        { key: 'origin', label: 'Origin', sortable: true },
        { key: 'delete', label: '' },
      ],
      datasetKeyword: '',
      languageFilter: '',
      ratingsOptions: [
        { value: 1, text: '1 star' },
        { value: 2, text: '2 stars' },
        { value: 3, text: '3 stars' },
        { value: 4, text: '4 stars' },
        { value: 5, text: '5 stars' },
      ],
    };
  },
  computed: {
    ...mapState('nodeLabels', [
      'filters',
      'isFetching',
      'isFetchingDatasets',
      'datasets',
      'activeTab',
    ]),
    ...mapGetters('auth', ['isUserLimited']),
    ...mapGetters('botManipulation/activeBot', [
      'nodesAsList',
      'specialNodes',
      'nodeById',
      'nodeByName',
      'nameOfId',
      'isRoot',
    ]),
    ...mapGetters('nodeLabels', [
      'selectedDataOrigins',
      'getTableView',
      'getFocusNode',
    ]),
    ...mapGetters('chatlogs', [
      'availableDataOrigins',
    ]),
    ...mapGetters('chatlogs', [
      'availableLanguages',
      'availableVariants',
    ]),
    ...mapGetters('variants', [
      'getNameFromId',
    ]),
    currentNodeLabelFilter() {
      return this.nodeLabelFilterOptions.find((e) => e.value === this.nodeLabelFilter).text;
    },
    tableView: {
      get() {
        return this.getTableView;
      },
      set(value) {
        this.setTableView(value);
        if (!value) {
          this.setFocusNode(null);
        }
      },
    },
    focusNode: {
      get() {
        return this.getFocusNode;
      },
      set(value) {
        this.setFocusNode(value);
      },
    },
    focusNodeOptions() {
      return this.nodesAsList
        .filter((e) => {
          if (this.isRoot(e.id)) return false;

          const node = this.nodeById(e.id);
          const hasChildWithMatching = !!node.children.filter((child) => {
            const childNode = this.nodeById(child);
            return (childNode.match.examples.length || childNode.match.useClfToMatch);
          }).length;
          return hasChildWithMatching
          && e.name.toLowerCase().includes(this.focusNodeKeyword.toLowerCase());
        })
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((e) => ({ value: e.id, text: e.name }));
    },
    filteredDataset() {
      return this.datasets.filter((dataset) => dataset.name
        .toLowerCase().includes(this.datasetKeyword.toLowerCase()));
    },
    variantOptions() {
      if (this.availableVariants === null) {
        return null;
      }
      const variants = this.availableVariants.map((x) => (
        { value: x, text: this.getNameFromId(x) || `Deleted variant (${x})` }
      ));
      variants.unshift({ value: null, text: 'No variant (main bot)' });
      variants.unshift({ value: '', text: 'Any variant' });
      return variants;
    },
    languageOptions() {
      if (this.availableLanguages === null) {
        return null;
      }
      const languages = [...this.availableLanguages];
      languages.unshift({ value: 'any', text: 'Any language' });
      return languages.map((e) => ({ value: e.value, text: this.getLanguageName(e.text) }));
    },
    filteredLanguageOptions() {
      return this.languageOptions?.filter(
        (x) => x.text.toLowerCase().includes(this.languageFilter.toLowerCase()));
    },
    ignoreNodeIds() {
      return this.filters.ignoreNodeIds;
    },
    nodeNames() {
      const alreadySelectedNodes = this.filters.passThroughNodeIds
        .concat(this.filters.ignoreNodeIds)
        .map((nodeId) => this.nodeById(nodeId));
      const unfilteredNodes = this.nodesAsList.concat(this.specialNodes)
        .filter((node) => !alreadySelectedNodes.includes(node))
        .map((node) => node.name);
      return unfilteredNodes;
    },
    selectedNodeNames() {
      return this.filters.passThroughNodeIds.map((nodeId) => this.nodeById(nodeId).name);
    },
    ignoreNodeNames() {
      return this.filters.ignoreNodeIds.map((nodeId) => this.nodeById(nodeId).name);
    },
    selectedVariant: {
      get() {
        return this.filters.selectedVariant;
      },
      set(newValue) {
        this.updateTrainingFilter({ key: 'selectedVariant', newValue });
      },
    },
    chatRatings: {
      get() {
        return this.filters.selectedRatings;
      },
      set(newValue) {
        this.updateTrainingFilter({ key: 'selectedRatings', newValue });
      },
    },
    selfServedFilter: {
      get() {
        return this.filters.selfServedFilter;
      },
      set(newValue) {
        this.updateTrainingFilter({ key: 'selfServedFilter', newValue });
      },
    },
    selectedLanguage: {
      get() {
        return this.languageOptions?.find((e) => e.value === this.filters.selectedLanguage)?.text;
      },
      set(newValue) {
        this.updateTrainingFilter({ key: 'selectedLanguage', newValue });
      },
    },
    text: {
      get() {
        return this.filters.freeText;
      },
      set(newValue) {
        this.updateTrainingFilter({ key: 'freeText', newValue });
      },
    },
    activeBot() {
      return this.$store.state.botManipulation.activeBot;
    },
    selectedOrigins: {
      get() {
        return this.selectedDataOrigins;
      },
      set(value) {
        this.setSelectedDataOrigins(value);
      },
    },
    currentTab: {
      get() {
        return this.activeTab;
      },
      set(value) {
        this.setActiveTab(value);
      },
    },
  },
  async mounted() {
    if (this.$route.query && Object.keys(this.$route.query).length > 0) {
      this.resetTrainingFilter();
    }
    await this.fetchVariants();
    await this.fetchDataOrigins();
    this.selectedOrigins = this.availableDataOrigins;
    if (this.$route.query.startDate && this.$route.query.endDate) {
      const startDate = new Date(this.$route.query.startDate);
      const endDate = new Date(this.$route.query.endDate);
      this.updateTrainingFilter({ key: 'endDate', newValue: endDate });
      this.updateTrainingFilter({ key: 'startDate', newValue: startDate });
    }
    if (this.$route.query.includeNodes) {
      this.updateTrainingFilter({ key: 'passThroughNodeIds', newValue: this.$route.query.includeNodes });
    }
    if (this.$route.query.rating) {
      this.updateTrainingFilter({ key: 'selectedRatings', newValue: this.$route.query.rating });
    }
    if (this.$route.query.selfServedFilter) {
      this.updateTrainingFilter({ key: 'selfServedFilter', newValue: this.$route.query.selfServedFilter });
    }
    const chatId = this.$route.hash ? this.$route.hash.substring(1) : null;
    this.$root.$emit('refreshTraining', chatId);
  },
  beforeDestroy() {
    this.setActiveTab(0);
    this.setTableView(false);
    this.setFocusNode(null);
  },
  methods: {
    getLanguageName,
    ...mapMutations('nodeLabels', [
      'setSelectedDataOrigins', 'updateTrainingFilter', 'resetTrainingFilter',
      'setSelectedDataset', 'setActiveTab', 'setTableView', 'setFocusNode',
    ]),
    ...mapActions('nodeLabels', ['fetchDatasets', 'deleteDataset']),
    ...mapActions('chatlogs', [
      'fetchDataOrigins',
      'fetchVariants',
    ]),
    tabChanged(index) {
      if (index === 1) {
        this.fetchDatasets();
      } else {
        this.setSelectedDataset(null);
        this.$refs.datasetTable[0].clearSelected();
      }
    },
    rowClicked(item) {
      this.setSelectedDataset(item.id);
    },
    setRequireNode(requiredNodes) {
      const nodeIds = requiredNodes.map((nodeName) => this.nodeByName(nodeName).id);
      this.updateTrainingFilter({ key: 'passThroughNodeIds', newValue: nodeIds });
    },
    setIgnoreNodes(ignoreNodes) {
      const nodeIds = ignoreNodes.map((nodeName) => this.nodeByName(nodeName).id);
      this.updateTrainingFilter({ key: 'ignoreNodeIds', newValue: nodeIds });
    },
    sortCompare(aRow, bRow, key) {
      let aValue;
      let bValue;
      if (key === 'timeRange') {
        aValue = new Date(aRow.start_time).getTime();
        bValue = new Date(bRow.start_time).getTime();
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      }
      if (key === 'labeled') {
        aValue = aRow.label_count / aRow.labelable_count;
        bValue = bRow.label_count / bRow.labelable_count;
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      }
      return null;
    },

  },
};

</script>
<style scoped>
::v-deep .b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
::v-deep .focus-node-dropdown{
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidde;
  width: 500px;
}
::v-deep .focus-node-btn{
  min-width: 150px;
}
::v-deep .focus-node-item > .dropdown-item {
  white-space: normal;
}
</style>
