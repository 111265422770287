<template>
  <div class="wrapper">
    <b-card
      class="r-75 shadow"
      body-class="p-2 p-lg-3"
    >
      <b-row no-gutters>
        <b-col
          class="pr-1 text-center"
          cols="6"
        >
          <h3
            class="mb-0 cursor-pointer tab-title r-25"
            :class="activeTab === 0 ? 'active-tab' : ''"
            @click="activeTab = 0"
          >
            General
          </h3>
        </b-col>
        <b-col class="px-1 text-center">
          <h3
            class="mb-0 cursor-pointer tab-title r-25"
            :class="activeTab === 1 ? 'active-tab' : ''"
            @click="activeTab = 1"
          >
            Details
          </h3>
        </b-col>
        <b-col
          cols="auto"
          class="pl-1"
        >
          <b-btn-group>
            <b-button
              v-b-tooltip.hover.noninteractiv.viewport
              class="filters-btn"
              :title="showFilters ? 'Hide filters' : 'Show filters'"
              :pressed="!showFilters"
              @click.exact="showFilters = !showFilters"
            >
              <font-awesome-icon icon="filter" />
            </b-button>
            <b-button
              v-b-tooltip.hover.noninteractiv.viewport
              class="filters-btn"
              :disabled="anyLoading || refreshValue"
              title="Download statistics"
              @click="$refs.statsFilters.downloadKPIs()"
            >
              <font-awesome-icon icon="download" />
            </b-button>

            <b-dropdown
              id="dropdown-1"
              ref="settingsDropdown"
              toggle-class="text-decoration-none filters-btn"
              class="filters-btn"
              type="text"
              no-caret
              menu-class="primary"
              right
              text="Dropdown Button"
            >
              <template #button-content>
                <font-awesome-icon icon="ellipsis-v" />
              </template>
              <b-dropdown-item
                v-b-toggle.statistics-description-sidebar
                variant="primary"
              >
                <font-awesome-icon icon="info-circle" />
                Statistics description
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.statistics-settings-modal
                variant="primary"
              >
                <font-awesome-icon icon="cogs" />
                Statistics settings
              </b-dropdown-item>
            </b-dropdown>
          </b-btn-group>
        </b-col>
      </b-row>
      <statistics-filter
        ref="statsFilters"
        :active-tab="activeTab"
        :show-filters="showFilters"
      />
    </b-card>
    <b-alert
      :show="!hasNodeInterpretations"
      dismissible
      variant="warning"
      class="text-center mt-3"
    >
      <h4>Warning!</h4>
      <p class="font-weight-bold">
        You are using the statistics page without setting up node interpretations.
        This will cause the statistics to potentially be incorrect or misleading.
      </p>
      <hr>
      <p>
        You must configure
        <b-button
          v-b-modal.statistics-settings-modal
          variant="link"
          class="p-0 pb-1"
          size="sm"
        >
          Node interpretations.
        </b-button>
      </p>
    </b-alert>
    <b-card
      v-if="refreshValue"
      class="mt-3 r-75 shadow"
    >
      <h4
        style="padding-top: 20px"
        class="my-auto text-center pb-3"
      >
        Click "Compute" to refresh statistics.
      </h4>
    </b-card>
    <div v-else-if="activeTab === 0">
      <b-row
        class="mt-3"
        no-gutters
      >
        <b-col
          class="mb-3 pr-xl-2 pr-2"
          cols="6"
          xl="3"
        >
          <NewKPICard
            title="Automation rate"
            format-type="percent"
            :value="getKPICardValue('automation_rate', 'actual')"
            :previous-value="getKPICardValue('automation_rate', 'previous')"
            :is-loading="isLoading('automation_rate')"
          />
        </b-col>
        <b-col
          class="mb-3 px-xl-2 pl-2"
          cols="6"
          xl="3"
        >
          <NewKPICard
            title="Estimated cost savings"
            format-type="currency"
            :currency="currencyCopy"
            :value="getKPICardValue('estimated_cost_savings', 'actual')"
            :previous-value="getKPICardValue('estimated_cost_savings', 'previous')"
            :is-loading="isLoading('estimated_cost_savings')"
            truncate
          />
        </b-col>
        <b-col
          class="mb-3 px-xl-2 pr-2"
          cols="6"
          xl="3"
        >
          <NewKPICard
            title="Number of conversations"
            format-type="number"
            :value="getKPICardValue('num_chats', 'actual')"
            :previous-value="getKPICardValue('num_chats', 'previous')"
            :is-loading="isLoading('num_chats')"
            truncate
          />
        </b-col>
        <b-col
          class="mb-3 pl-xl-2 pl-2"
          cols="6"
          xl="3"
        >
          <NewKPICard
            title="Estimated visitor time saved"
            format-type="hours"
            :value="getKPICardValue('estimated_user_time_saved', 'actual') / 60"
            :previous-value="getKPICardValue('estimated_user_time_saved', 'previous') / 60"
            :is-loading="isLoading('estimated_user_time_saved')"
            truncate
          />
        </b-col>
      </b-row>
      <automation-details />
      <b-row
        v-if="!(statsFilter.selectedLanguage !== 'any' || statsFilter.selectedVariant)"
        class="my-3"
      >
        <b-col>
          <b-overlay :show="isLoading('language_stats')">
            <b-card
              class="r-75 shadow text-center"
              body-class="p-3"
            >
              <b-card-title>
                KPIs per language
              </b-card-title>
              <b-table
                :items="languageTableItems"
                :fields="languageTableFields"
                hover
                responsive
                show-empty
                empty-text="This bot has no chats to show statistics for."
              >
                <template #cell(rating)="data">
                  <font-awesome-icon icon="star" /> {{ data.item.rating }}
                </template>
              </b-table>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <b-overlay :show="isLoading('distributions_over_time')">
            <b-card
              class="r-75 shadow text-center"
              body-class="p-3"
            >
              <b-row no-gutters>
                <b-col class="my-auto">
                  <b-card-title style="margin-right: -71px">
                    Distribution over time
                  </b-card-title>
                </b-col>
                <b-col
                  class="my-auto pr-2"
                  cols="auto"
                >
                  <b-button-group>
                    <b-button
                      v-b-tooltip.hover.noninteractive
                      variant="primary"
                      class="chart-type-button"
                      :pressed="selectedDistribution === distType.COUNT"
                      title="Volume"
                      @click.stop="selectedDistribution = distType.COUNT"
                    >
                      <font-awesome-icon icon="comment" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover.noninteractive
                      variant="primary"
                      :pressed="selectedDistribution === distType.COVERAGE"
                      class="chart-type-button"
                      title="Coverage"
                      @click.stop="selectedDistribution = distType.COVERAGE"
                    >
                      <font-awesome-icon icon="project-diagram" />
                    </b-button>
                    <b-button
                      v-if="isExternalStatsEnabled()"
                      v-b-tooltip.hover.noninteractive
                      variant="primary"
                      :pressed="selectedDistribution === distType.RATING"
                      class="chart-type-button"
                      title="Rating"
                      @click.stop="selectedDistribution = distType.RATING"
                    >
                      <font-awesome-icon icon="fa-solid fa-star" />
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
              <b-row
                no-gutters
                style="min-height: 400px;"
              >
                <b-col cols="12">
                  <plot-category-metric
                    class="mt-2 h-100"
                    style="height: 400px;"
                    :chart-data="chartData"
                    :chart-type="chartType"
                    :distribution="selectedDistribution"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
      <b-row
        v-if="isExternalStatsEnabled()"
        class="my-3"
      >
        <b-col>
          <b-overlay :show="isLoading('chat_ratings') || isLoading('num_chats')">
            <b-card
              title="User satisfaction"
              class="r-75 shadow text-center"
              body-class="p-3"
            >
              <template v-if="hasChatRatings">
                <b-row class="my-3">
                  <b-col>
                    <h5>Rated conversations</h5>
                    <span
                      v-b-tooltip.hover.noninteractive.viewport
                      class="d-block h1 mb-0 font-weight-bold mb-1 text-primary"
                    >
                      {{ getNumRating }}
                    </span>
                  </b-col>
                  <b-col>
                    <h5>Unhappy users</h5>
                    <span
                      v-b-tooltip.hover.noninteractive.viewport
                      class="d-block h1 mb-0 font-weight-bold mb-1 text-primary"
                    >
                      {{ badRatingFraction }}
                    </span>
                  </b-col>
                  <b-col cols="12">
                    <b-button
                      v-b-tooltip.hover.top.noninteractive
                      size="sm"
                      variant="primary"
                      :title="`${showRatingsDetails ? 'Hide' : 'Show'} details`"
                      class="py-0"
                      @click="showRatingsDetails = !showRatingsDetails"
                    >
                      <font-awesome-icon :icon="showRatingsDetails ? 'angle-up' : 'angle-down'" />
                    </b-button>
                  </b-col>
                </b-row>
                <b-collapse :visible="showRatingsDetails">
                  <b-alert
                    variant="info"
                    show
                    class="px-5"
                  >
                    <p>
                      It is a well-established facts that unhappy users are more likely to give
                      unsolicited ratings. We therefore here emphasize the fraction of negative
                      ratings instead of displaying the average ratings as that number will likely
                      be inaccurate unless you are actively reaching out for ratings.
                    </p>
                    <p class="mt-1">
                      A detailed breakdown of ratings is provided below. Be aware that all ratings
                      are converted to a 5-star grade when displayed here and "unhappy users" means
                      2 stars or less.
                    </p>
                  </b-alert>
                  <b-row
                    v-for="([key, value], index) in getChatRatings"
                    :key="index"
                    class="mb-1"
                  >
                    <b-col
                      cols="auto"
                      class="my-auto"
                    >
                      <b-form-rating disabled :value="key" style="height: 2rem;" />
                    </b-col>
                    <b-col class="my-auto">
                      <b-progress
                        :class="id2Class(key)"
                        height="2rem"
                        :value="value"
                        :max="getKPICardValue('num_chats', 'actual')"
                        show-value
                      />
                    </b-col>
                  </b-row>
                </b-collapse>
              </template>
              <h5 v-else class="text-center py-4">
                There is no data to show.
              </h5>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </div>
    <statistics-details v-else class="mt-3" />

    <sidebar-description
      id="statistics-description-sidebar"
      title="Statistics description"
    >
      <template #content>
        <statistics-description />
      </template>
    </sidebar-description>

    <b-modal
      id="statistics-settings-modal"
      title="Statistics Settings"
      size="lg"
      ok-title="Save"
      :ok-disabled="$v.settings.$invalid"
      @ok="updateStatisticsSettings()"
      @hidden="resetStatisticsSettings()"
    >
      <b-overlay :show="isFetchingSettings">
        <b-form-group
          label="Partial resolution mode"
        >
          <b-form-select
            v-model="settings.pr_rate_calculation_mode"
            class="bg-white"
            :options="computationModeOptions"
          />
        </b-form-group>
        <b-form-group
          v-if="settings.pr_rate_calculation_mode === 'fixed_percentage'
            || settings.pr_rate_calculation_mode === 'average_user_messages'"
          :label="settings.pr_rate_calculation_mode === 'fixed_percentage'
            ? ' Partial Resolution Rate' : 'Average no. of total user messages in chats'"
        >
          <b-form-input
            v-if="settings.pr_rate_calculation_mode === 'fixed_percentage'"
            v-model="settings.fixed_pr_rate"
            class="bg-white"
            max="1"
            min="0"
            type="number"
            step="0.01"
          />
          <b-form-input
            v-else
            v-model="settings.avg_user_messages"
            class="bg-white"
            min="0"
            type="number"
            step="0.1"
          />
        </b-form-group>
        <b-form-group
          label="Average conversation handling cost"
        >
          <b-input-group>
            <b-form-input
              v-model="settings.avg_cost_per_chat"
              class="bg-white"
              min="0"
              step="0.1"
              type="number"
            />
            <b-input-group-append>
              <b-form-select
                v-model="settings.avg_cost_currency"
                class="bg-white"
                :options="currencyOptions"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Average agent response time (minutes)"
        >
          <b-input-group>
            <b-form-input
              v-model="settings.avg_response_time"
              class="bg-white"
              min="0"
              type="number"
              step="0.1"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Average queue time for agent pickup (minutes)"
        >
          <b-input-group>
            <b-form-input
              v-model="settings.avg_queue_time"
              class="bg-white"
              min="0"
              type="number"
              step="0.1"
            />
          </b-input-group>
        </b-form-group>
      </b-overlay>
      <hr>
      <node-interpretations />
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import cloneDeep from 'lodash/cloneDeep';
import {
  required, minValue, between, requiredIf,
} from 'vuelidate/lib/validators';
import NewKPICard from 'supwiz/components/NewKPICard.vue';
import SidebarDescription from 'supwiz/components/SidebarDescription.vue';
import { getChartLabels } from '@/js/utils';
import endpoints from '@/js/urls';
import { metricDistributionType } from '@/js/constants';
import StatisticsDescription from '@/components/descriptions/StatisticsDescription.vue';
import { num2str } from '@/js/vuefilters';
import { isExternalStatsEnabled } from '@/js/featureFlags';
import getLanguageName from '@/js/languageMap';
import NodeInterpretations from '@/pages/Statistics/NodeInterpretations/NodeInterpretations.vue';
import StatisticsDetails from '@/pages/Statistics/StatisticsDetails.vue';
import PlotCategoryMetric from '@/pages/Statistics/PlotCategoryMetric.vue';
import AutomationDetails from '@/pages/Statistics/AutomationDetails.vue';
import StatisticsFilter from '@/pages/Statistics/StatisticsFilter.vue';

export default {
  name: 'StatisticsPage',
  components: {
    StatisticsDetails,
    StatisticsFilter,
    NodeInterpretations,
    StatisticsDescription,
    AutomationDetails,
    PlotCategoryMetric,
    NewKPICard,
    SidebarDescription,
  },
  mixins: [validationMixin],
  data() {
    return {
      showFilters: true,
      activeTab: 0,
      distType: metricDistributionType,
      selectedDistribution: metricDistributionType.COUNT,
      settings: {},
      currencyCopy: null,
      isFetchingSettings: false,
      currencyOptions: [
        { value: 'EUR', text: 'EUR' },
        { value: 'DKK', text: 'DKK' },
        { value: 'USD', text: 'USD' },
      ],
      showRatingsDetails: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation', ['activeBotId']),
    ...mapGetters('statistics', [
      'getKPIData',
      'getKPIDataInfo',
      'isLoading',
      'anyLoading',
      'getKPICardValue',
      'statsSettings',
    ]),
    ...mapGetters('statistics/filters', ['statsFilter', 'needsRefresh']),
    ...mapGetters('nodeInterpretations', [
      'optOutNodes',
      'flowNodes',
      'transferNodes',
    ]),
    ...mapGetters('chatlogs', ['availableLanguages']),
    computationModeOptions() {
      const options = [
        { value: 'fixed_percentage', text: 'Fixed percentage' },
        { value: 'average_user_messages', text: 'Fixed message count' },
      ];
      if (this.isExternalStatsEnabled()) {
        options.push({ value: 'actual_user_messages', text: 'Actual message count' });
      }
      return options;
    },
    refreshValue() {
      return this.activeTab === 0 ? this.needsRefresh.general : this.needsRefresh.details;
    },
    hasNodeInterpretations() {
      return (this.optOutNodes.length || this.flowNodes.length || this.transferNodes.length);
    },
    hasChatRatings() {
      return Object.values(this.getKPIData('chat_ratings')?.info || {}).some((e) => e !== 0);
    },
    getChatRatings() {
      return Object.entries(
        this.getKPIData('chat_ratings')?.info || {},
      ).reverse();
    },
    getNumRating() {
      return Object.values(this.getKPIData('chat_ratings')?.info || {}).reduce(
        (partialSum, a) => partialSum + a,
        0,
      );
    },
    badRatingFraction() {
      const ratings = Object.values(this.getKPIData('chat_ratings')?.info || {});
      if (ratings.length && this.getKPICardValue('num_chats', 'actual')) {
        return `${((ratings[0] + ratings[1]) / this.getKPICardValue('num_chats', 'actual') * 100).toFixed(1)}%`;
      }
      return 'N/A';
    },
    chartType() {
      if (this.chartData.datasets[0].data.length === 1 && ['days', 'months']
        .includes(this.statsFilter.selectedGroupBy)) {
        return 'bar';
      }
      return 'line';
    },
    chartData() {
      const datasets = [];
      if (this.selectedDistribution === this.distType.COUNT) {
        datasets.push({
          label: 'Chat count',
          fill: false,
          borderColor: '#2A80B9',
          backgroundColor: '#2A80B9',
          borderWidth: 3,
          pointRadius: 0,
          data: Object.values(
            this.getKPIData('distributions_over_time')?.info?.chatCount || {},
          ).map((e) => (typeof e === 'number' ? e.toFixed(0) : 0)),
        });
      } else if (this.selectedDistribution === this.distType.COVERAGE) {
        datasets.push({
          label: 'Coverage',
          fill: false,
          borderColor: '#43A047',
          backgroundColor: '#43A047',
          borderWidth: 3,
          pointRadius: 0,
          data: Object.values(
            this.getKPIData('distributions_over_time')?.info?.flowCoverage || {},
          ).map((e) => (typeof e === 'number' ? (e * 100).toFixed(2) : 0)),
        });
      } else if (this.selectedDistribution === this.distType.RATING) {
        datasets.push({
          label: 'Average Rating',
          fill: false,
          borderColor: '#0A2C63',
          backgroundColor: '#0A2C63',
          borderWidth: 3,
          pointRadius: 0,
          data: Object.values(
            this.getKPIData('distributions_over_time')?.info?.averageRating || {},
          ).map((e) => (typeof e === 'number' ? +e.toFixed(2) : 0)),
        });
      }
      return {
        datasets,
        labels: this.getChartLabels(this.statsFilter),
      };
    },
    languageTableFields() {
      const fields = [
        {
          key: 'language',
          stickyColumn: true,
        },
        {
          key: 'conversationCount',
          label: 'Conv. count',
        },
        {
          key: 'estimatedCostSavings',
        },
        {
          key: 'automationRate',
        },
        {
          key: 'covered',
        },
        {
          key: 'partiallyResolved',
        },
        {
          key: 'selfService',
          label: 'Self-service',
        },
        {
          key: 'optoutRate',
        },
      ];
      if (this.isExternalStatsEnabled()) {
        fields.push(
          {
            key: 'rating',
          },
        );
        fields.push({ key: 'ratingCount', label: 'Rating#' });
      }
      return fields;
    },
    languageTableItems() {
      const items = [];
      if (!this.isLoading('language_stats')) {
        for (const [language, data] of Object.entries(this.getKPIDataInfo('language_stats'))) {
          // if language is '' (unknown), dont show if there is no data
          if (language === '' && data.num_chats === 0) {
            continue;
          }
          const languageText = getLanguageName(language);
          const automationRate = data.automation_rate;
          const optoutRate = data.percent_optout;
          const autoDist = data.automation_distributions;
          const covered = autoDist.flow_coverage;
          const coveredPartiallyResolved = (
            autoDist.flow_coverage * autoDist.flow_coverage_other_partial_resolution
          );
          const notCoveredPartiallyResolved = (
            autoDist.not_covered * autoDist.not_covered_other_partial_resolution
          );
          let partiallyResolved;
          if (coveredPartiallyResolved) {
            partiallyResolved = notCoveredPartiallyResolved
              ? (coveredPartiallyResolved + notCoveredPartiallyResolved) : coveredPartiallyResolved;
          } else {
            partiallyResolved = notCoveredPartiallyResolved || null;
          }
          const coveredSelfService = (
            autoDist.flow_coverage * autoDist.flow_coverage_self_service
          );
          const notCoveredSelfService = (
            autoDist.not_covered * autoDist.not_covered_self_service
          );
          let selfService;
          if (coveredSelfService) {
            selfService = notCoveredSelfService
              ? (coveredSelfService + notCoveredSelfService) : coveredSelfService;
          } else {
            selfService = notCoveredSelfService || null;
          }
          let totalRating = 0;
          let totalConversations = 0;
          for (const [rating, count] of Object.entries(data.chat_ratings)) {
            totalRating += Number(rating) * Number(count);
            totalConversations += Number(count);
          }
          const averageRating = totalConversations > 0 ? totalRating / totalConversations : 0;
          items.push({
            language: languageText,
            conversationCount: data.num_chats,
            estimatedCostSavings: `${num2str(Math.round(data.estimated_cost_savings))} ${this.currencyCopy}`,
            automationRate: !automationRate ? '-' : `${automationRate.toFixed(1)}%`,
            covered: !covered ? '-' : `${(covered * 100).toFixed(1)}%`,
            partiallyResolved: !partiallyResolved ? '-' : `${(partiallyResolved * 100).toFixed(1)}%`,
            selfService: !selfService ? '-' : `${(selfService * 100).toFixed(1)}%`,
            optoutRate: !optoutRate ? '-' : `${optoutRate.toFixed(1)}%`,
            rating: averageRating.toFixed(1),
            ratingCount: totalConversations,
          });
        }
      }
      return items;
    },
  },
  created() {
    if ([null, 'weeks', 'months'].includes(this.statsFilter.selectedGroupBy) && this.activeTab === 0) {
      this.updateStatsFilter({ key: 'selectedGroupBy', newValue: 'hoursAccum' });
    }
  },
  async mounted() {
    this.fetchNodeDetailsGroups();
    await this.fetchStartUrls();
    await this.fetchStatisticsSettings();
    this.settings = cloneDeep(this.statsSettings);
    this.currencyCopy = this.settings.avg_cost_currency;
    this.$root.$on('fetchNodeStatsData', (nodes) => {
      this.fetchNodeDetailsData({ ...this.statsFilter, selected_nodes: nodes });
    });
  },
  destroyed() {
    this.clearStatistics();
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('statistics', ['fetchNodeDetailsData', 'fetchStatisticsSettings', 'fetchNodeDetailsGroups']),
    ...mapActions('chatlogs', ['fetchStartUrls']),
    ...mapMutations('statistics/filters', ['setNeedsRefresh', 'updateStatsFilter']),
    ...mapMutations('statistics', ['clearStatistics']),
    isExternalStatsEnabled,
    getChartLabels,
    resetStatisticsSettings() {
      this.settings = cloneDeep(this.statsSettings);
    },
    async updateStatisticsSettings() {
      try {
        if (this.settings.chatLength < 1) {
          this.settings.chatLength = null;
        }
        if (this.settings.chatPrice < 1) {
          this.settings.chatPrice = null;
        }
        const response = await axios.post(endpoints.statisticsConfig,
          {
            bot_id: this.activeBotId,
            ...this.settings,
          },
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } });

        if (response.status === 200) {
          this.setNeedsRefresh({ key: 'general', value: true });
          this.setNeedsRefresh({ key: 'details', value: true });
          this.fetchStatisticsSettings();
          this.$refs.settingsDropdown.hide();
          this.settings = cloneDeep(this.statsSettings);
        }
      } catch (error) {
        this.showWarning({
          title: 'Failed to update statistics settings',
          text: error.message,
          variant: 'danger',
        });
      }
    },
    id2Class(id) {
      switch (id) {
        case '5':
          return 'bar-green';
        case '4':
          return 'bar-light-green';
        case '3':
          return 'bar-yellow';
        case '2':
          return 'bar-orange';
        default:
          return 'bar-red';
      }
    },
  },
  validations() {
    return {
      settings: {
        fixed_pr_rate: {
          required: requiredIf(() => this.settings.pr_rate_calculation_mode === 'fixed_percentage'),
          between(value) {
            return between(0, 1)(value);
          },
        },
        avg_user_messages: {
          required: requiredIf(() => this.settings.pr_rate_calculation_mode === 'average_user_messages'),
          nonNegative: minValue(0),
        },
        avg_cost_per_chat: {
          nonNegative: minValue(0),
          required,
        },
        avg_response_time: {
          nonNegative: minValue(0),
          required,
        },
        avg_queue_time: {
          nonNegative: minValue(0),
          required,
        },
      },
    };
  },
};
</script>
<style scoped>
::v-deep .filters-btn {
  width: 40px !important;
  height: 37px !important;
  font-size: 0.9rem !important;
  color: #005f89 !important;
}
.tab-title {
  height: 37px !important;
  background-color: #fff;
  font-size: 0.9375rem;
  border: 1px solid #0000001a;
  font-weight: 400;
  line-height: 37px;
}
.tab-title:hover{
  color: #fff !important;
  background-color: #005f89 !important;
}
.active-tab {
  color: #fff !important;
  background-color: #005f89 !important;
}

::v-deep .stat-overlay {
  border-radius: 0.75rem;
}
::v-deep .bar-green > .progress-bar {
  background-color: #28a745;
}
::v-deep .bar-light-green > .progress-bar {
  background-color: #6ec932;
}
::v-deep .bar-yellow > .progress-bar {
  background-color: #f2c700;
}
::v-deep .bar-orange > .progress-bar {
  background-color: #e99002;
}
::v-deep .bar-red > .progress-bar {
  background-color: #f04124;
}
.wrapper {
  color: #404040;
}
</style>
