import axios from 'axios';
import Vue from 'vue';
import endpoints from '@/js/urls';

const adminState = {
  users: {},
  groups: {},
  objects: {},
  objectsLoading: false,
};

const getters = {
  getUsernameFromId: (state) => (userId) => {
    if (userId in state.users) {
      return state.users[userId].username;
    } return '(User not found)';
  },
  getGroupNameFromId: (state) => (groupId) => {
    if (groupId in state.groups) {
      return state.groups[groupId].name;
    } return '(Group not found)';
  },
  getDisplayNameFromId: (state) => (userId) => {
    if (userId in state.users) {
      const user = state.users[userId];
      if (user.first_name && user.last_name) {
        return `${user.first_name} ${user.last_name}`;
      }
      if (user.first_name) {
        return user.first_name;
      }
      return user.username;
    }
    return 'System';
  },
  getObjectFromId: (state) => (id) => {
    if (Object.values(state.objects).length) {
      return state.objects.find((e) => e.id === id).name;
    } return null;
  },
};

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  updateUser(state, { id, user }) {
    Vue.set(state.users, id, user);
  },
  setGroups(state, groups) {
    state.groups = groups;
  },
  updateGroup(state, { id, group }) {
    Vue.set(state.groups, id, group);
  },
  deleteGroup(state, id) {
    Vue.delete(state.groups, id);
  },
  setObjects(state, objects) {
    state.objects = objects;
  },
  setObjectsLoading(state, value) {
    state.objectsLoading = value;
  },
};

const actions = {
  async fetchUsers({ rootState, commit }) {
    commit('setObjectsLoading', true);
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    };
    const resp = await axios.get(endpoints.userList, config);
    commit('setUsers', resp.data.result);
    commit('setObjectsLoading', false);
  },
  async fetchGroups({ rootState, commit }) {
    commit('setObjectsLoading', true);
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    };
    const resp = await axios.get(endpoints.group, config);
    commit('setGroups', resp.data.result);
    commit('setObjectsLoading', false);
  },
  async fetchObjects({ rootState, commit }, model) {
    commit('setObjectsLoading', true);
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      params: { model },
    };
    const resp = await axios.get(endpoints.permission, config);
    commit('setObjects', resp.data.result);
    commit('setObjectsLoading', false);
  },
};

export default {
  namespaced: true,
  state: adminState,
  getters,
  mutations,
  actions,
};
