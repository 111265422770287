<template>
  <div
    class="h-100 d-flex flex-column r-75 overflow-hidden"
  >
    <div class="bg-dark text-center py-3">
      <b-button
        v-b-tooltip.hover
        title="Hide demo panel"
        class="text-white m-2 border-0"
        style="position:absolute; top:2px; right:2px; background: transparent;"
        @click="$emit('hide-bot-demo')"
      >
        <font-awesome-icon
          icon="compress-alt"
          class="h4"
        />
      </b-button>
      <div class="text-white font-weight-bold px-5">
        Conversation with {{
          currentlySpeakingBotName
        }}
      </div>
    </div>
    <ul
      id="messages"
      ref="messages"
      class="mx-0 px-0 mb-2"
      :class="outDated ? 'outdated-bot' : ''"
    >
      <template v-for="(msg, index) in messageList">
        <robot-message
          v-if="msg.from === 'agent'"
          :key="index + 'robotMessage'"
          class="my-1"
          :message="msg.text"
          :show-name="index === 0 || messageList[index - 1].from !== msg.from"
          :has-user-response="lastUserMsg > -1 && index < lastUserMsg"
          @userPickedOption="userPickedOption"
        />
        <user-message
          v-else-if="msg.from === 'customer'"
          :key="index + 'userMessage'"
          class="my-1"
          :message="msg.text"
          :audio-url="msg.audioUrl"
          :show-name="index === 0 || messageList[index - 1].from !== msg.from"
          :selected-for-replay="index === activeIndex"
          @pickMeForReplay="onMsgClick(index)"
        />
        <info-message
          v-else-if="msg.from === 'info'"
          :key="index + 'infoMessage'"
          :message="msg.text"
          :last-message="index + 1 === messageList.length
            || messageList[index + 1].from !== msg.from"
          :show-name="index === 0 || messageList[index - 1].from !== msg.from"
        />
      </template>
    </ul>
    <div class="bg-light r-25 mx-2">
      <form
        @submit.prevent="onSubmit"
      >
        <b-input-group>
          <b-form-input
            id="m"
            ref="chatInput"
            v-model="message"
            class="shadow-none border-0 bg-transparent"
            type="text"
            autocomplete="off"
            :placeholder="messagePlaceholder"
            :disabled="recordingVoice"
          />
          <b-input-group-append v-b-hover="handleTextButtonHover">
            <b-button
              v-if="!recordingVoice"
              variant="transparent"
              class="pl-1 pr-3 send-button"
              @click.prevent="onSubmit"
            >
              <font-awesome-icon
                :class="isHovered.text ? 'icon-hovered' : ''"
                icon="paper-plane"
                size="lg"
              />
            </b-button>
          </b-input-group-append>
          <b-input-group-append
            v-if="recordingVoice"
            v-b-hover="handleDiscardButtonHover"
          >
            <b-button
              variant="transparent"
              class="pl1 pr-3 send-button"
              @click.prevent="discardVoiceRecording"
            >
              <font-awesome-icon
                :class="isHovered.discard ? 'icon-hovered' : ''"
                icon="trash-alt"
                size="lg"
              />
            </b-button>
          </b-input-group-append>
          <b-input-group-append
            v-if="voicebotPlatformSelected"
            v-b-hover="handleVoiceButtonHover"
          >
            <b-button
              variant="transparent"
              class="pl1 pr-3 send-button"
              @click.prevent="toggleVoiceRecord"
            >
              <font-awesome-icon
                :class="isHovered.voice ? 'icon-hovered' : ''"
                :icon="recordingVoice ? 'stop-circle' : 'microphone'"
                size="lg"
              />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </form>
    </div>

    <div class="p-2 wrapper-bottom">
      <div class="text-center">
        <ul
          v-if="botIsReady"
          id="errors"
          class="p-0 m-0"
        >
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error }}
          </li>
        </ul>
        <ul v-else>
          <li v-if="!error_log">
            Status: Loading bot
            <font-awesome-icon
              icon="spinner"
              size="lg"
              spin
            />
          </li>
        </ul>
      </div>

      <div
        v-if="error_log"
      >
        <div
          style="color: red"
        >
          <b class="align-middle">ERROR:  </b>
          <kbd
            class="align-middle"
          >
            {{ error_log }}
          </kbd>
        </div>
        <div
          v-if="error_node_id"
          style="color: red"
        >
          <b class="align-middle">NODE:  </b>
          <b-button
            key="error_node_id"
            variant="link"
            size="sm"
            class="p-0"
            @click="goToNode(error_node_id)"
          >
            {{ error_node_name || error_node_id }}
          </b-button>
        </div>
      </div>

      <b-alert
        :show="real_api"
        variant="danger"
        class="text-center py-1 mt-1"
      >
        Using Real API
        <p class="font-weight-bold">
          This can have real-world effects!
        </p>
      </b-alert>

      <div class="text-center my-2">
        <b-button-group class="w-100">
          <!-- This button will show if there are no variants for the bot. Otherwise a
          dropdown-split button (below) will show allowing users to refresh with a specific variant.
           -->
          <b-button
            v-if="variantsList.length === 0"
            variant="dark"
            class="py-1"
            @click="refresh(null)"
          >
            <span
              v-if="outDated"
              v-b-tooltip.hover
              title="Refresh to include changes."
            >
              <font-awesome-icon
                icon="exclamation-circle"
                class="refresh-icon"
              />
            </span>
            <font-awesome-icon
              v-else
              icon="sync"
              class="refresh-icon"
            />
            {{ activeIndex !== null ? 'Replay' : 'Refresh' }}
          </b-button>
          <b-dropdown
            v-else
            variant="dark"
            split
            @click="refresh(currentVariantId)"
          >
            <template #button-content>
              <!-- This is just copied from the button above -->
              <span
                v-if="outDated"
                v-b-tooltip.hover
                title="Refresh to include changes."
              >
                <font-awesome-icon
                  icon="exclamation-circle"
                  class="refresh-icon"
                />
              </span>
              <font-awesome-icon
                v-else
                icon="sync"
                class="refresh-icon"
              />
              {{ activeIndex !== null ? 'Replay' : 'Refresh' }}
            </template>
            <b-dropdown-item
              v-for="variant in refreshDropdownItems"
              :key="`refresh_variant_${variant.id}`"
              @click="chooseAndRefresh(variant.id)"
            >
              {{ variant.name }}
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            v-if="error_log"
            v-b-tooltip.hover
            title="Go to health page"
            variant="warning"
            @click="!$route.path.includes('health') || $route.hash !== '#errors'
              ? $router.push({ name: 'health', hash: '#errors' }) : null"
          >
            <font-awesome-icon
              icon="wrench"
              class="refresh-icon"
            />
          </b-button>
          <b-button
            v-b-modal.botStateModal
            v-b-tooltip.hover
            title="State: presents an overview of internal variables,
             tokens, etc. from the bot"
            variant="info"
          >
            <font-awesome-icon
              icon="info-circle"
              class="refresh-icon"
            />
          </b-button>
          <b-button
            v-if="!isUserLimited"
            v-b-modal.addUnitTestModal
            v-b-tooltip.hover
            :disabled="real_api"
            :title="real_api
              ? 'Cannot make test case using real API'
              : 'Add to Conversation Tests'"
            variant="primary"
          >
            <font-awesome-icon
              icon="vial"
              class="refresh-icon"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover
            :title="real_api ? 'Using Real integrations' : 'Using Mock integrations'"
            :variant="real_api ? 'danger' : 'success'"
            @click="real_api = !real_api"
          >
            <font-awesome-icon
              icon="cloud"
              class="mr-1"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover
            :title="allow_sleep ? 'Conversation sleep enabled' : 'Conversation sleep disabled'"
            :variant="allow_sleep ? 'success' : 'secondary'"
            @click="allow_sleep = !allow_sleep"
          >
            <font-awesome-icon
              icon="hourglass-start"
              class="mr-1"
            />
          </b-button>
          <b-button
            v-if="voicebotPlatformSelected && readMessagesMuted"
            v-b-tooltip.hover
            variant="dark"
            :pressed="false"
            title="Toggle autoplay messages"
            @click="toggleAutoplayMessages"
          >
            <font-awesome-icon
              v-if="!autoplayMessages"
              class="refresh-icon"
              icon="volume-mute"
              color="white"
            />
            <font-awesome-icon
              v-else
              class="refresh-icon"
              icon="volume-up"
              color="white"
            />
          </b-button>
        </b-button-group>
      </div>
      <div
        v-if="current_node_name"
        class="border p-1 r-25"
        style="font-size: small; max-height:90px; overflow-y:auto;"
      >
        <b>Node path: </b>
        <template v-for="(node, index) in bot_path">
          <b-button
            v-if="index > 0"
            :key="`${index}_matching`"
            variant="link"
            :disabled="Object.keys(node.matching).length === 0"
            class="py-0 px-1"
            @click="openMatchingModal(index)"
          >
            <font-awesome-icon
              style="font-size:0.8rem"
              icon="long-arrow-alt-right"
            />
          </b-button>
          <b-button
            :key="`${node.id}_${index}_bot_path`"
            :disabled="!node.id"
            variant="link"
            size="sm"
            class="p-0"
            @click="goToNode(node.id)"
          >
            {{ node.name + "+".repeat(node.repetitions - 1) }}
          </b-button>
        </template>
      </div>
    </div>
    <b-modal
      id="botStateModal"
      title="State inspector"
      ok-only
      scrollable
      ok-title="Close"
      size="xl"
      @show="botStateModalShown"
      @hidden="stateModalOpen = false"
    >
      <b-row align-h="center">
        <h5>Bot variables:</h5>
      </b-row>
      <b-row v-if="actionResults">
        <b-col>
          <display-key-value
            v-for="[key, value] in formatStateModalData(actionResults)"
            :key="key"
            size="sm"
            class="mb-2"
            :min-key-width="150"
            :key-prop="key"
            :value-prop="value"
          />

          <!-- Allow toggling of other misc state -->
          <b-row align-h="center">
            <b-col cols="12">
              <b-button
                v-b-toggle.collapse-botState
                class="p-2 mb-2"
                variant="primary"
                block
              >
                Show/hide internal bot state
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-collapse id="collapse-botState">
                <display-key-value
                  v-for="[key, value] in formatStateModalData(botState)"
                  :key="key"
                  size="sm"
                  class="mb-2"
                  :min-key-width="150"
                  :key-prop="key"
                  :value-prop="value"
                />

                <display-key-value
                  v-for="[key, value] in formatStateModalData(botEmits)"
                  :key="key"
                  size="sm"
                  class="mb-2"
                  :min-key-width="150"
                  :key-prop="key"
                  :value-prop="value"
                />
              </b-collapse>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        v-else
        align-v="center"
      >
        <b-spinner style="width: 3rem; height: 3rem;" />
        Loading state...
      </b-row>
    </b-modal>
    <b-modal
      id="matching-modal"
      title="Matching scores"
      size="lg"
      ok-only
    >
      <node-probabilities-chart
        v-if="matchIndex"
        :probabilities="probabilities"
        :node-name="bot_path[matchIndex - 1]['name']"
        :responsibe="false"
      />
    </b-modal>
    <add-conv-test-modal
      :path="bot_path"
      :messages="messageList"
    />
  </div>
</template>

<script>
import {
  mapGetters, mapActions, mapState, mapMutations,
} from 'vuex';
import io from 'socket.io-client';
import RecordRTC from 'recordrtc';
import { truncateString, UUID_REGEX } from '@/js/utils';
import AddConvTestModal from '@/components/BotDemo/AddConvTestModal.vue';
import NodeProbabilitiesChart from '@/pages/ChatLogs/NodeProbabilitiesChart.vue';
import RobotMessage from '@/components/BotDemo/RobotMessage.vue';
import UserMessage from '@/components/BotDemo/UserMessage.vue';
import InfoMessage from '@/components/BotDemo/InfoMessage.vue';
import DisplayKeyValue from '@/components/DisplayKeyValue.vue';

const url = '/bot.io';

export default {
  name: 'AppBotDemo',
  components: {
    DisplayKeyValue,
    NodeProbabilitiesChart,
    AddConvTestModal,
    RobotMessage,
    UserMessage,
    InfoMessage,
  },
  data() {
    return {
      robot_name: 'Robot',
      customer_name: 'User',
      errors: [],
      current_node_name: null,
      bot_path: [],
      error_log: null,
      error_node_id: null,
      error_node_name: null,
      downloadingBot: false,
      botState: null,
      actionResults: null,
      botEmits: null,
      botIsReady: false,
      realApi: false,
      allowSleep: true,
      activeETag: null,
      matchIndex: null,
      activeIndex: null,
      replayMsgs: [],
      readMessagesMuted: true,
      currentVariantName: null,
      currentVariantId: null,
      isHovered: {
        text: false,
        voice: false,
        discard: false,
      },
      stateModalOpen: false,
      socket: null,
      recordingVoice: false,
      voiceRecorder: null,
      audioBlob: null,
      audioUrl: null,
      stream: null,
      cancelledRecording: false,
      recordingTimer: '0.0',
      messagePlaceholder: 'Send a message...',
    };
  },
  computed: {
    ...mapState('botSynchronization', ['lastBotETagReceived']),
    ...mapGetters('unitTest', ['getUnitTests']),
    ...mapGetters('auth', ['isUserLimited']),
    ...mapGetters('demopanel', [
      'autoplayMessages',
      'messageList',
      'currentMessage',
    ]),
    ...mapGetters('botManipulation', ['activeBotId']),
    ...mapGetters('botManipulation/activeBot/config', [
      'getRoutingLanguage',
      'getPlatforms',
    ]),
    ...mapGetters('variants', ['variantsList']),
    ...mapGetters('userSettings', ['isVoicebotPlatform']),
    refreshDropdownItems() {
      return [{ id: null, name: this.botName }].concat(
        this.variantsList.map(
          (y) => ({ id: y.id, name: `${this.botName} [${y.name}]` }),
        ),
      );
    },
    message: {
      get() {
        return this.currentMessage;
      },
      set(value) {
        this.setCurrentMessage(value);
      },
    },
    real_api: {
      get() {
        return this.realApi;
      },
      set(value) {
        this.realApi = value;
        this.refresh(this.currentVariantId);
      },
    },
    allow_sleep: {
      get() {
        return this.allowSleep;
      },
      set(value) {
        this.allowSleep = value;
        this.refresh(this.currentVariantId);
      },
    },
    botName() {
      if (this.$store.state.botManipulation.activeBotSet) {
        return truncateString(this.$store.state.botManipulation.activeBot.config.name, 35);
      }
      return 'Nobody';
    },
    currentlySpeakingBotName() {
      if (this.currentVariantName) {
        return `${this.botName} [${this.currentVariantName}]`;
      }
      return this.botName;
    },
    outDated() {
      return this.activeETag !== this.lastBotETagReceived;
    },
    probabilities() {
      if (!this.matchIndex) {
        return [];
      }
      const matching = this.bot_path[this.matchIndex].matching;
      if (matching.node_type === 'multipleChoice') {
        // Multiple choice nodes also link to themselves.
        // We remove that link in the visualization
        const children = Object.keys(matching.probabilities).filter((id) => id !== matching.node);
        return children.map((id) => matching.probabilities[id]);
      }
      return Object.values(matching.probabilities);
    },
    voicebotPlatformSelected() {
      return this.isVoicebotPlatform(this.getPlatforms);
    },
    lastUserMsg() {
      return this.messageList.findLastIndex((msg) => msg.from === 'customer');
    },
  },
  watch: {
    messageList() {
      // nextTick ensures that msgContainer has been rendered before using height to scroll
      this.$nextTick(() => {
        const msgContainer = this.$refs.messages;
        if (msgContainer) {
          msgContainer.scrollTop = msgContainer.scrollHeight - msgContainer.clientHeight;
        }
      });
    },
  },
  created() {
    this.newSocket(null);
  },
  mounted() {
    // Note: We may have to revisit this, when other audio-components make use of the store, too
    this.clearQueue();
  },
  destroyed() {
    this.closeSocket();
    this.clearMessages();
  },
  methods: {
    ...mapMutations('audio', ['clearQueue']),
    ...mapMutations('demopanel', [
      'toggleAutoplayMessages',
      'clearMessages',
      'appendMessage',
      'setCurrentMessage',
    ]),
    ...mapActions('botSynchronization', ['syncBot']),
    ...mapActions('sidebar', ['showWarning']),
    formatStateModalData(data) {
      const formatted = {};
      const parsedData = JSON.parse(data);
      for (const key of Object.keys(parsedData)) {
        const newKey = key.replace(UUID_REGEX, '');
        formatted[newKey] = parsedData[key];
      }
      return Object.entries(formatted);
    },
    closeSocket() {
      if (this.socket && this.socket.connected) {
        this.socket.disconnect();
        this.socket = null;
      }
    },
    newSocket(variantId, eventname = 'ready') {
      this.closeSocket();
      // this method is also used to refresh the bot
      this.activeETag = this.lastBotETagReceived;
      this.socket = io(url, {
        transports: ['websocket'],
        upgrade: false,
        reconnection: false,
      });
      this.socket.on('connect_error', () => {
        setTimeout(() => {
          this.socket.connect();
        }, 1000);
      });

      this.socket.on('connect', () => {
        this.errors = ['Status: ready'];

        const mainBotID = this.$store.state.botManipulation.activeBotId
          ? this.$store.state.botManipulation.activeBotId
          : '';
        const realApi = this.realApi;
        const allowSleep = this.allowSleep;

        this.socket.emit(eventname,
          {
            mainBotID,
            variantId,
            realApi,
            allowSleep,
            jwt: this.$store.state.auth.jwt,
          });
      });
      this.socket.on('disconnect', (reason) => {
        this.errors.push(`Disconnected, ${reason}`);
        if (reason === 'io server disconnect' || reason === 'io client disconnect') {
          // Don't reconnect if either the server or the client manually closed the connection
          return;
        }
        if (this.activeIndex === null) {
          // The websocket disconnected for some reason. Make sure we replay the conversation
          // upon reconnecting
          const lastMsgIndex = this.messageList.findLastIndex((x) => x.from === 'customer');
          this.activeIndex = lastMsgIndex >= 0 ? lastMsgIndex : null;
        }
        this.refresh(this.currentVariantId);
      });
      this.socket.on('reconnect', () => {
        this.errors.push('Reconnected to server');
      });
      this.socket.on('reconnecting', () => {
        this.errors.push('Attempting to re-connect to the server');
      });
      this.socket.on('error', () => {
        this.errors.push(
          ('No connection to {0}. Check the README.md file for '
              + 'instructions on how to start the server').replace('{0}', url),
        );
      });
      this.socket.on('connecting', () => {
        this.errors.push('Connecting');
      });
      this.socket.on('action.chat.sendChat', (msg) => {
        this.appendMessage({
          from: 'agent',
          name: this.robot_name,
          text: msg.message,
        });
      });
      this.socket.on('action.chat.receiveChat', (msg) => {
        this.appendMessage({
          from: 'customer',
          name: this.customer_name,
          text: msg.message,
        });
      });
      this.socket.on('action.chat.infoChat', (msg) => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: msg.message,
        });
      });
      this.socket.on('action.chat.transferToDepartment', (params) => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: `You got transferred to ${params.departmentName}!`,
        });
      });
      this.socket.on('action.chat.promptForm', (params) => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: `User prompted to fill out form with fields: ${params.fields.map((x) => x.text)}`,
        });
      });
      this.socket.on('action.chat.addNote', (params) => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: `Internal note added: ${params.note}`,
        });
      });
      this.socket.on('action.set.tag', (params) => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: `Added tag: ${params.tag}`,
        });
      });
      this.socket.on('action.chat.end', () => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: 'The conversation has ended',
        });
      });
      this.socket.on('action.chat.leave', () => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: 'The bot has left the conversation',
        });
      });
      this.socket.on('action.chat.runMacro', (params) => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: `Ran macro: ${params.macro_name}`,
        });
        if ('keep_assignment' in params) {
          this.appendMessage({
            from: 'info',
            name: 'Info',
            text: `Keep assignment: ${params.keep_assignment}`,
          });
        }
      });
      this.socket.on('action.chat.callback', () => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: 'The bot has left the conversation and an agent will callback',
        });
      });
      this.socket.on('action.chat.setBotNode', (msg) => {
        this.current_node_name = msg.node_name;
      });
      this.socket.on('action.chat.setBotPath', (msg) => {
        // Don't show 'root' in bot-path, as botbuilders cannot navigate to it / does not know what
        // it is
        if (msg.path[0].id === 'root') {
          this.bot_path = msg.path.slice(1);
        } else {
          this.bot_path = msg.path;
        }
      });
      this.socket.on('action.chat.setErrorLog', (msg) => {
        this.error_log = msg.error_log;
        this.error_node_id = msg.error_node_id;
        this.error_node_name = msg.error_node_name;
        this.replayMsg();
      });
      this.socket.on('action.chat.sendBotState', (msg) => {
        this.botState = msg.bot_state;
        this.actionResults = this.sortVariables(JSON.parse(msg.action_results));
        this.botEmits = msg.emits;
      });
      this.socket.on('action.chat.sendReadySignal', () => {
        this.botIsReady = true;
      });
      this.socket.on('action.chat.speechToTextResponse', (msg) => {
        if (!this.cancelledRecording) {
          if (msg !== '') {
            this.audioUrl = URL.createObjectURL(this.audioBlob);
            this.setCurrentMessage(msg);
            this.onSubmit();
          } else {
            this.showWarning({
              title: 'Speech detection failed',
              text: 'No speech recognized',
              variant: 'danger',
            });
          }
          this.audioBlob = null;
        } else {
          this.cancelledRecording = false;
        }
      });
      this.socket.on('action.call.end', () => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: 'The call has ended',
        });
      });
      this.socket.on('action.call.transferToPhone', (params) => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: `You got transferred to phone: ${params.phoneNumber} with params: ${JSON.stringify(params)}!`,
        });
      });
      this.socket.on('action.call.transferToWebsocket', (params) => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: `Call transferred to websocket: ${params.uri} with headers: ${JSON.stringify(params.headers)}`,
        });
      });
      this.socket.on('action.call.transferToSip', (params) => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: `Call transferred to SIP: ${params.uri} with headers: ${JSON.stringify(params.headers)}`,
        });
      });
      this.socket.on('action.call.sendDTMF', (params) => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: `Sent DTMF tones to call: ${params.digits}.`,
        });
      });
      this.socket.on('action.echo.message', (params) => {
        this.appendMessage({
          from: 'info',
          name: 'Info',
          text: `Echoing message from bot: ${params.message}.`,
        });
        this.socket.emit('event.channel.message.received', params);
      });
    },
    onSubmit() {
      if (!this.botIsReady) {
        return false;
      }
      this.socket.emit('event.channel.message.received', { message: this.currentMessage });
      this.appendMessage({
        from: 'customer',
        name: this.customer_name,
        text: this.currentMessage,
        audioUrl: this.audioUrl,
      });
      this.setCurrentMessage('');
      this.audioUrl = null;
      return true;
    },
    chooseAndRefresh(variantId) {
      this.currentVariantId = variantId;
      this.refresh(this.currentVariantId);
    },
    async toggleVoiceRecord() {
      if (!this.recordingVoice) {
        this.recordingTimer = 0.0;
        this.message = '';
        try {
          this.stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          this.recordingVoice = true;
          this.voiceRecorder = RecordRTC(this.stream, {
            type: 'audio',
            recorderType: RecordRTC.StereoAudioRecorder,
            numberOfAudioChannels: 1,
            desiredSampRate: 16000,
            disableLogs: true,
            timeSlice: 300,
            ondataavailable: (blob) => {
              this.socket.emit('event.channel.chat.receivedBytes', { bytes: blob });
            },
          });
          this.voiceRecorder.startRecording();
          this.socket.emit('event.channel.chat.startSTT', { });
          this.recordingTimerUpdate();
        } catch (error) {
          let errorText = error;
          if (error instanceof DOMException) {
            if (error.message === 'Permission denied') {
              errorText = 'Microphone access denied';
            }
          }
          this.showWarning({
            title: 'Speech detection failed',
            text: errorText,
            variant: 'danger',
          });
          this.recordingVoice = false;
        }
      } else {
        try {
          this.voiceRecorder.stopRecording(() => {
            this.audioBlob = this.voiceRecorder.getBlob();
            this.stream.getTracks().forEach((track) => track.stop());
          });
        } catch (error) {
          this.showWarning({
            title: 'Speech detection failed',
            text: error,
            variant: 'danger',
          });
        } finally {
          this.recordingVoice = false;
          this.socket.emit('event.channel.chat.stopSTT', { });
        }
      }
    },
    async discardVoiceRecording() {
      this.recordingVoice = false;
      this.voiceRecorder.stopRecording();
      this.cancelledRecording = true;
      this.socket.emit('event.channel.chat.stopSTT', { });
      this.voiceRecorder.reset();
    },
    async recordingTimerUpdate() {
      this.recordingTimer = (parseFloat(this.recordingTimer) + 0.1).toFixed(1);
      this.messagePlaceholder = `Recording for: ${this.recordingTimer} seconds`;
      if (parseFloat(this.recordingTimer) > 3000) {
        this.toggleVoiceRecord();
        this.showWarning({
          title: 'Voice recording stopped',
          text: 'Recording has exeeded the limit of 5 minutes',
          variant: 'danger',
        });
      } else if (this.recordingVoice) {
        setTimeout(this.recordingTimerUpdate, 100);
      } else {
        this.messagePlaceholder = 'Send a message...';
      }
    },
    async refresh(variantId) {
      if (this.activeIndex !== null) {
        this.replayMsgs = this.messageList.slice(0, this.activeIndex + 1).filter((msg) => msg.from === 'customer');
      }
      this.activeIndex = null;
      this.current_node_name = null;
      this.error_log = null;
      this.bot_path = [];
      this.matchIndex = null;
      this.clearMessages();
      this.botIsReady = false;
      this.socket.destroy();
      await this.syncBot();
      this.newSocket(variantId);
      if (variantId) {
        this.currentVariantName = this.variantsList.find(
          (x) => x.id === variantId,
        ).name || 'UNKNOWN_VARIANT';
      } else {
        this.currentVariantName = null;
      }
      await this.$refs?.chatInput?.focus();
    },
    goToNode(nodeID) {
      const botId = this.$route.params.botId;
      this.$router.push({ name: 'edit-node', params: { botId, nodeId: nodeID } });
    },
    botStateModalShown() {
      this.stateModalOpen = true;
      this.botState = null;
      this.actionResults = null;
      this.botEmits = null;
      this.socket.emit('event.channel.chat.getBotState', { });
    },
    openMatchingModal(index) {
      this.matchIndex = index;
      this.$bvModal.show('matching-modal');
    },
    onMsgClick(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    },
    replayMsg() {
      if (!this.error_log && this.replayMsgs.length > 0) {
        const message = this.replayMsgs.shift();
        this.setCurrentMessage(message.text);
        this.audioUrl = message.audioUrl;
        this.onSubmit();
      }
    },
    userPickedOption(optionText) {
      this.setCurrentMessage(optionText);
      this.onSubmit();
    },
    handleTextButtonHover(hover) {
      this.isHovered.text = hover;
    },
    handleVoiceButtonHover(hover) {
      this.isHovered.voice = hover;
    },
    handleDiscardButtonHover(hover) {
      this.isHovered.discard = hover;
    },
    sortVariables(variables) {
      const variableKeys = Object.keys(variables);
      const subflowRegex = new RegExp('subflow_.*_subvar_');
      const botVariables = {};
      const subflowVariables = {};
      variableKeys.forEach((key) => {
        if (subflowRegex.test(key)) {
          subflowVariables[key] = variables[key];
        } else {
          botVariables[key] = variables[key];
        }
      });
      return JSON.stringify({ ...botVariables, ...subflowVariables });
    },
  },
  sockets: {},
};
</script>

<style>
  /* Keep it DRY: User-, Info and RobotMessage will use these classes
  */
  .messageContent {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    padding-top: 2px;
  }

  .name {
    color: #888;
    padding-top: 4px;
  }

  .last-message {
    margin-top: 0px;
    padding-bottom: 20px
  }
</style>

<style scoped>
  ul {
    list-style: none;
  }

  #messages {
    list-style-type: none;
    height: 100%;
    word-wrap: break-word;
    overflow-y: auto;
  }
  .btn:focus {
    outline: none;
    box-shadow: none;
  }
  .icon-hovered > path{
    color:#0e0d0d;
  }
  label {
    display: inline-block;
    width: 90%;
  }

  #errors {
    max-height: 200px;
    overflow: auto;
  }

  .material-switch > input[type="checkbox"] {
      display: none;
  }

  .material-switch > label {
      cursor: pointer;
      height: 0px;
      position: relative;
      width: 40px;
  }

  .material-switch > label::before {
      background: rgb(0, 0, 0);
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      content: '';
      height: 16px;
      margin-top: -8px;
      position:absolute;
      opacity: 0.3;
      transition: all 0.4s ease-in-out;
      width: 40px;
  }
  .material-switch > label::after {
      background: rgb(150, 255, 70);
      border-radius: 16px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
      content: '';
      height: 24px;
      left: -4px;
      margin-top: -8px;
      position: absolute;
      top: -4px;
      transition: all 0.3s ease-in-out;
      width: 24px;
  }
  .material-switch > input[type="checkbox"]:checked + label::before {
      background: inherit;
      opacity: 0.5;
  }
  .material-switch > input[type="checkbox"]:checked + label::after {
      background: rgb(255, 50, 100);
      left: 20px;
  }

  .refresh-icon {
    vertical-align: middle;
    margin-top: -0.15em;
  }

  .outdated-bot {
    background-color: rgba(245, 180, 0, 0.27);
  }

</style>
