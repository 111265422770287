<template>
  <main
    role="main"
  >
    <b-card
      title="Subflows"
      class="r-75"
      body-class="p-3"
    >
      <p>
        Subflows are useful for common dialogs that are used many places in the bot such as
        getting the user's account number or similar.
      </p>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-table
        :tbody-tr-attr="attributesForRow"
        :items="items"
        :fields="fields"
        show-empty
        empty-text="There are currently no subflows defined for the bot."
        hover
        small
        style="border:none !important;"
        class="r-75 mb-0"
        @row-clicked="editSubFlow"
      >
        <template #cell(buttons)="row">
          <b-btn
            size="sm"
            variant="outline-danger"
            @click="setDeleteSubflowIndex(row.index)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-btn>
        </template>
        <template #custom-foot>
          <b-tr style="text-align: center">
            <b-td
              colspan="4"
              class="pt-3"
            >
              <b-btn
                variant="primary"
                @click.stop="addNew"
              >
                Add new subflow
              </b-btn>
            </b-td>
          </b-tr>
        </template>
      </b-table>
    </b-card>

    <b-modal
      id="deleteSubflowModal"
      title="Delete subflow"
      ok-title="Delete"
      :ok-disabled="isSubflowReferenced(subflowToDelete.id)"
      @ok="deleteSubFlow(subflowToDelete.id)"
    >
      Are you sure you want to delete the subflow
      {{ Object.keys(subflowToDelete).length ? subflowToDelete.config.name : '' }}?
      <p
        v-if="isSubflowReferenced(subflowToDelete.id)"
        class="text-danger"
      >
        You cannot delete a subflow that is either referenced by a node in the bot, or used as an
        <config-link category="authentication">
          authentication subflow
        </config-link>.
      </p>
    </b-modal>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import ConfigLink from '@/pages/BotConfig/ConfigLink.vue';

export default {
  name: 'SubflowPage',
  components: { ConfigLink },
  mixins: [validationMixin],
  data() {
    return {
      fields: [
        {
          key: 'buttons',
          label: '',
          thClass: 'text-left',
          tdClass: 'text-left',
          thStyle: 'width:50px',
        },
        {
          key: 'name',
          tdClass: 'align-middle font-weight-bold',
        },
        {
          key: 'description',
          tdClass: 'align-middle',
        },
      ],
      subflowIndex: null,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', ['isMainBot']),
    ...mapGetters('botManipulation', ['getSubFlows']),
    ...mapState('botManipulation', ['activeBotId']),
    ...mapGetters('botManipulation/activeBot/config/auth', [
      'subflowId',
      'authenticationSubflowIsEnabled',
    ]),
    attributesForRow() {
      return (rowItem, rowIndex) => {
        if (rowItem === null || rowIndex === 'row-empty') {
          return null;
        }
        return { style: 'cursor:pointer' };
      };
    },
    /**
     * Check whether subflow is referenced explicitly (in a subflow node) in mainbot, or implicitly
     * as authentication subflow in mainbot.
     */
    isSubflowReferenced() {
      return (subflowId) => {
        const node = Object.values(this.$store.state.botManipulation.activeBot.nodes).find(
          (x) => x.subFlowMap.subFlowID === subflowId,
        );
        if (node !== undefined) {
          return true;
        }
        if (this.authenticationSubflowIsEnabled && this.subflowId === subflowId) {
          return true;
        }
        return false;
      };
    },
    items() {
      return this.getSubFlows.map((x) => ({
        id: x.id,
        name: x.config.name,
        description: x.config.description,
      }));
    },
    subflowToDelete() {
      // Don't just test for truthy values here, as a valid value of zero for this.subflowIndex
      // will evaluate Falsy
      if (this.subflowIndex !== null) {
        return this.getSubFlows[this.subflowIndex];
      }
      return {};
    },
  },
  methods: {
    ...mapMutations('graphView', ['clearGraphData']),
    async addNew() {
      const id = await this.$store.dispatch('botManipulation/newSubFlow');
      this.editSubFlow({ id });
    },
    deleteSubFlow(id) {
      this.$store.dispatch('botManipulation/deleteSubFlow', id);
      this.subflowIndex = null;
    },
    setDeleteSubflowIndex(idx) {
      this.subflowIndex = idx;
      this.$bvModal.show('deleteSubflowModal');
    },
    async editSubFlow(item) {
      this.clearGraphData();
      this.$router.push({ name: 'flow', params: { botId: `${this.activeBotId}-${item.id}` } });
    },
  },
};
</script>

<style scoped></style>
