<template>
  <div>
    <h3>Authentication</h3>
    <b-card
      no-body
    >
      <p>
        User authentication configuration.
      </p>
    </b-card>
    <hr>
    <b-form-group>
      <b-form-checkbox
        :key="checkboxKey"
        v-model="configBeingModified.authenticationSubflowIsEnabled"
        switch
        size="lg"
      >
        Use authentication subflow for authentication
      </b-form-checkbox>
    </b-form-group>

    <div v-if="configBeingModified.authenticationSubflowIsEnabled">
      <b-form-group
        label-for="pickAuthenticationSubflow"
        label="Authentication subflow"
      >
        <b-form-select
          id="pickAuthenticationSubflow"
          v-model="configBeingModified.subflowId"
          :options="subflowOptions"
          :state="$v.configBeingModified.subflowId.$invalid ? false : null"
          aria-describedby="chosenSubflowFeedback"
        />
        <b-form-invalid-feedback
          id="chosenSubflowFeedback"
          :state="$v.configBeingModified.subflowId.$invalid ? false : null"
        >
          <div v-if="!$v.configBeingModified.subflowId.required">
            Select a subflow to be used for authenticating users
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <div
        v-if="configBeingModified.subflowId"
      >
        <b-form-group
          label-for="successNode"
          label="Exit node for successful authentication"
        >
          <b-form-select
            id="successNode"
            v-model="configBeingModified.successNodeId"
            :options="subflowOutgoingNodes"
            :state="$v.configBeingModified.successNodeId.$invalid ? false : null"
            aria-describedby="successNodeFeedback"
          />
          <b-form-invalid-feedback
            id="successNodeFeedback"
            :state="$v.configBeingModified.successNodeId.$invalid ? false : null"
          >
            <div v-if="!$v.configBeingModified.successNodeId.required">
              Please choose a node that represents successfull authentication.
            </div>
            <div v-else-if="!$v.configBeingModified.successNodeId.notSameAsFailureNode">
              The nodes for succesfull and non-succesfull authentication should not be the same
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label-for="failureNode"
          label="Exit node for non-successful authentication"
        >
          <b-form-select
            id="failureNode"
            v-model="configBeingModified.failureNodeId"
            :options="subflowOutgoingNodes"
            :state="$v.configBeingModified.failureNodeId.$invalid ? false : null"
            aria-describedby="failureNodeFeedback"
          />
          <b-form-invalid-feedback
            id="failureNodeFeedback"
            :state="$v.configBeingModified.failureNodeId.$invalid ? false : null"
          >
            <div v-if="!$v.configBeingModified.failureNodeId.required">
              Please choose a node that represents unsuccessfull authentication.
            </div>
            <div v-else-if="!$v.configBeingModified.failureNodeId.notSameAsSuccessNode">
              The nodes for succesfull and non-succesfull authentication should not be the same
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <SaveButton
      :save-disabled="disableSave"
      :has-unsaved-changes="changesHaveBeenMade"
      @save="attemptSave"
    />

    <b-modal
      id="disableAuthenticationModal"
      title="Confirm disabling authentication"
      ok-title="Disable authentication"
      @ok="save"
    >
      <p>
        If you disable authentication, the following nodes that currently require authentication
        will be accessible without prior authentication.
      </p>
      <b-list-group>
        <b-list-group-item
          v-for="nodeRequiringAuthentication in nodesRequiringAuthentication"
          :key="nodeRequiringAuthentication.id"
        >
          <router-link
            :to="{ name: 'edit-node', params: { nodeId: nodeRequiringAuthentication.id } }"
          >
            {{ nodeRequiringAuthentication.name }}
          </router-link>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { validationMixin } from 'vuelidate';
import { requiredIf } from 'vuelidate/lib/validators';
import SaveButton from '@/components/SaveButton.vue';

export default {
  name: 'EditAuthentication',
  components: {
    SaveButton,
  },
  mixins: [validationMixin],
  data() {
    return {
      /**
       * This is a hack, but a needed one: We cannot prevent the checkbox from togggling its state
       * when clicked, even when its underlying state is actually not changed subsequently. The hack
       * here is used to re-render the checkbox, so that it does not appear to user that the
       * checkbox changed state.
       */
      checkboxKey: 0,
      configBeingModified: {
        authenticationSubflowIsEnabled: null,
        subflowId: null,
        successNodeId: null,
        failureNodeId: null,
      },
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getSubFlows',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'allNormalNodes',
    ]),
    ...mapGetters('botManipulation/activeBot/config/auth', [
      'subflowId',
      'successNode',
      'failureNode',
      'authenticationSubflowIsEnabled',
    ]),
    /**
    Return true if user has made unsaved changes to the authentication configuration.
    For each configurable element, we'll simply check if they match the corresponding saved one
     */
    changesHaveBeenMade() {
      if (!this.configBeingModified.authenticationSubflowIsEnabled
        && !this.authenticationSubflowIsEnabled) {
        // Subflow authentication was not enabled before and still isn't.
        // In this case we do not wish to compare the remaining configs, specifically because we're
        // not resetting these other values to initial values when we disable using the
        // authentication subflow.
        return false;
      }
      if (this.configBeingModified.authenticationSubflowIsEnabled
        !== this.authenticationSubflowIsEnabled) {
        return true;
      }
      if (this.configBeingModified.subflowId !== this.subflowId) {
        return true;
      }
      if (this.configBeingModified.successNodeId !== this.successNode) {
        return true;
      }
      if (this.configBeingModified.failureNodeId !== this.failureNode) {
        return true;
      }
      return false;
    },
    subflowOptions() {
      const options = [{ text: 'Pick authentication subflow', value: null }];
      for (const subflow of this.getSubFlows) {
        options.push({ text: subflow.config.name, value: subflow.id });
      }
      return options;
    },
    selectedSubflow() {
      if (!this.configBeingModified.subflowId) {
        return null;
      }
      const { subFlows } = this.$store.state.botManipulation;
      return subFlows.find((subflow) => subflow.id === this.configBeingModified.subflowId);
    },
    subflowOutgoingNodes() {
      if (!this.selectedSubflow) {
        return [];
      }
      const options = [{ text: 'Select node', value: null }];
      for (const node of Object.values(this.selectedSubflow.nodes)) {
        if (node.options.outgoing) {
          options.push({ text: node.name, value: node.id });
        }
      }
      return options;
    },
    nodesRequiringAuthentication() {
      return Object.values(this.allNormalNodes)
        .filter((node) => node.requiresAuth);
    },
    /**
     * Disable save if we're not in a valid state
     */
    disableSave() {
      return this.$v.$invalid;
    },
  },
  watch: {
    changesHaveBeenMade(newVal) {
      this.$emit('unsavedChanges', newVal);
    },
  },
  mounted() {
    this.setAuthTmpFromStore();
  },
  beforeDestroy() {
    this.$emit('unsavedChanges', false);
  },
  methods: {
    ...mapMutations('botManipulation/activeBot/config/auth', [
      'setValueForAuthenticationEnabled',
      'setSubflowId',
      'setSuccessNode',
      'setFailureNode',
    ]),
    attemptSave() {
      // Check if we're about to disable authentication subflow, while nodes exist that require
      // authentication
      if (this.configBeingModified.authenticationSubflowIsEnabled === false
          && this.nodesRequiringAuthentication.length > 0) {
        // Without this hack it will appear to user that switch has changed from "ON" to "OFF",
        // which it hasn't untill user has indeed confirmed in modal to disable authentication.
        this.checkboxKey += 1;

        // Show modal with nodes requiring authentication
        this.$bvModal.show('disableAuthenticationModal');
        return;
      }
      // All is great: either we're enabling authentication subflows or we're disabling it while no
      // nodes are requiring authentication
      this.save();
    },
    setAuthTmpFromStore() {
      this.configBeingModified.authenticationSubflowIsEnabled = this.authenticationSubflowIsEnabled;
      this.configBeingModified.subflowId = this.subflowId;
      this.configBeingModified.successNodeId = this.successNode;
      this.configBeingModified.failureNodeId = this.failureNode;
    },
    save() {
      this.setValueForAuthenticationEnabled(
        this.configBeingModified.authenticationSubflowIsEnabled,
      );
      if (this.configBeingModified.authenticationSubflowIsEnabled) {
        this.setSubflowId(this.configBeingModified.subflowId);
        this.setSuccessNode(this.configBeingModified.successNodeId);
        this.setFailureNode(this.configBeingModified.failureNodeId);
      }
      this.setAuthTmpFromStore();
      this.$emit('unsavedChanges', false);
    },
  },
  validations() {
    return {
      configBeingModified: {
        subflowId: {
          required: requiredIf(() => this.configBeingModified.authenticationSubflowIsEnabled),
        },
        successNodeId: {
          required: requiredIf(() => this.configBeingModified.authenticationSubflowIsEnabled),
          notSameAsFailureNode(newValue) {
            return newValue !== this.configBeingModified.failureNodeId;
          },
        },
        failureNodeId: {
          required: requiredIf(() => this.configBeingModified.authenticationSubflowIsEnabled),
          notSameAsSuccessNode(newValue) {
            return newValue !== this.configBeingModified.successNodeId;
          },
        },
      },
    };
  },
};
</script>
