<template>
  <main role="main">
    <b-card
      title="Users"
      class="r-75"
      body-class="p-3"
    >
      <p class="font-weight-bold">
        Here you can create / delete users and manage user permissions.
      </p>
      <p class="mb-3">
        Click on a user to edit permissions.
      </p>
      <b-form-checkbox
        v-if="getLockedUsers > 0"
        id="includeInactiveUsersCheckbox"
        v-model="includeInactiveUsers"
        name="includeInactiveUsersCheckbox"
      >
        {{ getLockedUsersText }}
        <tooltipped-text value="Locked users cannot log in to BotStudio" />
      </b-form-checkbox>

      <b-table
        :tbody-tr-attr="rowStyle"
        :items="usersFormatted"
        :fields="userFields"
        show-empty
        :empty-text="emptyTableText"
        hover
        @row-clicked="editUser"
      >
        <template #cell(isADUser)="row">
          <p v-if="row.item.isADUser">
            Organization account (Active Directory)
          </p>
          <p v-else>
            Username and password
          </p>
        </template>
        <!-- This column is presented as: "Has access" -->
        <template #cell(is_active)="row">
          <p v-if="row.item.is_active">
            <span
              v-b-tooltip.hover
              title="User has access to BotStudio"
            >
              <font-awesome-icon
                icon="check-circle"
                size="lg"
              />
            </span>
          </p>
          <p v-else>
            <span
              v-b-tooltip.hover
              title="User does not have access to BotStudio"
            >
              <font-awesome-icon
                icon="ban"
                size="lg"
              />
            </span>
          </p>
        </template>
        <template #cell(buttons)="row">
          <b-button
            v-if="row.item.is_active"
            v-b-tooltip.hover
            title="Lock the user"
            size="sm"
            variant="outline-danger"
            @click="() => openLockModal(row.item.id)"
          >
            <font-awesome-icon icon="ban" />
          </b-button>
          <b-button
            v-if="!row.item.is_active"
            v-b-tooltip.hover
            title="Unlock the user"
            size="sm"
            variant="success"
            @click="() => presentUnlockUserModal(row.item.id)"
          >
            <font-awesome-icon icon="unlock" />
          </b-button>
        </template>
      </b-table>
      <div class="text-center">
        <b-button
          v-b-modal.new-user-modal
          variant="success"
        >
          <font-awesome-icon icon="plus" /> New user
        </b-button>
      </div>
    </b-card>
    <b-card
      title="Groups"
      class="r-75 mt-3"
      body-class="p-3"
    >
      <p class="font-weight-bold">
        Here you can create / delete permission groups and manage their permissions and members.
      </p>
      <p class="mb-3">
        Click on a group to edit permissions.
      </p>
      <b-table
        :tbody-tr-attr="{ style: 'cursor:pointer' }"
        :items="groupsFormatted"
        :fields="groupFields"
        show-empty
        empty-text="No groups to show"
        hover
        @row-clicked="editGroup"
      >
        <template #cell(buttons)="row">
          <b-button
            v-b-tooltip.hover
            title="Delete the group"
            size="sm"
            variant="outline-danger"
            @click="() => openDeleteModal(row.item.id)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
      </b-table>
      <div class="text-center">
        <b-button
          v-b-modal.new-group-modal
          variant="success"
        >
          <font-awesome-icon icon="plus" /> New group
        </b-button>
      </div>
    </b-card>

    <add-update-user-modal mode="createNew" />
    <add-group-modal />

    <b-modal
      id="lock-user-modal"
      title="Lock user"
      ok-title="Lock"
      ok-variant="danger"
      @ok="lockUser"
    >
      <p>
        Locking the user will prevent them from logging in to BotStudio.<br>
      </p>
      <p
        v-if="userToLockIsADUser"
        class="text-secondary px-3"
      >
        <font-awesome-icon icon="exclamation-circle" />
        This user is signed in using an organization account (Active Directory).
        <br>
        Locking this user will prevent her/him from signing into BotStudio.
        <br>
        If the user is no longer within your organization, you may lock the
        user, to clean up the table of users.
      </p>
      Confirm the locking of user: <b>{{ toLockUsername }}</b>
    </b-modal>

    <b-modal
      id="unlock-user-modal"
      title="Unlock user"
      ok-title="Unlock"
      ok-variant="success"
      @ok="unlockUser"
    >
      <p>
        Unlocking the user will give the user access to sign-in to BotStudio
        again.
      </p>
    </b-modal>

    <b-modal
      id="delete-group-modal"
      title="Delete group"
      ok-title="Delete"
      ok-variant="danger"
      @ok="deleteGroup"
    >
      <p>
        Deleting the group is <b>permanent</b> and cannot be undone.
      </p>
      <p>
        All users will be removed from the group.
      </p>
      Confirm the deletion of group: <b>{{ toDeleteGroupName }}</b>
    </b-modal>
  </main>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import axios from 'axios';
import endpoints from '@/js/urls';
import AddUpdateUserModal from '@/pages/Admin/AddUpdateUserModal.vue';
import AddGroupModal from '@/pages/Admin/AddGroupModal.vue';
import { UUID_REGEX } from '@/js/utils';
import TooltippedText from '@/components/TooltippedText.vue';

export default {
  name: 'AdminUsersGroupsOverview',
  components: {
    AddUpdateUserModal,
    AddGroupModal,
    TooltippedText,
  },
  data() {
    return {
      userFields: [
        'username',
        'email',
        'usertype',
        {
          key: 'isADUser',
          label: 'Sign-in method',
        },
        {
          key: 'is_active',
          label: 'Has access',
          tdClass: 'text-middle',
        },
        {
          key: 'buttons',
          label: 'Actions',
          thClass: 'text-right',
          tdClass: 'text-right align-middle',
        },
      ],
      groupFields: [
        'name',
        {
          key: 'buttons',
          label: 'Actions',
          thClass: 'text-right',
          tdClass: 'text-right align-middle',
        },
      ],
      toLockUserId: null,
      unlockUserId: null,
      toDeleteGroupId: null,
      includeInactiveUsers: false,
    };
  },
  computed: {
    ...mapState('administration', ['users']),
    ...mapState('administration', ['groups']),
    getLockedUsers() {
      return Object.values(this.users).filter((x) => !x.is_active).length;
    },
    getLockedUsersText() {
      const numUsers = this.getLockedUsers;
      return `Include ${numUsers} locked user${numUsers > 1 ? 's' : ''}`;
    },
    emptyTableText() {
      /**
       * This is only invoked when there are
       */
      if (this.includeInactiveUsers) {
        // In this case there really are no users to show (not even locked out ones)
        return 'No users to show';
      }
      return `No users to show. Check "${this.getLockedUsersText}" to see ${this.getLockedUsers} more user(s)`;
    },
    toLockUsername() {
      if (this.toLockUserId && this.toLockUserId in this.users) {
        const user = this.users[this.toLockUserId];
        if (this.userIsADUser(user.username)) {
          return user.email;
        }
        return user.username;
      }
      return null;
    },
    toDeleteGroupName() {
      if (this.toDeleteGroupId && this.toDeleteGroupId in this.groups) {
        const group = this.groups[this.toDeleteGroupId];
        return group.name;
      }
      return null;
    },
    userToLockIsADUser() {
      if (this.toLockUserId === null || this.toLockUserId === undefined) {
        return false;
      }
      const user = this.users[this.toLockUserId];
      if (user === undefined) {
        return false;
      }
      return this.userIsADUser(user.username);
    },
    /**
     * usersFormatted makes for nicer presentation of users having logged in with Azure AD: Instead
     * of showing their 'username' (a UUID), we use their email as username.
     */
    usersFormatted() {
      const usersCopy = Object.values(this.users).slice();
      const includeFilter = (user) => {
        if (!user.is_active && !this.includeInactiveUsers) {
          return false;
        }
        return user.username !== 'superuser' && user.username !== 'chatbot-service-account';
      };
      return usersCopy
        .filter(includeFilter)
        .map((user) => {
          const userCopy = { ...user, isADUser: false };
          if (this.userIsADUser(user.username)) {
            userCopy.isADUser = true;
            if (user.email !== '') {
              // Override username
              userCopy.username = user.email;
            }
          }
          return userCopy;
        })
        .sort((a, b) => a.username.localeCompare(b.username));
    },
    groupsFormatted() {
      const groupsCopy = Object.values(this.groups).slice();
      return groupsCopy.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    ...mapActions('administration', [
      'fetchGroups',
      'fetchUsers',
    ]),
    ...mapMutations('administration', {
      stateDeleteGroup: 'deleteGroup',
      updateUser: 'updateUser',
    }),
    editUser(item) {
      const user = this.users[item.id];
      if (!user.is_active) {
        // If the user is locked out, "prevent" editing permissions
        return;
      }
      this.$router.push({
        name: 'admin-users-single',
        params: { userId: item.id },
      });
    },
    editGroup(item) {
      this.$router.push({
        name: 'admin-groups-single',
        params: { groupId: item.id },
      });
    },
    async lockUser() {
      await this.updateIsActiveStatusForUser(this.toLockUserId, false);
    },
    async deleteGroup() {
      const resp = await axios.delete(endpoints.group + this.toDeleteGroupId, {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      });
      if (resp.data.success) {
        this.stateDeleteGroup(resp.data.id);
      }
    },
    openLockModal(id) {
      this.toLockUserId = id;
      this.$bvModal.show('lock-user-modal');
    },
    openDeleteModal(id) {
      this.toDeleteGroupId = id;
      this.$bvModal.show('delete-group-modal');
    },
    openCreateUserModal() {
      this.$bvModal.show('new-user-modal');
    },
    openCreateGroupModal() {
      this.$bvModal.show('new-group-modal');
    },
    userIsADUser(userId) {
      return userId.match(UUID_REGEX) !== null;
    },
    presentUnlockUserModal(unlockUserId) {
      this.unlockUserId = unlockUserId;
      this.$bvModal.show('unlock-user-modal');
    },
    async unlockUser() {
      await this.updateIsActiveStatusForUser(this.unlockUserId, true);
    },
    async updateIsActiveStatusForUser(userId, newIsActiveStatus) {
      const payload = {
        isActive: newIsActiveStatus,
      };
      const resp = await axios.put(endpoints.lockUser + userId, payload, {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      });
      this.updateUser({ id: resp.data.id, user: resp.data.result });
    },

    rowStyle(item, type) {
      if (!item || type !== 'row') {
        return {};
      }
      if (item.is_active) {
        return {
          style: 'cursor:pointer',
        };
      }
      return {};
    },
  },
};
</script>
