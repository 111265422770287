import Vue from 'vue';
import axios from 'axios';
import endpoints from '@/js/urls';

const suggestionsState = {
  isFetching: {
    flowPotentials: false,
    simpleNodeFallbacks: false,
    transferredChats: false,
  },
  flowPotentials: [],
  transferredChats: {
    total: 0,
    transferred: {},
    transferredCovered: {},
    transferredNonCovered: {},
  },
  simpleNodeFallbacks: [],
  improvementsTimeDelta: 7,
  flowPotentialChats: {
    // <flowNodeId>: [
    //   ChatBotLog
    // ]
  },
  suggestionsFilter: {
    selectedVariant: '',
  },
};
const getters = {
  isFetchingFlowPotentials: (state) => state.isFetching.flowPotentials,
  isFetchingSimpleNodeFallback: (state) => state.isFetching.simpleNodeFallbacks,
  isFetchingTransferredChats: (state) => state.isFetching.transferredChats,
  getFlowPotentials: (state) => (numberOfNodes) => state.flowPotentials.slice(0, numberOfNodes),
  getSimpleNodeFallbacks: (state) => (numberOfNodes) => state
    .simpleNodeFallbacks.slice(0, numberOfNodes),
  getTransferredChats: (state) => state.transferredChats,
  suggestionsFilter: (state) => state.suggestionsFilter,
};
const mutations = {
  setImprovementsTimeDelta(state, value) {
    state.improvementsTimeDelta = value;
  },
  setFlowPotentials(state, payload) {
    state.flowPotentials = payload;
  },
  setTransferredChats(state, payload) {
    state.transferredChats = payload;
  },
  setSimpleNodeFallbacks(state, payload) {
    state.simpleNodeFallbacks = payload;
  },
  setIsFetching(state, { prop, value }) {
    Vue.set(state.isFetching, prop, value);
  },
  resetSuggestions(state) {
    state.flowPotentials = [];
    state.simpleNodeFallbacks = [];
    state.flowPotentialChats = {};
  },
  updateSuggestionsFilter(state, { key, newValue }) {
    state.suggestionsFilter[key] = newValue;
  },
};
const actions = {
  async fetchHealthSuggestions({
    state,
    rootState, commit, rootGetters, dispatch,
  }) {
    commit('setIsFetching', { prop: 'flowPotentials', value: true });
    commit('setIsFetching', { prop: 'transferredChats', value: true });
    commit('setIsFetching', { prop: 'simpleNodeFallbacks', value: true });
    try {
      const data = {
        bot_id: rootGetters['botManipulation/getBotId'],
        variant_id: state.suggestionsFilter.selectedVariant,
        selected_data_origins: rootGetters['chatlogs/availableDataOrigins'].map((x) => x.rawValue),
        days_delta: state.improvementsTimeDelta,
      };
      const response = await axios.post(
        endpoints.healthSuggestions,
        data,
        { headers: { Authorization: `JWT ${rootState.auth.jwt}` } },
      );
      if (response.status === 200) {
        commit('setIsFetching', { prop: 'flowPotentials', value: true });
        commit('task/addTask', {
          celeryId: response.data.flowPotentialsTaskId,
          callbackDone: (d) => {
            commit('setFlowPotentials', d.result);
            commit('setIsFetching', { prop: 'flowPotentials', value: false });
          },
          callbackFailed: () => {
            dispatch('sidebar/showWarning', {
              title: 'An error occured',
              text: 'Failed to compute flow potential',
              variant: 'danger',
            }, { root: true });
            commit('setIsFetching', { prop: 'flowPotentials', value: false });
          },
        }, { root: true });
        commit('setIsFetching', { prop: 'simpleNodeFallbacks', value: true });
        commit('task/addTask', {
          celeryId: response.data.transferredChatsTaskId,
          callbackDone: () => {
            dispatch('fetchTransferredChats', response.data.transferredChatsTaskId);
          },
          callbackFailed: () => {
            dispatch('sidebar/showWarning', {
              title: 'An error occured',
              text: 'Failed to compute transferred chats',
              variant: 'danger',
            }, { root: true });
          },
        }, { root: true });
        commit('task/addTask', {
          celeryId: response.data.simpleNodeFallbacksTaskId,
          callbackDone: (d) => {
            commit('setSimpleNodeFallbacks', d.result);
            commit('setIsFetching', { prop: 'simpleNodeFallbacks', value: false });
          },
          callbackFailed: () => {
            dispatch('sidebar/showWarning', {
              title: 'An error occured',
              text: 'Failed to compute simple nodes fallbacks',
              variant: 'danger',
            }, { root: true });
            commit('setIsFetching', { prop: 'simpleNodeFallbacks', value: false });
          },
        }, { root: true });
      }
    } catch (e) {
      commit('setIsFetching', { prop: 'flowPotentials', value: false });
      commit('setIsFetching', { prop: 'simpleNodeFallbacks', value: false });
      commit('setIsFetching', { prop: 'transferredChats', value: false });
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch health suggestions',
        text: e.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  async fetchFlowPotentials({ commit, rootState, dispatch }, taskId) {
    commit('setIsFetching', { prop: 'flowPotentials', value: true });
    try {
      const response = await axios.get(endpoints.healthSuggestions, {
        params: { task_id: taskId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      commit('setFlowPotentials', response.data.info);
      commit('setIsFetching', { prop: 'flowPotentials', value: false });
    } catch (e) {
      commit('setIsFetching', { prop: 'flowPotentials', value: false });
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch flow potentials',
        text: e.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  async fetchTransferredChats({ commit, rootState, dispatch }, taskId) {
    commit('setIsFetching', { prop: 'transferredChats', value: true });
    try {
      const response = await axios.get(endpoints.healthSuggestions, {
        params: { task_id: taskId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      commit('setTransferredChats', response.data.info);
      commit('setIsFetching', { prop: 'transferredChats', value: false });
    } catch (e) {
      commit('setIsFetching', { prop: 'transferredChats', value: false });
      dispatch('sidebar/showWarning', {
        title: 'Failted to fetch transferred chats',
        text: e.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  async fetchSimpleNodeFallbacks({ commit, rootState, dispatch }, taskId) {
    commit('setIsFetching', { prop: 'simpleNodeFallbacks', value: true });
    try {
      const response = await axios.get(endpoints.healthSuggestions, {
        params: { task_id: taskId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      commit('setSimpleNodeFallbacks', response.data.info);
      commit('setIsFetching', { prop: 'simpleNodeFallbacks', value: false });
    } catch (e) {
      commit('setIsFetching', { prop: 'simpleNodeFallbacks', value: false });
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch simple node fallbacks',
        text: e.message,
        variant: 'danger',
      }, { root: true });
    }
  },
};
export default {
  namespaced: true,
  state: suggestionsState,
  getters,
  mutations,
  actions,
};
