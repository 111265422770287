<template>
  <div>
    <h3>Voice</h3>
    <b-card
      no-body
    >
      <p>
        Define voice-specific bot settings.
      </p>
    </b-card>
    <hr>
    <b-form-group
      description="Toggle whether the speech-to-text engine should support multi-language
        detection and understanding. If enabled, there will be support for English and the
        selected routing language."
      label="Speech-to-text multi-language support"
      label-for="voiceSpeech2textMultiLanguage"
    >
      <b-form-checkbox
        id="voiceSpeech2textMultiLanguage"
        v-model="speech2textMultiLanguage"
        switch
      >
        Recognize and understand multiple languages
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      description="Select the voice to use for generating bot audio in BotStudio"
      label="Text-to-speech voice"
      label-for="voiceText2speechVoice"
    >
      <b-overlay
        rounded
        spinner-small
        :show="isFetchingVoices"
      >
        <b-form-select
          id="voiceText2speechVoice"
          v-model="text2speechVoiceSelected"
          :options="voiceOptions"
          :disabled="isFetchingVoices"
        />
      </b-overlay>
    </b-form-group>
    <b-form-group
      description="Select whether the user should be able to interrupt the bot"
      label="User interruption mode"
      label-for="voiceInterruptMode"
    >
      <b-form-radio-group
        id="voiceInterruptMode"
        v-model="interruptModeSelected"
        :options="interruptModeOptions"
        stacked
      />
    </b-form-group>
    <b-form-group
      label="Delay before repeating"
      label-for="repeatBehaviour"
    >
      <template slot="description">
        The number of seconds before the bot repeats its last messages.
        Setting a value of 0 disables repeats altogether.
      </template>
      <b-form-input
        v-model="delayBeforeRepeat"
        :state="$v.delayBeforeRepeat.$invalid ? false : null"
        type="number"
        placeholder="0"
        aria-describedby="delayBeforeRepeatFeedback"
        @blur="ensureDelayRepeatValidState"
      />
      <b-form-invalid-feedback id="delayBeforeRepeatFeedback">
        <div v-if="!$v.delayBeforeRepeat.nonNegative">
          The delay must be a non-negative integer.
        </div>
        <div v-if="!$v.delayBeforeRepeat.integer">
          Waiting time must be an integer.
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label="Speech-to-text phrases"
    >
      <string-list
        class="phrase-list"
        fixed-input
        :strings="speech2textContext.phrases"
        placeholder="Add phrase"
        :validate="false"
        @change="updatePhrases"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { debounce } from 'lodash';
import { validationMixin } from 'vuelidate';
import { integer, minValue } from 'vuelidate/lib/validators';
import StringList from 'supwiz/components/StringList.vue';
import update from 'immutability-helper';
import { nodeTypes } from '@/js/constants';

export default {
  name: 'Voice',
  components: {
    StringList,
  },
  mixins: [validationMixin],
  data() {
    return {
      allowInterrupt: false,
      interruptModeOptions: [
        { text: 'No interruption allowed', value: 'none' },
        { text: 'Allow interrupt of all nodes', value: 'all' },
        { text: 'Only allow interrupt of smart nodes', value: 'smart' },
      ],
      speech2textContext: {},
      debouncer: null,
      voices: [],
      isFetchingVoices: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config/voice', [
      'getSpeech2textMultiLanguage',
      'getText2speechVoice',
      'getInterruptMode',
      'getSpeech2textContext',
      'getDefaultText2speech',
      'getValidVoices',
      'getInterruptNode',
      'getDelayBeforeRepeat',
      'getInbandDTMF',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    delayBeforeRepeat: {
      get() {
        return this.getDelayBeforeRepeat;
      },
      set(newValue) {
        if (newValue === null) {
          // Set it to zero
          this.setDelayBeforeRepeat(0);
          return;
        }
        this.setDelayBeforeRepeat({
          newDelayBeforeRepeat: newValue,
        });
      },
    },
    text2speechVoiceOptions() {
      if (!this.text2speechVoiceEngineOptions) {
        return [];
      }
      return this.text2speechVoiceEngineOptions[this.text2speechSelected];
    },
    speech2textMultiLanguage: {
      get() {
        return this.getSpeech2textMultiLanguage;
      },
      set(value) {
        this.setSpeech2textMultiLanguage({ value });
      },
    },
    text2speechVoiceSelected: {
      get() {
        return this.getText2speechVoice;
      },
      set(value) {
        this.setText2speechVoice({ voice: value });
      },
    },
    speech2textSelected: {
      get() {
        return this.getSpeech2text || 'google';
      },
      set(value) {
        this.setSpeech2text({ engine: value });
      },
    },
    interruptModeSelected: {
      get() {
        return this.getInterruptMode || 'none';
      },
      set(value) {
        this.setInterruptMode({ mode: value });
      },
    },
    inbandDTMF: {
      get() {
        return this.getInbandDTMF || false;
      },
      set(value) {
        this.setInbandDTMF({ inbandDTMF: value });
      },
    },
    activeBot() {
      return this.$store.state.botManipulation.activeBot;
    },
    globalNodes() {
      return Object.values(this.activeBot.nodes).filter(
        (n) => n.options.global && n.options.nodeType !== nodeTypes.QA,
      );
    },
    voiceOptions() {
      return this.voices.map((v) => ({ value: v.name, text: v.display_name }));
    },
  },
  watch: {
    getSpeech2textContext(newValue) {
      if (newValue !== this.speech2textContext) {
        const val = JSON.parse(newValue);
        if (val.length) {
          this.speech2textContext = val[0];
        } else {
          this.speech2textContext = val || {};
        }
      }
    },
    async getText2speech() {
      await this.fetchVoices();
    },
  },
  async created() {
    const val = JSON.parse(this.getSpeech2textContext);
    if (val && val.length) {
      this.speech2textContext = val[0];
    } else {
      this.speech2textContext = val || {};
    }
    this.speech2textContextDebouncer = debounce(
      this.updateSpeech2textContext,
      500,
      { leading: false, trailing: true },
    );
    await this.fetchVoices();
  },
  methods: {
    ...mapMutations('botManipulation/activeBot/config/voice', [
      'setSpeech2text',
      'setSpeech2textMultiLanguage',
      'setText2speech',
      'setInterruptMode',
      'setRecordCalls',
      'setSpeech2textContext',
      'setText2speechVoice',
      'setInterruptNode',
      'setDelayBeforeRepeat',
      'setInbandDTMF',
    ]),
    ...mapActions('botManipulation/activeBot/config/voice', [
      'fetchSpeech2textVoices',
    ]),
    async fetchVoices() {
      this.isFetchingVoices = true;
      const voices = await this.fetchSpeech2textVoices();
      this.voices = voices;
      if (this.voiceOptions.findIndex((x) => x.value === this.text2speechVoiceSelected) < 0) {
        this.setText2speechVoice({ voice: this.voiceOptions[0].value });
      }
      this.isFetchingVoices = false;
    },
    ensureDelayRepeatValidState() {
      if (this.$v.delayBeforeRepeat.$invalid) {
        // User left the input in an invalid state, we cannot know what she/he intended to set it to
        this.delayBeforeRepeat = 0;
      }
    },
    updateSpeech2textContext() {
      this.setSpeech2textContext({ context: JSON.stringify(this.speech2textContext) });
    },
    updatePhrases(phrases) {
      if (!Object.prototype.hasOwnProperty.call(this.speech2textContext, 'phrases')) {
        this.speech2textContext.phrases = [];
      }
      Object.assign(this.speech2textContext, update(this.speech2textContext, { phrases }));
      this.updateSpeech2textContext();
    },
  },
  validations: {
    delayBeforeRepeat: {
      nonNegative: minValue(0),
      integer,
    },
  },
};
</script>
<style scoped>
.phrase-list{
  max-height: 300px;
  overflow-y:auto;
}
</style>
