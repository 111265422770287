import { render, staticRenderFns } from "./ChatEvent.vue?vue&type=template&id=475d65dc&scoped=true&"
import script from "./ChatEvent.vue?vue&type=script&lang=js&"
export * from "./ChatEvent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "475d65dc",
  null
  
)

export default component.exports