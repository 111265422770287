<template>
  <b-row class="mb-3">
    <b-col>
      <b-overlay :show="isLoading('automation_distributions')">
        <b-card
          title="Automation details"
          class="r-75 shadow text-center"
          body-class="p-3"
        >
          <div>
            <!-- LEVEL 1 -->
            <b-row
              no-gutters
              class="pt-1"
            >
              <b-col
                :cols="value2Grid(key2Value('flow_coverage'))"
              >
                <bar-piece
                  show-arrow
                  label="Covered"
                  :value="key2Value('flow_coverage')"
                  :bg-color="colors.covered"
                />
              </b-col>
              <b-col
                :cols="12 - value2Grid(key2Value('flow_coverage'))"
              >
                <bar-piece
                  show-arrow
                  label="Not covered"
                  :value="key2Value('not_covered')"
                  :bg-color="colors.notCovered"
                />
              </b-col>
            </b-row>

            <b-row no-gutters>
              <!-- LEFT SIDE -->
              <b-col class="pr-2">
                <b-row no-gutters>
                  <b-col class="r-25 border py-2 px-2">
                    <b-button
                      v-b-tooltip.hover.top.noninteractive
                      size="sm"
                      variant="primary"
                      :title="`${coveredVisible ? 'Hide' : 'Show'} details`"
                      class="py-0"
                      :class="coveredVisible ? 'd-block' : 'float-left'"
                      onmouseup="this.blur()"
                      @click="coveredVisible = !coveredVisible"
                    >
                      <font-awesome-icon :icon="coveredVisible ? 'angle-up' : 'angle-down'" />
                    </b-button>

                    <span
                      v-if="!coveredVisible"
                      style="margin-left: -28px;"
                    >
                      Automation rate
                      <span
                        :class="coveredVisible ? '' : 'h1 mb-0 mb-1 d-block'"
                        class="font-weight-bold text-primary"
                      >
                        {{ coveredAutomationRate }}
                      </span>
                    </span>
                    <template v-else>
                      <b-overlay
                        variant="white bar-overlay"
                        :show="!hasAutomationData('flow_coverage', 1)"
                        opacity="0.8"
                      >
                        <template #overlay>
                          <span />
                        </template>

                        <b-row no-gutters>
                          <b-col :cols="value2Grid(key2Value('flow_coverage_self_service'))">
                            <bar-piece
                              label="Self-serviced"
                              :value="key2Value('flow_coverage_self_service')"
                              :bg-color="colors.selfService"
                            />
                          </b-col>
                          <b-col :cols="12 - value2Grid(key2Value('flow_coverage_self_service'))">
                            <bar-piece
                              label="Partially automated conversations"
                              show-arrow
                              :value="key2Value('flow_coverage_other')"
                              :bg-color="colors.other"
                            />
                          </b-col>
                        </b-row>
                      </b-overlay>
                      <b-overlay
                        variant="white bar-overlay"
                        :show="!hasAutomationData('flow_coverage', 2)"
                        opacity="0.8"
                      >
                        <template #overlay>
                          <span />
                        </template>
                        <b-row no-gutters>
                          <b-col
                            :cols="value2Grid(key2Value('flow_coverage_other_partial_resolution'))"
                          >
                            <bar-piece
                              label="Partial resolution rate"
                              :value="key2Value('flow_coverage_other_partial_resolution')"
                              :bg-color="colors.partialResolution"
                            />
                          </b-col>
                          <b-col
                            :cols="12
                              - value2Grid(key2Value('flow_coverage_other_partial_resolution'))"
                          >
                            <bar-piece
                              :value="key2Value('flow_coverage_other_other')"
                              :bg-color="colors.other"
                            />
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </b-col>
                </b-row>
              </b-col>
              <!-- RIGHT SIDE -->
              <b-col class="pl-2">
                <b-row no-gutters>
                  <b-col class="r-25 border py-2 px-2">
                    <b-button
                      v-b-tooltip.hover.top.noninteractive
                      size="sm"
                      variant="primary"
                      :title="`${notCoveredVisible ? 'Hide' : 'Show'} details`"
                      class="py-0"
                      :class="notCoveredVisible ? 'd-block' : 'float-left'"
                      onmouseup="this.blur()"
                      @click="notCoveredVisible = !notCoveredVisible"
                    >
                      <font-awesome-icon :icon="notCoveredVisible ? 'angle-up' : 'angle-down'" />
                    </b-button>
                    <span
                      v-if="!notCoveredVisible"
                      style="margin-left: -28px;"
                    >
                      Automation rate
                      <span
                        :class="notCoveredVisible ? '' : 'h1 mb-0 mb-1 d-block'"
                        class="font-weight-bold text-primary"
                      >
                        {{ notCoveredVisible ? '' : notCoveredAutomationRate }}
                      </span>
                    </span>
                    <template v-else>
                      <b-overlay
                        variant="white bar-overlay"
                        :show="!hasAutomationData('not_covered', 1)"
                        opacity="0.8"
                      >
                        <template #overlay>
                          <span />
                        </template>
                        <b-row no-gutters>
                          <b-col :cols="value2Grid(key2Value('not_covered_self_service'))">
                            <bar-piece
                              label="Self-serviced"
                              :value="key2Value('not_covered_self_service')"
                              :bg-color="colors.selfService"
                            />
                          </b-col>
                          <b-col :cols="12 - value2Grid(key2Value('not_covered_self_service'))">
                            <bar-piece
                              label="Partially automated conversations"
                              show-arrow
                              :value="key2Value('not_covered_other')"
                              :bg-color="colors.other"
                            />
                          </b-col>
                        </b-row>
                      </b-overlay>
                      <b-overlay
                        variant="white bar-overlay"
                        :show="!hasAutomationData('not_covered', 2)"
                        opacity="0.8"
                      >
                        <template #overlay>
                          <span />
                        </template>
                        <b-row no-gutters>
                          <b-col
                            :cols="value2Grid(key2Value('not_covered_other_partial_resolution'))"
                          >
                            <bar-piece
                              label="Partial resolution rate"
                              :value="key2Value('not_covered_other_partial_resolution')"
                              :bg-color="colors.partialResolution"
                            />
                          </b-col>
                          <b-col
                            :cols="12
                              - value2Grid(key2Value('not_covered_other_partial_resolution'))"
                          >
                            <bar-piece
                              :value="key2Value('not_covered_other_other')"
                              :bg-color="colors.other"
                            />
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from 'vuex';
import BarPiece from '@/pages/Statistics/BarPiece.vue';

export default {
  name: 'AutomationDetails',
  components: {
    BarPiece,
  },
  data() {
    return {
      colors: {
        covered: '#546e7a',
        notCovered: '#90a4ae',
        selfService: '#43a047',
        other: '#b0bec5',
        partialResolution: '#66bb6a',
      },
      coveredVisible: false,
      notCoveredVisible: false,
    };
  },
  computed: {
    ...mapGetters('statistics', ['getKPIData', 'isLoading']),
    isEmpty() {
      return !this.getKPIData('automation_distributions');
    },
    coveredAutomationRate() {
      const data = this.getKPIData('automation_distributions');
      if (this.hasAutomationData('flow_coverage', 1)) {
        if (this.hasAutomationData('flow_coverage', 2)) {
          return `${((data.info.flow_coverage_self_service + (data.info.flow_coverage_other
       * data.info.flow_coverage_other_partial_resolution)) * 100).toFixed(1)}%`;
        }
        return `${(data.info.flow_coverage_self_service * 100).toFixed(1)}%`;
      }
      return 'N/A';
    },
    notCoveredAutomationRate() {
      const data = this.getKPIData('automation_distributions');
      if (this.hasAutomationData('not_covered', 1)) {
        if (this.hasAutomationData('not_covered', 2)) {
          return `${((data.info.not_covered_self_service + (data.info.not_covered_other
        * data.info.not_covered_other_partial_resolution)) * 100).toFixed(1)}%`;
        }
        return `${(data.info.not_covered_self_service * 100).toFixed(1)}%`;
      }
      return 'N/A';
    },
  },
  methods: {
    value2Grid(value) {
      if (value === null) {
        return 6;
      }
      if (value >= 82) return 10;
      if (value >= 72) return 9;
      if (value >= 63) return 8;
      if (value >= 53) return 7;
      if (value >= 47) return 6;
      if (value >= 37) return 5;
      if (value >= 28) return 4;
      if (value >= 18) return 3;
      return 2;
    },
    key2Value(key) {
      const value = this.getKPIData('automation_distributions');
      if (value?.info && value.info[key] !== null) {
        return Math.round(value.info[key] * 10000) / 100;
      }
      return null;
    },
    hasAutomationData(key, depth) {
      if (key === 'flow_coverage') {
        if (depth === 1) {
          return !!this.key2Value('flow_coverage_self_service') || !!this.key2Value('flow_coverage_other');
        }
        return this.hasAutomationData('flow_coverage', 1) && (!!this.key2Value('flow_coverage_other_partial_resolution') || !!this.key2Value('flow_coverage_other_other'));
      }
      if (depth === 1) {
        return !!this.key2Value('not_covered_self_service') || !!this.key2Value('not_covered_other');
      }
      return this.hasAutomationData('not_covered', 1) && (!!this.key2Value('not_covered_other_partial_resolution') || !!this.key2Value('not_covered_other_other'));
    },
  },
};
</script>
<style scoped>
::v-deep .stat-overlay {
  border-radius: 0.5rem;
}
</style>
