import Vue from 'vue';
import moment from 'moment';
import buildinfo from '@/assets/buildinfo.json';
import { PHRASE_REGEX } from '@/js/constants';
import { COMPOUND_RESPONSE, nodeHasResponse, RESPONSE } from '@/js/activity';
import spec from '../../package.json';
import { ssmlRegex } from './voicebotTags';
import { isProduction } from './featureFlags';

const name = spec.name;
const version = spec.version;

export function versionInfo() {
  return {
    name,
    version,
    ...buildinfo,
  };
}

export function versionInfoArray() {
  const info = versionInfo();
  const ret = [];
  for (const k of Object.keys(info)) {
    ret.push({ name: k, value: info[k] });
  }
  return ret;
}

export function truncateString(text, maxLength) {
  if (text && text.length > maxLength) {
    return `${text.substring(0, maxLength - 3)}...`;
  }
  return text;
}

export const UUID_REGEX = new RegExp(
  '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}', 'i',
);

export function guidGenerator() {
  /**
   * @return {string}
   */
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (`${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`);
}

/**
 * This will remove (secret) keys from params,
 * or make sure they are present (using the default if missing)
 * To prevent react in this method a shallow copy of params is returned
 */
export function includeOrRemoveParams(params, doInclude, extraParams) {
  const ret = params ? params.slice() : [];
  if (!extraParams || extraParams.length === 0) {
    return ret;
  }

  if (!doInclude) {
    const extraParamKeys = extraParams.map((p) => p.name);
    return ret.filter((existingParam) => !extraParamKeys.includes(existingParam.key));
  }

  for (const extraParam of extraParams) {
    if (!ret.some((existingParam) => existingParam.key === extraParam.name)) {
      ret.push({ key: extraParam.name, value: extraParam.default });
    }
  }

  return ret;
}

/**
 * Change the key value pair in params.
 * If the key does not exists in params, include it.
 *
 * To prevent react in this method a shallow copy of params is made and the modified param is a copy
 */
export function changeOrAddParam(params, key, value, hintIndex = -1) {
  const ret = params ? params.slice() : [];

  // check hint exists and works
  if (hintIndex >= 0) {
    const param = ret[hintIndex];
    if (param && param.key === key) {
      ret[hintIndex] = { key, value };
      return ret;
    }
  }

  const index = ret.findIndex((param) => param.key === key);
  if (index < 0) {
    ret.push({ key, value });
    return ret;
  }
  ret[index] = { key, value };
  return ret;
}

export function getBotstudioEnvironment() {
  // Be cautious of what you allow yourself to use this value for. An enduser
  // (although a technical one) may change the value him/herself in the developer console.
  // In any case you should not rely on information based in the frontend to allow/disallow making
  // changes in the backend; the backend should always itself verify that the user is allowed to
  // perform the requested operation.
  return isProduction() ? window.botstudioEnvironment : process.env.NODE_ENV;
}

export function setState(state, newState, skipKeys) {
  for (const [key, value] of Object.entries(newState)) {
    Vue.set(state, key, value);
  }
  const oldKeys = Object.keys(state);
  const newKeys = new Set(Object.keys(newState));
  let keysToRemove = oldKeys.filter((x) => !newKeys.has(x));
  if (skipKeys && skipKeys.length) {
    keysToRemove = keysToRemove.filter((x) => !skipKeys.includes(x));
  }
  keysToRemove.forEach((key) => {
    Vue.delete(state, key);
  });
}

export function chatSystemChatLink(chatLogData) {
  if (!chatLogData) {
    return false;
  }
  const supportedPlatforms = ['zendesk', 'zendesk-support', 'supchat'];
  if (!supportedPlatforms.includes(chatLogData.chatMeta.platform)) {
    return false;
  }
  // Each platform has links in different ways
  if (chatLogData.chatMeta.platform === 'zendesk') {
    if (!!chatLogData.chatMeta.chat_system_url && !!chatLogData.chatMeta.raw_zendesk_id) {
      // chat_system_url should be something like https://d3v-supwizhelp.zendesk.com
      const u = `${chatLogData.chatMeta.chat_system_url}/chat/transcripts/${chatLogData.chatMeta.raw_zendesk_id}`;
      return u.replace('//chat', '/chat');
    }
    return false;
  }
  if (chatLogData.chatMeta.platform === 'zendesk-support') {
    if (!!chatLogData.chatMeta.chat_system_url && !!chatLogData.chatMeta.chat_system_id) {
      const u = `${chatLogData.chatMeta.chat_system_url}/agent/tickets/${chatLogData.chatMeta.chat_system_id}`;
      return u.replace('//agent', '/agent');
    }
    return false;
  }
  if (chatLogData.chatMeta.platform === 'supchat') {
    if (!!chatLogData.chatMeta.chat_system_url && !!chatLogData.chatMeta.chat_system_id) {
      const u = `${chatLogData.chatMeta.chat_system_url}/dashboard/history-single/${chatLogData.chatMeta.chat_system_id}`;
      return u.replace('//dashboard', '/dashboard');
    }
    return false;
  }
  return false;
}

export function chatlogTransformer(logEntry) {
  return {
    summary: {
      started: (new Date(logEntry.chat_started)),
      initialUserMessage: logEntry.initial_user_message,
      messageCount: logEntry.messages_in_chat,
    },
    variantId: logEntry.variant_id,
    chatId: logEntry.chat_id,
    externalId: logEntry.external_id,
    logEvents: logEntry.log_entries,
    chatMeta: logEntry.chat_meta,
    internalMeta: logEntry.internal_meta,
    language: logEntry.language,
    chatStarted: logEntry.chat_started,
    chatEnded: logEntry.chat_ended,
    messagesInChat: logEntry.messages_in_chat,
    startUrl: logEntry.start_url,
    _showDetails: false,
  };
}

export function capitalizeFirstLetter(string) {
  return string.replace(/^./, string[0].toUpperCase());
}

export function getConnectionErrorMsg(error) {
  if (!error) {
    return 'Browser Cancelled Request';
  }
  if (!error.response) {
    return 'No Connection Error';
  }

  let body = null;
  if (error.response.data) {
    body = error.response.data;
  } else {
    return 'Internal Server Error';
  }

  if (body.error) {
    // Default field for error messages
    return body.error;
  }
  if (body.detail) {
    // 401 will give detail
    return body.detail;
  }

  if (error.statusText) {
    return error.statusText;
  }

  if (error.message) {
    return error.message;
  }

  return `Unhandled error: ${error.response.status}`;
}

/**
 * Wrap SSML tags in <span> with ssmltag class and also escape < and >.
 * @param {string} text - The string which contains SSML tags.
 * @returns {string} - Returns formatted string with ssml tags wrapped in spans.
 */
export function formatChatlogText(text, isVoicebot = false, showSSML = true) {
  let html = text;
  if (html === '') return '<i>Empty message</i>';
  html = html
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\n/g, '<br>');
  if (isVoicebot) {
    if (!showSSML) {
      const emptyMatchingTags = ['p', 's'];
      const paramMatchingTags = ['say-as', 'phoneme'];
      const emptyMatchingTagsRegex = new RegExp(
        `&lt;(?:${emptyMatchingTags.join('|')})&gt;(.*?)&lt;\\/(?:${emptyMatchingTags.join('|')})&gt;`, 'g',
      );
      const paramMatchingTagsRegex = new RegExp(
        `&lt;(?:${paramMatchingTags.join('|')}).*?&gt;(.*?)&lt;\\/(?:${paramMatchingTags.join('|')})&gt;`, 'g',
      );
      html = html.replace(emptyMatchingTagsRegex, '$1');
      html = html.replace(paramMatchingTagsRegex, '$1');
      html = html.replace(/<br>&lt;break.*?\/&gt;<br>/g, '<br>');
      html = html.replace(/^&lt;break.*?\/&gt;<br>/, '');
      html = html.replace(/&lt;break.*?\/&gt;/g, '');
      html = html.replace(
        /&lt;audio src=("[^\s"]*")\/&gt;/g,
        '<span class="ssmltag bg-primary text-white">Playback of $1</span>',
      );
    } else {
      const SSMLTags = html.match(ssmlRegex);
      if (SSMLTags !== null) {
        SSMLTags.forEach((tag) => {
          const preparedTag = tag
            .replace(/&lt;|\/?&gt;/g, '');
          html = html.replace(
            tag,
            `<span class="ssmltag bg-primary text-white">${preparedTag.trim()}</span>`,
          );
        });
      }
    }
  }
  return html;
}
export function eventsByNode(chatEvents) {
  // NOTE: events on the node are recorded before the node_change event to the node
  // However, responses from the visitor is registered after the node change
  let currentNode = null;
  const eventCollections = [];
  let currentChatEvents = [];
  // iterate the events from last to first
  for (const event of chatEvents) {
    if (event.type === 'node_change') {
      // insert empty probability events before nodes without prob events
      if (eventCollections.length > 0) {
        const currentCollection = eventCollections[eventCollections.length - 1];
        if (currentCollection.probabilities === null) {
          eventCollections.push({
            node: currentNode,
            probabilities: true,
            disable: true,
            key: `${currentNode.node}-${event.timestamp}-false-prob`,
          });
        }
      }
      // push node change event with events collected since last node change
      currentNode = event.data;
      eventCollections.push({
        node: currentNode,
        events: currentChatEvents,
        probabilities: null,
        key: `${currentNode.node}-${event.timestamp}`,
      });
      currentChatEvents = [];
    } else if (event.type === 'prob') {
      let probabilities = Object.values(event.data.probabilities);
      if (probabilities && currentNode.node_type === 'multipleChoice') {
        probabilities = probabilities.filter(
          // eslint-disable-next-line no-loop-func
          (p) => p.display_name !== currentNode.display_name,
        );
      }
      eventCollections.push({
        node: currentNode,
        events: [],
        probabilities,
        key: `${currentNode.node}-${event.timestamp}`,
      });
    } else if (event.type === 'msg' && event.data.author === 'customer') {
      // we include customer msgs in the collection leading up to the message
      const currentCollection = eventCollections[eventCollections.length - 1];
      currentCollection.events.push(event);
    } else if (event.type === 'msg' || event.type === 'error' || event.type === 'action'
            || event.type === 'traceback' || event.type === 'interaction'
            || event.type === 'upload' || event.type === 'fallback') {
      currentChatEvents.push(event);
    } else if (event.type === 'chat-moved') {
      if (event.data.type === 'moved-from') {
        eventCollections[0].events.unshift(event);
      } else {
        currentChatEvents.push(event);
      }
    } else if (event.type === 'chat_ended') {
      // Whatever happened after the final activities. This is typically only
      // errors happening during final node processing.
      const finalEvent = eventCollections[eventCollections.length - 1];
      if (finalEvent.node.node === 'final') {
        finalEvent.events.push(...currentChatEvents);
        continue;
      }
    }
  }
  // remove collections caused by GOTO dynamics
  // this does not seem to fully work for subflows
  let lastNodeChange = null;
  const collectionsToDelete = new Set();
  for (const collection of eventCollections) {
    let deleteDecider;
    if (collection.probabilities) {
      deleteDecider = lastNodeChange;
    } else {
      lastNodeChange = collection;
      deleteDecider = collection;
    }
    if (deleteDecider.node.require_response.startsWith('GOTO')) {
      collectionsToDelete.add(collection.key);
    }
  }
  return eventCollections.filter((x) => !collectionsToDelete.has(x.key));
}

export function formatTextWithLinebreaks(text) {
  return text
    .replaceAll('</p>', '</p><br><br>')
    .replace(/<(?!br\s*\/?)[^>]+>/g, '');
}

export function filterDateBuilder(date, time) {
  const minutes = (time * 10) % 10 !== 0 ? 30 : 0;
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), Math.floor(time), minutes);
}

export function formatDateTime(date, time) {
  return filterDateBuilder(date, time).toISOString();
}

export function getDashboardTimeRange() {
  const startDateTime = new Date(moment().subtract(7, 'days').startOf('day'));
  const endDateTime = new Date(moment().endOf('day'));
  return { startDateTime, endDateTime };
}

export function getTimeFloatFromMoment(dt) {
  const hours = parseInt(dt.format('HH'), 10);
  const minutes = parseInt(dt.format('mm'), 10);
  return hours + (Math.floor(minutes / 30) * 0.5);
}

// SNAKE-CAMEL-CONVERSION
export function strToCamel(str) {
  return str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase().replace('-', '').replace('_', ''),
  );
}

export function objToCamel(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [strToCamel(k), v]));
}
export function strToSnake(str) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function objToSnake(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [strToSnake(k), v]));
}
export function nluModelInUse(nodes, nodeName) {
  return Object.values(nodes).filter(
    (node) => {
      // check matching function
      if (node.match.nluModel.name === nodeName) {
        return true;
      }
      // check for call model activity
      for (const activity of Object.values(node.activities)) {
        if (activity.modelName === nodeName) {
          return true;
        }
      }
      return false;
    },
  );
}

export function prepareTimeStamps({
  startTime, endTime, interval, format,
}) {
  const currentTimestamp = moment(startTime).startOf(interval);
  const timestamps = [];
  while (currentTimestamp < endTime) {
    timestamps.push((currentTimestamp.format(format)));
    currentTimestamp.add(1, interval).startOf(interval);
  }
  return timestamps;
}

export function getChartLabels(currentFilters) {
  const startDatetime = moment(filterDateBuilder(
    currentFilters.startDate,
    currentFilters.startTime,
  ));
  const endDatetime = moment(filterDateBuilder(currentFilters.endDate, currentFilters.endTime));
  switch (currentFilters.selectedGroupBy) {
    case 'months': {
      return prepareTimeStamps({
        startTime: startDatetime,
        endTime: endDatetime,
        interval: 'Month',
        format: 'MMM YYYY',
      });
    }
    case 'days': {
      return prepareTimeStamps({
        startTime: startDatetime,
        endTime: endDatetime,
        interval: 'Day',
        format: 'DD/MM/YYYY',
      });
    }
    case 'hours': {
      return prepareTimeStamps({
        startTime: startDatetime,
        endTime: endDatetime,
        interval: 'Hour',
        format: 'DD MMM, HH:mm',
      });
    }
    case 'monthsAccum': return moment.monthsShort();
    case 'monthdaysAccum': {
      const monthdays = [];
      let currentMonth = startDatetime;
      const lastMonth = endDatetime;
      // Every second month has 31 days, so no reason to look at more than 2 months.
      for (let i = 0; i < 2; i++) {
        if (currentMonth.unix() >= lastMonth.unix()) {
          break;
        }
        monthdays.push(currentMonth.daysInMonth());
        currentMonth = currentMonth.add(1, 'month');
      }
      return Array.from(Array(Math.max(...monthdays)).keys()).map((e) => e + 1);
    }
    case 'weekdaysAccum': return Array.apply(0, Array(7)).map((_, i) => moment().weekday(i + 1).format('ddd'));
    case 'hoursAccum':
    {
      const labels = [];
      new Array(24).fill().forEach((acc, index) => {
        labels.push(moment({ hour: index }).format('HH:mm'));
      });
      return labels;
    }
    default: return [];
  }
}
export function phrase2Id(phrase) {
  return phrase.substring(8, 44);
}
export function phraseId2Format(id) {
  return `[phrase:${id}]`;
}
export function mapPhrases(text) {
  const str = text;
  const matches = str.match(PHRASE_REGEX);
  const substrings = str.split(PHRASE_REGEX);

  const result = substrings.length < 2 ? substrings
    : substrings.reduce((acc, val, idx) => acc.concat(val, matches[idx]), [])
      .filter((e) => e && e.length > 0);

  const mappedResults = result.map((e) => ({ value: e, isPhrase: !!e.match(PHRASE_REGEX) }));

  if (mappedResults.filter((e) => !e.isPhrase).length === 0) {
    return [{ value: '', isPhrase: false }].concat(mappedResults);
  }
  return mappedResults;
}
export function phraseUsage(phraseId, nodesAsList) {
  const data = nodesAsList.filter(nodeHasResponse);
  const usage = [];
  data.forEach((node) => {
    Object.entries(node.activities).forEach(([id, activity]) => {
      if ([RESPONSE, COMPOUND_RESPONSE].includes(activity.type)) {
        if (activity.text.includes(phraseId2Format(phraseId))) {
          usage.push({ ...activity, nodeId: node.id, activityId: id });
        }
      }
    });
  });
  return usage;
}

export function sortedNodeDetailsChildren(children, nameOfId, activeSorting) {
  const array = Object.entries(children)
    .map(([key, value]) => ({ id: key, ...value }));

  array.sort((a, b) => {
    const key = activeSorting.key;
    const order = activeSorting.order === 'asc' ? 1 : -1;
    let aValue;
    let bValue;
    if (key === 'name') {
      aValue = nameOfId(a.id);
      bValue = nameOfId(b.id);
    } else {
      const aInfo = (a[key].info || []);
      const bInfo = (b[key].info || []);
      aValue = aInfo.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      bValue = bInfo.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      if (key !== 'num_chats') {
        const aLength = aInfo.filter((e) => e > 0).length;
        if (aLength) {
          aValue /= aLength;
        }
        const bLength = bInfo.filter((e) => e > 0).length;
        if (bLength) {
          bValue /= bLength;
        }
      }
    }
    if (aValue < bValue) {
      return -1 * order;
    }
    if (aValue > bValue) {
      return 1 * order;
    }
    return 0;
  });
  return array;
}

export function generateNodeDetailsLabels(startDate, endDate, selectedGroupBy) {
  const labels = [];

  if (selectedGroupBy === null) {
    labels.push(`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`);
    return labels;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);
  let current = new Date(start);

  if (selectedGroupBy === 'weeks') {
    while (current <= end) {
      // Set the current date to the first day of the week (Monday)
      current = new Date(current.getFullYear(),
        current.getMonth(),
        current.getDate() - current.getDay() + 1);

      const weekEnd = new Date(current);
      weekEnd.setDate(current.getDate() + 6);
      labels.push(`${current.toLocaleDateString()} - ${weekEnd.toLocaleDateString()}`);

      current.setDate(current.getDate() + 7);
    }
  } else if (selectedGroupBy === 'months') {
    while (current <= end) {
      const monthEnd = new Date(current.getFullYear(), current.getMonth() + 1, 0);
      labels.push(`${current.toLocaleDateString()} - ${monthEnd.toLocaleDateString()}`);
      current.setMonth(current.getMonth() + 1);
    }
  }

  return labels;
}

export function getTopPrediction(showOld, probabilities) {
  if (probabilities.length) {
    const highestScore = probabilities
      .reduce((prev, current) => ((prev.score > current.score) ? prev : current));
    if (showOld) {
      const displayName = highestScore.display_name.length > 10
        ? `${highestScore.display_name.slice(0, 10)}...`
        : highestScore.display_name;

      return `${(highestScore.score * 100).toFixed(2)}% ${displayName}`;
    }
    return `${(highestScore.score * 100).toFixed(2)}%`;
  }
  return null;
}

export function metricSortFunction(a, b) {
  return a.displayOrder - b.displayOrder;
}
