<template>
  <b-modal
    id="delete-node-modal"
    title="Delete node"
    ok-title="Delete"
    ok-variant="danger"
    auto-focus-button="cancel"
    :ok-disabled="$v.deleteNodeName.$invalid || !canBeDeleted"
    @ok="$emit('delete')"
    @hide="deleteNodeName = ''"
  >
    <template v-if="canBeDeleted">
      Are you sure that you want to delete this node?
      <fallback-node-delete-warning :fallback-usage="fallbackUsage" />
      <fallback-node-delete-warning
        :fallback-usage="authFallbackUsage"
        warning-type="auth"
      />
      <b-form-group class="mb-0 mt-3">
        <template #label>
          To confirm, type <strong>"{{ nodeName }}"</strong> in the box below
        </template>
        <b-form-input v-model="deleteNodeName" />
      </b-form-group>
    </template>
    <p v-else>
      This node cannot be deleted.<br>
      <span v-if="usesClassifierForMatching">
        This node uses a classifier for matching. Remove the classifier first to delete the node.
      </span>
      <span v-else-if="hasNodeClassifier">
        This node has a node classifier. Remove the node classifier first to delete the node.
      </span>
      <span v-else>
        This node is used in node interpretations. Remove the node from node intepretations first to
        delete it.
      </span>
    </p>
  </b-modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { applyThisArgs } from '@/js/storeHelpers';
import FallbackNodeDeleteWarning from '@/components/FallbackNodeDeleteWarning.vue';

export default {
  components: { FallbackNodeDeleteWarning },
  mixins: [validationMixin],
  props: {
    nodeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deleteNodeName: '',
    };
  },
  computed: {
    ...mapState('botManipulation', ['activeBotId']),
    ...applyThisArgs(
      mapGetters('botManipulation/activeBot', {
        activeNode: 'nodeById',
        fallbackUsage: 'getFallbackUsageOfNode',
        authFallbackUsage: 'getAuthFallbackUsageOfNode',
      }),
      'nodeId',
    ),
    ...mapGetters('nodeInterpretations', [
      'optOutNodes',
      'flowNodes',
      'transferNodes',
    ]),
    ...mapGetters('nlu/classifier', [
      'getGlobalNLUModels',
      'isModelsLoading',
    ]),
    nodeName() {
      return this.activeNode?.name;
    },
    usesClassifierForMatching() {
      return this.activeNode?.match?.useClfToMatch;
    },
    hasNodeClassifier() {
      if (this.isModelsLoading) {
        return true;
      }
      for (const clf of Object.values(this.getGlobalNLUModels)) {
        if (
          clf.botId === this.activeBotId
          && clf.nodeId === this.nodeId
          && clf.mainClassifier === null
        ) {
          return true;
        }
      }
      return false;
    },
    isInNodeInterpretations() {
      return this.optOutNodes.includes(this.nodeId) || this.flowNodes.includes(this.nodeId)
      || this.transferNodes.includes(this.nodeId);
    },
    canBeDeleted() {
      return !this.usesClassifierForMatching && !this.hasNodeClassifier
      && !this.isInNodeInterpretations;
    },
  },
  methods: { },
  validations() {
    return {
      deleteNodeName: {
        required,
        matchNodeName(value) {
          return value === this.nodeName;
        },
      },
    };
  },
};
</script>
