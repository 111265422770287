<template>
  <b-modal
    id="showUnitTestModal"
    size="xl"
    :title="`Conversation test: ${toShowUnitTest ? toShowUnitTest.title : ''}`"
    ok-only
    scrollable
    @hide="$emit('hideModal')"
  >
    <div>
      <b-card
        body-class="px-1 py-0"
        class="mb-3"
      >
        <p class="mb-2 font-weight-bold">
          Status
        </p>
        <b-list-group>
          <template
            v-if="getRunningUnitTests
              && getRunningUnitTestStatus && getRunningUnitTestStatus.info"
          >
            <b-list-group-item
              v-if="toShowUnitTest.key in getRunningUnitTestStatus.info.info
                && !getRunningUnitTestStatus.info.info[toShowUnitTest.key].finished"
              variant="primary"
            >
              <p class="text-center">
                <font-awesome-icon
                  icon="spinner"
                  size="lg"
                  spin
                />
              </p>
              <p class="mb-0 text-center font-weight-bold">
                Test is running
              </p>
            </b-list-group-item>
            <b-list-group-item
              v-if="toShowUnitTest.key in getRunningUnitTestStatus.info.info
                && getRunningUnitTestStatus.info.info[toShowUnitTest.key].finished
                && !getRunningUnitTestStatus.info.info[toShowUnitTest.key].failed"
              variant="success"
            >
              <p class="mb-0 text-center font-weight-bold">
                Test ran successfully without errors
              </p>
            </b-list-group-item>
            <b-list-group-item
              v-if="toShowUnitTest.key in getRunningUnitTestStatus.info.info
                && getRunningUnitTestStatus.info.info[toShowUnitTest.key].finished
                && getRunningUnitTestStatus.info.info[toShowUnitTest.key].failed
                && !('content' in getRunningUnitTestStatus.info.info[toShowUnitTest.key])"
              variant="danger"
            >
              <p class="text-center font-weight-bold">
                BotStudio error while running test
              </p>
              <p class="text-center font-weight-bold">
                If the error persists, please contact your BotStudio administrator.
              </p>
              <!-- At the moment only 1 element i
                s returned by the test runner in the msgs array -->
              <b-form-textarea
                plaintext
                style="font-family: monospace; overflow:auto"
                :value="getUnitTestErrorDetails"
                rows="3"
                max-rows="200"
              />
            </b-list-group-item>
            <b-list-group-item
              v-if="toShowUnitTest.key in getRunningUnitTestStatus.info.info
                && getRunningUnitTestStatus.info.info[toShowUnitTest.key].finished
                && getRunningUnitTestStatus.info.info[toShowUnitTest.key].failed
                && getContentError !== null"
              variant="danger"
            >
              <p class="text-center font-weight-bold">
                Test failed - the chatbot sent different messages than expected
                <template v-if="getContentError !== null && getContentError.type === 'shared'">
                  According to the test specification, the chatbot should have said
                  "{{ getContentError.expected.message }}", but actually it said
                  "{{ getContentError.actual.message }}".
                </template>
              </p>
            </b-list-group-item>
            <b-list-group-item
              v-if="toShowUnitTest.key in getRunningUnitTestStatus.info.info
                && getRunningUnitTestStatus.info.info[toShowUnitTest.key].finished
                && getRunningUnitTestStatus.info.info[toShowUnitTest.key].failed
                && getPathError !== null"
              variant="danger"
            >
              <p class="text-center font-weight-bold">
                Test failed - the chatbot went on a different path than expected
                <template v-if="getPathError !== null && getPathError.type === 'shared'">
                  According to the test specification, the chatbot should have visited the node
                  "{{ getPathError.expected.name }}", but actually it visited
                  "{{ getPathError.actual.name }}".
                </template>
              </p>
            </b-list-group-item>
            <b-list-group-item
              v-if="toShowUnitTest.key in getRunningUnitTestStatus.info.info
                && getRunningUnitTestStatus.info.info[toShowUnitTest.key].finished
                && getRunningUnitTestStatus.info.info[toShowUnitTest.key].failed
                && (getContentError !== null || getPathError !== null)"
              variant="info"
            >
              <p class="text-center font-weight-bold">
                If this is intended (e.g. you edited the bot) and the new bot behavior
                is correct, you can replace the expected result of the test to match the actual one.
              </p>
              <b-button
                variant="primary"
                size="sm"
                class="mt-2 font-weight-bold"
                block
                @click="replaceTest"
              >
                Replace test
              </b-button>
            </b-list-group-item>
          </template>
          <b-list-group-item
            v-else
            variant="info"
          >
            Test is not running.
          </b-list-group-item>
        </b-list-group>
      </b-card>

      <div class="mx-1">
        <p class="mb-2 font-weight-bold">
          Testing Title
        </p>
        <b-form-group
          id="editModalTitleLabel"
        >
          <b-form-input
            id="editModalTitle"
            ref="editTitleFormInput"
            v-model="modalData.title"
          />
        </b-form-group>

        <p class="mb-2 font-weight-bold">
          Testing
        </p>
        <b-form-checkbox
          v-model="modalData.testPath"
          switch
          class="mb-2"
          :disabled="toEditTestPathMissing"
        >
          Test path
        </b-form-checkbox>

        <b-form-checkbox
          v-model="modalData.testContent"
          switch
          class="mb-2"
        >
          Test content
        </b-form-checkbox>
        <div
          v-if="toShowUnitTest.path.length > 0"
          class="mb-2 node-path"
        >
          <p class="mb-1 mt-3 font-weight-bold">
            Conversation path
          </p>
          <b-row
            no-gutters
            class="path-wrapper"
          >
            <b-col
              v-for="(row, idx) in pathRows"
              :key="idx"
              cols="auto"
              class="path-single mt-auto"
            >
              <b-row no-gutters>
                <b-col
                  cols="auto"
                >
                  <b-button-group
                    vertical
                    class="mt-auto"
                  >
                    <b-button
                      v-if="row.expected"
                      variant="info"
                      pill
                      class="mb-1"
                      size="sm"
                      @click="goToNode(row.expected.node)"
                    >
                      {{ nameOfId(row.expected.node) }}
                    </b-button>

                    <b-button
                      v-if="row.actual"
                      :style="!row.ok ? '' : 'visibility:hidden'"
                      pill
                      class="mb-1"
                      size="sm"
                      variant="warning"
                      @click="goToNode(row.actual.node)"
                    >
                      {{ nameOfId(row.actual.node) }}
                    </b-button>
                    <!-- below button is just to keep the layout if there is no expected node -->
                    <b-button
                      v-else
                      style="visibility:hidden"
                      class="mb-1"
                      size="sm"
                    >
                      empty
                    </b-button>
                  </b-button-group>
                </b-col>
                <b-col
                  v-if="idx !== pathRows.length - 1"
                  cols="auto"
                  :style="!pathRows[idx + 1].ok && pathRows[idx + 1].actual
                    ? 'padding-bottom: 0.6rem' : 'padding-bottom:2.8rem'"
                  class="px-1 mt-auto text-center"
                >
                  <p>
                    <font-awesome-icon icon="long-arrow-alt-right" />
                  </p>
                  <p
                    v-if="!pathRows[idx + 1].ok && pathRows[idx + 1].actual"
                    style="padding-top:0.8rem;"
                  >
                    <font-awesome-icon
                      :icon="!pathRows[idx + 1].ok && row.ok ? 'level-up-alt' : 'long-arrow-alt-right'"
                      :class="!pathRows[idx + 1].ok && row.ok ? 'fa-rotate-90' : ''"
                    />
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <p class="mb-0 mt-3 font-weight-bold">
          Conversation content
        </p>
        <div
          class="diff-container"
        >
          <div
            v-for="(row, index) in contentRows"
            :key="index"
          >
            <hr>
            <b-row v-if="!row.ok">
              <b-col
                v-if="row.type === 'missing'"
                class="h5"
              >
                <b-badge variant="warning">
                  Missing bot message during test:
                </b-badge>
              </b-col>
              <b-col
                v-else-if="row.type === 'new'"
                class="h5"
              >
                <b-badge variant="warning">
                  Extra bot message during test:
                </b-badge>
              </b-col>
              <b-col
                v-else-if="row.type === 'shared'"
                class="h5"
              >
                <b-badge
                  variant="warning"
                >
                  Bot message differs from expectation:
                </b-badge>
              </b-col>
            </b-row>
            <b-row
              no-gutters
              class="mb-1"
            >
              <b-col
                cols="auto"
                style="min-width:34px;"
              >
                <div v-if="!row.newMsg">
                  <b-button
                    v-b-tooltip.noninteractive.viewport.right
                    size="sm"
                    class="d-block"
                    variant="primary"
                    title="Add expected message"
                    @click="addMessage('expected', getLastAdded(index))"
                  >
                    <font-awesome-icon icon="robot" />
                  </b-button>
                  <b-button
                    v-b-tooltip.noninteractive.viewport.right
                    class="d-block mt-1"
                    size="sm"
                    title="Add user message"
                    variant="primary"
                    @click="addMessage('user', getLastAdded(index))"
                  >
                    <font-awesome-icon icon="user-plus" />
                  </b-button>
                </div>
              </b-col>
              <b-col
                cols="4"
                class="pl-1"
              >
                <editable-message
                  v-if="row.type === 'shared' || row.type === 'missing'"
                  :event="row.expected"
                  :index="index"
                  :style="getMessageStyle(row, 'expected')"
                  class="cursor-pointer"
                  :editing-message="editingMessage"
                  user="Bot - Expected"
                  @onEditMessage="onEditMessage"
                  @onUpdateMessage="onUpdateMessage"
                  @onEditMessageFinished="onEditMessageFinished"
                />
              </b-col>
              <b-col
                cols="4"
                class="pl-1"
              >
                <editable-message
                  v-if="row.type === 'shared' || row.type === 'new' "
                  :style="getMessageStyle(row, 'actual')"
                  :event="row.actual"
                  :index="null"
                  user="Bot - Actual"
                />
              </b-col>
              <b-col
                class="pl-1 border-left ml-1"
              >
                <editable-message
                  v-if="row.type === 'message'"
                  :event="row.event"
                  :index="index"
                  class="cursor-pointer"
                  style="width:100%"
                  :editing-message="editingMessage"
                  user="User"
                  @onEditMessage="onEditMessage"
                  @onUpdateMessage="onUpdateMessage"
                  @onEditMessageFinished="onEditMessageFinished"
                />
              </b-col>
            </b-row>
          </div>
        </div>
        <ul>
          <li
            v-for="(ev, index) in getUnitTestEvents"
            :key="index"
          >
            {{ ev }}
          </li>
        </ul>
      </div>
    </div>
    <template #modal-footer="{ hide }">
      <div
        v-if="unsavedChanges"
      >
        <span class="text-warning">*Unsaved changes</span>
        <b-button
          class="ml-2 px-4"
          size="sm"
          variant="primary"
          @click="proxyEditUnittest();"
        >
          Save
        </b-button>
      </div>
      <b-button
        size="sm"
        variant="primary"
        @click="hide()"
      >
        Close
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import { deepEqualsJson, deepCopyJson } from 'supwiz/util/data';
import { cloneDeep } from 'lodash';
import EditableMessage from '@/pages/Health/ConversationTests/EditableMessage.vue';

export default {
  name: 'ConversationTestModal',
  components: { EditableMessage },
  props: {
    toShowUnitTest: {
      type: Object,
      default: null,
    },
    toEditUnitTestIdx: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      modalData: {
        title: null,
        testPath: null,
        testContent: null,
      },
      contentRows: null,
      pathRows: null,
      editingMessage: null,
      modalDataCopy: null,
      contentRowsCopy: null,
    };
  },
  computed: {
    ...mapGetters('unitTest', [
      'getUnitTests',
      'getRunningUnitTests',
      'getRunningUnitTestStatus',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nameOfId',
    ]),
    toEditTestPathMissing() {
      if (this.toEditUnitTestIdx === null) {
        return null;
      }
      return !this.getUnitTests[this.toEditUnitTestIdx].path;
    },
    getUnitTestErrorDetails() {
      if (this.getRunningUnitTestStatus) {
        const infolist = this.getRunningUnitTestStatus.info.info;
        if (infolist[this.toShowUnitTest.key]) {
          return infolist[this.toShowUnitTest.key].msgs.join('\n');
        }
      }
      return '';
    },
    getUnitTestEvents() {
      if (this.getRunningUnitTestStatus) {
        const infolist = this.getRunningUnitTestStatus.info.info;
        if (infolist[this.toShowUnitTest.key]) {
          return infolist[this.toShowUnitTest.key].actual;
        }
      }
      return [];
    },
    getContentError() {
      for (const row of (this.contentRows || [])) {
        if (!row.ok) {
          return row;
        }
      }
      return null;
    },
    getPathError() {
      for (const row of (this.pathRows || [])) {
        if (!row.ok) {
          return row;
        }
      }
      return null;
    },
    toEditContent() {
      return this.getContent('expected');
    },
    unsavedChanges() {
      return JSON.stringify(this.modalData) !== JSON.stringify(this.modalDataCopy)
      || JSON.stringify(this.contentRows) !== JSON.stringify(this.contentRowsCopy);
    },
    getRunningUnitTestStatusInfo() {
      return this.getRunningUnitTestStatus?.info?.info;
    },
    getTestStatus() {
      if (this.getRunningUnitTests && this.getRunningUnitTestStatus
      && this.getRunningUnitTestStatus.info) {
        return 'fal';
      }
      return 'Not started';
    },
    getTestDescription() {
      return 'description';
    },
  },
  watch: {
    getRunningUnitTestStatusInfo() {
      this.contentRows = this.computeTestCaseRows('content');
      this.contentRowsCopy = cloneDeep(this.contentRows);
      this.pathRows = this.computeTestCasePath();
    },
  },
  mounted() {
    this.modalData.title = this.getUnitTests[this.toEditUnitTestIdx].title;
    this.modalData.testPath = this.getUnitTests[this.toEditUnitTestIdx].test_path;
    this.modalData.testContent = this.getUnitTests[this.toEditUnitTestIdx].test_content;
    this.contentRows = this.computeTestCaseRows('content');
    this.pathRows = this.computeTestCasePath();
    // create copy of data to check for changes
    this.createLocalCopy();

    this.$bvModal.show('showUnitTestModal');
  },
  methods: {
    showActualPath(row) {
      return !row.expected || (row.actual && row.actual.node !== row.expected.node);
    },
    addMessage(version, index) {
      if (version === 'user') {
        this.contentRows.splice(index + 1, 0, {
          type: 'message',
          newMsg: true,
          event: {
            type: 'message',
            user: 'user',
            message: '',
          },
          ok: true,
        });
        this.editingMessage = index + 1;
      } else if (index < this.contentRows.length && this.contentRows[index].type === 'new') {
        this.contentRows[index].type = 'shared';
        this.contentRows[index].expected = deepCopyJson(this.contentRows[index].actual);
        this.contentRows[index].ok = true;
        this.editingMessage = index;
      } else {
        this.contentRows.splice(index + 1, 0, {
          type: 'missing',
          newMsg: true,
          expected: {
            type: 'message',
            user: 'agent',
            message: '',
          },
          ok: true,
        });
        this.editingMessage = index + 1;
      }
    },
    getLastAdded(index) {
      if (this.contentRows[index + 1] !== undefined
       && this.contentRows[index + 1].newMsg !== undefined) {
        return this.getLastAdded(index + 1);
      }
      return index;
    },
    getContent(version) {
      if (this.contentRows === null) return null;
      let content = '';
      for (const row of this.contentRows) {
        if (row.type === 'message') {
          content += `> ${row.event.message}\n`;
        } else if (row[version]) {
          content += `${row[version].message}\n`;
        }
      }
      return content;
    },
    firstButtonText(type) {
      switch (type) {
        case 'shared': return 'Difference:';
        case 'missing': return 'Missing:';
        case 'new': return 'Unexpected:';
        default: return '';
      }
    },
    getMessageStyle(row, type) {
      if (!row.ok && (!row.actual || !row.expected)) {
        return 'border: 2px solid #E99002';
      }
      if (row.expected?.message === row.actual?.message) {
        return 'border: 2px solid #28A745';
      }
      if (type === 'actual'
      ) {
        return 'border: 2px solid #E99002';
      }
      return null;
    },
    onEditMessage(index) {
      if (this.editingMessage !== index) {
        this.editingMessage = index;
      } else {
        this.editingMessage = null;
      }
    },
    onUpdateMessage(index, value) {
      if (this.contentRows[index].type === 'message') {
        this.contentRows[index].event.message = value;
      } else {
        this.contentRows[index].expected.message = value;
      }
    },
    onEditMessageFinished(index) {
      if (this.contentRows[index].type === 'message') {
        if (!this.contentRows[index].event.message.trim()) {
          this.contentRows.splice(index, 1);
        }
      } else if (this.contentRows[index].type === 'missing') {
        if (!this.contentRows[index].expected.message.trim()) {
          this.contentRows.splice(index, 1);
        }
      } else if (this.contentRows[index].type === 'shared') {
        if (!this.contentRows[index].expected.message.trim()) {
          this.$delete(this.contentRows[index], 'expected');
          this.contentRows[index].type = 'new';
        }
      }
      this.editingMessage = null;
    },
    goToNode(nodeID) {
      const botId = this.$route.params.botId;
      this.$router.push({ name: 'edit-node', params: { botId, nodeId: nodeID } });
    },
    proxyEditUnittest() {
      this.$store.dispatch('unitTest/editUnitTest', {
        index: this.toEditUnitTestIdx,
        title: this.modalData.title,
        content: this.toEditContent,
        test_path: this.modalData.testPath,
        test_content: this.modalData.testContent,
      }).then(() => {
        this.createLocalCopy();
      });
    },
    createLocalCopy() {
      this.modalDataCopy = cloneDeep(this.modalData);
      this.contentRowsCopy = cloneDeep(this.contentRows);
    },
    replaceTest() {
      const infolist = this.getRunningUnitTestStatus.info.info;
      const newPath = [];
      for (const ev of infolist[this.toShowUnitTest.key].path.actual) {
        newPath.push(ev.node);
      }
      this.$store.dispatch('unitTest/editUnitTest', {
        index: this.toEditUnitTestIdx,
        content: this.getContent('actual'),
        path: newPath,
      });
    },
    computeTestCasePath() {
      const rows = [];
      const infolist = this.getRunningUnitTestStatus?.info?.info;
      if (infolist && (this.toShowUnitTest.key in infolist) && ('path' in infolist[this.toShowUnitTest.key])) {
        const actual = infolist[this.toShowUnitTest.key].path.actual;
        const expected = infolist[this.toShowUnitTest.key].path.expected;
        let ok = true;
        for (let i = 0; i < expected.length || i < actual.length; i++) {
          ok = ok && expected[i]?.name === actual[i]?.name;
          rows.push({
            expected: expected[i],
            actual: actual[i],
            ok,
          });
        }
      } else {
        for (const node of this.toShowUnitTest.path) {
          rows.push({
            type: 'missing',
            expected: {
              type: 'visit',
              node,
              name: this.nameOfId(node),
            },
            ok: true,
          });
        }
      }

      return deepCopyJson(rows);
    },
    computeTestCaseRows(part) {
      const rows = [];
      const infolist = this.getRunningUnitTestStatus?.info?.info;
      if (infolist
        && (this.toShowUnitTest.key in infolist)
        && (part in infolist[this.toShowUnitTest.key])) {
        const shouldTest = infolist[this.toShowUnitTest.key][part].test;
        const actual = infolist[this.toShowUnitTest.key][part].actual;
        const expected = infolist[this.toShowUnitTest.key][part].expected;
        const diffs = infolist[this.toShowUnitTest.key][part].diff;
        const indexes = [];
        for (let i = 0; i < expected.length; i++) {
          indexes.push(i);
        }
        for (const diff of diffs) {
          if (diff.type === 'insert') {
            indexes.splice(diff.position, 0, null);
          } else if (diff.type === 'delete') {
            indexes.splice(diff.position, 1);
          } else if (diff.type === 'swap') {
            // do nothing
          }
        }
        for (let i = 0, j = 0; i < expected.length || j < actual.length;) {
          if (i < expected.length && j < actual.length && indexes[j] === i) {
            if (expected[i].type === 'message' && expected[i].user === 'customer') {
              rows.push({
                type: 'message',
                event: expected[i],
                ok: true,
              });
              i++; j++;
            } else {
              rows.push({
                type: 'shared',
                expected: expected[i],
                actual: actual[j],
                ok: !shouldTest || deepEqualsJson(expected[i], actual[j]),
                same: deepEqualsJson(expected[i], actual[j]),
              });
              i++; j++;
            }
          } else if (j < actual.length && indexes[j] === null) {
            rows.push({
              type: 'new',
              actual: actual[j],
              ok: !shouldTest,
            });
            j++;
          } else if (i < expected.length) {
            rows.push({
              type: 'missing',
              expected: expected[i],
              ok: !shouldTest,
            });
            i++;
          } else {
            break;
          }
        }
      } else if (part === 'content') {
        const lines = this.toShowUnitTest.content.split('\n');
        for (let i = 0; i < lines.length; i++) {
          if (i + 1 === lines.length && lines[i] === '') break;
          const isUser = lines[i].startsWith('> ');
          if (isUser) {
            rows.push({
              type: 'message',
              event: {
                type: 'message',
                user: 'user',
                message: lines[i].substring(2),
              },
              ok: true,
            });
          } else {
            rows.push({
              type: 'missing',
              expected: {
                type: 'message',
                user: 'agent',
                message: lines[i],
              },
              ok: true,
            });
          }
        }
      } else {
        for (const node of this.toShowUnitTest.path) {
          rows.push({
            type: 'missing',
            expected: {
              type: 'visit',
              node,
              name: this.nameOfId(node),
            },
            ok: true,
          });
        }
      }
      return deepCopyJson(rows);
    },
  },
};
</script>
<style scoped>
.path-wrapper{
  flex-wrap: nowrap;
  overflow-x: auto;
}
</style>
