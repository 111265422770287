<template>
  <div>
    <b-row v-if="objectNotFound">
      <b-col>
        <h1>Label not found</h1>
        <p>Requested label could not be found.</p>
      </b-col>
    </b-row>
    <b-card
      v-else
      class="r-75"
      body-class="p-3"
    >
      <b-row
        no-gutters
        class="mb-2"
      >
        <b-col class="my-auto">
          <h4 class="mb-0 card-title">
            {{ title }}
          </h4>
        </b-col>
        <b-col cols="auto">
          <b-link :to="{ name: 'nlu-labels-overview' }">
            <b-button
              variant="primary"
            >
              Back
            </b-button>
          </b-link>
        </b-col>
      </b-row>
      <b-table
        class="mb-1"
        :tbody-tr-attr="{ style: 'cursor:pointer' }"
        :items="sourcesWithExamples"
        :fields="fields"
        hover
        show-empty
        @row-clicked="rowClicked"
      >
        <template #cell(buttons)="row">
          <b-button
            :disabled="row.item.id === 'examples'"
            @click.stop="deleteSrc(row.item)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
      </b-table>
      <b-btn
        variant="primary"
        @click="openNewSrcModal(activeLabel)"
      >
        Add source
      </b-btn>
      <b-btn
        v-if="!activeModel.nodeId"
        variant="danger"
        class="ml-2"
        @click="deleteLabel(activeLabel.id)"
      >
        Delete label
      </b-btn>
    </b-card>

    <new-src-modal
      ref="new-src-modal"
      @src-updated="fetchLabels(activeModel.id)"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import endpoints from '@/js/urls';
import NewSrcModal from '@/pages/NLU/Labels/NewSrcModal.vue';

export default {
  name: 'LabelSingleOverview',
  components: {
    NewSrcModal,
  },
  data() {
    return {
      newSrcLabel: {},
      showSrcObj: {},
      fields: [
        'type',
        'name',
        'rows',
        {
          key: 'buttons',
          label: '',
          thClass: 'text-right',
          tdClass: 'text-right align-middle',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('nlu/classifier', [
      'labels',
      'activeModel',
      'activeLabel',
      'sourcesWithExamples',
    ]),
    title() {
      return `Labels / ${this.activeLabel?.displayName} / Sources`;
    },
    objectNotFound() {
      return this.activeLabel === null;
    },
  },
  methods: {
    ...mapActions('nlu/classifier', ['fetchLabels']),
    openNewSrcModal() {
      this.$refs['new-src-modal'].show();
    },
    rowClicked(srcObj) {
      this.$router.push({
        name: 'nlu-label-source',
        params: { sourceId: srcObj.id },
      });
    },
    async deleteSrc(src) {
      if (src.type === 'file') {
        await axios.delete(endpoints.labelFile, {
          params: { id: src.id },
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
      } else if (src.type === 'node') {
        await axios.delete(endpoints.labelNode, {
          params: { id: src.id },
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
      } else if (src.type === 'external') {
        await axios.delete(endpoints.labelExternal, {
          params: { id: src.id },
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
      }
      this.fetchLabels(this.activeModel.id);
    },
    async deleteLabel(id) {
      await axios.delete(endpoints.labels, {
        params: { id },
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      });
      this.fetchLabels(this.activeModel.id);
      this.$router.push({ name: 'nlu-labels-overview' });
    },
  },
};
</script>

<style scoped>
</style>
