<template>
  <div>
    <b-row v-if="isDashboard" class="mb-2">
      <b-col>
        <div class="dashboard-card-title text-truncate d-color-light">
          Bot hygiene
        </div>
      </b-col>
      <b-col cols="auto">
        <b-button
          v-b-tooltip.hover
          title="Recompute checks"
          variant="link"
          @click="recomputeAllMetrics"
        >
          <font-awesome-icon
            icon="redo"
            size="lg"
          />
        </b-button>
        <b-button class="count-btn mr-2 d-bg-danger text-light">
          {{ highPriorityCount }}
        </b-button>
        <b-button class="count-btn mr-2 d-bg-warning text-light">
          {{ mediumPriorityCount }}
        </b-button>
        <b-button class="count-btn d-bg-info text-light">
          {{ lowPriorityCount }}
        </b-button>
      </b-col>
    </b-row>
    <b-list-group v-else class="my-3">
      <b-list-group-item
        v-if="!healthTaskConfigLoaded(getBotId) || metricsInProgress(getBotId)"
        variant="info"
        class="r-25 health-alert"
      >
        <b-row class="mx-0 py-2 h-100">
          <b-col class="my-auto">
            <h4 class="my-auto">
              Loading checks...
            </h4>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item
        v-else
        :variant="inProgressFailedOrNotSatisfiedCards(getBotId).length > 0 ? 'info' : 'success'"
      >
        <b-row
          class="mx-0"
          role="tablist"
          align-h="between"
        >
          <b-col
            cols="*"
            class="my-auto"
          >
            <b-button
              v-b-tooltip.hover
              title="Recompute checks"
              variant="link"
              @click="recomputeAllMetrics"
            >
              <font-awesome-icon
                icon="redo"
                size="lg"
              />
            </b-button>
          </b-col>
          <b-col class="py-2">
            <h4
              v-if="inProgressFailedOrNotSatisfiedCards(getBotId).length === 0"
              class="my-1"
            >
              All checks resolved.
            </h4>
            <h4
              v-else
              class="my-1"
            >
              While checking the bot, {{ inProgressFailedOrNotSatisfiedCards(getBotId).length }}
              hygiene
              {{ inProgressFailedOrNotSatisfiedCards(getBotId).length > 1
                ? 'checks were' : 'check was' }}
              identified.
            </h4>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <template
      v-if="healthTaskConfigLoaded(getBotId)
        && inProgressFailedOrNotSatisfiedCards(getBotId).length > 0"
    >
      <div
        class="accordion"
        role="tablist"
        :style="isDashboard ? `max-height: ${getMaxHeight}; overflow-y: auto` : ''"
      >
        <health-metric
          v-for="metricIdentifier in inProgressFailedOrNotSatisfiedCards(getBotId)"
          :key="metricIdentifier"
          :metric-identifier="metricIdentifier"
        />
      </div>
    </template>

    <template
      v-if="showCompleted && !isDashboard && satisfiedCards.length > 0"
    >
      <div
        class="mt-3 accordion"
        role="tablist"
      >
        <health-metric
          v-for="metricIdentifier in satisfiedCards"
          :key="metricIdentifier"
          :metric-identifier="metricIdentifier"
        />
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SupportedMetrics from '@/js/healthConfig';
import { metricSortFunction } from '@/js/utils';
import { ComputationTaskStatus } from '@/js/constants';
import HealthMetric from '@/pages/Health/HealthMetric.vue';
import { isExternalStatsEnabled } from '@/js/featureFlags';

export default {
  name: 'BotHygiene',
  components: {
    HealthMetric,
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapState('health', ['showCompleted']),
    ...mapGetters('health', {
      healthTaskConfigLoaded: 'getHealthTaskConfigLoaded',
      metricIsCurrentlyDisabled: 'metricIsCurrentlyDisabled',
      taskProgress: 'getTaskProgress',
      getMetricResult: 'getMetricResult',
      metricsInProgress: 'metricsInProgress',
      inProgressFailedOrNotSatisfiedCards: 'inProgressFailedOrNotSatisfiedCards',
    }),
    getMaxHeight() {
      return isExternalStatsEnabled() ? '450px' : '390px';
    },
    isDashboard() {
      return this.$route.path.includes('dashboard');
    },
    highPriorityCount() {
      return this.inProgressFailedOrNotSatisfiedCards(this.getBotId, true)
        .filter((e) => e.priority === 1).length;
    },
    mediumPriorityCount() {
      return this.inProgressFailedOrNotSatisfiedCards(this.getBotId, true)
        .filter((e) => e.priority === 2).length;
    },
    lowPriorityCount() {
      return this.inProgressFailedOrNotSatisfiedCards(this.getBotId, true)
        .filter((e) => e.priority === 3).length;
    },
    satisfiedCards() {
      if (!this.healthTaskConfigLoaded(this.getBotId)) {
        return [];
      }
      const botId = this.getBotId;
      const unsorted = Object.values(SupportedMetrics).filter((x) => {
        // Exclude disabled cards
        if (this.metricIsCurrentlyDisabled(this.getBotId, x.identifier)) {
          return false;
        }

        const progressForMetric = this.taskProgress(botId, x.identifier);
        if (progressForMetric === null) {
          // Card has not even been queued yet, hence it cannot be completed
          return false;
        }

        // If metric-progress is in any of the following states it cannot be completed
        if (progressForMetric === ComputationTaskStatus.IN_PROGRESS
        || progressForMetric === ComputationTaskStatus.QUEUED
        || progressForMetric === ComputationTaskStatus.FAILED) {
          return false;
        }

        const metricResult = this.getMetricResult(botId, x.identifier);
        if (metricResult === null) {
          return false;
        }
        if (metricResult?.metric_satisfied === undefined) {
          return false;
        }
        return metricResult.metric_satisfied;
      });
      return unsorted.sort(metricSortFunction).map((y) => y.identifier);
    },
  },
  methods: {
    ...mapActions('health', ['recomputeAllMetrics']),
  },
};
</script>
<style scoped>
.count-btn {
  font-weight: 600;
  padding: auto;
  line-height: 1;
  width: 30px;
  height: 30px;
  cursor: initial !important;
  border: 0px;
}
</style>
