/*
The keys (i.e. HAS_PLATFORM_BEEN_CONFIGURED) must match those used by backend.
Hence, when adding a new type of metric-computation in backend, then add that metric-identifier here
aswell.
*/
export default Object.freeze({
  // Basic metrics go below, with unique displayOrder values incrementing from 100
  HAS_PLATFORM_BEEN_CONFIGURED: {
    identifier: 'HAS_PLATFORM_BEEN_CONFIGURED',
    displayName: 'Select platform',
    description: 'You should select a platform to ensure that all relevant features of the bot'
      + ' are available.',
    displayOrder: 101,
    priority: 1,
    allowsForComputeCustomization: false,
  },
  HAS_LANGUAGE_BEEN_CONFIGURED: {
    identifier: 'HAS_LANGUAGE_BEEN_CONFIGURED',
    displayName: 'Select main language',
    description: 'You should select a main language for the bot to ensure that statistics, training,'
      + 'and other features work correctly.',
    displayOrder: 102,
    priority: 1,
    allowsForComputeCustomization: false,
  },
  FALLBACKNODE_CONFIGURED: {
    identifier: 'FALLBACKNODE_CONFIGURED',
    displayName: 'Configure fallback node',
    description: 'The fallback node should be configured such that it is not a dead end.',
    displayOrder: 103,
    priority: 1,
    allowsForComputeCustomization: false,
  },
  ERRORNODE_CONFIGURED: {
    identifier: 'ERRORNODE_CONFIGURED',
    displayName: 'Configure error-node',
    description: "The error node should be configured such that it is not a dead end.',",
    displayOrder: 104,
    priority: 1,
    allowsForComputeCustomization: false,
  },
  HAS_NEUTRAL_MATCHING_EXAMPLES: {
    identifier: 'HAS_NEUTRAL_MATCHING_EXAMPLES',
    displayName: 'Add neutral examples to improve matching',
    description: 'Neutral examples are used to improve matching of the bot when typing examples'
      + ' into the matching section of nodes. Neutrals should represent a rough sampling of general'
      + ' text written by users.',
    displayOrder: 105,
    priority: 1,
    allowsForComputeCustomization: false,
  },
  HAS_LOOSE_ENDS: {
    identifier: 'HAS_LOOSE_ENDS',
    displayName: 'Nodes have loose ends',
    description: 'You should not have nodes with no children and nor "end conversation",'
      + ' "leave conversation" (Zendesk) or "transfer to department" action. Such nodes will'
      + ' represent a dead end and cause chats to hang for the user.',
    displayOrder: 106,
    priority: 1,
    allowsForComputeCustomization: false,
  },
  LINK_CHECKING: {
    identifier: 'LINK_CHECKING',
    displayName: 'Bot has bad links',
    description: 'This check evaluates if bot has any links that do not work anymore.'
    + ' You should update all invalid links in your bot.',
    displayOrder: 107,
    priority: 1,
    allowsForComputeCustomization: false,
  },
  HAS_SMARTNODE_CHILDREN_WO_MATCHING: {
    identifier: 'HAS_SMARTNODE_CHILDREN_WO_MATCHING',
    displayName: 'Add matching to nodes that appear as options',
    description: 'If a node appears as an option to a smart or multiple-choice node and does not'
      + 'have matching configured it will mess up the bot\'s flow if the user writes free text'
      + ' instead of clicking on a button.',
    displayOrder: 201,
    priority: 2,
    allowsForComputeCustomization: false,
  },
  HAS_ENOUGH_EXAMPLES_PER_NODE: {
    identifier: 'HAS_ENOUGH_EXAMPLES_PER_NODE',
    displayName: 'Add more examples to matching',
    description: 'When using examples for matching it is advised to have a healthy number of'
      + ' examples to ensure good language understanding.'
      + ' The number of examples required by node can be configured, it is 5 by default.',
    displayOrder: 202,
    priority: 2,
    allowsForComputeCustomization: true,
  },
  USES_FRESH_CLASSIFIERS: {
    identifier: 'USES_FRESH_CLASSIFIERS',
    displayName: 'Ensure classifiers are fresh',
    description: 'It is advised that classifiers used in bot are no more than 30 days old - unless'
      + ' older versions perform better.',
    displayOrder: 203,
    priority: 2,
    allowsForComputeCustomization: false,
  },
  ENSURE_CLASSIFIER_COMPATIBILITY: {
    identifier: 'ENSURE_CLASSIFIER_COMPATIBILITY',
    displayName: 'Ensure classifier compatibility',
    description: 'This check evaluates whether a classifier used in the bot has incompatible '
    + 'labels which will prevent updating the classifier to a newer version',
    displayorder: 204,
    priority: 2,
    allowsForComputeCustomization: false,
  },
  NUMBER_OF_DRAFT_REPLIES: {
    identifier: 'NUMBER_OF_DRAFT_REPLIES',
    displayName: 'Evaluate draft responses',
    description: 'The bot should not have too many draft responses.',
    displayOrder: 205,
    priority: 2,
    allowsForComputeCustomization: true,
  },
  BUTTON_TEXT_LENGTH: {
    identifier: 'BUTTON_TEXT_LENGTH',
    displayName: 'Keep button texts at a reasonable length',
    description: 'If a button has a button text that is too long it might look'
      + ' bad and clutter content. It is recommended to keep the button text length below'
      + ' 40 characters.',
    displayOrder: 206,
    priority: 2,
    allowsForComputeCustomization: false,
  },
  TOO_MANY_DETACHED_NODES: {
    identifier: 'TOO_MANY_DETACHED_NODES',
    displayName: 'Check for detached nodes',
    description: 'Nodes that are detached from the general conversation flow can clutter up'
      + ' responses and more, so it is advised that you delete these if they are not needed.',
    displayOrder: 400,
    priority: 3,
    allowsForComputeCustomization: false,
  },
  HAS_ATLEAST_3_CONVERSATION_TESTS: {
    identifier: 'HAS_ATLEAST_3_CONVERSATION_TESTS',
    displayName: 'Set up at least three conversation tests',
    description: 'Conversation tests are a way to quality assure the bot while you expand it, to'
      + ' ensure that old flows, etc. still work as intended.',
    displayOrder: 401,
    priority: 3,
    allowsForComputeCustomization: false,
  },
  CHECKUP_ON_YOUNG_NODE_RESPONSES: {
    identifier: 'CHECKUP_ON_YOUNG_NODE_RESPONSES',
    displayName: 'Check if young nodes should be reviewed',
    description: 'Often when creating a new flow, some node responses are hastily written, '
      + 'or the flow is slightly changed from the original goal.',
    displayOrder: 402,
    priority: 3,
    allowsForComputeCustomization: true,
  },
});
