<template>
  <div>
    <template v-if="isModelsLoading">
      <b-spinner small /> Loading classifiers...
    </template>
    <b-button
      v-else-if="nodeClassifier"
      size="sm"
      variant="primary"
      :to="{ name: 'nlu-model-page', params: { modelId: nodeClassifier } }"
    >
      Go to classifier
    </b-button>
    <b-button
      v-else
      size="sm"
      variant="primary"
      @click="createClassifierProxy"
    >
      Create node classifier
    </b-button>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'EditNodeClassifier',
  props: {
    nodeId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('botManipulation', ['activeBotId']),
    ...mapGetters('nlu/classifier', [
      'getGlobalNLUModels',
      'isModelsLoading',
    ]),
    nodeClassifier() {
      for (const clf of Object.values(this.getGlobalNLUModels)) {
        if (
          clf.botId === this.activeBotId
          && clf.nodeId === this.nodeId
          && clf.mainClassifier === null
        ) {
          return clf.id;
        }
      }
      return null;
    },
  },
  created() {
    this.fetchGlobalNLUModels();
  },
  methods: {
    ...mapActions('nlu/classifier', [
      'fetchGlobalNLUModels',
      'createClassifier',
    ]),
    createClassifierProxy() {
      this.createClassifier({
        type: 'swml.bot',
        botId: this.activeBotId,
        nodeId: this.nodeId,
      });
    },
  },
};
</script>
