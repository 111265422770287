<template>
  <div>
    <b-card
      class="r-75"
      body-class="p-3"
      title="Labels"
    >
      <p>
        The labels of the classifier represent the different types of user queries
        that the classifier should be able to distinguish. Each label category must be
        provided with data which the classifier then uses for training.
      </p>
      <b-alert
        :show="hasLabelWithFewRows"
        class="my-2"
        variant="warning"
      >
        <font-awesome-icon
          icon="exclamation-circle"
        />
        The classifier has labels with few rows associated to them.
        It is unlikely to learn these labels well.
      </b-alert>
      <b-table
        class="mt-4"
        hover
        :items="items"
        :fields="fields"
        :sort-compare="sortCompare"
        :busy="localTranslating"
        :tbody-tr-attr="{ style: 'cursor:pointer' }"
        sort-by="label"
        @row-clicked="rowClicked"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(rows)="data">
          <div v-if="data.item.rows == null">
            <b-spinner small />
          </div>
          <template v-else>
            {{ data.value }}
          </template>
        </template>
        <template #cell(fraction)="data">
          <b-row
            no-gutters
          >
            <b-col
              cols="2"
            >
              {{ data.item.fraction }}
            </b-col>
            <b-col
              v-if="data.item.label !== 'Total'"
            >
              <b-progress
                :value="data.item.fraction"
                :max="100"
                variant="primary"
              />
            </b-col>
          </b-row>
        </template>
      </b-table>
      <div class="mt-1">
        <b-button
          v-if="!activeModel.nodeId"
          class="mr-2"
          variant="primary"
          @click="showNewLabelModal"
        >
          Add label
        </b-button>
        <b-button
          v-b-modal.toggle-autolabels-modal
          v-b-tooltip.hover
          variant="primary"
          title="Activate or disable autolabels for all label categories with one click."
        >
          Toggle autolabels
        </b-button>
        <b-btn
          v-if="activeModel.nodeId && !activeModel.mainClassifier"
          v-b-modal.import-examples-modal
          v-b-tooltip.hover
          title="Import examples from the children of the node that the classifier binds to."
          variant="primary"
          class="ml-2"
        >
          Import examples
        </b-btn>
        <b-btn
          v-if="showTranslationComponents"
          v-b-modal.translate-modal
          v-b-tooltip.hover
          title="Import and translate data from the main language of the classifier."
          variant="primary"
          class="ml-2"
        >
          Translate data
        </b-btn>
      </div>
    </b-card>

    <b-modal
      id="new-label-modal"
      ref="new-label-modal"
      title="New label"
      ok-only
      @ok="addNewLabel"
    >
      <b-form-group
        description="Write a label name and press enter."
      >
        <b-form-input
          ref="newLabelNameInput"
          v-model="newLabelName"
          type="text"
          :state="$v.newLabelName.$invalid ? false : null"
          aria-describedby="newLabelNameFeedback"
          @keyup.enter="addNewLabel"
        />
        <b-form-invalid-feedback id="newLabelNameFeedback">
          <div v-if="!$v.newLabelName.required">
            The label needs a name.
          </div>
          <div v-if="!$v.newLabelName.unique">
            This name is already in use.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-modal>

    <b-modal
      id="toggle-autolabels-modal"
      title="Toggle autolabels"
      ok-title="Include"
      cancel-title="Exclude"
      ok-variant="primary"
      cancel-variant="info"
      @ok="setIncludeAutolabelsAll(true)"
      @cancel="setIncludeAutolabelsAll(false)"
    >
      Should autolabels be included or not? Your choice here will apply to all node
      sources in all labels.
    </b-modal>

    <b-modal
      id="import-examples-modal"
      title="Import examples"
      ok-title="Import"
      @ok="importExamples"
    >
      <p class="font-weight-bold">
        Do you wish to import examples from the nodes?
      </p>
      <p>
        Note that examples added to the nodes during initialization of a bot
        from a dataset should not be imported. These examples will already be
        included in the external data source registered on the label.
      </p>
    </b-modal>

    <b-modal
      id="translate-modal"
      title="Translate data"
      ok-title="Translate"
      @ok="translateData"
    >
      <p class="font-weight-bold">
        Do you wish to translate data from the main language variant of the classifier?
      </p>
      <p>
        This will create a dataset with translated data that the labels will reference as
        sources. If you translate multiple times, then you should delete the datasets with
        previous translations to avoid using the same data multiple times (or remove the
        references under each label).
      </p>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import endpoints from '@/js/urls';

export default {
  name: 'SingleLabelOverview',
  mixins: [validationMixin],
  data() {
    return {
      newLabelName: '',
      savingExamples: false,
      fields: [
        { key: 'label', sortable: true },
        { key: 'rows', sortable: true },
        { key: 'fraction', sortable: true },
      ],
      localTranslating: false,
    };
  },
  computed: {
    ...mapGetters('nlu/classifier', [
      'labels',
      'activeModel',
    ]),
    ...mapGetters('userSettings', ['showTranslationFeatures']),
    showTranslationComponents() {
      if (this.isMainClassifier) {
        return false;
      }
      return this.showTranslationFeatures;
    },
    items() {
      if (this.isSWML) {
        if (this.version === null) {
          return [];
        }
        const version = this.activeModel.versions[this.version];
        return version.labels.map((x) => ({ label: x }));
      }
      const tableItems = [];
      let rowsAll = 0;
      for (const label of Object.values(this.labels)) {
        tableItems.push({
          id: label.id,
          label: label.displayName,
          rows: label.rows,
        });
        rowsAll += label.rows;
      }
      if (tableItems.filter((row) => row.rows === null).length) {
        rowsAll = null;
      }
      tableItems.push({
        label: 'Total',
        rows: rowsAll,
        _rowVariant: 'primary',
        isFooter: true,
      });
      for (const item of tableItems) {
        if (rowsAll !== null && rowsAll > 0) {
          item.fraction = `${(item.rows / rowsAll * 100).toFixed(1)}%`;
        } else {
          item.fraction = 'N/A';
        }
      }
      return tableItems;
    },
    hasLabelWithFewRows() {
      for (const label of Object.values(this.labels)) {
        if (label.rows < 10) {
          return true;
        }
      }
      return false;
    },
    isMainClassifier() {
      return this.activeModel.mainClassifier === null;
    },
  },
  methods: {
    ...mapMutations('nlu/classifier', ['updateExamplesWithHelper']),
    ...mapActions('nlu/classifier', [
      'fetchLabels', 'saveExamples', 'fetchClassifier',
    ]),
    ...mapMutations('task', ['addTask']),
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('templateStore', ['templateSendNotification']),
    async addNewLabel() {
      if (this.$v.newLabelName.$invalid) {
        return;
      }
      this.$refs['new-label-modal'].hide();
      const data = {
        name: this.newLabelName,
        clfId: this.activeModel.id,
      };
      await axios.post(endpoints.labels, data, {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      });
      this.fetchLabels(this.activeModel.id);
    },
    showNewLabelModal() {
      this.newLabelName = '';
      this.$refs['new-label-modal'].show();
    },
    async setIncludeAutolabelsAll(value) {
      await axios.put(
        endpoints.labelNode,
        { includeAutolabels: value, clfId: this.activeModel.id },
        { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
      );
      this.fetchLabels(this.activeModel.id);
    },
    sortCompare(aRow, bRow, key, sortDesc) {
      const reversed = sortDesc ? -1 : 1;
      if (aRow.isFooter) {
        return 1 * reversed;
      }
      if (bRow.isFooter) {
        return -1 * reversed;
      }
      // returning null will yield default sorting
      return null;
    },
    rowClicked(item) {
      if (item.label !== 'Total') {
        this.$router.push({
          name: 'nlu-label-single-overview',
          params: { labelId: item.id },
        });
      }
    },
    async importExamples() {
      const resp = await axios.get(endpoints.botsBase + this.activeModel.botId, {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      });
      const nodes = resp.data.nodes;
      for (const label of Object.values(this.labels)) {
        if (label.name in nodes) {
          const node = nodes[label.name];
          const exampleSet = new Set(label.examples);
          for (const nodeExample of node.match.examples) {
            if (!exampleSet.has(nodeExample)) {
              this.updateExamplesWithHelper(
                { id: label.id, payload: { $push: [nodeExample] } },
              );
            }
          }
        }
        this.saveExamples(label.id);
      }
    },
    async translateData() {
      this.localTranslating = true;
      const data = { clfId: this.activeModel.id };
      const resp = await axios.post(endpoints.translateClassifierData, data, {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      });
      if (resp.data.celery_id) {
        this.showWarning({
          title: 'Translation started',
          text: 'It can take a few minutes before translated data becomes available',
          variant: 'primary',
          toast: true,
        });
        this.fetchLabels(this.activeModel.id);
        this.addTask(
          {
            celeryId: resp.data.celery_id,
            callbackDone: () => {
              this.localTranslating = false;

              this.showWarning({
                title: 'Task finished',
                text: `Translation of data for ${this.activeModel.name} is done. You may need
                  to reload to see the effect on the labels page`,
                variant: 'primary',
                toast: true,
              });
              this.fetchClassifier(this.$route.params.modelId);
            },
            callbackFailed: () => {
              this.localTranslating = false;
              this.showWarning({
                title: 'Translation error',
                text: `Failed to translate classifier data for ${this.activeModel.name}.`,
                variant: 'danger',
              });
            },
          },
        );
      } else if (resp.data.integrityError) {
        this.localTranslating = false;
        this.showWarning({
          title: 'Translation error',
          text: `A dataset with translations already exists for this classifier variant. If you really mean
            to create another translation dataset, then you must first delete or rename the other one in the
            data exploration section.`,
          variant: 'danger',
        });
      } else {
        this.localTranslating = false;
        this.showWarning({
          title: 'Translation error',
          text: 'Unknown cause.',
          variant: 'danger',
        });
      }
    },
  },
  validations: {
    newLabelName: {
      required,
      unique(newVal) {
        for (const label of Object.values(this.labels)) {
          if (label.name === newVal) {
            return false;
          }
        }
        return true;
      },
    },
  },
};
</script>

<style scoped>
</style>
