<template>
  <div id="graph-wrapper">
    <div
      id="graph-canvas"
      @mousedown="mouseDown"
      @mousemove="mouseMove"
      @mouseup="mouseUp"
    >
      <vue-tree
        ref="scaleTree"
        style="width: 100%; height: 100%;"
        :collapse-enabled="false"
        :dataset="dataset"
        :config="treeConfig"
      >
        <template #node="{ node, collapsed }">
          <node-box
            v-if="node.type === 'node'"
            :id="collapsed + node"
            :config="node"
            :compact-view="compactView"
            :hovered-node="hoveredNode"
            :active-node-id="activeNodeConfig.id"
            @toggleExpand="toggleExpand"
            @nodeClicked="nodeClicked"
            @hoverNode="v=>setHoveredNode(v)"
            @linkNode="v=>linkNodeLocal(v)"
            @nodeChildrenChange="v=>nodeChildrenChange(v)"
            @zoomOnNode="v=>zoomOnNodeProxy(v.uid)"
            @zoomOut="v=>zoomOutOneStep(v)"
          />
          <collapsed-nodes-button
            v-else
            :compact-view="compactView"
            :config="node"
            @toggleShowAll="v=>toggleShowAll(v, node.parentUid)"
            @changeVisibleNodes="v=>changeVisibleNodes(v, node.parentUid)"
          />
        </template>
      </vue-tree>
    </div>
    <div class="nav-buttons" style="top: 20px">
      <b-button
        v-b-tooltip.hover.right
        title="Switch to tree view"
        class="nav-button"
        variant="info"
        :to="{ name: 'flow' }"
      >
        <font-awesome-icon icon="diagram-project" />
      </b-button>
      <b-button
        v-b-tooltip.hover.right
        v-b-modal="'searchModal'"
        variant="primary"
        class="nav-button"
        title="Search the text in response field"
      >
        <font-awesome-icon icon="search" />
      </b-button>
    </div>
    <div class="nav-buttons" style="top: 120px;">
      <b-button
        v-b-tooltip.hover.right
        class="nav-button"
        :title="compactView ? 'Standard view' : 'Compact view'"
        @click="compactView = !compactView"
      >
        <font-awesome-icon :icon="compactView ? 'maximize' : 'minimize'" />
      </b-button>
      <b-dropdown
        v-b-tooltip.hover.right
        toggle-class="nav-button"
        no-caret
        no-flip
        title="Bookmarked nodes"
        dropright
        menu-class="bg-white bookmarked-nodes w-100"
      >
        <template #button-content>
          <font-awesome-icon icon="list" />
        </template>
        <b-dropdown-form form-class="px-2">
          <b-form-input
            v-model="bookmarkedFilterKeyword"
            size="sm"
            placeholder="Type to search"
            class="bg-white"
          />
        </b-dropdown-form>
        <b-dropdown-divider />
        <div class="bookmarks-content">
          <node-group
            v-for="(item, index) in filteredBookmarks"
            :key="index"
            :keyword="bookmarkedFilterKeyword"
            :item="item"
            @bookmarkClicked="bookmarkClicked"
          />
        </div>
        <b-dropdown-form form-class="px-2">
          <b-button size="sm" variant="primary" @click="newFolder">
            New folder
          </b-button>
        </b-dropdown-form>
      </b-dropdown>
      <b-button
        v-b-tooltip.hover.right
        class="nav-button"
        title="Zoom in graph"
        @click="$refs.scaleTree.zoomIn()"
      >
        <font-awesome-icon icon="magnifying-glass-plus" />
      </b-button>
      <b-button
        v-b-tooltip.hover.right
        class="nav-button"
        title="Zoom out graph"
        @click="$refs.scaleTree.zoomOut()"
      >
        <font-awesome-icon icon="magnifying-glass-minus" />
      </b-button>
      <b-dropdown
        v-b-tooltip.hover.right
        toggle-class="nav-button"
        no-caret
        no-flip
        title="Global nodes"
        dropright
        menu-class="bg-white bookmarked-nodes w-100"
      >
        <template #button-content>
          <font-awesome-icon icon="earth-europe" />
        </template>
        <b-dropdown-form form-class="px-2">
          <b-form-input
            v-model="globalFilterKeyword"
            size="sm"
            placeholder="Type to search"
            class="bg-white"
          />
        </b-dropdown-form>
        <b-dropdown-divider />
        <b-dropdown-text>
          Special nodes
          <tooltipped-text
            value="The following global nodes have special properties and are always part of the bot"
          />
        </b-dropdown-text>
        <b-dropdown-item @click="editFallbackNode">
          Fallback node
        </b-dropdown-item>
        <b-dropdown-item @click="editErrorNode">
          Error node
        </b-dropdown-item>
        <b-dropdown-item :disabled="disableInactivityBtn" @click="editInactiveNode">
          Inactivity node
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          v-for="node in globalNodes"
          :key="node.id"
          @click="globalNodeClicked(node.id)"
        >
          {{ nameOfId(node.id) }}
        </b-dropdown-item>
      </b-dropdown>

      <b-button
        v-if="isRootGlobalNode"
        v-b-tooltip.hover.right
        title="Return to main bot"
        class="nav-button"
        @click="leaveGlobalNodes"
      >
        <font-awesome-icon icon="right-to-bracket" />
      </b-button>
      <b-button
        v-b-tooltip.hover.right
        title="Reset position"
        class="nav-button"
        @click="resetPosition"
      >
        <font-awesome-icon icon="diagram-next" />
      </b-button>
      <b-button
        v-if="$store.state.botManipulation.activeBot.root !== dataset.id"
        v-b-tooltip.hover.right
        title="Reset zoom"
        class="nav-button"
        @click="resetZoom"
      >
        <font-awesome-icon icon="house" />
      </b-button>
    </div>
    <graph-sidebar
      :active-node-id="activeNodeConfig.id"
      :active-parent-id="activeNodeConfig.parentId"
      @toggleSidebar="v=>toggleSidebarLocal(v)"
    />
    <node-search />
    <add-node-modal
      v-if="linkNodeConfig.id"
      :modal-id="`add-${linkNodeConfig.id}`"
      :parent-node-id="linkNodeConfig.id"
      @nodeAdded="reactToNodeAdded"
    />
    <link-node-modal
      v-if="linkNodeConfig.id"
      :modal-id="`link-${linkNodeConfig.id}`"
      :node-id="linkNodeConfig.id"
      @nodeAdded="reactToNodeAdded"
    />
    <b-modal
      id="link-wrapper-modal"
      centered
      size="sm"
      hide-footer
      hide-header
    >
      <b-button
        variant="primary"
        block
        @click="launchModal('add')"
      >
        New child node
      </b-button>
      <b-button
        variant="primary"
        block
        @click="launchModal('link')"
      >
        New child reference
      </b-button>
    </b-modal>
    <b-modal
      id="parentSelector"
      title="Multiple parent nodes detected"
      @ok="zoomOutToParent(activeNodeConfig, selectedParent)"
    >
      <b-form-group label="Select which node you want to zoom out on">
        <b-form-select v-model="selectedParent" :options="parentOptions" />
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import _, { cloneDeep } from 'lodash';
import VueTree from '@ssthouse/vue-tree-chart';
import NodeBox from '@/pages/GraphView/NodeBox.vue';
import CollapsedNodesButton from '@/pages/GraphView/CollapsedNodesButton.vue';
import { guidGenerator } from '@/js/utils';
import GraphSidebar from '@/pages/GraphView/GraphSidebar.vue';
import NodeSearch from '@/pages/TreeView/NodeSearch.vue';
import AddNodeModal from '@/pages/TreeView/AddNodeModal.vue';
import LinkNodeModal from '@/pages/TreeView/LinkNodeModal.vue';
import NodeGroup from '@/pages/GraphView/NodeGroup.vue';
import {
  nodeExpand, nodeCollapse, findNode, findNodes, reinitializeChildren,
  showAllNodes, updateVisibleNodes, initializePath,
} from '@/js/graphComputations';
import { nodeTypes } from '@/js/constants';
import TooltippedText from '@/components/TooltippedText.vue';

export default {
  name: 'GraphViewPage',
  components: {
    NodeBox,
    CollapsedNodesButton,
    GraphSidebar,
    VueTree,
    NodeSearch,
    AddNodeModal,
    LinkNodeModal,
    NodeGroup,
    TooltippedText,
  },
  data() {
    return {
      dataset: {},
      zoomedDataset: {},
      treeConfig: { nodeWidth: 220, nodeHeight: 105, levelHeight: 220 },
      compactView: false,
      activeNodeConfig: {},
      scale: 1,
      translate: { x: 800, y: -95 },
      hoveredNode: null,
      linkNodeConfig: {},
      highlightedNode: null,
      bookmarkedFilterKeyword: '',
      globalFilterKeyword: '',
      parentOptions: [],
      selectedParent: null,
      dragging: false,
      clicked: false,
    };
  },
  computed: {
    ...mapState('treeView', [
      'tree', 'modalId', 'activeId',
    ]),
    ...mapGetters('treeView', [
      'zoomRoot',
      'children',
    ]),
    ...mapState('graphView', [
      'graph',
      'showSidebar',
      'paths',
      'topNodeId',
      'highlightEditNodeId',
      'bookmarks',
      'zoom']),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
      'nodesAsList',
      'nameOfId',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'getUseInactiveNode',
    ]),
    disableInactivityBtn() {
      return !(this.getUseInactiveNode);
    },
    filteredBookmarks() {
      return [{ isFolder: true, name: 'Default', id: null }].concat(this.bookmarks).filter((e) => e.isFolder
       && e.name.includes(this.bookmarkedFilterKeyword));
    },
    globalNodes() {
      return Object.values(this.$store.state.botManipulation.activeBot.nodes).filter(
        (node) => node.options.global && ![nodeTypes.QA, nodeTypes.SMALLTALK]
          .includes(node.options.nodeType)
        && node.name.toLowerCase().includes(this.globalFilterKeyword.toLowerCase()),
      );
    },
    isRootGlobalNode() {
      if (this.graph?.id) {
        const current = this.nodeById(this.graph.id);
        return current.options.global && current.options.nodeType !== nodeTypes.QA;
      }
      return false;
    },
  },
  created() {
    this.fetchBookmarks();
    if (this.highlightEditNodeId) {
      // initialize from edit node page (root)
      this.setTopNodeId(this.$store.state.botManipulation.activeBot.root);
      this.initializeFromPaths();
    } else if (this.topNodeId) {
      // initialize from edit node page (zoomed)
      this.initialize();
    } else if (this.graph) {
      // initialize from saved state
      this.dataset = cloneDeep(this.graph);
      // zoom if necessary
      if (this.zoom.length) {
        const current = findNode(this.dataset, this.zoom[this.zoom.length - 1]);
        this.dataset = current;
      }
      this.setTopNodeId(this.dataset.id);
    } else {
      // fresh initialization
      this.setTopNodeId(this.$store.state.botManipulation.activeBot.root);
      this.initialize();
    }
  },
  beforeDestroy() {
    if (!this.zoom.length) {
      this.saveGraph(this.dataset);
    }
    this.setShowSidebar(false);
    this.setHighlightEditNodeId(null);
    this.setTopNodeId(null);
    this.setBookmarks([]);
  },
  mounted() {
    this.resetPosition();
    document.getElementById('graph-canvas')
      .addEventListener('wheel', (e) => {
        // eslint-disable-next-line no-underscore-dangle
        if (!e.composedPath().map((p) => p._prevClass).join(' ').includes('graph-nodes-dropdown') || e.ctrlKey) {
          e.preventDefault();
          if (e.ctrlKey) {
            this.onPinch(e);
          } else {
            if (Math.abs(e.deltaY) !== 0) {
              if (e.shiftKey) {
                this.translate.x = Math.floor(this.translate.x + (e.deltaY < 0 ? 10 : -10));
              } else {
                this.translate.y = Math.floor(this.translate.y + (e.deltaY < 0 ? 10 : -10));
              }
            }
            if (Math.abs(e.deltaX) !== 0) {
              this.translate.x = Math.floor(this.translate.x + (e.deltaX < 0 ? 10 : -10));
            }
            const current = this.getTranslate(document.getElementsByClassName('vue-tree')[0]);
            document.getElementsByClassName('vue-tree')[0].style.transform = `scale(${current.scale}) translate(${this.translate.x}px, ${this.translate.y}px)`;
            document.getElementsByClassName('dom-container')[0].style.transform = `scale(${current.scale}) translate(${this.translate.x}px, ${this.translate.y}px)`;
          }
        }
      });
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('graphView', ['fetchBookmarks']),
    ...mapMutations('graphView', ['saveGraph', 'setShowSidebar', 'setTopNodeId',
      'setHighlightEditNodeId', 'setBookmarks', 'setActiveBookmark', 'setZoomPath', 'setZoom',
      'zoomOnNode']),
    initialize() {
      const children = this.nodeById(this.topNodeId).children;
      this.dataset = {
        uid: guidGenerator(),
        id: this.topNodeId,
        name: this.nameOfId(this.topNodeId),
        parentId: null,
        children: [],
        type: 'node',
        expanded: children.length > 0,
      };
      nodeExpand(this.dataset, this.nodeById, this.nameOfId);
      this.saveGraph(cloneDeep(this.dataset));
    },
    leaveGlobalNodes() {
      this.setZoom([]);
      this.setTopNodeId(this.$store.state.botManipulation.activeBot.root);
      this.initialize();
    },
    toggleExpand(uid) {
      const current = findNode(this.dataset, uid);
      if (current.expanded) {
        nodeCollapse(current);
      } else {
        nodeExpand(current, this.nodeById, this.nameOfId);
      }
    },
    nodeChildrenChange(id) {
      const current = findNodes(this.dataset, id).filter((e) => e.expanded);
      current.forEach((node) => reinitializeChildren(node, this.nodeById, this.nameOfId));
    },
    nodeClicked(config) {
      this.activeNodeConfig = config;
      this.setShowSidebar(true);
    },
    globalNodeClicked(nodeId) {
      this.setZoom([]);
      this.setTopNodeId(nodeId);
      this.initialize();
    },
    toggleShowAll(value, parentUid) {
      const current = findNode(this.dataset, parentUid);
      if (value) {
        showAllNodes(current, this.nodeById, this.nameOfId);
      } else {
        current.children = [];
        current.expanded = false;
      }
    },
    changeVisibleNodes(values, parentUid) {
      const current = findNode(this.dataset, parentUid);
      updateVisibleNodes(current, values, this.nodeById, this.nameOfId);
    },
    toggleSidebarLocal(v) {
      this.setShowSidebar(v);
      if (!v) {
        this.activeNodeConfig = {};
      }
    },
    linkNodeLocal(config) {
      this.linkNodeConfig = config;
      this.$nextTick(() => {
        this.$bvModal.show('link-wrapper-modal');
      });
    },
    launchModal(prefix) {
      this.$bvModal.show(`${prefix}-${this.linkNodeConfig.id}`);
      this.$bvModal.hide('link-wrapper-modal');
    },
    reactToNodeAdded() {
      this.linkNodeConfig = {};
    },
    setHoveredNode(id) {
      this.hoveredNode = id;
    },
    zoomOnNodeProxy(uid) {
      const current = findNode(this.dataset, uid);
      if (!current.expanded) {
        nodeExpand(current, this.nodeById, this.nameOfId);
      }
      if (!this.zoom.length) {
        this.saveGraph(cloneDeep(this.dataset));
      } else {
        const copy = cloneDeep(this.graph);
        const savedNode = findNode(copy, uid);
        savedNode.children = cloneDeep(current.children);
        savedNode.expanded = true;
        this.saveGraph(copy);
      }
      this.zoomOnNode(current.uid);
      this.dataset = current;

      this.setTopNodeId(this.dataset.id);
      // reset position when animation is done
      setTimeout(() => {
        this.resetPosition();
      }, 300);
    },
    zoomOutOneStep(config) {
      const currentZoom = cloneDeep(this.zoom);
      if (currentZoom.length) {
        currentZoom.pop();
        this.setZoom(currentZoom);
        if (currentZoom.length) {
          const lastUid = currentZoom[currentZoom.length - 1];
          this.dataset = cloneDeep(findNode(this.graph, lastUid));
        } else {
          this.dataset = cloneDeep(this.graph);
        }
        this.setTopNodeId(this.dataset.id);
      } else {
        const preds = this.nodeById(config.id).preds;
        const current = findNode(this.dataset, config.uid);
        if (preds.length > 1) {
          this.parentOptions = preds.map((e) => ({ value: e, text: this.nameOfId(e) }));
          this.activeNodeConfig = current;
          this.$bvModal.show('parentSelector');
        } else {
          this.zoomOutToParent(current, preds[0]);
        }
      }
    },
    zoomOutToParent(current, parentId) {
      this.dataset = {
        id: parentId,
        name: this.nameOfId(parentId),
        children: [current],
        type: 'node',
        expanded: true,
      };
      this.setTopNodeId(this.dataset.id);
      reinitializeChildren(this.dataset, this.nodeById, this.nameOfId);
      this.activeNodeConfig = {};
      this.parentOptions = [];
    },
    resetPosition() {
      const wrapper = document.getElementById('graph-wrapper');
      this.translate = { x: Math.floor(wrapper.offsetWidth / 2), y: -95 };
      this.$refs.scaleTree.restoreScale();
      const current = this.getTranslate(document.getElementsByClassName('vue-tree')[0]);
      document.getElementsByClassName('vue-tree')[0].style.transform = `scale(${current.scale}) translate(${this.translate.x}px, ${this.translate.y}px)`;
      document.getElementsByClassName('dom-container')[0].style.transform = `scale(${current.scale}) translate(${this.translate.x}px, ${this.translate.y}px)`;
    },
    resetZoom() {
      this.setHighlightEditNodeId(null);
      if (this.isRootGlobalNode) {
        this.setTopNodeId(this.graph.id);
      } else {
        this.setTopNodeId(this.$store.state.botManipulation.activeBot.root);
      }
      this.dataset = {};
      this.zoomedDataset = {};
      this.initialize();
    },
    initializeFromPaths() {
      this.dataset = {
        id: this.topNodeId,
        name: this.nameOfId(this.topNodeId),
        children: [],
        type: 'node',
        path: [this.topNodeId].join(','),
        expanded: true,
      };
      this.paths.forEach((path) => {
        initializePath(this.dataset, path, 0, this.nodeById, this.nameOfId);
      });
    },
    mouseDown() {
      this.clicked = true;
      this.dragging = false;
    },
    mouseMove() {
      if (this.clicked) {
        this.dragging = true;
      }
    },
    mouseUp(e) {
      this.clicked = false;
      if (!this.dragging && (e.srcElement.className === 'tree-container'
      || e.srcElement.className?.baseVal === 'svg vue-tree')) {
        this.toggleSidebarLocal(false);
        this.$root.$emit('collapsedNodesButtonHide', true);
      }
      const value = this.getTranslate(document.getElementsByClassName('vue-tree')[0]);
      this.translate.x = Math.floor(value.x / value.scale);
      this.translate.y = Math.floor(value.y / value.scale);
    },
    getTranslate(element) {
      const style = window.getComputedStyle(element);
      const matrix = new DOMMatrixReadOnly(style.transform);
      return {
        scale: matrix.a,
        x: matrix.m41,
        y: matrix.m42,
      };
    },
    bookmarkClicked(id) {
      const children = this.nodeById(id).children;
      this.dataset = {
        uid: guidGenerator(),
        id,
        name: this.nameOfId(id),
        children: [],
        type: 'node',
        expanded: children.length > 0,
      };
      nodeExpand(this.dataset, this.nodeById, this.nameOfId);
    },
    newFolder() {
      this.setActiveBookmark(null);
      this.$bvModal.show('bookmark-modal');
    },
    onPinch: _.throttle(function zoom(e) {
      if (e.deltaY < -1) {
        this.$refs.scaleTree.zoomIn();
      } else if (e.deltaY > 1) {
        this.$refs.scaleTree.zoomOut();
      }
    }, 50),
    editFallbackNode() {
      const botId = this.$route.params.botId;
      this.$router.push({ name: 'edit-node', params: { botId, nodeId: 'fallback' } });
    },
    editErrorNode() {
      const botId = this.$route.params.botId;
      this.$router.push({ name: 'edit-node', params: { botId, nodeId: 'error' } });
    },
    editInactiveNode() {
      const botId = this.$route.params.botId;
      this.$router.push({ name: 'edit-node', params: { botId, nodeId: 'inactive' } });
    },
  },
};
</script>
<style scoped>
#graph-wrapper{
  position:absolute;
  height: calc( 100% + 2rem);
  width: calc( 100% + 2rem);
  overflow:hidden;
  background-color: white;
  margin-left: -1rem;
  margin-top: -1rem;
}
#graph-canvas{
  position: relative;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  height: 100%;
  min-height: 300px;
  background-size: 26px;
  background-color: #f5f5f5;
  background-image: url('@/assets/background.png');
}
.nav-buttons{
  position: absolute;
  width: 35px;
  left: 20px;
  z-index: 101 !important;
}
::v-deep .nav-button{
  width: 36px;
  height: 36px;
  margin-bottom: 5px;
}
::v-deep .dropdown-item{
  color: #111F2D !important;
}
::v-deep .bookmarked-nodes{
  min-width: 300px;
  max-height: 400px;
  overflow: hidden;
}
::v-deep .bookmarks-content{
  max-height: 300px;
  overflow-y: auto;
}

</style>
<style>
.tree-node-item-enter,
.tree-node-item-leave-to {
  transition: transform 0.3s !important;
}
.tree-node-item-enter-active,
.tree-node-item-leave-active {
  transition: all 0.3s !important;
}
.node-slot {
  transition: all 0.3s !important;
}
</style>
