<template>
  <b-list-group-item class="p-0 border">
    <AddNodeModal
      :parent-node-id="nodeId"
      :modal-id="insertChildModalId"
    />

    <!-- Show and add Parents -->
    <div
      class="p-2 bg-white m-1"
    >
      <h4>
        Parents
        <tooltipped-text
          class="ml-1"
          value="Parents are nodes which consider the current node
          as a follow-up part of their flow"
        />
      </h4>
      <div v-if="!activeNode.options.global">
        <chip-list
          :confirm-remove="{ number: 50, role: 'parent' }"
          :completions="nodeNames"
          :value="parentNames"
          :collapse="9"
          :disabled-entries="disabledParentsNames"
          placeholder="Add parent"
          @input="setParents"
          @click="goToNode"
        />
      </div>
      <b-alert
        :show="fallbackUsage.length !== 0"
        variant="primary"
        class="mb-0"
      >
        This node is used as a custom fallback node in {{ fallbackUsage.length }} smart nodes.
      </b-alert>

      <!-- Global nodes' parents -->
      <div
        v-if="activeNode.options.global"
      >
        <p>
          Global nodes have all other nodes as parents.
        </p>
      </div>
    </div>

    <!-- Add children: smart-node, simple node, global nodes -->
    <div
      v-if="nodeTypeAllowsChildrenNodes"
      class="p-2 bg-white m-1"
    >
      <h4>
        Children
        <tooltipped-text
          class="ml-1"
          value="Children are nodes which can follow up the current node in the bot flow
         depending on which has the highest matching score."
        />
      </h4>
      <chip-list
        :confirm-remove="{ number: 50, role: 'child' }"
        :completions="nodeNames"
        :value="childNames"
        :disabled-entries="disabledChildNames"
        :collapse="9"
        placeholder="Add child"
        add-button-text="Create new child"
        :display-add-button="true"
        @addchipclicked="presentCreateChildNodeModal"
        @input="setChildren"
        @click="goToNode"
      />
      <p v-if="isSmalltalk">
        Small talk nodes do not have children.
      </p>
    </div>

    <!-- Add children for subflow's outgoing nodes -->
    <div
      v-if="isSubflow"
    >
      <div
        v-for="node in outGoingNodes"
        :key="node.id"
        class="mt-2 p-2"
      >
        <h4>Children: {{ node.name }}</h4>
        <chip-list
          :confirm-remove="{ number: 50, role: 'child' }"
          :completions="nodeNames"
          :value="getOutgoinChildren(node.id)"
          placeholder="Add child"
          @input="names => setOutgoingChildren(node.id, names)"
          @click="goToNode"
        />
      </div>
      <h6
        v-if="outGoingNodes.length === 0"
        class="mt-1 px-3"
      >
        You should define outgoing nodes inside the subflow.
      </h6>
    </div>

    <template v-if="!isStrictMpc && !isSubflow">
      <b-form-group
        label="Node response behaviour"
        class="px-2 my-2"
      >
        <b-form-radio
          v-model="responseMode"
          value="REQUIRE_RESPONSE"
          :disabled="!shouldAllowSettingRequiresResponse"
          style="z-index: 0"
        >
          Require response
          <tooltipped-text
            value="If require response is chosen, the bot will wait for user input before moving
             to a child after executing this node's activities."
          />
        </b-form-radio>
        <b-form-radio
          v-model="responseMode"
          value="PASSTHROUGH"
          :disabled="!shouldAllowSettingRequiresResponse"
          style="z-index: 0"
        >
          Pass through
          <tooltipped-text
            value="If pass through is chosen, the bot will immediately continue to the highest
              matching child following the execution of this node's activities."
          />
        </b-form-radio>
      </b-form-group>
    </template>

    <b-form-group
      v-if="!isMainBot && !isSubflow"
      class="my-3 px-2"
      label-for="outgoingCheckbox"
      label="Relation to mainflow"
    >
      <b-form-checkbox
        id="outgoingCheckbox"
        v-model="outgoingNode"
      >
        Outgoing node
        <tooltipped-text
          value="Check if this is a node that returns from subflow to main flow"
        />
      </b-form-checkbox>
    </b-form-group>
  </b-list-group-item>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import ChipList from 'supwiz/components/ChipList.vue';
import AddNodeModal from '@/pages/TreeView/AddNodeModal.vue';
import { nodeTypes } from '@/js/constants';
import TooltippedText from '@/components/TooltippedText.vue';
import { applyThisArgs, addThisArgs } from '@/js/storeHelpers';
import botManipulationMixin from '@/mixins/BotManipulationMixin';

export default {
  name: 'NodeEditContexts',
  components: {
    TooltippedText,
    ChipList,
    AddNodeModal,
  },
  mixins: [botManipulationMixin],
  props: {
    nodeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      arrow: 'angle-down',
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
      'nodeByName',
      'nodeNames',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      getResponseMode: 'getResponseMode',
      getIsOutgoing: 'getIsOutgoing',
      fallbackUsage: 'getFallbackUsageOfNode',
      isStrictMpc: 'isStrictMpc',
    }), 'nodeId'),
    nodeTypeAllowsChildrenNodes() {
      const nodeTypesAllowingChildrenNodes = [nodeTypes.MULTIPLE_CHOICE, nodeTypes.SIMPLE];
      return nodeTypesAllowingChildrenNodes.includes(this.activeNode.options.nodeType);
    },
    isSubflow() {
      return this.nodeType === nodeTypes.SUBFLOW;
    },
    isSmalltalk() {
      return this.nodeType === nodeTypes.SMALLTALK;
    },
    nodeType() {
      return this.activeNode.options.nodeType;
    },
    shouldAllowSettingRequiresResponse() {
      if (this.isSmalltalk) {
        return false;
      }
      return true;
    },
    insertChildModalId() {
      return `${this.nodeId}-child`;
    },
    activeBot() {
      return this.$store.state.botManipulation.activeBot;
    },
    nodeNames() {
      // Special nodes are not included here
      return Object.values(this.activeBot.nodes).map((n) => n.name).sort();
    },
    nodeName(id) {
      return this.nodeById(id).name;
    },
    activeNode() {
      return this.nodeById(this.nodeId);
    },
    parents() {
      return this.activeNode.preds.map((id) => this.nodeById(id));
    },
    parentNames() {
      return this.parents.map((n) => n.name);
    },
    disabledParentsNames() {
      const disabledParents = this.parents.filter((x) => x.options.disable === true);
      return disabledParents.map((n) => n.name);
    },
    children() {
      return this.activeNode.children.map((id) => this.nodeById(id));
    },
    childNames() {
      return this.children.map((n) => n.name);
    },
    disabledChildNames() {
      const disabledChildren = this.children.filter((x) => x.options.disable === true);
      return disabledChildren.map((n) => n.name);
    },
    subFlow() {
      const subFlows = this.$store.state.botManipulation.subFlows;
      const subFlowID = this.activeNode.subFlowMap.subFlowID;
      return subFlows.find((sf) => sf.id === subFlowID);
    },
    subFlowMap() {
      return this.activeNode.subFlowMap;
    },
    outGoingNodes() {
      return Object.values(this.subFlow.nodes).filter((n) => n.options.outgoing);
    },
    responseMode: {
      get() {
        return this.getResponseMode === 'require' ? 'REQUIRE_RESPONSE' : 'PASSTHROUGH';
      },
      set(value) {
        const valueForStore = value === 'REQUIRE_RESPONSE' ? 'require' : 'pass';
        this.setResponseMode({ responseMode: valueForStore });
      },
    },
    outgoingNode: {
      get() {
        return this.getIsOutgoing;
      },
      set(isOutgoing) {
        this.setIsOutgoing({ isOutgoing });
      },
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', [
      'setResponseMode',
      'setIsOutgoing',
      'updateOutgoing',
    ]), { id: 'nodeId' }),
    presentCreateChildNodeModal() {
      this.$bvModal.show(this.insertChildModalId);
    },
    toggleArrow() {
      if (this.arrow === 'angle-down') {
        this.arrow = 'angle-up';
      } else {
        this.arrow = 'angle-down';
      }
    },
    setParents(nameArray) {
      if (!nameArray) {
        return;
      }
      const nodeIds = this.nodeNames2Ids(nameArray);
      if (!nodeIds) return;
      this.$store.dispatch(
        'botManipulation/activeBot/updateActiveNode',
        { value: nodeIds, attribute: 'preds', id: this.nodeId },
      );
    },
    setChildren(nameArray) {
      if (!nameArray) {
        return;
      }
      const nodeIds = this.nodeNames2Ids(nameArray);
      if (!nodeIds) return;
      this.setChildrenByIdArray(nodeIds);
    },
    getOutgoinChildren(subNodeID) {
      if (this.subFlowMap.outgoing[subNodeID] == null) {
        this.setOutgoingChildren(subNodeID, []);
      }
      return this.subFlowMap.outgoing[subNodeID].childrenIds.map((id) => this.nodeById(id).name);
    },
    setOutgoingChildren(subNodeID, childrenNames) {
      const outgoingCopy = JSON.parse(JSON.stringify(this.subFlowMap.outgoing));
      outgoingCopy[subNodeID] = {
        childrenIds: this.nodeNames2Ids(childrenNames),
      };
      // update outgoing in subFlowMap
      this.updateOutgoing({ value: outgoingCopy });
      // update children array
      const childrenIds = [];
      Object.keys(outgoingCopy).forEach((key) => {
        const ids = outgoingCopy[key].childrenIds;
        const uniqueIds = ids.filter((x) => !(childrenIds.includes(x)));
        childrenIds.push(...uniqueIds);
      });
      this.setChildrenByIdArray(childrenIds);
    },
    goToNode(name) {
      const [id] = this.nodeNames2Ids([name]);
      if (id !== this.nodeId) {
        const params = { botId: this.$route.params.botId, nodeId: id };
        this.$router.push({ name: 'edit-node', params });
      }
    },
  },
};
</script>
