<template>
  <main
    role="main"
  >
    <b-card
      title="Integrations"
      class="r-75"
      body-class="p-3"
    >
      <p>
        Integrations are used for interacting with backend systems,
        chat clients, and much more.
      </p>
      <b-row
        v-if="nodeWarnings.length > 0"
        align-v="center"
      >
        <b-col>
          <p>
            You have one or mode nodes that contain an integration that was created on a previous
            version of an integration blueprint, click button below to see a list of these and to
            update the integration(s) in the node(s) accordingly.
          </p>
          <b-btn
            v-b-modal.showWarningsModal
            class="mt-2"
            variant="warning"
          >
            List out-of-sync integrations
          </b-btn>
        </b-col>
      </b-row>
    </b-card>

    <manage-bot-secrets
      v-if="isMainBot"
      class="mt-3"
    />

    <b-card
      class="r-75 mt-3 p-3"
      no-body
    >
      <b-table
        :tbody-tr-attr="attributesForRow"
        :items="getBotActions"
        :fields="actionsFields"
        responsive
        hover
        small
        class="mb-0"
        show-empty
        :empty-text="tableEmptyText"
        @row-clicked="action => showActionModal(action.name)"
      >
        <template #cell(buttons)="row">
          <b-btn
            title="Delete Integration"
            size="sm"
            variant="outline-danger"
            @click="showDeleteActionModal(row.item.name)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-btn>
          <b-btn
            title="List references"
            class="ml-1"
            size="sm"
            variant="outline-success"
            @click="showActionReferencesModal(row.item.name)"
          >
            <font-awesome-icon icon="search" />
          </b-btn>
        </template>

        <template
          #custom-foot
        >
          <b-tr
            style="text-align: center"
          >
            <b-td
              class="pt-3"
              colspan="4"
            >
              <b-btn
                variant="primary"
                @click.stop="showAddActionModal"
              >
                Add custom integration
              </b-btn>
              <b-btn
                class="ml-2"
                variant="primary"
                @click.stop="addPredefinedIntegration"
              >
                Add integration from template
              </b-btn>
            </b-td>
          </b-tr>
        </template>
      </b-table>
    </b-card>

    <api-action-modal
      :is-edit="isEdit"
      :edit-id="editId"
    />

    <APIActionTemplatePickerModal
      @integrationCreatedId="integrationWasCreated"
    />

    <!-- "Are you sure you want to delete this rule?" - modal -->
    <b-modal
      id="deleteActionModal"
      :title="'Delete integration ' + getDeleteActionName()"
      ok-title="Delete"
      ok-variant="danger"
      @hidden="clearDeleteActionId"
      @ok="deleteAction"
    >
      Are you sure you want to delete the {{ getDeleteActionName() }} integration?
      Deleting it will remove the integration from all nodes.
      <!-- TODO: We should probably give a link to all of the nodes that will be affected.
           This will be extra difficult to do for subflows. -->
    </b-modal>

    <b-modal
      id="showWarningsModal"
      size="lg"
      title="Out-of-sync integration in nodes"
      ok-title="Clear all warnings"
      @ok="clearAllModifiedActionWarnings()"
    >
      <p>
        These nodes have integrations that used a previous version of the integration blueprint.
        You should inspect that the integration in nodes are updated accordingly.
      </p>
      <p
        v-for="nodeWarning in nodeWarnings"
        :key="nodeWarning.nodeId"
      >
        <router-link
          :to="{ name: 'edit-node', params: { nodeId: nodeWarning.nodeId } }"
        >
          {{ nodeWarning.nodeName }}
        </router-link>
        <span role="tablist">
          <action-activity
            v-for="activityId in nodeWarning.activityIds"
            :key="activityId"
            :node-id="nodeWarning.nodeId"
            :activity-id="activityId"
            :indent="0"
          />
        </span>
      </p>
    </b-modal>

    <b-modal
      id="integrationReferences"
      size="lg"
      ok-only
      :title="'References to Integration ' + searchId"
    >
      <APIActionRefList
        :id="searchId"
      />
    </b-modal>
  </main>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import ActionActivity from '@/pages/EditNode/activity/ActionActivity.vue';
import ApiActionModal from '@/pages/APIActions/APIActionModal.vue';
import APIActionRefList from '@/pages/APIActions/APIActionRefList.vue';
import APIActionTemplatePickerModal from '@/pages/APIActions/APIActionTemplatePickerModal.vue';
import ManageBotSecrets from '@/pages/APIActions/BotSecrets/ManageBotSecrets.vue';

export default {
  name: 'APIActionsPage',
  components: {
    ActionActivity,
    ApiActionModal,
    APIActionRefList,
    APIActionTemplatePickerModal,
    ManageBotSecrets,
  },
  data() {
    return {
      // This is the fields to display for the actions. We only display the name in the table
      tableEmptyText: 'No integrations are defined for bot. To use an integration in bot, '
      + 'click the button below to create an integration',
      actionsFields: [
        {
          key: 'buttons',
          label: '',
          thClass: 'text-left',
          tdClass: 'text-left',
          thStyle: 'width:80px',
        },
        {
          key: 'name',
          label: 'Integration',
          tdClass: 'align-middle font-weight-bold',
          sortable: true,
        },
        {
          key: 'httpRequestType',
          label: 'Type',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'endpoint',
          label: 'Endpoint',
          tdClass: 'align-middle',
          sortable: true,
        },
      ],
      toDeleteId: null,
      isEdit: false,
      editId: null,
      searchId: null,
    };
  },
  computed: {
    ...mapState('botManipulation/activeBot', [
      'nodes',
    ]),
    ...mapGetters('botManipulation', [
      'getBotActions',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    attributesForRow() {
      return (rowItem, rowIndex) => {
        if (rowItem === null || rowIndex === 'row-empty') {
          return null;
        }
        return { style: 'cursor:pointer' };
      };
    },
    nodeWarnings() {
      const nodeWarnings = [];
      for (const { id, name } of Object.values(this.nodes)) {
        const activityIds = this.$store.getters['botManipulation/activeBot/getActionsModified'](id);
        if (activityIds.length > 0) {
          nodeWarnings.push({ activityIds, nodeName: name, nodeId: id });
        }
      }
      return nodeWarnings;
    },
  },
  watch: {
    nodeWarnings(newValue) {
      // When the last node-warning is dismissed in modal, we want to automatically close the modal
      if (newValue.length === 0) {
        // Close modal - this should have no effect if modal is not shown
        this.$bvModal.hide('showWarningsModal');
      }
    },
  },
  mounted() {
    const hash = this.$route.hash;
    const lookup = hash.replace('#', '');
    const action = this.getBotActions.find((a) => a.name === lookup);
    if (action) {
      this.showActionModal(action.name);
    } else if (lookup === 'showWarnings') {
      this.$bvModal.show('showWarningsModal');
    }
  },
  methods: {
    ...mapMutations('botManipulation', [
      'deleteBotAction',
    ]),
    ...mapMutations('botManipulation/activeBot', [
      'clearAllModifiedActionWarnings',
    ]),
    deleteAction() {
      // Delete the specified action, update the pagination and clear variables
      this.deleteBotAction({ id: this.toDeleteId });
      this.clearDeleteActionId();
    },
    clearDeleteActionId() {
      this.toDeleteId = null;
    },
    showDeleteActionModal(id) {
      this.toDeleteId = id;
      this.$bvModal.show('deleteActionModal');
    },
    getDeleteActionName() {
      if (this.toDeleteId === null) {
        return '';
      }
      const action = this.getBotActions.find((a) => a.name === this.toDeleteId);
      return action.name;
    },
    showActionModal(id) {
      this.editId = id;
      this.isEdit = true;
      // We need to allow for update of editId and isEdit to propagate to modal before presenting
      // modal
      this.$nextTick(() => {
        this.$bvModal.show('editActionModal');
      });
    },
    showAddActionModal() {
      this.editId = null;
      this.isEdit = false;
      // We need to allow for update of editId and isEdit to propagate to modal before presenting
      // modal
      this.$nextTick(() => {
        this.$bvModal.show('editActionModal');
      });
    },
    showActionReferencesModal(id) {
      this.searchId = id;
      this.$bvModal.show('integrationReferences');
    },
    addPredefinedIntegration() {
      this.$bvModal.show('api-action-template-picker-modal');
    },
    integrationWasCreated(id) {
      // React to event raised by template picker modal, signaling that an action was created from
      // a template. The 'reaction' here is to open the EditAction modal for the user; this is
      // particular nice, because enduser will realize that she/he still need to replace some
      // placeholder-values
      this.showActionModal(id);
    },
  },
};

</script>
