var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.children),function(child){return _c('div',{key:`${child.id}ChildOfMC`,staticClass:"mb-3"},[_c('b-row',{attrs:{"id":'GroupDisplayName' + child.id,"no-gutters":""}},[_c('b-col',{staticClass:"my-auto pr-2",class:_vm.alwaysOpen ? '' : 'text-right',attrs:{"cols":_vm.alwaysOpen ? '12' : '4'}},[_c('label',{staticClass:"m-0",attrs:{"for":'displayName' + child.id}},[_c('b-link',{attrs:{"to":_vm.linkToNode(child.id)}},[_vm._v(_vm._s(child.name)+" ")]),(_vm.voicebotPlatformSelected)?_c('voicebot-audio',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive",modifiers:{"noninteractive":true}}],staticClass:"border-0 bg-transparent shadow-none ml-1",attrs:{"text":_vm.getDisplayNameForId(child.id) || _vm.getDefaultDisplayName(child.id),"small":"","title":"Play this message"}}):_vm._e()],1)]),_c('b-col',{staticClass:"my-auto",attrs:{"cols":_vm.alwaysOpen ? '12' : '8'}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.noninteractive.viewport",modifiers:{"hover":true,"noninteractive":true,"viewport":true}}],attrs:{"title":_vm.getDirectMatchOnlyForId(child.id) ? 'Click to show when presenting multiple choice options' : 'Click to hide when presenting multiple choice options'},on:{"click":function($event){_vm.setDirectMatchOnlyForId({
                childId: child.id,
                isDirectMatch: !_vm.getDirectMatchOnlyForId(child.id),
              })}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.getDirectMatchOnlyForId(child.id) ? 'eye-slash' : 'eye'}})],1),(!_vm.isStrictMpc)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.noninteractive.viewport",modifiers:{"hover":true,"noninteractive":true,"viewport":true}}],attrs:{"title":_vm.getAutoThresholdEnabledForId(child.id) ? 'Remove jump threshold override' : 'Override jump threshold'},on:{"click":function($event){return _vm.toggleAutoThreshold(child.id)}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.getAutoThresholdEnabledForId(child.id) ? 'times' : 'sliders'}})],1):_vm._e()]},proxy:true}],null,true)},[_c('b-form-input',{ref:'input' + child.id,refInFor:true,attrs:{"id":'displayName' + child.id,"disabled":_vm.getDirectMatchOnlyForId(child.id),"value":_vm.getDisplayNameForId(child.id),"placeholder":_vm.getDefaultDisplayName(child.id),"type":"text","required":""},on:{"focus":function($event){return _vm.autoFill(child.id)},"input":displayName =>
              _vm.setDisplayNameForId({ childId: child.id, displayName: displayName })}}),(_vm.enableResponseEnhancement)?_c('display-name-enhance',{staticClass:"position-absolute mt-1 mb-1",style:({
              right: '0',
              top: '0',
              zIndex: '3',
              marginRight: !_vm.isStrictMpc ? '4rem' : '2rem',
            }),attrs:{"text-element":() => _vm.$refs['input' + child.id][0],"default-name":child.name,"variant":"link","size":"sm"},on:{"result":selected => _vm.replaceSelected(child.id, selected)}}):_vm._e()],1),(_vm.longNameWarning(child.id))?_c('div',[_c('small',{staticClass:"text-warning"},[_c('font-awesome-icon',{attrs:{"icon":"circle-exclamation"}}),_vm._v(" We recommend using short and concise display names. "+_vm._s(_vm.aiRecommendation)+" ")],1)]):_vm._e()],1)],1),(_vm.isSupchatPlatform)?_c('b-row',{staticClass:"pt-1",attrs:{"id":'GroupDescription' + child.id,"no-gutters":""}},[_c('b-col',{staticClass:"my-auto pr-2",class:_vm.alwaysOpen ? 'text-left' : 'text-right',attrs:{"cols":_vm.alwaysOpen ? '12' : '4'}},[_c('label',{staticClass:"m-0",attrs:{"for":'description' + child.id}},[_c('small',[_vm._v(" Button description ")])])]),_c('b-col',{staticClass:"my-auto"},[_c('b-form-input',{attrs:{"id":'description' + child.id,"disabled":_vm.getDirectMatchOnlyForId(child.id),"value":_vm.getDescriptionForId(child.id),"placeholder":"","type":"text"},on:{"input":description => _vm.setDescriptionForId({ childId: child.id, description })}})],1)],1):_vm._e(),(_vm.getAutoThresholdEnabledForId(child.id) && !_vm.isStrictMpc)?_c('b-row',{staticClass:"pt-2",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"my-auto pr-2",class:_vm.alwaysOpen ? 'text-left' : 'text-right',attrs:{"cols":_vm.alwaysOpen ? '12' : '4'}},[_c('label',{staticClass:"m-0 pb-1",attrs:{"for":'threshold' + child.id}},[_vm._v(" Override direct jump threshold ")])]),_c('b-col',{staticClass:"my-auto"},[_c('input',{staticClass:"custom-range",class:_vm.alwaysOpen ? '' : 'pt-3 mt-1',attrs:{"id":'threshold' + child.id,"aria-labelledby":'threshold' + child.id,"type":"range"},domProps:{"value":_vm.getAutoThresholdValueForId(child.id)},on:{"input":(event) => _vm.setAutoThresholdValueForId({
            childId: child.id,
            threshold: event.target.value,
          })}}),(Number(_vm.getAutoThresholdValueForId(child.id)) < 20)?_c('small',{staticClass:"text-warning"},[_c('font-awesome-icon',{attrs:{"icon":"circle-exclamation"}}),_vm._v(" Very low threshold override values may cause undesired behavior where we jump to this node too often. ")],1):_vm._e(),_c('div',{staticStyle:{"float":"right"}},[(Number(_vm.getAutoThresholdValueForId(child.id)) === 100)?_c('div',[_vm._v(" Never go directly to "+_vm._s(child.name)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.getAutoThresholdValueForId(child.id))+"% ")])])])],1):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }