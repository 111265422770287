import axios from 'axios';
import Vue from 'vue';
import update from 'immutability-helper';
import endpoints from '@/js/urls';

const classifierState = {
  models: {},
  isModelsLoading: false,
  isClassifierLoading: false,
  labels: {},
  versions: {},
  versionsPagination: {
    count: null,
    currentPage: 1,
    perPage: 10,
  },
  isFetchingVersions: false,
  isFetchingLabels: false,
  activeModelId: null,
  activeLabelId: null,
  activeSourceId: null,
  activeVersionId: null,
  activeVersion: null,
  activeTraining: false,
  globalIsTrainingTmp: false, // tmp state to set while waiting for backend
};

export const classifierGetters = {
  activeModel(state) {
    if (state.activeModelId && state.activeModelId in state.models) {
      return state.models[state.activeModelId];
    }
    return null;
  },
  activeLabel(state) {
    if (state.activeLabelId && state.activeLabelId in state.labels) {
      return state.labels[state.activeLabelId];
    }
    return null;
  },
  activeSource(state, getters) {
    if (state.activeSourceId && state.activeSourceId && getters.sourcesWithExamples) {
      return getters.sourcesWithExamples.find((source) => source.id === state.activeSourceId)
        || null;
    }
    return null;
  },
  activeVersion(state) {
    return state.activeVersion;
  },
  sourcesWithExamples(state, getters) {
    if (state.activeLabelId) {
      const examplesSrc = {
        id: 'examples',
        name: 'Examples',
        rows: getters.activeLabel?.examples?.length,
        type: 'examples',
      };
      return getters.activeLabel?.sources
        ? [examplesSrc].concat(getters.activeLabel?.sources) : [examplesSrc];
    }
    return [];
  },
  getGlobalNLUModels(state) {
    return state.models ? state.models : {};
  },
  isModelsLoading(state) {
    return state.isModelsLoading;
  },
  labels(state) {
    return state.labels ? state.labels : {};
  },
  // Adding this computed getter to make more debuggable code
  getGlobalNLUModelById: (state) => (modelId) => {
    const model = state.models[modelId];
    return model;
  },
  getModelFromId: (state) => (modelId) => {
    if (modelId !== null && modelId in state.models) {
      return state.models[modelId];
    }
    return null;
  },
  getModelVersionForModel: (state) => (modelId, modelVersionId) => {
    const model = state.models[modelId];
    if (!model) {
      return undefined;
    }
    const modelVersion = model.versions[modelVersionId];
    return modelVersion;
  },
  globalIsTraining: (state, getters) => {
    if (getters.activeModel.type === 'swml') {
      return false;
    }
    if (state.globalIsTrainingTmp) {
      return true;
    }
    for (const version of Object.values(state.versions)) {
      const trainTask = version.classifierversionbot
        ? version.classifierversionbot.train_task : null;
      if (trainTask && !trainTask.finished) {
        return true;
      }
    }
    return false;
  },
  modelVariants: (state) => (modelId) => {
    const model = state.models[modelId];
    const mainId = model.mainClassifier ? model.mainClassifier : model.id;
    return Object.values(state.models).filter(
      (x) => x.id === mainId || x.mainClassifier === mainId,
    );
  },
};

const mutations = {
  setActiveModelId(state, id) {
    state.activeModelId = id;
  },
  setActiveLabelId(state, id) {
    state.activeLabelId = id;
  },
  setActiveSourceId(state, id) {
    state.activeSourceId = id;
  },
  setActiveVersionId(state, id) {
    state.activeVersionId = id;
  },
  setGlobalNLUModels(state, models) {
    for (const model of Object.values(models)) {
      model.displayName = model.name;
      if (model.type === 'swml') {
        model.typeText = 'Uploaded';
      } else if (model.type === 'swml.bot') {
        if (model.nodeId) {
          model.typeText = 'Node classifier';
        } else {
          model.typeText = 'Trainable classifier';
        }
        if (model.language) {
          model.displayName = `${model.name} (${model.language})`;
        }
      }
    }
    state.models = models;
  },
  setClassifierVersions(state, versionList) {
    const versionDict = {};
    versionList.forEach((v) => {
      versionDict[v.id] = v;
    });
    Vue.set(state, 'versions', versionDict);
  },
  setIsModelsLoading(state, value) {
    state.isModelsLoading = value;
  },
  setIsClassifierLoading(state, value) {
    state.isClassifierLoading = value;
  },
  setLabels(state, labels) {
    Vue.set(state, 'labels', labels);
  },
  updateLabel(state, { id, values }) {
    for (const [key, value] of Object.entries(values)) {
      Vue.set(state.labels[id], key, value);
    }
  },
  updateExamplesWithHelper(state, { id, payload }) {
    Object.assign(state.labels[id], update(state.labels[id], { examples: payload }));
  },
  toggleModelDetails(state, item) {
    // eslint-disable-next-line no-underscore-dangle
    if (item._showDetails) {
      Vue.set(item, '_showDetails', false);
    } else {
      Vue.set(item, '_showDetails', true);
    }
  },
  setIsAborted(state, value) {
    // the purpose of this mutation is to react immediately in the frontend
    // while waiting for the backend to send the updated state
    state.activeVersion.classifierversionbot.train_task.aborted = value;
  },
  setGlobalIsTrainingTmp(state, value) {
    state.globalIsTrainingTmp = value;
  },
  setVersionsPaginationPage(state, value) {
    Vue.set(state.versionsPagination, 'currentPage', value);
  },
  setVersionsPaginationCount(state, value) {
    state.versionsPagination.count = value;
  },
  resetVersionsPagination(state) {
    state.versionsPagination = {
      count: null,
      currentPage: 1,
      perPage: 10,
    };
  },
  setActiveVersion(state, value) {
    state.activeVersion = value;
  },
  setIsFetchingVersions(state, value) {
    state.isFetchingVersions = value;
  },
  setIsFetchingLabels(state, value) {
    state.isFetchingLabels = value;
  },
  setActiveTraining(state, value) {
    state.activeTraining = value;
  },
};

const actions = {
  async fetchGlobalNLUModelsNoVersions({ rootState, commit }) {
    commit('setIsModelsLoading', true);
    const response = await axios.get(endpoints.classifiersLight, {
      headers: {
        Authorization: `JWT ${rootState.auth.jwt}`,
      },
    });
    if (response.status === 200) {
      commit('setGlobalNLUModels', response.data);
      commit('setIsModelsLoading', false);
    }
  },

  async fetchGlobalNLUModels({ rootState, commit }) {
    commit('setIsModelsLoading', true);
    const response = await axios.get(endpoints.classifiers, {
      headers: {
        Authorization: `JWT ${rootState.auth.jwt}`,
      },
    });
    if (response.status === 200) {
      commit('setGlobalNLUModels', response.data);
      commit('setIsModelsLoading', false);
    }
  },

  async fetchClassifier({ commit, dispatch }, clfId) {
    commit('setIsClassifierLoading', true);
    // loading labels are handled in subpages, so dont await
    await dispatch('fetchLabels', clfId);
    commit('setIsClassifierLoading', false);
  },

  async fetchLabels({ rootState, commit, dispatch }, clfId) {
    commit('setIsFetchingLabels', true);
    function updateLabels(labels) {
      const result = {};
      for (const [id, lbl] of Object.entries(labels)) {
        result[id] = { ...lbl };
        if (lbl.sources) {
          result[id].rows = lbl.sources.reduce(
            (a, b) => ({ rows: a.rows + b.rows }),
            { rows: lbl.examples.length },
          ).rows;
        }
      }
      commit('setLabels', result);
    }
    try {
      // get labels without row count
      const resp1 = await axios.get(endpoints.labels, {
        params: { clfId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      updateLabels(resp1.data);
      // start row counting task
      const resp2 = await axios.get(endpoints.labelsTask, {
        params: { clfId },
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      if (resp2.status === 200) {
        const task = resp2.data.task;
        commit(
          'task/addTask',
          {
            celeryId: task,
            callbackUpdate: ({ meta }) => {
              updateLabels(meta);
            },
            callbackDone: ({ meta }) => {
              updateLabels(meta);
              commit('setIsFetchingLabels', false);
            },
            callbackFailed: () => {
              dispatch(
                'sidebar/showWarning',
                {
                  title: 'Error occured',
                  text: 'Backend ran into problems while fetching the labels.',
                  variant: 'danger',
                },
                { root: true },
              );
              commit('setIsFetchingLabels', false);
            },
          },
          { root: true },
        );
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        dispatch('sidebar/showWarning', {
          title: 'Permission denied',
          text: 'You do not have permissions to this classifier. You must ask a superuser to grant you access.',
          variant: 'warning',
        }, { root: true });
      }
      commit('setIsFetchingLabels', false);
      throw err;
    }
  },

  async fetchClassifierVersions({ rootState, commit, dispatch }, { clfId, page }) {
    try {
      commit('setIsFetchingVersions', true);
      if (clfId) {
        const resp = await axios.get(endpoints.classifierVersion, {
          params: { classifier: clfId, page },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
        if (resp.status === 200) {
          commit('setClassifierVersions', resp.data.results);
          commit('setVersionsPaginationCount', resp.data.count);
        }
      }
    } catch (e) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch classifier versions',
        text: e,
        variant: 'warning',
      }, { root: true });
    } finally {
      commit('setIsFetchingVersions', false);
    }
  },
  async fetchVersionDetails({
    state, rootState, commit, dispatch,
  }) {
    try {
      const resp = await axios.get(`${endpoints.classifierVersion + state.activeVersionId}/`, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      if (resp.status === 200) {
        commit('setActiveVersion', resp.data);
      }
    } catch (e) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch classifier version',
        text: e,
        variant: 'warning',
      }, { root: true });
    }
  },
  async fetchTrainingTask({
    rootState, state, commit, dispatch,
  }, continuePulling = true) {
    const { activeModelId } = state;
    if (!activeModelId) {
      commit('setActiveTraining', false);
    } else {
      try {
        const { data } = await axios.get(endpoints.trainTask, {
          params: { clfId: activeModelId, exclude_log: true },
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
        if (data.taskFound) {
          commit('setActiveTraining', data.started && !data.finished);
        } else {
          commit('setActiveTraining', false);
        }
      } catch (e) {
        dispatch('sidebar/showWarning', {
          title: 'Failed to fetch current training info',
          text: e,
          variant: 'warning',
        }, { root: true });
      } finally {
        if (continuePulling) {
          setTimeout(() => dispatch('fetchTrainingTask'), 5000);
        }
      }
    }
  },
  async createClassifier({ rootState, dispatch }, data) {
    const resp = await axios.post(endpoints.classifiers, data, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
    dispatch('fetchGlobalNLUModels');
    return resp.data.id;
  },

  async deleteClassifier({ rootState }, { classifierId }) {
    try {
      const response = await axios.delete(endpoints.classifiers,
        {
          params: {
            classifier_id: classifierId,
          },
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        });
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      // if an error occurs or response code is not 200 we continue.
    }
    return { success: false, error: true };
  },

  async deleteClassifierVersion({ rootState }, { versionId }) {
    try {
      const response = await axios.delete(endpoints.classifiers,
        {
          params: {
            version_id: versionId,
          },
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
        });
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      // if an error occurs or response code is not 200 we continue.
    }
    return { success: false, error: true };
  },

  async updateClassifier({ state, rootState, dispatch }, {
    classifierId, description, name, config, autoTrain,
  }) {
    const payload = {
      classifier_id: classifierId,
      name: state.models[classifierId].nodeId ? null : name,
      description,
      config,
      autoTrain,
    };
    await axios.put(endpoints.classifiers, payload, {
      headers: {
        Authorization: `JWT ${rootState.auth.jwt}`,
      },
    });
    dispatch('fetchClassifier', classifierId);
  },

  async saveExamples({
    state, dispatch, rootState,
  }, labelId) {
    const data = {
      id: labelId,
      examples: state.labels[labelId].examples,
    };
    await axios.put(endpoints.labels, data, {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    });
    dispatch('fetchLabels', state.activeModelId);
  },
};

export default {
  namespaced: true,
  state: classifierState,
  getters: classifierGetters,
  mutations,
  actions,
};
