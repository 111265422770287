import { deepCopyJson, deepEqualsJson } from 'supwiz/util/data';
import axios from 'axios';
import { applyPatch, compare } from 'fast-json-patch';
import endpoints from '@/js/urls';

const syncState = {
  lastBotReceived: null,
  lastBotETagReceived: null,
  lastBotSent: null,

  isSendingBot: false,
  // bot sync
  botSyncError: null,
  manualSync: false,
};

const syncGetters = {
  getSyncErrorStatus(state) {
    return state.botSyncError;
  },
};

const syncMutations = {
  setLastBotReceived(state, bot) {
    state.lastBotReceived = deepCopyJson(bot);
  },
  setLastBotETagReceived(state, { ETag }) {
    state.lastBotETagReceived = ETag;
  },
  setLastBotSent(state, bot) {
    state.lastBotSent = deepCopyJson(bot);
  },
  setIsSendingBot(state, isSending) {
    state.isSendingBot = isSending;
  },
  setSyncError(state, error) {
    state.botSyncError = error;
  },
  setManualSync(state, value) {
    state.manualSync = value;
  },
};

const syncActions = {
  resetBotState({ commit }) {
    commit('setLastBotSent', null);
    commit('setLastBotReceived', null);
  },
  async syncBot({
    state, rootState, commit, dispatch, getters,
  }, manualSync) {
    const { botManipulation } = rootState;
    if ((state.isSendingBot || state.botSyncError
      || !botManipulation.activeBotId || !botManipulation.activeBotSet) && !manualSync) {
      return;
    }
    commit('setIsSendingBot', true);
    let onlyFetch = false;
    if (getters['auth/isUserLimited']) {
      onlyFetch = true;
    } else {
      onlyFetch = deepEqualsJson(botManipulation.activeBot, state.lastBotReceived);
    }
    const { activeBot, activeBotId, activeSubFlowId } = botManipulation;
    // update lastBotSend and send
    commit('setLastBotSent', activeBot);
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      // for manual sync we set timeout to 0, which means that there is no timeout from client side
      timeout: manualSync ? 0 : 60000,
      validateStatus: (status) => status === 200 || status === 304,
    };
    try {
      if (onlyFetch) {
        if (state.lastBotETagReceived) {
          config.headers['If-None-Match'] = state.lastBotETagReceived;
        }
        let url;
        if (activeSubFlowId) {
          url = endpoints.subFlows + activeSubFlowId;
        } else {
          url = endpoints.botsBase + activeBotId;
        }
        const { data, status, headers } = await axios.get(url, config);
        if (status !== 200 && status !== 304) {
          throw new Error(`Status expected to be 200 or 304, got ${status}`);
        }
        // If the bot id was changed in between loading then we will get the bot with 200
        if (state.lastBotSent && status === 200) {
          await dispatch('botManipulation/updateBot',
            { botReceived: data, ETag: headers.etag },
            { root: true });
        }
      } else {
        const diff = compare(state.lastBotReceived, activeBot);
        const putData = {
          last_bot_received: state.lastBotReceived,
          // Checking etag in backend takes a long tike at kk, so comment this out till fixed
          last_bot_etag_received: state.lastBotETagReceived,
          current_bot_diff: diff,
        };
        const response = await axios.put(endpoints.botsBase + activeBotId, putData, config);
        const { data, status, headers } = response;
        if (status !== 200 && status !== 304) {
          throw new Error(`Status expected to be 200, received status ${status}`);
        }
        // If the bot id was changed in between loading then "lastBotSent" will be reset to null.
        if (state.lastBotSent) {
          const {
            success, error, bot_diff: botDiff, etag, bot_changed: botChanged,
          } = data;
          if (!success) {
            throw new Error(`Couldn't merge bot on serverside: ${error}`);
          }
          if (botChanged) {
            // The received etag is for our last sent bot with the received
            // bot diff applied. Apply so we're up-to-date
            const patchedBot = applyPatch(deepCopyJson(state.lastBotSent), botDiff).newDocument;
            await dispatch('botManipulation/updateBot',
              { botReceived: patchedBot, ETag: etag },
              { root: true });
          } else {
            commit('setLastBotReceived', state.lastBotSent);
            commit('setLastBotETagReceived', { ETag: headers.etag });
          }
        }
      }
    } catch (error) {
      console.log(`${new Date()}: Tried to synchronize bot, but got: ${error}`);
      if (error.response && error.response.status === 503) {
        dispatch('sidebar/showWarning', {
          title: 'Failed to synchronize the bot',
          text: 'The server is currently unavailable.',
          variant: 'warning',
        }, { root: true });
      } else {
        commit('setSyncError', error);
      }
    } finally {
      commit('setIsSendingBot', false);
    }
  },
};

export default {
  namespaced: true,
  state: syncState,
  getters: syncGetters,
  mutations: syncMutations,
  actions: syncActions,
};
