<template>
  <div>
    <template v-if="!dataLoaded">
      <b-spinner style="width: 3rem; height: 3rem;" />
    </template>
    <template v-else>
      <b-row>
        <b-col>
          <b-card
            class="r-75"
            body-class="p-3"
          >
            <b-row>
              <b-col
                cols="auto"
                class="my-auto"
              >
                <h4 class="card-title">
                  Filters
                </h4>
              </b-col>
              <b-col class="my-auto pb-2 pr-4">
                <b-form inline>
                  <b-form-checkbox
                    v-model="filters.structured"
                    name="check-button"
                    class="ml-auto"
                    switch
                  >
                    Structured view
                  </b-form-checkbox>
                  <tooltipped-text
                    class="ml-2 pb-1"
                    value="Display and filter categories and subcategories together"
                  />
                </b-form>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                class="my-1"
                sm="12"
                md="4"
              >
                <b-input-group>
                  <b-form-input
                    v-model="filters.keyword"
                    placeholder="Filter statistics"
                  />
                  <template
                    v-if="tooltipsOn"
                    #append
                  >
                    <b-input-group-text>
                      <tooltipped-text
                        value="Search keyword in categories & subcategories"
                      />
                    </b-input-group-text>
                  </template>
                </b-input-group>
              </b-col>
              <b-col
                class="my-1"
                sm="12"
                md="4"
              >
                <b-input-group>
                  <b-form-input
                    v-model="filters.minLabelNumber"
                    :class="tooltipsOn ? 'w-75' : 'w-100'"
                    placeholder="Minimum number of labels"
                    type="number"
                    min="0"
                  />
                  <template
                    v-if="tooltipsOn"
                    #append
                  >
                    <b-input-group-text>
                      <tooltipped-text
                        :value="filters.structured ? 'Set minimum number of labels in subcategories'
                          : 'Set minimum number of labels in categories & subcategories'"
                      />
                    </b-input-group-text>
                  </template>
                </b-input-group>
              </b-col>
              <b-col
                class="my-1"
                sm="12"
                md="4"
              >
                <b-input-group>
                  <b-form-input
                    v-model="filters.maxLabelNumber"
                    :class="tooltipsOn ? 'w-75' : 'w-100'"
                    placeholder="Maximum number of labels"
                    type="number"
                    min="0"
                  />
                  <template
                    v-if="tooltipsOn"
                    #append
                  >
                    <b-input-group-text>
                      <tooltipped-text
                        :value="filters.structured ? 'Set maximum number of labels in subcategories'
                          : 'Set maximum number of labels in categories & subcategories'"
                      />
                    </b-input-group-text>
                  </template>
                </b-input-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card
            :title="filters.structured ? 'Category & subcategory statistics' : 'Category statistics'"
            class="r-75 mt-3 structured-categories"
            body-class="p-3"
          >
            <hr class="mb-1">
            <b-row
              v-for="{ id, name, count } in filteredCategoryStats"
              :key="id"
              no-gutters
              :class="filters.structured ? 'my-1' : ' my-1'"
            >
              <b-col cols="auto">
                <b-badge
                  class="stat-badge w-100 h-100 text-center"
                  style="cursor:pointer;"
                  variant="light"
                  @click="showEdit(id, name, true, false)"
                >
                  <font-awesome-icon icon="edit" />
                </b-badge>
              </b-col>
              <b-col
                cols="5"
              >
                <b-badge
                  v-if="excludeName(name)"
                  v-b-tooltip.hover.noninteractive.viewport
                  variant="primary"
                  class="stat-badge w-100 h-100"
                  style="opacity: 20%; cursor:pointer;"
                  :title="name.length > 40 ? `${name} (Excluded from filters)` : 'Excluded from filters'"
                  @click="showExamples(id, name, null)"
                >
                  {{ truncateString(name, 40) }}
                </b-badge>
                <b-badge
                  v-else
                  v-b-tooltip.hover.noninteractive.viewport
                  :title="name.length > 40 ? name : ''"
                  class="stat-badge w-100 h-100"
                  style="cursor:pointer;"
                  variant="primary"
                  @click="showExamples(id, name, null)"
                >
                  {{ truncateString(name, 40) }}
                </b-badge>
              </b-col>
              <b-col
                cols="1"
                class="px-1"
              >
                <b-badge
                  v-if="excludeCount(count)"
                  v-b-tooltip.hover
                  variant="primary"
                  class="stat-badge w-100 px-1 text-center h-100"
                  style="opacity: 20%;"
                  title="Excluded from filters"
                >
                  {{ count }}
                </b-badge>
                <b-badge
                  v-else
                  variant="primary"
                  class="stat-badge w-100 px-1 text-center h-100"
                >
                  {{ count }}
                </b-badge>
              </b-col>
              <b-col
                cols="5"
              >
                <b-progress
                  class="h-100"
                  :value="count"
                  :max="totalCategoryNumber"
                  variant="primary"
                />
              </b-col>
              <b-col
                v-for="{
                  id: sub_id,
                  name: sub_name,
                  parentName: sub_parentName,
                  count: sub_count,
                  isNone: sub_isNone,
                } in getSubcategories(name)"
                v-show="filters.structured"
                :key="sub_id"
                cols="12"
              >
                <b-row
                  no-gutters
                  class="mt-1"
                >
                  <b-col
                    offset="1"
                    cols="auto"
                  >
                    <b-badge
                      class="stat-badge text-center h-100 w-100"
                      style="cursor:pointer;float: left;"
                      variant="light"
                      @click="showEdit(sub_id, sub_name, false, sub_isNone)"
                    >
                      <font-awesome-icon icon="edit" />
                    </b-badge>
                  </b-col>
                  <b-col
                    cols="4"
                  >
                    <b-badge
                      v-b-tooltip.hover.noninteractive.viewport
                      class="stat-badge w-100 h-100"
                      style="cursor:pointer; "
                      variant="primary"
                      :title="sub_name.length > 35 ? sub_name : ''"
                      @click="showExamples(sub_id, sub_name, sub_parentName)"
                    >
                      {{ truncateString(sub_name, 35) }}
                    </b-badge>
                  </b-col>
                  <b-col
                    cols="1"
                    class="px-1"
                  >
                    <b-badge
                      variant="primary"
                      class="stat-badge w-100 px-1 text-center h-100"
                    >
                      {{ sub_count }}
                    </b-badge>
                  </b-col>
                  <b-col
                    cols="5"
                  >
                    <b-progress
                      class="h-100"
                      :value="sub_count"
                      :max="totalSubcategoryNumber"
                      variant="primary"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <hr class="mt-1 mb-0">
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row
        v-if="!filters.structured"
      >
        <b-col>
          <b-card
            title="Subcategory statistics"
            class="r-75 mt-3"
            body-class="p-3"
          >
            <hr class="mb-1">
            <b-row
              v-for="{
                id, name, parentName, count, isNone,
              } in filteredSubcategoryStats"
              :key="id"
              no-gutters
            >
              <b-col cols="auto">
                <b-badge
                  class="stat-badge w-100 h-100 text-center"
                  style="cursor:pointer"
                  variant="light"
                  @click="showEdit(id, name, false, isNone)"
                >
                  <font-awesome-icon icon="edit" />
                </b-badge>
              </b-col>
              <b-col
                cols="5"
              >
                <b-badge
                  v-b-tooltip.hover.noninteractive.viewport
                  class="stat-badge w-100 h-100"
                  :title="parentName.length > 20 || name.length > 20 ? `${parentName}:${name}` : ''"
                  style="cursor:pointer"
                  variant="primary"
                  @click="showExamples(id, name, parentName)"
                >
                  {{ truncateString(parentName, 20) }} : {{ truncateString(name, 20) }}
                </b-badge>
              </b-col>
              <b-col
                cols="1"
                class="px-1"
              >
                <b-badge
                  variant="primary"
                  class="stat-badge w-100 px-1 text-center h-100"
                >
                  {{ count }}
                </b-badge>
              </b-col>
              <b-col
                cols="5"
              >
                <b-progress
                  class="h-100"
                  :value="count"
                  :max="totalSubcategoryNumber"
                  variant="primary"
                />
              </b-col>
              <b-col cols="12">
                <hr class="mt-1 mb-0">
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-card
            title="User statistics"
            class="r-75 mt-3"
            body-class="p-3"
          >
            <hr class="mb-1">
            <b-row
              v-for="{ user_id, user, count } in userStats"
              :key="user_id"
              no-gutters
            >
              <b-col
                cols="auto"
              >
                <b-badge
                  class="stat-badge w-100 h-100 text-center"
                  variant="light"
                >
                  <font-awesome-icon
                    :icon="user_id == '__total' ? 'info' : 'user'"
                    style="width:18px;"
                  />
                </b-badge>
              </b-col>
              <b-col
                cols="5"
              >
                <b-badge
                  variant="primary"
                  class="stat-badge w-100 h-100"
                >
                  {{ user }}
                </b-badge>
              </b-col>
              <b-col
                class="px-1"
                cols="1"
              >
                <b-badge
                  variant="primary"
                  class="stat-badge w-100 px-1 text-center h-100"
                >
                  {{ count }}
                </b-badge>
              </b-col>
              <b-col>
                <b-progress
                  class="h-100"
                  :value="count"
                  :max="totalUserNumber"
                  variant="primary"
                />
              </b-col>
              <b-col cols="12">
                <hr class="mt-1 mb-0">
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </template>

    <b-modal
      ref="edit-modal"
      :title="editTitle"
      hide-footer
      @hide="resetEdit"
    >
      <template v-if="editIsNone">
        You cannot edit the "None" subcategory.
      </template>
      <template v-else>
        <b-input-group>
          <b-form-input
            v-model="renameTo"
            placeholder="Some name..."
          />
          <b-btn
            :disabled="editIsNone"
            variant="primary"
            style="width:80px"
            @click="doRename"
          >
            Rename
          </b-btn>
        </b-input-group>
        <b-input-group class="mt-3">
          <b-form-select
            v-model="mergeId"
            :options="mergeOptions"
          />
          <b-btn
            :disabled="!mergeId"
            variant="primary"
            style="width:80px"
            @click="doMerge"
          >
            Merge
          </b-btn>
        </b-input-group>
        <b-row class="mt-4">
          <b-col cols="3">
            <b-btn
              :disabled="!allowDelete"
              variant="danger"
              @click="doDelete(editId)"
            >
              Delete
            </b-btn>
          </b-col>
          <b-col>
            <div
              v-if="!allowDelete"
              style="font-size:small;color:red"
            >
              You must first re-label all datapoints associated with this category before you can
              delete it.
            </div>
          </b-col>
        </b-row>
        <b-row v-if="labelsExternalUsedByEditId.length">
          <b-col>
            <b-card
              class="mt-3 border"
              border-variant="warning"
            >
              <h4>Warning</h4>
              <p>
                The category is used in the following external data sources in the NLU section.
                Deleting or merging this category will delete those data sources (but not the
                NLU label). The below lists the relevant classifier-label combinations that use
                the category as a data source.
              </p>
              <b-table
                class="mt-3"
                hover
                :tbody-tr-attr="{ style: 'cursor:pointer' }"
                :items="labelsExternalUsedByEditId"
                :fields="['classifier_name', 'label_name']"
                @row-clicked="labelExternalClicked"
              />
            </b-card>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-modal
      ref="examples-modal"
      :title="examplesTitle"
      size="xl"
      hide-footer
      body-class="p-0"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(example, idx) in examples"
          :key="idx"
        >
          {{ example }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import axios from 'axios';
import TooltippedText from '@/components/TooltippedText.vue';
import endpoints from '@/js/urls';
import { truncateString } from '@/js/utils';

export default {
  name: 'DataExplorationStatisticsPage',
  components: {
    TooltippedText,
  },
  data() {
    return {
      dataLoaded: false,
      categoryStats: null,
      userStats: null,
      subcategoryStats: null,
      examples: [],
      examplesTitle: '',
      editTitle: '',
      editIsNone: false,
      renameTo: '',
      editId: null,
      mergeId: null,
      filters: {
        keyword: '',
        minLabelNumber: null,
        maxLabelNumber: null,
        structured: true,
      },
      totalCategoryNumber: null,
      totalSubcategoryNumber: null,
      totalUserNumber: null,

    };
  },
  computed: {
    ...mapState('dataExploration', ['datasets']),
    ...mapState('dataExploration/categories', ['categoryIds']),
    ...mapGetters('dataExploration/categories', ['nameOfId', 'getCategoryById']),
    ...mapState('nlu/labelExternal', ['labelsExternal']),
    ...mapGetters('userSettings', ['tooltipsOn']),
    datasetId() {
      return parseInt(this.$route.params.datasetId, 10);
    },
    allowDelete() {
      if (!this.editId) {
        return true;
      }
      const statsList = this.editIsMain ? this.categoryStats : this.subcategoryStats;
      const stats = statsList.find((x) => x.id === this.editId);
      return stats.count === 0;
    },
    mergeOptions() {
      const options = this.categoryIds
        .filter((id) => id !== this.editId)
        .filter((id) => this.getCategoryById(id).parentId !== this.editId)
        .map((id) => ({ text: this.nameOfId(id), value: id }));
      options.sort((a, b) => (a.text > b.text ? 1 : -1));
      options.unshift({
        text: 'Choose a category to merge into...',
        value: null,
      });
      return options;
    },
    filteredCategoryStats() {
      if (!this.filters.structured) {
        return this.applyFilters(this.categoryStats);
      }
      const filtered = this.applyFilters(this.categoryStats);
      this.applyFilters(this.subcategoryStats).forEach((element) => {
        if (!filtered.includes(this.getCategoryByName(element.parentName))) {
          filtered.push(this.getCategoryByName(element.parentName));
        }
      });
      filtered.sort((a, b) => b.count - a.count);
      return filtered;
    },
    filteredSubcategoryStats() {
      return this.applyFilters(this.subcategoryStats);
    },
    labelsExternalUsedByEditId() {
      return Object.values(this.labelsExternal).filter((x) => x.categories.includes(this.editId));
    },
  },
  async mounted() {
    this.loadStats();
  },
  methods: {
    ...mapActions('dataExploration/categories', ['deleteCategory']),
    ...mapActions('sidebar', ['showWarning']),
    truncateString,
    async loadStats() {
      this.dataLoaded = false;
      const config = {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        params: { dataset_id: this.datasetId },
      };
      const { data } = await axios.get(endpoints.dataExplorationStats, config);
      this.userStats = data.user_stats;
      this.userStats.unshift({
        user_id: '__total',
        user: 'Total',
        count: data.total,
      });
      this.categoryStats = data.category_stats;
      this.subcategoryStats = data.subcategory_stats;

      this.totalCategoryNumber = this.categoryStats.map((e) => e.count);
      this.totalCategoryNumber = this.totalCategoryNumber.reduce((a, b) => a + b, 0);

      this.totalSubcategoryNumber = this.subcategoryStats.map((e) => e.count);
      this.totalSubcategoryNumber = this.totalSubcategoryNumber.reduce((a, b) => a + b, 0);

      this.totalUserNumber = this.userStats.map((e) => e.user !== 'Total' && e.count);
      this.totalUserNumber = this.totalUserNumber.reduce((a, b) => a + b, 0);

      this.dataLoaded = true;
    },
    async showExamples(id, name, parentName) {
      const config = {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        params: { categoryID: id, isMain: parentName == null },
      };
      const { data } = await axios.get(endpoints.dataExplorationStatsExamples, config);
      this.examples = data.examples;
      this.examplesTitle = (parentName != null ? `${parentName} : ` : '') + name;
      this.$refs['examples-modal'].show();
    },
    async showEdit(id, name, isMain, isNone) {
      this.editIsMain = isMain;
      this.editIsNone = isNone;
      this.editId = id;
      this.renameTo = name;
      this.editTitle = `Edit "${name}"`;
      this.$refs['edit-modal'].show();
    },
    async doRename() {
      const data = { id: this.editId, name: this.renameTo };
      const config = {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      };
      await axios.put(endpoints.dataExplorationCategories, data, config);
      this.closeEdit();
    },
    async doMerge() {
      const data = { id: this.editId, mergeId: this.mergeId };
      const config = {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      };
      try {
        await axios.post(endpoints.dataExporationCategoryMerge, data, config);
      } catch (error) {
        if (error.response.status === 403) {
          this.showWarning({
            title: 'Permission denied',
            text: 'You do not have permission to change one or more classifiers pointing to this category.',
            variant: 'warning',
          });
        }
      }
      this.closeEdit();
    },
    async doDelete(categoryId) {
      await this.deleteCategory({ categoryId });
      this.closeEdit();
    },
    closeEdit() {
      this.$refs['edit-modal'].hide();
      this.resetEdit();
      this.loadStats();
    },
    resetEdit() {
      this.mergeId = null;
      this.editId = null;
    },
    applyFilters(inputData) {
      let filteredData = inputData;
      if (this.filters.minLabelNumber) {
        filteredData = filteredData.filter((stat) => stat.count >= this.filters.minLabelNumber);
      }
      if (this.filters.maxLabelNumber) {
        filteredData = filteredData.filter((stat) => stat.count <= this.filters.maxLabelNumber);
      }
      if (this.filters.keyword) {
        filteredData = filteredData.filter((stat) => {
          const combinedName = `${stat.parentName}:${stat.name}`.replace(/ /g, '').toLowerCase();
          if (combinedName.includes(this.filters.keyword.replace(/ /g, '').toLowerCase())) {
            return stat;
          }
          return false;
        });
      }
      return filteredData;
    },
    getSubcategories(category) {
      return this.subcategoryStats.filter((stat) => {
        const combinedName = `${stat.parentName}:${stat.name}`.replace(/ /g, '').toLowerCase();
        if (combinedName.includes(this.filters.keyword.replace(/ /g, '').toLowerCase())
         && this.checkNumberFilters(stat.count)) {
          return stat.parentName === category;
        }
        return false;
      });
    },
    getCategoryByName(name) {
      return this.categoryStats.find((element) => element.name === name);
    },
    checkNumberFilters(count) {
      let applies = true;
      if (this.filters.minLabelNumber && count < this.filters.minLabelNumber) {
        applies = false;
      }
      if (this.filters.maxLabelNumber && count > this.filters.maxLabelNumber) {
        applies = false;
      }
      return applies;
    },

    excludeCount(count) {
      let exclude = false;
      if (this.filters.structured) {
        if (this.filters.maxLabelNumber && this.filters.maxLabelNumber < count) {
          exclude = true;
        }
        if (this.filters.minLabelNumber && this.filters.minLabelNumber > count) {
          exclude = true;
        }
      }
      return exclude;
    },
    excludeName(name) {
      let exclude = false;
      if (this.filters.structured) {
        if (!name.replace(/ /g, '').toLowerCase().includes(this.filters.keyword.replace(/ /g, '').toLowerCase())) {
          exclude = true;
        }
      }
      return exclude;
    },
    labelExternalClicked(item) {
      this.$router.push({
        name: 'nlu-label-single-overview',
        params: { modelId: item.classifier_id, labelId: item.label_id },
      });
    },
  },
};
</script>

<style scoped>
.stat-badge{
  font-size: 1rem;
  text-align: left;
}
.badge, .progress{
border-radius: 0 !important;
}
.progress{
  background-color: #DEE2E6;
}
</style>
