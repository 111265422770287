<template>
  <div>
    <b-modal
      id="modal-dataset"
      :title="isEdit ? 'Update dataset' : 'Create dataset'"
      :ok-title="isEdit ? 'Update' : 'Create dataset'"
      :ok-disabled="$v.modalDataset.$invalid"
      @ok="editDatasetOk"
    >
      <b-form>
        <b-form-group
          label="Name"
          label-for="inputDatasetName"
        >
          <b-form-input
            id="inputDatasetName"
            v-model="modalDataset.name"
            type="text"
            class="form-control"
            placeholder="Name"
            :state="$v.modalDataset.name.$invalid ? false : null"
            aria-describedby="datasetNameFeedback"
            @keydown.native.enter="handleEnterEditDataset"
          />
          <b-form-invalid-feedback id="datasetNameFeedback">
            <div v-if="!$v.modalDataset.name.required">
              Your dataset must have a name.
            </div>
            <div v-if="!$v.modalDataset.name.uniqueName">
              Your dataset name must be unique.
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label="Description"
          label-for="inputDatasetDescription"
        >
          <b-form-input
            id="inputDatasetDescription"
            v-model="modalDataset.description"
            type="text"
            class="form-control"
            placeholder="Description"
            :state="$v.modalDataset.description.$invalid ? false : null"
            @keydown.native.enter="handleEnterEditDataset"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-card
      title="Datasets"
      class="r-75"
      body-class="p-3"
    >
      <p class="font-weight-bold">
        On this page you can manage data exploration datasets and their labeled data for all your
        bots.
      </p>
      <p>
        To get started, click/select a dataset from the menu below.
      </p>
    </b-card>

    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <div class="d-flex align-items-center mb-2 justify-content-between">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="numberOfRows"
          :per-page="pagination.perPage"
          size="sm"
          class="mb-0"
        />
        <b-form-checkbox
          v-model="showTranslations"
          switch
        >
          Show translation datasets
        </b-form-checkbox>
      </div>
      <b-table
        :items="items"
        :fields="fields"
        :tbody-tr-attr="{ style: 'cursor:pointer' }"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        hover
        empty-text="No data exploration datasets found"
        show-empty
        @row-clicked="({ id, permitted }) => clickRow(id, permitted)"
      >
        <template #cell(permitted)="row">
          <font-awesome-icon
            v-if="row.item.permitted"
            icon="check"
          />
          <font-awesome-icon
            v-else
            icon="lock"
          />
        </template>
        <template #cell(actions)="{ item: { id } }">
          <b-button
            :disabled="isDeleting(id)"
            size="sm"
            variant="outline-primary"
            title="Edit"
            class="mr-2"
            @click.stop="showEditDatasetModal(id)"
          >
            <font-awesome-icon icon="edit" />
          </b-button>

          <b-button
            :disabled="isDeleting(id)"
            size="sm"
            variant="outline-danger"
            title="Delete"
            @click.stop="promptDeleteDataset(id)"
          >
            <b-spinner
              v-if="isDeleting(id)"
              small
            />
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
      </b-table>
      <div class="text-center">
        <b-button
          variant="success"
          @click.stop="showAddDatasetModal()"
        >
          <font-awesome-icon icon="plus" /> Create dataset
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'DataExplorationOverview',
  mixins: [validationMixin],
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
      },
      orgName: null,
      modalDataset: {
        name: '',
        description: '',
      },
      editId: null,
      isEdit: false,
      showTranslations: false,
    };
  },
  computed: {
    ...mapState('dataExploration', ['datasets']),
    ...mapGetters('dataExploration', [
      'getDatasetByName',
      'isDeleting',
    ]),
    fields() {
      return [
        {
          key: 'permitted',
          label: 'Permission',
          sortable: false,
          class: 'text-center permission-col',
        },
        'name',
        'description',
        'data_count',
        'label_count',
        {
          key: 'actions',
          label: '',
          sortable: false,
          class: 'text-right',
        },
      ];
    },
    items() {
      const items = Object.values(this.datasets);
      if (!this.showTranslations) {
        return items.filter((x) => !x.has_translations);
      }
      return items;
    },
    numberOfRows() {
      return this.items.length;
    },
  },
  methods: {
    ...mapActions('dataExploration', [
      'deleteDataset',
      'createDataset',
      'editDataset',
    ]),
    ...mapActions('sidebar', ['showWarning']),
    clickRow(datasetId, permitted) {
      if (permitted) {
        if (!this.isDeleting(datasetId)) {
          this.goToDataset(datasetId);
        }
      } else {
        this.showWarning({
          title: 'Permission denied',
          text: 'You do not have permission to open this dataset.',
          variant: 'warning',
        });
      }
    },
    showEditDatasetModal(datasetId) {
      this.editId = datasetId;
      this.modalDataset.name = this.datasets[datasetId].name;
      this.modalDataset.description = this.datasets[datasetId].description;
      this.orgName = this.modalDataset.name;
      this.isEdit = true;
      this.$bvModal.show('modal-dataset');
    },
    async promptDeleteDataset(datasetId) {
      const question = 'Are you sure you want to delete this dataset?'
        + ' This will remove the dataset and all its associated labeled data.';
      const options = {
        okTitle: 'Delete',
        okVariant: 'danger',
        centered: true,
        title: 'Delete dataset',
      };
      if (await this.$bvModal.msgBoxConfirm(question, options)) {
        this.deleteDataset({ datasetId });
      }
    },
    showAddDatasetModal() {
      this.isEdit = false;
      this.modalDataset.name = '';
      this.modalDataset.description = '';
      this.$bvModal.show('modal-dataset');
    },
    goToDataset(datasetId) {
      this.$router.push({ name: 'dataExplorationDataset', params: { datasetId } });
    },
    editDatasetOk() {
      if (this.$v.modalDataset.$invalid) {
        return false;
      }
      if (this.isEdit) {
        // Edit dataset using vuex
        this.editDataset({ datasetId: this.editId, dataset: this.modalDataset });
      } else {
        // Create dataset using vuex
        this.createDataset({ dataset: this.modalDataset });
      }
      return true;
    },
    handleEnterEditDataset() {
      if (this.editDatasetOk()) {
        this.$bvModal.hide('modal-dataset');
      }
    },
  },
  validations: {
    modalDataset: {
      name: {
        required,
        uniqueName(value) {
          if (this.isEdit && value === this.orgName) {
            return true;
          }
          const foundDataset = this.getDatasetByName(value);
          return foundDataset === undefined;
        },
      },
      description: {},
    },
  },
};

</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
