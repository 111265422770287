import Vue from 'vue';
import update from 'immutability-helper';
import { nodeTypes } from '@/js/constants';
import {
  ACTION, CHAT_ACTION, CONTROL_FLOW, ENCRYPT, SET_VARIABLE,
} from '@/js/activity';

export const makeInitialNodeState = () => ({
  nodeIds: [],
  nodes: {},
});

// This cannot be in getters as mutations cannot access getters
function getSpecialNodes(state) {
  // Subflows do not define special-nodes (FallBack, Error, etc.)
  if (state.config.type === 'subflow') {
    return [];
  }
  return [
    state.errorNode, state.fallbackNode, state.inactiveNode,
    state.initNode, state.finalNode, state.elaborationNode,
  ].filter((x) => !!x);
}
function findAllPaths(sourceNodeId, targetNodeId, nodes) {
  if (sourceNodeId === targetNodeId) {
    return { paths: sourceNodeId, unfinished: false };
  }
  const paths = [];
  let unfinished = false;
  let counter = 0;
  function rec(path) {
    if (counter < 200 || !paths.length) {
      counter++;
      const nodeId = path[path.length - 1];
      const node = nodes[nodeId];
      const childrenIds = node.children;
      for (const childId of childrenIds) {
        if (childId === targetNodeId) {
          path.push(childId);
          paths.push(path.slice());
          path.pop();
        } else if (!path.includes(childId)) {
          path.push(childId);
          rec(path);
          path.pop();
        }
      }
    } else {
      unfinished = true;
    }
  }
  rec([sourceNodeId]);
  return { paths, unfinished };
}

// This really should be in activities, but cannot because of import cycle.
export function getVariableNames(action) {
  if (!action) {
    return [];
  }
  if (action.type === ACTION) {
    return [action.target];
  }
  if (action.type === SET_VARIABLE) {
    return [action.key];
  }
  if (action.type === CHAT_ACTION) {
    return [action.target];
  }
  if (action.type === ENCRYPT) {
    return [action.target];
  }
  if (action.type === CONTROL_FLOW) {
    if (action.iterable) {
      return [action.iterable];
    }
  }
  return [];
}

function findAllVariableNames(sourceNodeId, targetNodeId, nodes) {
  const paths = findAllPaths(sourceNodeId, targetNodeId, nodes).paths;

  const variables = {};

  for (const global of ['thisMessage', 'thisMessageVerbatim', 'transcript']) {
    variables[global] = {
      name: global,
      dist: -1,
      where: new Set(['Global']),
    };
  }

  for (const path of paths) {
    let dist = path.length;
    for (const nodeId of path) {
      dist--;

      const node = nodes[nodeId];
      if (!node || !node.activities) {
        continue;
      }
      for (const activity of Object.values(node.activities)) {
        const varNames = getVariableNames(activity);
        for (const varName of varNames) {
          const info = variables[varName];
          if (!info) {
            variables[varName] = {
              name: varName,
              where: new Set([node.name]),
              dist,
            };
          } else {
            info.where.add(node.name);
            info.dist = Math.min(info.dist, dist);
          }
        }
      }
    }
  }

  return variables;
}

export const nodesGetters = {
  // eslint-disable-next-line arrow-body-style
  isSpecialNode: (state) => (n) => {
    return getSpecialNodes(state).includes(n);
  },
  isRoot: (state) => (id) => id === state.root,
  rootNode: (state) => state.nodes[state.root],
  allNormalNodes: (state) => state.nodes,
  nodeById: (state) => (id) => {
    if (id === 'error') {
      return state.errorNode;
    }
    if (id === 'fallback') {
      return state.fallbackNode;
    }
    if (id === 'inactive') {
      return state.inactiveNode;
    }
    if (id === 'init') {
      return state.initNode;
    }
    if (id === 'final') {
      return state.finalNode;
    }
    if (id === 'elaboration') {
      return state.elaborationNode;
    }
    return state.nodes[id];
  },
  nameOfId: (state, getters, _, rootGetters) => (nodeId, subflowId) => {
    let node = null;
    if (subflowId !== undefined && subflowId !== null) {
      // We're looking for a node in a subflow
      const subflow = rootGetters['botManipulation/getSubFlows'].find((x) => x.id === subflowId);
      if (subflow === undefined) {
        return '(subflow not found)(unknown node)'; // Best effort instead of crashing
      }
      node = subflow.nodes[nodeId];
      if (node) {
        return `${subflow.config.name} - ${node.name}`;
      }
    } else {
      node = getters.nodeById(nodeId);
      if (node) {
        return node.name;
      }
    }
    return '(Deleted node)';
  },
  nodeByName: (state) => (name) => {
    const allNodes = Object.values(state.nodes).concat(getSpecialNodes(state));
    const namedNodes = allNodes.find((node) => node.name === name);
    return namedNodes === undefined ? null : namedNodes;
  },
  nodeNames: (state) => Object.values(state.nodes).map((n) => n.name).sort(),
  countNumberOfNames: (state) => (name) => {
    const allNodes = Object.values(state.nodes).concat(getSpecialNodes(state));
    return allNodes.filter((n) => n.name === name).length;
  },
  // eslint-disable-next-line arrow-body-style
  isNameUsed: (state, getters) => (name) => {
    // Use isNodeNameUnique if this is from an existing node
    return name ? getters.countNumberOfNames(name) > 0 : false;
  },
  isNodeNameUnique: (state, getters) => (id) => {
    // Checks that the node name exists exactly once (the id itself)
    const node = getters.nodeById(id);
    if (!node) {
      return false;
    }
    // Assert if count === 0?
    return getters.countNumberOfNames(node.name) === 1;
  },
  // TODO: Rename this function, since it doesn't get only the children but all descendants.
  getChildrenNodes: (state, getters) => (initialNodeId, forbiddenNodeIds) => {
    const allDescendents = [];
    const queue = [];
    const visitedNodes = {};
    for (const nodeId of forbiddenNodeIds) {
      visitedNodes[nodeId] = true;
    }
    queue.push(initialNodeId);
    visitedNodes[initialNodeId] = true;
    while (queue.length !== 0) {
      const currentNodeId = queue.shift();
      allDescendents.push(currentNodeId);
      const node = getters.nodeById(currentNodeId);
      for (const nodeId of node.children) {
        if (visitedNodes[nodeId] === undefined) {
          queue.push(nodeId);
          visitedNodes[nodeId] = true;
        }
      }
    }
    return allDescendents;
  },
  specialNodes: getSpecialNodes,
  nodesAsList(state) {
    return Object.values(state.nodes);
  },
  allNodesAsList(state) {
    return Object.values(state.nodes).concat(getSpecialNodes(state));
  },
  /**
   * Returns all nodes (special nodes included) that contain one or more replies marked as draft
   */
  nodesWithDraftResponses(state, getters) {
    const allNodes = Object.values(state.nodes).concat(getters.specialNodes);
    const nodesWithDraftReplies = [];
    for (const node of allNodes) {
      if (getters.hasDraftResponsesInNode(node.id)) {
        nodesWithDraftReplies.push(node);
      }
    }
    return nodesWithDraftReplies;
  },
  getCustomFallbackNodes(state) {
    const fallbackNodes = [];
    for (const node of Object.values(state.nodes)) {
      if (node.options.usesCustomFallbackNode && node.options.customFallbackNodeId) {
        fallbackNodes.push(node.options.customFallbackNodeId);
      }
    }
    return fallbackNodes;
  },
  getAuthFallbackNodes(state) {
    const fallbackNodes = [];
    for (const node of Object.values(state.nodes)) {
      if (node.fallbackAuth) {
        fallbackNodes.push(node.fallbackAuth);
      }
    }
    return fallbackNodes;
  },
  getFallbackUsageOfNode: (state, getters) => (id) => getters.allNodesAsList.filter(
    (node) => node.options.customFallbackNodeId === id,
  ),
  getAuthFallbackUsageOfNode: (state, getters) => (id) => getters.allNodesAsList.filter(
    (node) => node.fallbackAuth === id,
  ),
  getAllPaths: (state) => (id) => findAllPaths(
    state.root, id, state.nodes,
  ),
  getAllVariableNames: (state) => (id) => findAllVariableNames(
    state.root, id, state.nodes,
  ),
};

// These are getters that relate to a single node. They will be added to the set of all getters
// by transforming the getter into a function that takes the id and returns the value for the given
// node.
// In the code below the `state` argument will refer to the state of a single node, and not the
// state of the whole module.
export const singleNodeGetters = {
  getNodeActions: (state) => state.actions,
  getUseClfToMatch: (state) => state.match.useClfToMatch,
  getUseKeywordsToMatch: (state) => state.match.useKeywordsToMatch,
  getNLUModelName: (state) => state.match.nluModel.name,
  getNLULabel: (state) => state.match.nluModel.label,
  getNodeExamples: (state) => state.match.examples,
  getNodeKeywords: (state) => state.match.keywords,
  getAuthFallbackNodeId: (state) => state.fallbackAuth,
  isDisabled: (state) => state.options.disable,
  getUsesCustomFallbackNode: (state) => (
    state.options.usesCustomFallbackNode !== undefined
      ? state.options.usesCustomFallbackNode : false),
  getCustomFallbackNodeId: (state) => state.options.customFallbackNodeId || null,
  isGreetNode: (state) => state.id === 'greet',
  hasMatchingConfigured: (state) => {
    const hasMatchingWeight = !!state.options.matchingWeight;
    const hasExamplesSet = state.match.examples.length > 0;
    const hasKeywordsSet = state.match.keywords.length > 0;
    const hasRequirements = state.requirements.length > 0;
    const nluConfiguration = state.match.nluModel;
    const hasNluConfigured = nluConfiguration.name !== null && nluConfiguration.label !== null;
    return hasExamplesSet
      || hasNluConfigured || hasRequirements || hasMatchingWeight || hasKeywordsSet;
  },
  getMatchingWeight: (state) => state.options.matchingWeight || 1,
  getDisplayNames: (state) => state.options.displayNames || {},
  getDescriptions: (state) => state.options.descriptions || {},
  getAutoThreshold: (state) => state.options.autoThreshold || 90,
  getLimitOptions: (state) => state.options.limitOptions,
  getShowThreshold: (state) => state.options.showThreshold || 10,
  getSortChoices: (state) => (state.options.sortChoices == null ? true : state.options.sortChoices),
  getResponseMode: (state) => state.options.responseMode || 'require',
  getNodeType: (state) => state.options.nodeType || nodeTypes.SIMPLE,
  isStrictMpc: (state) => state.options.isStrictMpc || false,
  getSmallTalkThreshold: (state) => state.options.smallTalkThreshold || null,
  getGlobalThreshold: (state) => state.options.globalThreshold || null,
  isSmartNode: (state) => state.options.nodeType === nodeTypes.MULTIPLE_CHOICE,
  isSubflowNode: (state) => state.options.nodeType === nodeTypes.SUBFLOW,
  isQANode: (state) => state.options.nodeType === nodeTypes.QA,
  isSmallTalkNode: (state) => state.options.nodeType === nodeTypes.SMALLTALK,
  getOtherShow: (state) => (state.options.otherShow == null ? true : state.options.otherShow),
  getOtherText: (state) => state.options.otherText || null,
  getOtherDescription: (state) => state.options.otherDescription || null,
  getRequiresAuth: (state) => Boolean(state.requiresAuth),
  getDirectMatchOnly: (state) => state.options.directMatchOnly || {},
  getDirectMatchOnlyForId: (state) => (id) => {
    const directMatchOnly = state.options.directMatchOnly || {};
    return directMatchOnly[id] == null ? false : directMatchOnly[id];
  },
  getDisplayNameForId: (state) => (id) => {
    const displayNames = state.options.displayNames || {};
    return displayNames[id] || '';
  },
  getDescriptionForId: (state) => (id) => {
    const descriptions = state.options.descriptions || {};
    return descriptions[id] || '';
  },
  getAutoThresholdValueForId: (state) => (id) => {
    const autoThresholds = state.options.autoThresholds || {};
    const autoThreshold = autoThresholds[id] || {};
    return autoThreshold.threshold || null;
  },
  getAutoThresholdEnabledForId: (state) => (id) => {
    const autoThresholds = state.options.autoThresholds || {};
    const autoThreshold = autoThresholds[id] || {};
    return !!autoThreshold.enabled;
  },
  getOptionsQuery: (state) => state.options.optionsQuery || null,
  getConfirmQuery: (state) => state.options.confirmQuery || null,
  getIsOutgoing: (state) => state.options.outgoing || false,
  getResponseApproved: (state) => state.options.responseApproved,
};

// Takes a getter that retrives information about a single node and transforms it into a getter
// that is a function that takes an id and retrieves information about the node corresponding to
// the id.
function convertGetter(getter) {
  return (state, getters) => (id) => {
    const node = getters.nodeById(id);
    return getter(node);
  };
}

for (const [name, fn] of Object.entries(singleNodeGetters)) {
  nodesGetters[name] = convertGetter(fn);
}

/**
 * Given two arrays we return an array that contains all the values from the newArray, but
 * where all the values from oldArray that are also in newArray are retained in the same order
 * as previously. The values that are in newArray but not in oldArray are last in the returned
 * array.
 *
 * @param oldArray Array with the old values
 * @param newArray Array with the new values.
 * @returns {[]} The array as described.
 */
function tryToKeepOrder(oldArray, newArray) {
  const arr = [];
  const count = {};
  for (const val of newArray) {
    if (count[val] === undefined) {
      count[val] = 0;
    }
    count[val] += 1;
  }
  // First we insert all the values from the oldArray that are also in newArray.
  // Then we insert the remaining values.
  for (const val of oldArray.concat(newArray)) {
    if (count[val] !== undefined && count[val] > 0) {
      count[val] -= 1;
      arr.push(val);
    }
  }
  return arr;
}

export const nodeMutations = {
  setNodes(state, { nodes, nodeIds }) {
    Vue.set(state, 'nodes', nodes);
    state.nodeIds = nodeIds;
  },

  removeNodes(state, { nodeIdsToDelete }) {
    const nodeIdsToDeleteSet = new Set(nodeIdsToDelete);
    state.nodeIds = state.nodeIds.filter((id) => !nodeIdsToDeleteSet.has(id));
    for (const nodeId of nodeIdsToDelete) {
      Vue.delete(state.nodes, nodeId);
    }
    // iterate through nodes in bot and remove references
    for (const node of Object.values(state.nodes).concat(getSpecialNodes(state))) {
      node.children = node.children.filter((id) => !nodeIdsToDeleteSet.has(id));
      node.preds = node.preds.filter((id) => !nodeIdsToDeleteSet.has(id));
      // remove fallback reference
      if (node.options.usesCustomFallbackNode
        && nodeIdsToDeleteSet.has(node.options.customFallbackNodeId)) {
        node.options.usesCustomFallbackNode = false;
        node.options.customFallbackNodeId = null;
      }
      // remove auth fallback reference
      if (node.fallbackAuth && nodeIdsToDeleteSet.has(node.fallbackAuth)) {
        node.fallbackAuth = null;
      }
      // remove from subflowmap
      const outgoing = node.subFlowMap.outgoing;
      if (outgoing) {
        for (const outMap of Object.values(outgoing)) {
          outMap.childrenIds = outMap.childrenIds
            .filter((id) => !nodeIdsToDeleteSet.has(id));
        }
      }
    }
  },
  recomputeParents(state) {
    const nodes = Object.values(state.nodes).concat(getSpecialNodes(state));
    const parents = {};
    for (const node of nodes) {
      parents[node.id] = [];
    }
    for (const node of nodes) {
      if (node.children) {
        for (const child of node.children) {
          parents[child].push(node.id);
        }
      }
    }
    for (const node of nodes) {
      node.preds = tryToKeepOrder(node.preds, parents[node.id]);
    }
  },
  recomputeChildren(state) {
    const nodes = Object.values(state.nodes).concat(getSpecialNodes(state));
    const children = {};
    for (const node of nodes) {
      children[node.id] = [];
    }
    for (const node of nodes) {
      if (node.preds) {
        for (const parent of node.preds) {
          children[parent].push(node.id);
        }
      }
    }
    for (const node of nodes) {
      node.children = tryToKeepOrder(node.children, children[node.id]);
    }
  },

};

// These are mutations that relate to a single node. They will be added to the set of all mutations
// by transforming the mutation into another mutation that first extracts the node using the id
// of the payload and then applies the original mutation.
// In the code below the `state` argument will refer to the state of a single node, and not the
// state of the whole module.
const singleNodeMutations = {
  // TODO: I think that this function can be made nicer.
  updateActiveNodeCommit(state, { attribute, value }) {
    /** payload as object containing value and attribute name */
    const validAttributes = ['id', 'name', 'preds', 'children', 'match', 'subflow', 'comment'];
    if (validAttributes.includes(attribute)) {
      state[attribute] = value;
    } else {
      throw new Error(`Invalid attribute ${attribute}`);
    }
  },
  setNodeExamplesWithHelper(state, { examples }) {
    Object.assign(state.match, update(state.match, { examples }));
  },
  setNodeKeywordsWithHelper(state, { keywords }) {
    Object.assign(state.match, update(state.match, { keywords }));
  },
  setAuthFallbackNodeId(state, { fallbackNodeId }) {
    Vue.set(state, 'fallbackAuth', fallbackNodeId);
  },
  /**
   * Update a certain property of subflowMap.
   * keys is a list of string keys used to specify what to update in subflowmap.
   * For instance say you want to update input.somekey in subflowmap: in that case provide
   * ['input', 'somekey'] for argument keys.
   */
  updateSubFlowMap(state, { keys, value }) {
    let d = state.subFlowMap;
    for (const key of keys.slice(0, -1)) {
      // Traverse into dictionary
      d = d[key];
    }
    d[keys.pop()] = value;
  },
  removeKeyInSubFlowMap(state, { keys }) {
    let d = state.subFlowMap;
    for (const key of keys.slice(0, -1)) {
      // Traverse into dictionary
      d = d[key];
    }
    delete d[keys.pop()];
  },
  updateOutgoing(state, { value }) {
    Vue.set(state.subFlowMap, 'outgoing', value);
  },
  toggleIsDisabled(state) {
    Vue.set(state.options, 'disable', !state.options.disable);
  },
  setIsDisabled(state, { isDisabled }) {
    Vue.set(state.options, 'disable', isDisabled);
  },
  setUsesCustomFallbackNode(state, { usesCustomFallbackNode }) {
    Vue.set(state.options, 'usesCustomFallbackNode', usesCustomFallbackNode);
  },
  setCustomFallbackNodeId(state, { fallbackNodeId }) {
    Vue.set(state.options, 'customFallbackNodeId', fallbackNodeId);
  },
  setAllowTraceback(state, { allowTraceback }) {
    Vue.set(state.options, 'allowTraceback', allowTraceback);
  },
  setTracebackThreshold(state, { tracebackThreshold }) {
    Vue.set(state.options, 'tracebackThreshold', tracebackThreshold);
  },
  setMatchingWeight(state, { matchingWeight }) {
    Vue.set(state.options, 'matchingWeight', matchingWeight);
  },
  setDisplayNames(state, { displayNames }) {
    Vue.set(state.options, 'displayNames', displayNames);
  },
  setAutoThreshold(state, { autoThreshold }) {
    Vue.set(state.options, 'autoThreshold', autoThreshold);
  },
  setLimitOptions(state, { limitOptions }) {
    Vue.set(state.options, 'limitOptions', limitOptions);
  },
  setShowThreshold(state, { showThreshold }) {
    Vue.set(state.options, 'showThreshold', showThreshold);
  },
  setSortChoices(state, { sortChoices }) {
    Vue.set(state.options, 'sortChoices', sortChoices);
  },
  setResponseMode(state, { responseMode }) {
    Vue.set(state.options, 'responseMode', responseMode);
  },
  setNodeType(state, { nodeType, isStrictMpc }) {
    Vue.set(state.options, 'nodeType', nodeType);
    Vue.set(state.options, 'isStrictMpc', isStrictMpc);
  },
  setSmallTalkThreshold(state, { smallTalkThreshold }) {
    Vue.set(state.options, 'smallTalkThreshold', Number(smallTalkThreshold));
  },
  setGlobalThreshold(state, { globalThreshold }) {
    Vue.set(state.options, 'globalThreshold', Number(globalThreshold));
  },
  setOtherShow(state, { otherShow }) {
    Vue.set(state.options, 'otherShow', otherShow);
  },
  setOtherText(state, { otherText }) {
    Vue.set(state.options, 'otherText', otherText);
    Vue.set(state.options, 'responseApproved', false);
  },
  setOtherDescription(state, { otherDescription }) {
    Vue.set(state.options, 'otherDescription', otherDescription);
    Vue.set(state.options, 'responseApproved', false);
  },
  setRequiresAuth(state, { requiresAuth }) {
    Vue.set(state, 'requiresAuth', requiresAuth);
  },
  setUseClfToMatch(state, { useClfToMatch }) {
    Vue.set(state.match, 'useClfToMatch', useClfToMatch);
  },
  setUseKeywordsToMatch(state, { useKeywordsToMatch }) {
    Vue.set(state.match, 'useKeywordsToMatch', useKeywordsToMatch);
  },
  setNLUModelName(state, { nluModelName }) {
    Vue.set(state.match.nluModel, 'name', nluModelName);
  },
  setNLULabel(state, { nluLabel }) {
    Vue.set(state.match.nluModel, 'label', nluLabel);
  },
  updateRequirementsViaSpec(state, { updateSpec }) {
    Object.assign(state, update(state, { requirements: updateSpec }));
  },
  setDirectMatchOnly(state, { directMatchOnly }) {
    Vue.set(state.options, 'directMatchOnly', directMatchOnly);
  },
  setDisplayNameForId(state, { childId, displayName }) {
    // TODO: Is this necessary?
    if (!state.options.displayNames) {
      Vue.set(state.options, 'displayNames', {});
    }
    if (displayName === '') {
      Vue.delete(state.options.displayNames, childId);
    } else {
      Vue.set(state.options.displayNames, childId, displayName);
    }
  },
  setDescriptionForId(state, { childId, description }) {
    if (!state.options.descriptions) {
      Vue.set(state.options, 'descriptions', {});
    }
    if (description === '') {
      Vue.delete(state.options.descriptions, childId);
    } else {
      Vue.set(state.options.descriptions, childId, description);
    }
  },
  setDirectMatchOnlyForId(state, { childId, isDirectMatch }) {
    // TODO: Is this necessary?
    if (!state.options.directMatchOnly) {
      Vue.set(state.options, 'directMatchOnly', {});
    }
    Vue.set(state.options.directMatchOnly, childId, isDirectMatch);
  },
  setOptionsQuery(state, { optionsQuery }) {
    Vue.set(state.options, 'optionsQuery', optionsQuery);
    Vue.set(state.options, 'responseApproved', false);
  },
  setConfirmQuery(state, { confirmQuery }) {
    Vue.set(state.options, 'confirmQuery', confirmQuery);
    Vue.set(state.options, 'responseApproved', false);
  },
  setResponseApproved(state, { approved }) {
    Vue.set(state.options, 'responseApproved', !!approved);
  },
  setIsOutgoing(state, { isOutgoing }) {
    Vue.set(state.options, 'outgoing', isOutgoing);
  },
  setAutoThresholdValueForId(state, { childId, threshold }) {
    if (!state.options.autoThresholds) {
      Vue.set(state.options, 'autoThresholds', {});
    }
    if (!state.options.autoThresholds[childId]) {
      Vue.set(state.options.autoThresholds, childId, {});
    }
    Vue.set(state.options.autoThresholds[childId], 'threshold', threshold);
  },
  setAutoThresholdStateForId(state, { childId, value }) {
    if (!state.options.autoThresholds) {
      Vue.set(state.options, 'autoThresholds', {});
    }
    if (!state.options.autoThresholds[childId]) {
      Vue.set(state.options.autoThresholds, childId, {});
    }
    Vue.set(state.options.autoThresholds[childId], 'enabled', value);
  },
};

// Takes a mutation that transforms a single node and transforms it into a mutation that is given
// the id of the node to mutate.
function convertMutation(mutation) {
  return (state, payload) => {
    const node = nodesGetters.nodeById(state)(payload.id);
    return mutation(node, payload);
  };
}

for (const [name, fn] of Object.entries(singleNodeMutations)) {
  nodeMutations[name] = convertMutation(fn);
}

export const nodeActions = {
  removeNode({ commit, dispatch }, { id }) {
    commit('removeNodes', { nodeIdsToDelete: [id] });
    dispatch('treeView/normalize', undefined, { root: true });
    dispatch('graphView/normalize', undefined, { root: true });
  },

  // TODO: Change name as it is no longer the active node, but any node.
  updateActiveNode({ commit, dispatch }, payload) {
    commit('updateActiveNodeCommit', payload);
    if (payload.attribute === 'preds') {
      dispatch('botManipulation/handleParentChange', {}, { root: true });
    } else if (payload.attribute === 'children') {
      dispatch('botManipulation/handleChildChange', {}, { root: true });
    }
  },

  // TODO: Seems weird to call an action from an action in this case.
  //  Despite the name, `updateNames`, it only updates a single name.
  updateNames({ dispatch }, { value, id }) {
    dispatch('updateActiveNode', { value, id, attribute: 'name' });
  },
  updateSubFlowMap({ commit }, { keys, value, id }) {
    commit('updateSubFlowMap', { keys, value, id });
  },
  removeKeyIfNullOrUpdateSubflowMap({ commit }, { keys, value, id }) {
    if (value === null || value === '') {
      commit('removeKeyInSubFlowMap', { keys, id });
      return;
    }
    commit('updateSubFlowMap', { keys, value, id });
  },
};
