<template>
  <div>
    <b-form-group
      label="Bot"
      label-for="bot-form"
    >
      <b-form-select
        id="bot-form"
        v-model="botId"
        :state="!$v.botId.$invalid"
        :options="botOptions"
        :disabled="disabled"
        @input="selectBotId"
      />
      <b-form-invalid-feedback>
        You need to select a bot.
      </b-form-invalid-feedback>
    </b-form-group>

    <div v-if="botId">
      <b-form-group
        label="Label"
        label-for="node-form"
        aria-describedby="nodeFeedback"
      >
        <b-form-select
          id="node-form"
          v-model="childNodeId"
          :disabled="disabled"
          :state="disabled || showLabelSelector ? null : !$v.childNodeId.$invalid"
          :options="options.labels"
        />
        <b-form-invalid-feedback id="nodeFeedback">
          <div v-if="!$v.childNodeId.required">
            You need to select a label.
          </div>
          <div v-if="!$v.childNodeId.sourcesLoaded">
            Label sources are still being loaded. You cannot add a new
            label before loading has finished.
          </div>
          <div v-if="!$v.childNodeId.unique">
            This combination is already in use.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="showLabelSelector || (!showLabelSelector && childNodeId)"
        label="Visited (or labeled) parent"
        label-for="parent-node-form"
        description="To limit the data, select a single parent as source; otherwise, include data from all parents."
      >
        <b-form-select
          id="parent-node-form"
          v-model="parentNodeId"
          :disabled="disabled"
          :options="options.parents"
        />
      </b-form-group>
      <b-form-group
        v-if="showLabelSelector"
        label="Visited node"
        label-for="label-form"
        description="Filter using the labels that have been made on the 'training' page
                   of the chosen bot."
      >
        <b-form-select
          id="label-form"
          v-model="actualLabelName"
          :disabled="disabled"
          :options="options.children"
        />
      </b-form-group>
    </div>
    <slot name="extraInput" />
    <b-btn
      v-if="showAddButton"
      class="mt-3"
      :disabled="addButtonDisabled"
      variant="primary"
      @click="addButtonClicked"
    >
      <font-awesome-icon
        v-if="loading"
        icon="spinner"
        spin
      />
      Add
    </b-btn>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { partition } from 'lodash';
import endpoints from '@/js/urls';
import { NEUTRAL_NODE_ID } from '@/js/constants';

export default {
  name: 'BotNodeSelector',
  mixins: [validationMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    // false == NLU page, true == data exploration page
    showLabelSelector: {
      type: Boolean,
      default: false,
    },
    language: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      nodeLabels: {}, // Data from bot
      historicData: {
        labels: [], // IDs
        parents: [], // IDs
        children: [], // Names (for now)
      },
    };
  },
  computed: {
    ...mapGetters('botManipulation', ['botsNotHidden']),
    ...mapGetters('nlu/classifier', ['labels']),
    botId: {
      get() {
        return this.value?.botId;
      },
      set(value) {
        this.$emit('input', { key: 'botId', value });
      },
    },
    childNodeId: {
      get() {
        return this.value?.childNodeId;
      },
      set(value) {
        this.$emit('input', { key: 'childNodeId', value });
      },
    },
    parentNodeId: {
      get() {
        return this.value?.parentNodeId || '';
      },
      set(value) {
        this.$emit('input', { key: 'parentNodeId', value });
      },
    },
    actualLabelName: {
      get() {
        const value = this.value?.actualLabelName;
        return value === '' ? value : value;
      },
      set(value) {
        this.$emit('input', { key: 'actualLabelName', value });
      },
    },
    botOptions() {
      const botList = this.botsNotHidden.map((bot) => ({ value: bot.id, text: bot.name }));
      botList.unshift({ value: null, text: 'No bot chosen' });
      return botList;
    },
    actualLabelId() {
      if (!this.actualLabelName) {
        return this.actualLabelName;
      }
      return Object.values(this.nodeLabels).find((x) => x.name === this.actualLabelName)?.id;
    },
    globalOptions() {
      // Global node options
      return Object.values(this.nodeLabels).filter((e) => e.global)
        .map((e) => ({ text: e.name, value: e.id })).sort(this.compareOptions);
    },
    specialOptions() {
      // Special node options
      return Object.values(this.nodeLabels).filter((e) => e.special && !e.global)
        .map((e) => ({ text: e.name, value: e.id })).sort(this.compareOptions);
    },
    nodesToConsider() {
      return Object.values(this.nodeLabels).filter((e) => !e.special && !e.global);
    },
    parentSelected() {
      return this.parentNodeId !== null && this.parentNodeId !== '';
    },
    visitedSelected() {
      return this.actualLabelId !== null && this.actualLabelId !== '';
    },
    labelSelected() {
      return this.childNodeId !== null && this.childNodeId !== ''
        && this.childNodeId !== NEUTRAL_NODE_ID;
    },
    suggestFunctions() {
      return {
        labels: (e) => !(
          (this.parentSelected && !e.parents.includes(this.parentNodeId))
          || (this.visitedSelected && !e.siblings.includes(this.actualLabelId))
        ),
        parents: (e) => !(
          ((this.labelSelected && !e.children.includes(this.childNodeId))
          || (this.visitedSelected && !e.children.includes(this.actualLabelId)))
        ),
        children: (e) => !(
          ((this.labelSelected && !e.siblings.includes(this.childNodeId))
          || (this.parentSelected && !e.parents.includes(this.parentNodeId)))
        ),
      };
    },
    splitOptions() {
      const splits = {};
      Object.keys(this.historicData).forEach((key) => {
        const [suggested, other] = this.partition2options(
          this.nodesToConsider,
          this.suggestFunctions[key],
          key === 'children', // Use names instead of ids for children
        );
        suggested.sort(this.compareOptions);
        other.sort(this.compareOptions);
        if (key === 'labels') {
          // Labels need the "None of the above" special value
          suggested.push({ text: '"None of the above"', value: NEUTRAL_NODE_ID });
        }
        splits[key] = { suggested, other };
      });
      return splits;
    },
    historicOptions() {
      const options = {};
      Object.keys(this.historicData).forEach((key) => {
        options[key] = this.filterHistoricData(
          this.splitOptions[key].suggested.concat(this.specialOptions, this.globalOptions),
          this.historicData[key],
          key === 'children',
        ).sort(this.compareOptions);
      });
      return options;
    },
    options() {
      const options = {};
      Object.keys(this.historicData).forEach((key) => {
        const showNotSelected = key !== 'parents';
        options[key] = [];
        if (showNotSelected) {
          options[key].push({ text: '- Not selected -', value: null });
        }
        const suggested = this.splitOptions[key].suggested;
        const historicName = `${suggested.length > 0 ? 'Other h' : 'H'}istoric nodes`;
        options[key] = options[key].concat(
          this.filterEmptyGroups([
            { text: '- Any -', value: '' },
            { label: 'Suggested nodes', options: suggested },
            { label: historicName, options: this.historicOptions[key] },
            { label: 'Special nodes', options: this.specialOptions },
            { label: 'Global nodes', options: this.globalOptions },
            { label: 'Other nodes', options: this.splitOptions[key].other },
          ]),
        );
      });
      return options;
    },
    addButtonDisabled() {
      return this.$v.botId.$invalid || this.$v.childNodeId.$invalid || this.loading;
    },
  },
  watch: {
    async botId(newVal) {
      if (newVal !== null) {
        await this.fetchNodeLabelOptions();
      }
    },
    async childNodeId() {
      await this.fetchHistoricValues('label');
    },
    async parentNodeId() {
      await this.fetchHistoricValues('parent');
    },
    async actualLabelName() {
      await this.fetchHistoricValues('child');
    },
  },
  mounted() {
    if (this.value?.botId) {
      this.fetchNodeLabelOptions();
    }
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    selectBotId() {
      this.childNodeId = null;
      this.parentNodeId = null;
      this.actualLabelName = null;
    },
    filterHistoricData(options, elements, useNameAsValue = false) {
      const result = [];
      // Note: "id" is name if "useNameAsValue"
      elements.forEach((value) => {
        if (!options.find((option) => option.value === value)) {
          let id = value;
          if (useNameAsValue) {
            id = Object.values(this.nodeLabels).find((x) => x.name === value)?.id;
          }
          const name = this.nodeLabels[id]?.name || `Deleted node (${value})`;
          result.push({ text: name, value });
        }
      });
      return result;
    },
    compareOptions(a, b) {
      return a.text.localeCompare(b.text);
    },
    partition2options(nodes, fn, useName = false) {
      return partition(nodes, fn).map((n) => this.nodes2options(n, useName));
    },
    nodes2options(nodes, useName = false) {
      return nodes.map((n) => this.node2option(n, useName));
    },
    node2option(node, useName = false) {
      return { text: node.name, value: useName ? node.name : node.id };
    },
    filterEmptyGroups(options) {
      return options.filter((x) => x.options === undefined || x.options.length > 0);
    },
    async fetchHistoricValues(trigger) {
      if (this.botId === null) {
        return;
      }
      const getValues = new Set(['label', 'parent']);
      if (this.showLabelSelector) {
        getValues.add('child');
      }
      getValues.delete(trigger);
      const params = {
        bot_id: this.botId,
        get_values: Array.from(getValues),
        label: this.childNodeId,
        parent: this.parentNodeId,
        child: this.actualLabelName,
      };
      try {
        const result = await axios.get(endpoints.nodeLabelDistinctValues, {
          params,
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
        if (getValues.has('label')) {
          this.$set(this.historicData, 'labels', result.data.label);
        }
        if (getValues.has('parent')) {
          this.$set(this.historicData, 'parents', result.data.parent);
        }
        if (getValues.has('child')) {
          this.$set(this.historicData, 'children', result.data.child);
        }
      } catch (error) {
        this.showWarning({
          title: 'Failed to fetch node label values',
          text: error.message,
          variant: 'danger',
        });
        throw error;
      }
    },
    async fetchNodeLabelOptions() {
      const url = endpoints.labelNodeOptions + this.botId;
      try {
        const resp = await axios.get(url, {
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        });
        if (resp.status === 200) {
          const nodeLabels = resp.data.result;
          this.nodeLabels = nodeLabels;
        }
      } catch (error) {
        if (error.response.status === 403) {
          this.showWarning({
            title: 'Permission denied',
            text: 'You do not have permissions to this bot. You must ask a superuser to grant you access.',
            variant: 'warning',
          });
        }
        throw error;
      }
    },
    addButtonClicked() {
      const childId = this.value.childNodeId;
      const childNodeName = childId === NEUTRAL_NODE_ID
        ? NEUTRAL_NODE_ID : this.nodeLabels[childId].name;
      this.$emit('add', {
        childNodeName,
        parentNodeName: this.nodeLabels[this.value.parentNodeId]?.name,
      });
    },
    languageIsAny(language) {
      return language === null || language === '' || language === 'any';
    },
  },
  validations: {
    botId: {
      required,
    },
    childNodeId: {
      required,
      sourcesLoaded() {
        for (const label of Object.values(this.labels)) {
          if (label.sources === null) {
            return false;
          }
        }
        return true;
      },
      unique() {
        for (const label of Object.values(this.labels)) {
          if (label.sources === null) {
            return true;
          }
          for (const src of Object.values(label.sources)) {
            if (src.type !== 'node') {
              // Not node source
              continue;
            }
            if (!(src.parentId === null || this.parentNodeId === ''
              || src.parentId === this.parentNodeId)
            ) {
              // Parent not matched
              continue;
            }
            if (src.childId !== this.childNodeId) {
              // Child not matched
              continue;
            }
            if (!(this.language === undefined || this.languageIsAny(this.language)
              || this.languageIsAny(src.language)
              || src.language === this.language)
            ) {
              // Language not matched
              continue;
            }
            return false;
          }
        }
        return true;
      },
    },
  },
};
</script>
