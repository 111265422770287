<template>
  <main
    role="main"
  >
    <b-card
      :title="model.replace(/^./, model[0].toUpperCase()) + 's'"
      class="r-75"
      body-class="p-3"
    >
      <p class="font-weight-bold">
        Here you can manage {{ model }} permissions.
      </p>
      <p>
        Click on a {{ model }} to edit permissions.
      </p>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-table
        :tbody-tr-attr="{ style: 'cursor:pointer' }"
        :items="alphabeticallySortedObjects"
        :fields="fields"
        hover
        class="mb-0"
        @row-clicked="editObject"
      />
    </b-card>
  </main>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'AdminOverview',
  props: {
    model: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      fields: ['name'],
    };
  },
  computed: {
    ...mapState('administration', ['objects']),
    alphabeticallySortedObjects() {
      return Object.values(this.objects).sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    editObject(item) {
      this.$router.push({ name: `admin-${this.model}s-single`, params: { id: item.id } });
    },
  },
};

</script>

<style scoped>
</style>
