<template>
  <div>
    <b-sidebar
      right
      :visible="showSidebar"
      no-header
      title="Sidebar"
      body-class="px-3 bg-white"
      sidebar-class="sidebar-top-offset"
      shadow
      width="450px"
      no-slide
      @hidden="tabIndex = 0"
    >
      <template v-if="activeNodeId" #default>
        <b-row
          style="position:sticky; top:0px; z-index: 100;"
          no-gutters
          align-v="center"
          class="py-2 bg-white"
        >
          <b-col>
            <b-button
              variant="link"
              @click="$emit('toggleSidebar', !showSidebar)"
            >
              <font-awesome-icon
                icon="times"
                size="lg"
              />
            </b-button>
          </b-col>

          <b-col cols="auto">
            <b-btn
              v-if="isSuperUser"
              v-b-tooltip.hover
              class="mr-2"
              size="sm"
              @click="showHistory"
            >
              Show history
            </b-btn>
            <b-button
              size="sm"
              @click="edit"
            >
              Edit node page
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-input-group>
              <b-form-input
                v-model="nodeName"
              />
              <template #append>
                <b-button
                  v-b-tooltip.hover.noninteractive.viewport
                  :variant="isFlowNode(nodeId) ? 'primary' : 'secondary'"
                  :title="isFlowNode(nodeId) ? 'Remove node from flow nodes' : 'Add node to flow nodes'"
                  @click="toggleFlowNode(nodeId)"
                >
                  <font-awesome-icon icon="sitemap" />
                </b-button>
                <b-dropdown
                  ref="bookmarkDropdown"
                  v-b-tooltip.hover.bottom
                  text="Dropdown Button"
                  title="Bookmark node"
                  :disabled="bookmarkOptions.length === 1"
                  no-caret
                  toggle-class="px-2"
                  right
                  menu-class="bg-white p-2 mt-1 w-100"
                >
                  <template #button-content>
                    <font-awesome-icon
                      icon="bookmark"
                    />
                  </template>
                  <b-dropdown-group header-classes="px-0 pt-0" header="Bookmark node">
                    <b-form-select
                      :options="bookmarkOptions"
                      :value="null"
                      @input="addBookmarkProxy"
                    />
                  </b-dropdown-group>
                </b-dropdown>
              </template>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-form-checkbox
              v-model="responseMode"
              switch
            >
              Require response
              <tooltipped-text
                value="If require response is chosen, the bot will wait for user input before moving
             to a child after executing this node's activities."
              />
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-tabs
              v-model="tabIndex"
              class="bg-white h-100 r-25"
              content-class="mt-2 r-25 p-0"
              fill
            >
              <b-tab>
                <template #title>
                  Activities
                  <tooltipped-text
                    value="What the node should do"
                  />
                </template>
                <node-edit-activities
                  :node-id="nodeId"
                  always-open
                />
              </b-tab>
              <b-tab
                v-if="!isSubflowNode && !isSpecialNode(activeNode) && !isRoot"
                title="Matching"
              >
                <template #title>
                  Matching
                  <tooltipped-text
                    value="Requirements, examples and classifiers used with this node"
                  />
                  <span
                    v-b-tooltip.hover
                    title="Node has no matching configured"
                  >
                    <font-awesome-icon
                      v-if="!isGreetNode && !hasMatchingConfigured"
                      :icon="['far', 'circle']"
                      class="ml-1"
                      size="xs"
                    />
                  </span>
                </template>
                <node-edit-match
                  always-open
                  :node-id="nodeId"
                />
              </b-tab>
              <b-tab>
                <template #title>
                  Options
                  <tooltipped-text
                    value="Miscellaneous options for this node"
                  />
                </template>
                <node-edit-options
                  :node-id="nodeId"
                  always-open
                />
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </template>
      <template v-if="activeNodeId" #footer>
        <div class="bg-white py-2 px-3 text-right">
          <b-button
            v-b-tooltip.hover.noninteractive.viewport
            size="sm"
            class="mr-2"
            :disabled="activeParentId === null"
            :title="activeParentId !== null ? '' : 'This node cannot be disabled'"
            @click="toggleDisability"
          >
            {{ isDisabled ? 'Enable' : 'Disable' }} node
          </b-button>
          <b-button
            v-b-tooltip.hover.noninteractive.viewport
            variant="warning"
            size="sm"
            class="mr-2"
            :disabled="activeParentId === null"
            @click="removeReference"
          >
            Remove reference
          </b-button>
          <b-button
            v-b-modal.delete-node-modal
            v-b-tooltip.hover.noninteractive.viewport
            variant="danger"
            :title="!disableDelete ? '' : 'Greet and special nodes cannot be deleted'"
            size="sm"
            :disabled="disableDelete"
          >
            Delete node
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <delete-node-modal :node-id="nodeId" @delete="deleteNode" />
    <bookmark-node-modal :active-node-id="activeNodeId" />
  </div>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import NodeEditActivities from '@/pages/EditNode/NodeEditActivities.vue';
import NodeEditOptions from '@/pages/EditNode/NodeEditOptions.vue';
import NodeEditMatch from '@/pages/EditNode/NodeEditMatch.vue';
import { nodeTypes } from '@/js/constants';
import TooltippedText from '@/components/TooltippedText.vue';
import BookmarkNodeModal from '@/pages/GraphView/BookmarkNodeModal.vue';
import DeleteNodeModal from '@/components/DeleteNodeModal.vue';

export default {
  name: 'GraphSidebar',
  components: {
    BookmarkNodeModal,
    NodeEditActivities,
    NodeEditOptions,
    NodeEditMatch,
    TooltippedText,
    DeleteNodeModal,
  },
  props: {
    activeNodeId: {
      type: String,
      default: '',
    },
    activeParentId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById', 'isSpecialNode',
    ]),
    ...mapState('graphView', ['showSidebar', 'bookmarks']),
    ...applyThisArgs(
      mapGetters('botManipulation/activeBot', {
        activeNode: 'nodeById',
        hasUniqueNodeName: 'isNodeNameUnique',
        hasMatchingConfigured: 'hasMatchingConfigured',
        isDisabled: 'isDisabled',
        getResponseMode: 'getResponseMode',
        isGreetNode: 'isGreetNode',
        isRoot: 'isRoot',
      }),
      'nodeId',
    ),
    ...mapGetters('nodeInterpretations', ['isFlowNode']),
    ...mapGetters('auth', ['isSuperUser']),
    disableDelete() {
      if (this.isGreetNode || this.isSpecialNode(this.activeNode)) {
        return true;
      }
      return false;
    },
    bookmarkOptions() {
      const options = [{ value: null, text: 'Select folder' }].concat(this.bookmarks.filter((e) => {
        if (e.isFolder) {
          return this.bookmarks
            .find((b) => b.parent === e.id && b.node === this.activeNodeId) === undefined;
        }
        return false;
      }).map((e) => ({ value: e.id, text: e.name })));

      if (this.bookmarks.filter((e) => !e.isFolder
      && e.node === this.activeNodeId && e.parent === null).length === 0) {
        options.unshift({ value: 'default', text: 'Default' });
      }
      return options;
    },
    nodeId() {
      return this.activeNodeId;
    },
    isSubflowNode() {
      return this.activeNode.options.nodeType === nodeTypes.SUBFLOW;
    },
    nodeName: {
      get() {
        return this.activeNode?.name;
      },
      set(value) {
        this.$store.dispatch('botManipulation/activeBot/updateNames', { id: this.nodeId, value });
      },
    },
    responseMode: {
      get() {
        return this.getResponseMode === 'require';
      },
      set(value) {
        const valueForStore = value ? 'require' : 'pass';
        this.setResponseMode({ responseMode: valueForStore });
      },
    },
  },
  watch: {
    activeNodeId(n) {
      if (!n) {
        this.setShowSidebar(false);
      }
    },
  },
  methods: {
    ...mapActions('nodeInterpretations', ['handleDeletionOfNode']),
    ...addThisArgs(mapMutations('botManipulation/activeBot', [
      'setResponseMode',
    ]), { id: 'nodeId' }),
    ...mapActions('graphView', ['addBookmark']),
    ...mapMutations('graphView', ['setShowSidebar']),
    ...mapActions('nodeInterpretations', ['toggleFlowNode']),
    addBookmarkProxy(value) {
      this.addBookmark({
        parent: value === 'default' ? null : value,
        node: this.activeNodeId,
      });
      this.$refs.bookmarkDropdown.hide();
    },
    edit() {
      const { botId } = this.$route.params;
      this.$router.push({ name: 'edit-node', params: { botId, nodeId: this.nodeId } });
    },
    removeReference() {
      // for subflows handle outgoing
      const parent = this.nodeById(this.activeParentId);
      if (parent.options.nodeType === 'subflow') {
        const outgoing = JSON.parse(JSON.stringify(parent.subFlowMap.outgoing));
        for (const outmap of Object.values(outgoing)) {
          outmap.childrenIds = outmap.childrenIds.filter((x) => x !== this.nodeId);
        }
        this.$store.commit(
          'botManipulation/activeBot/updateOutgoing',
          { id: parent.id, value: outgoing },
        );
      }
      // remove parent and child relations (through updateActiveNode)
      let predsCp = JSON.parse(JSON.stringify(this.activeNode.preds));
      predsCp = predsCp.filter((p) => p !== this.activeParentId);
      this.$store.dispatch(
        'botManipulation/activeBot/updateActiveNode',
        { value: predsCp, attribute: 'preds', id: this.nodeId },
      );
      this.$emit('toggleSidebar', false);
    },
    deleteNode() {
      this.$emit('toggleSidebar', false);
      this.$store.dispatch('botManipulation/activeBot/removeNode', { id: this.nodeId });
      this.handleDeletionOfNode(this.activeNodeId);
    },
    toggleDisability() {
      this.$store.commit('botManipulation/activeBot/toggleIsDisabled', { id: this.nodeId });
    },
    showHistory() {
      this.$router.push({
        name: 'history',
        hash: `#${this.nodeId}`,
      });
    },
  },
};
</script>
<style scoped>
::v-deep .nav-link {
    border: 0px solid #fff !important;
}
::v-deep .nav-link {
    background-color: white;
}
::v-deep .nav-item > .active{
    background-color: white;
    border-bottom: 3px solid #005F89 !important;
}
::v-deep .sidebar-top-offset{
    margin-top: 60px;
    height: calc(100% - 60px);
}
</style>
