<template>
  <b-list-group>
    <b-list-group-item
      v-for="(item, index) in results"
      :key="'integrationreflist_' + index"
      button
      variant="info"
      @click="event => nav(item.id, event)"
    >
      <p class="mb-0">
        {{ item.name }}
      </p>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ACTION,
} from '@/js/activity';

export default {
  name: 'APIActionRefList',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ACTION,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'allNodesAsList',
    ]),
    botId() {
      return this.$store.getters['botManipulation/getBotId'];
    },
    results() {
      if (!this.id) {
        return [];
      }

      const results = [];
      for (const node of Object.values(this.allNodesAsList)) {
        for (const activity of Object.values(node.activities)) {
          if (activity.type === ACTION && activity.name === this.id) {
            results.push(node);
            break;
          }
        }
      }

      results.sort((a, b) => (a.name < b.name ? -1 : 1));
      return results;
    },
  },
  methods: {
    nav(id, event) {
      const botId = this.$route.params.botId;
      if (event && event.ctrlKey) {
        const route = this.$router.resolve({ name: 'edit-node', params: { botId, nodeId: id } });
        window.open(route.href, '_blank');
      } else {
        this.$router.push({ name: 'edit-node', params: { botId, nodeId: id } });
      }
    },
  },
};
</script>
