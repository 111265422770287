<template>
  <div>
    <hr>
    <div
      v-for="eventCollection of eventsByNodeWrapper"
      :key="`collection-${eventCollection.key}`"
      class="d-flex"
    >
      <div
        v-if="!simpleView"
        class="border-right pr-2 flowcolumn d-flex justify-content-center align-items-center mb-1"
      >
        <template v-if="eventCollection.node !== null">
          <b-link
            v-if="eventCollection.probabilities"
            :disabled="eventCollection.disable"
            :class="{ 'text-muted': eventCollection.disable }"
            @click="() => toggleStats(eventCollection.key)"
          >
            <font-awesome-icon icon="arrow-down" />
          </b-link>
          <b-button
            v-else
            v-b-tooltip.noninteractive
            variant="primary"
            pill
            size="sm"
            class="text-truncate"
            style="max-width:90%"
            :title="eventCollection.node.display_name"
            :to="linkToNode(eventCollection.node.node)"
          >
            {{ eventCollection.node.display_name }}
          </b-button>
        </template>
      </div>
      <div :class="logColumnClasses">
        <node-probabilities-chart
          v-if="!simpleView && eventCollection.probabilities && activeStats[eventCollection.key]"
          :probabilities="eventCollection.probabilities"
          :node-name="eventCollection.node.display_name"
          :responsive="false"
          :height="150"
        />
        <chat-event
          v-for="(event, idx) in eventCollection.events"
          :key="`event-${idx}-${eventCollection.key}`"
          :chat-event="event"
          :chat-log-id="chatLogId"
          :chat-log-platform="chatLogPlatform"
          :show-actions="showActions"
          :show-ssml="showSsml"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import NodeProbabilitiesChart from '@/pages/ChatLogs/NodeProbabilitiesChart.vue';
import ChatEvent from '@/pages/ChatLogs/ChatEvent.vue';
import { eventsByNode } from '@/js/utils';

export default {
  name: 'ChatTranscript',
  components: {
    NodeProbabilitiesChart,
    ChatEvent,
  },
  props: {
    chatEvents: {
      type: Array,
      required: false,
      default: null,
    },
    showActions: {
      type: Boolean,
      required: true,
    },
    chatLogId: {
      type: String,
      required: true,
    },
    chatLogPlatform: {
      type: String,
      required: true,
    },
    showSsml: {
      type: Boolean,
      required: false,
      default: true,
    },
    simpleView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      activeStats: {},
    };
  },
  computed: {
    eventsByNodeWrapper() {
      return eventsByNode(this.chatEvents);
    },
    logColumnClasses() {
      if (!this.simpleView) {
        return ['ml-2', 'logcolumn'];
      }
      return 'w-100';
    },
  },
  methods: {
    linkToNode(nodeId) {
      return { name: 'edit-node', params: { botId: this.$route.params.botId, nodeId } };
    },
    toggleStats(key) {
      if (this.activeStats[key] === undefined) {
        Vue.set(this.activeStats, key, true);
      } else {
        this.activeStats[key] = !this.activeStats[key];
      }
    },
  },
};
</script>

<style scoped>
.flowcolumn {
  min-width: 25%;
  max-width: 25%;
  border-width:.25rem!important
}
.logcolumn {
  min-width: 75%;
  max-width: 75%;
}
a.disabled {
  pointer-events: none;
}
::v-deep .bubble {
  min-width: 75px;
  word-break: break-word;
  border-radius: 15px;
  color: #fff
}
::v-deep .meta {
  font-size: 0.75rem;
  color: #838383;
}
</style>
