<template>
  <div>
    <p>
      Numerous enchancements have been applied to Generative AI features, including:
      <ul>
        <li>
          Introduction of bot personas and allowed emoticons
        </li>
        <li>
          Option to add Generative AI actions in variants
        </li>
        <li>
          Fine-tuning of prompts to enchance the accuracy and quality of outputs
        </li>
        <li>Control over formatting of AI-generated responses</li>
      </ul>
    </p>
    <div class="text-center my-2">
      <b-button
        href="https://www.supwiz.com/generative-ai-supwiz"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to read more
      </b-button>
    </div>
    <b-alert
      v-if="!isGenerativeAiEnabled"
      show
      class="mt-3"
      variant="info"
    >
      Generative AI is an add-on feature that must be enabled for your SupWiz products before you
      can use it. Reach out to your SupWiz' rep to learn more.
    </b-alert>
  </div>
</template>

<script>
import { isGenerativeAiEnabled } from '@/js/featureFlags';

export default {
  name: 'GenerativeAIImprovements',
  data() {
    return { isGenerativeAiEnabled };
  },
};
</script>
