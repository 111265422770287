<template>
  <div class="p-3">
    <b-row>
      <b-col
        cols="auto"
        class="my-auto"
      >
        <h4 class="card-title">
          Node examples
        </h4>
      </b-col>
      <b-col class="my-auto pb-2">
        <b-dropdown
          right
          variant="primary"
          text="Actions"
          class="float-right same-width"
        >
          <b-dropdown-item @click="exportExamples()">
            Export examples
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.import-variant-examples-modal>
            Import variant examples
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="!selectedVariantNodeExamples.bot && !selectedVariantNodeExamples.subflows"
            @click="exportVariantExamples()"
          >
            Download current examples
          </b-dropdown-item>
          <b-dropdown-item
            v-if="showTranslationComponents"
            v-b-modal.translation-setup-modal
            v-b-tooltip.hover.noninteractive.viewport
            :disabled="currentTranslation.isTranslating || !hasSelectedLanguage"
            :title="hasSelectedLanguage ? '' : 'Variant routing language not selected'"
          >
            Translate
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <p>
      You can use the translate action to automatically translate node matching
      examples from the main bot.
    </p>
    <p>
      To manually translate examples from node matching throughout the bot:
    </p>
    <ol>
      <li>Export the examples using the button below</li>
      <li>
        Translate examples in the structure json file
      </li>
      <li>Upload a translated version</li>
    </ol>
    <b-list-group-item
      v-if="currentTranslation.isTranslating"
      class="r-25"
    >
      <b-row
        class="text-center"
      >
        <b-col cols="12">
          <b-spinner small />
          Translating examples
        </b-col>
        <b-col cols="12">
          Last updated: {{ currentTranslation.timestamp.toLocaleTimeString() }}
        </b-col>
      </b-row>
    </b-list-group-item>

    <h4 class="mt-4">
      Classifiers
    </h4>
    <div>
      <strong>Main bot classifiers</strong>
    </div>
    <b-list-group v-if="mainModels.length">
      <b-list-group-item
        v-for="(model, index) in mainModels"
        :key="index"
      >
        <variant-classifier
          :model="model"
          @edit="setEditVariantNLUModel(model.name)"
          @clear="clearSelectedVariantNLUModel(model.name)"
        />
      </b-list-group-item>
    </b-list-group>
    <b-card
      v-else
      no-body
    >
      <p class="p-2">
        There are no classifiers in main bot.
      </p>
    </b-card>
    <div class="mt-2">
      <strong>Subflow classifiers</strong>
    </div>
    <b-list-group v-if="subflowModels.length">
      <b-list-group-item
        v-for="(model, index) in subflowModels"
        :key="index"
      >
        <variant-classifier
          :model="model"
          @edit="setEditVariantNLUModel(model.name, model.subflowId)"
          @clear="clearSelectedVariantNLUModel(model.name, model.subflowId)"
        />
      </b-list-group-item>
    </b-list-group>
    <b-card
      v-else
      no-body
    >
      <p class="p-2">
        There are no subflow classifiers.
      </p>
    </b-card>
    <b-modal
      id="setVariantNLUModal"
      ref="setVariantNLUModal"
      title="Select NLU Model"
      ok-title="Save"
      :ok-disabled="$v.modalNLUModel.$invalid"
      @ok="setSelectedVariantNLUModel"
    >
      <b-form>
        <b-form-group
          label="Classifier"
        >
          <b-form-select
            v-model="modalNLUModel.modelId"
            :options="nluModelOptionsForVariant"
            :state="!$v.modalNLUModel.modelId.$invalid"
          />
        </b-form-group>
        <b-form-group
          v-if="modalNLUModel.modelId"
          label="Version"
        >
          <b-form-select
            v-model="modalNLUModel.modelVersion"
            :options="nluModelVersions"
            :state="!$v.modalNLUModel.modelVersion.$invalid"
          />
        </b-form-group>
        <b-alert
          v-if="!$v.modalNLUModel.$invalid"
          :show="modalMissingLabels.length > 0"
          variant="warning"
        >
          The bot uses the following label{{ modalMissingLabels.length > 1 ? 's' : '' }}
          not found in the selected variant classifier:
          {{ modalMissingLabels.join(', ') }}
        </b-alert>
      </b-form>
    </b-modal>

    <b-modal
      id="import-variant-examples-modal"
      title="Import node examples"
      ok-title="Upload"
      @ok="importVariantExamples"
    >
      <b-form-group
        label="Variant node examples file"
        label-for="nodeExamplesFileForm"
        description="The file should be formatted in the same way as the node examples file that
          can be exported from this page."
      >
        <b-form-file
          id="nodeExamplesFileForm"
          v-model="importVariantNodeExamplesModel.file"
          placeholder="Choose file..."
        />
      </b-form-group>
    </b-modal>

    <b-modal
      id="translation-setup-modal"
      title="Translate node examples"
      ok-title="Translate"
      :ok-disabled="!currentTranslation.type"
      @ok="translateExamples"
    >
      <b-form-group
        label="Translation type"
      >
        <b-form-select
          v-model="currentTranslation.type"
          :options="translationOptions"
        />
        <template #description>
          {{ translationDescription }}
          <span
            v-if="currentTranslation.type === 'differing' || currentTranslation.type === 'all'"
            class="text-warning d-block"
          >
            Use this option with caution as it will overwrite custom translated examples.
          </span>
        </template>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { cloneDeep } from 'lodash';
import { toDatetimeFilter } from '@/js/vuefilters';
import endpoints from '@/js/urls';
import { singleNodeGetters } from '@/store/botManipulation/activeBot/nodes';
import VariantClassifier from './VariantClassifier.vue';

export default {
  name: 'VariantClassifiers',
  components: { VariantClassifier },
  mixins: [validationMixin],
  data() {
    return {
      modalNLUModel: {
        isSubflow: null,
        subflowId: null,
        name: '',
        modelId: null,
        modelVersion: null,
        type: null,
      },
      importVariantNodeExamplesModel: {
        file: null,
      },
      activeTranslation: {
        isTranslating: false,
        type: null,
        timestamp: null,
      },
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'getNLUModels',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nodesAsList',
      'getUseClfToMatch',
      'getNLUModelName',
      'getNLULabel',
    ]),
    ...mapGetters('botManipulation', [
      'getSubFlows',
      'activeBotId',
      'getBotNameFromId',
    ]),
    ...mapGetters('nlu/classifier', [
      'getGlobalNLUModels',
      'getGlobalNLUModelById',
      'getModelVersionForModel',
    ]),
    ...mapGetters('userSettings', ['showTranslationFeatures']),
    ...mapGetters('variants', [
      'selectedVariantClassifierMapping',
      'selectedVariantNodeExamples',
      'selectedVariant',
      'variantsList',
      'hasSelectedLanguage',
    ]),
    allModels() {
      const models = [...cloneDeep(this.getNLUModels), ...cloneDeep(this.subflowClassifiers)];
      for (const model of models) {
        const subflowId = model.subflowId || null;
        const variantSelected = this.getSelectedVariantNLUModel(model.name, subflowId);
        Object.assign(model, {
          originalModel: this.getGlobalNLUModelById(model.modelId),
          variantSelected,
          variantModel: variantSelected
            ? this.getGlobalNLUModelById(variantSelected.modelId) : null,
          variantVersion: variantSelected ? this.getModelVersionString(variantSelected) : null,
          missingLabels: variantSelected
            ? [...this.variantMissingMainLabels(model.name, variantSelected, subflowId)] : null,
        });
      }
      return models;
    },
    mainModels() {
      return this.allModels.filter((x) => x.subflowId === undefined || x.subflowId === null);
    },
    subflowModels() {
      return this.allModels.filter((x) => x.subflowId !== undefined && x.subflowId !== null);
    },
    usedClfLabels() {
      // Compute the labels used by the different parts of the main bot for each of the named
      // classifiers.
      const mainKey = null;
      const models = {
        [mainKey]: Object.fromEntries(this.getNLUModels.map((x) => [x.name, new Set()])),
      };
      for (const model of this.subflowClassifiers) {
        if (!Object.hasOwn(models, model.subflowId)) {
          models[model.subflowId] = {};
        }
        if (!Object.hasOwn(models[model.subflowId], model.name)) {
          models[model.subflowId][model.name] = new Set();
        }
      }
      // Handle main bot labels
      if (this.nodesAsList) {
        for (const node of this.nodesAsList.filter((x) => this.getUseClfToMatch(x.id))) {
          models[mainKey][this.getNLUModelName(node.id)].add(this.getNLULabel(node.id));
        }
      }
      // Handle subflow labels
      const { getUseClfToMatch, getNLUModelName, getNLULabel } = singleNodeGetters;
      for (const sf of this.getSubFlows) {
        for (const node of Object.values(sf.nodes).filter((x) => getUseClfToMatch(x))) {
          models[sf.id][getNLUModelName(node)].add(getNLULabel(node));
        }
      }
      return models;
    },
    modalMissingLabels() {
      return [...this.variantMissingMainLabels(
        this.modalNLUModel.name,
        this.modalNLUModel,
        this.modalNLUModel.subflowId,
      )];
    },
    showTranslationComponents() {
      return this.showTranslationFeatures;
    },
    nluModelInModal() {
      return this.modalNLUModel.modelId ? this.getGlobalNLUModels[this.modalNLUModel.modelId]
        : null;
    },
    nluModelOptionsForVariant() {
      // TODO: For the "real" classifier page, a bunch of additional filtering is done
      let models = Object.values(this.getGlobalNLUModels);
      models = models.filter((x) => !(x.botId && x.botId !== this.activeBotId));
      const options = models.map((x) => ({ value: x.id, text: x.displayName }));
      options.unshift({
        value: null,
        text: 'Select NLU model',
      });
      return options;
    },
    nluModelVersions() {
      if (!this.nluModelInModal) {
        return [];
      }
      const versionList = Object.values(this.nluModelInModal.versions).filter(
        (v) => !('task_success' in v && !v.task_success),
      );
      return versionList.map(
        (v) => ({ value: v.id, text: `${v.description} - ${toDatetimeFilter(v.createdTime)}` }),
      );
    },
    subflowClassifiers() {
      const subflowClassifiersList = [];
      for (const sf of this.getSubFlows) {
        for (const model of sf.config.nluModels) {
          subflowClassifiersList.push({
            subflowId: sf.id,
            subflowName: sf.config.name,
            name: model.name,
            modelId: model.modelId,
          });
        }
      }
      return subflowClassifiersList;
    },
    translationOptions() {
      return [
        { value: 'missing', text: 'Translate nodes with zero variant examples' },
        { value: 'differing', text: 'Translate nodes with non-matching number of examples' },
        { value: 'all', text: 'Translate all nodes' },
      ];
    },
    translationDescription() {
      switch (this.activeTranslation.type) {
        case 'missing': return 'Translates nodes that have examples in master but not in variant.';
        case 'differing': return 'Translates nodes that have different number of examples in master than in variant.';
        case 'all': return 'Translates all node examples from master.';
        default: return '';
      }
    },
    currentTranslation() {
      return this.activeTranslation;
    },
  },
  methods: {
    ...mapMutations('variants', [
      'removeSelectedVariantClassifier',
      'setSelectedVariantClassifier',
    ]),
    ...mapActions('variants', [
      'pushVariantClassifiers',
      'importVariantExamplesFile',
      'refreshAllSelectedVariantData',
    ]),
    ...mapActions('sidebar', ['showWarning']),
    ...mapMutations('task', ['addTask']),
    getSelectedVariantNLUModel(modelName, subflowId = null) {
      const isSubflow = subflowId !== null;
      if (!isSubflow && this.selectedVariantClassifierMapping.bot
          && modelName in this.selectedVariantClassifierMapping.bot) {
        return this.selectedVariantClassifierMapping.bot[modelName];
      }
      if (isSubflow && this.selectedVariantClassifierMapping.subflows
          && subflowId in this.selectedVariantClassifierMapping.subflows
          && modelName in this.selectedVariantClassifierMapping.subflows[subflowId]) {
        return this.selectedVariantClassifierMapping.subflows[subflowId][modelName];
      }
      return undefined;
    },
    getModelVersionString(model) {
      const modelVersion = this.getModelVersionForModel(model.modelId, model.modelVersion);
      return `${modelVersion.description} - ${modelVersion.createdTime}`;
    },
    setDiff(set1, set2) {
      return new Set([...set1].filter((x) => !set2.has(x)));
    },
    variantMissingMainLabels(
      mainName,
      { modelId: variantId, modelVersion: variantVersion },
      subflowId = null,
    ) {
      if (!variantId || !variantVersion) {
        return new Set();
      }
      const mainLabels = new Set(this.usedClfLabels[subflowId][mainName]);
      const variantLabels = new Set(
        this.getModelVersionForModel(variantId, variantVersion)?.labels,
      );
      return this.setDiff(mainLabels, variantLabels);
    },
    async clearSelectedVariantNLUModel(modelName, subflowId = null) {
      this.removeSelectedVariantClassifier({ isSubflow: subflowId !== null, subflowId, modelName });
      await this.pushVariantClassifiers();
    },
    setEditVariantNLUModel(modelName, subflowId = null) {
      const isSubflow = subflowId !== null;
      const variantCopy = cloneDeep(
        this.getSelectedVariantNLUModel(modelName, subflowId),
      );
      if (variantCopy) {
        this.modalNLUModel = variantCopy;
      } else {
        this.modalNLUModel = {
          modelId: null,
          modelVersion: null,
          type: null,
        };
      }
      this.modalNLUModel = {
        ...this.modalNLUModel,
        isSubflow,
        subflowId,
        name: modelName,
      };
      this.$bvModal.show('setVariantNLUModal');
    },
    async setSelectedVariantNLUModel() {
      if (this.nluModelInModal) {
        // Set the new mapping in the store and push it to the backend.
        // This is a bit cumbersome because of subflow bookkeeping.
        this.setSelectedVariantClassifier({
          isSubflow: this.modalNLUModel.isSubflow,
          subflowId: this.modalNLUModel.subflowId,
          modelName: this.modalNLUModel.name,
          modelInfo: {
            modelId: this.modalNLUModel.modelId,
            modelVersion: this.modalNLUModel.modelVersion,
            type: this.nluModelInModal.type,
          },
        });
        await this.pushVariantClassifiers();
        this.modalNLUModel = {
          isSubflow: null,
          subflowId: null,
          name: '',
          modelId: null,
          modelVersion: null,
          type: null,
        };
      }
    },
    async exportExamples() {
      const botId = this.activeBotId;
      const botName = this.getBotNameFromId(botId);
      const result = await axios.get(endpoints.botNodeExamples, {
        params: { bot_id: this.activeBotId },
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      });
      const DownloadHeader = 'data:text/json;charset=utf-8,';
      const fileData = DownloadHeader + encodeURIComponent(JSON.stringify(result.data, null, 2));
      const link = document.createElement('a');
      link.setAttribute('href', fileData);
      link.setAttribute('download', `${botName}_examples.json`);
      document.body.appendChild(link); // Required for FF
      link.click();
      link.remove();
    },
    async importVariantExamples() {
      try {
        await this.importVariantExamplesFile({
          fileObj: this.importVariantNodeExamplesModel.file,
        });
      } catch (e) {
        this.showWarning({
          title: 'Import failed',
          text: 'Failed to import variant examples. Typically this is because of a malformed json file',
          variant: 'danger',
        });
      }
      this.importVariantNodeExamplesModel = {
        file: null,
      };
    },
    async exportVariantExamples() {
      // We need to fetch some stuff to create the filename
      const botId = this.activeBotId;
      const botName = this.getBotNameFromId(botId);
      const variantName = this.variantsList.find((x) => x.id === this.selectedVariant).name;
      const fileName = `${botName}_variant_${variantName}_examples.json`;
      const DownloadHeader = 'data:text/json;charset=utf-8,';
      const fileData = DownloadHeader
        + encodeURIComponent(JSON.stringify(this.selectedVariantNodeExamples, null, 2));
      const link = document.createElement('a');
      link.setAttribute('href', fileData);
      link.setAttribute('download', fileName);
      document.body.appendChild(link); // Required for FF
      link.click();
      link.remove();
    },
    async translateExamples() {
      try {
        this.activeTranslation.timestamp = new Date();
        this.activeTranslation.isTranslating = true;
        const resp = await axios.post(endpoints.variantTranslation, {
          translate_examples: this.activeTranslation.type,
          bot_variant_id: this.selectedVariant,
        }, {
          headers: {
            Authorization: `JWT ${this.$store.state.auth.jwt}`,
          },
        });
        this.addTask(
          {
            celeryId: resp.data.translation_task,
            callbackUpdate: () => {
              this.activeTranslation.timestamp = new Date();
            },
            callbackDone: () => {
              this.cleanActiveTranslation();
              this.refreshAllSelectedVariantData();
            },
            callbackFailed: () => {
              this.cleanActiveTranslation();
              this.showWarning({
                title: 'Failed to translate examples',
                text: 'Failed to translate bot examples.',
                variant: 'danger',
              });
            },
          },
        );
      } catch (error) {
        this.showWarning({
          title: 'Failed to translate examples',
          text: 'Failed to translate bot examples.',
          variant: 'danger',
        });
        this.cleanActiveTranslation();
        throw error;
      }
    },
    cleanActiveTranslation() {
      this.activeTranslation.timestamp = null;
      this.activeTranslation.type = null;
      this.activeTranslation.isTranslating = false;
    },
  },
  validations: {
    modalNLUModel: {
      modelId: {
        required,
        existsInOptions(value) {
          return this.nluModelOptionsForVariant.map((v) => v.value).includes(value);
        },
      },
      name: {
        required,
      },
      modelVersion: {
        required,
        existsInOptions(value) {
          return this.nluModelVersions.map((v) => v.value).includes(value);
        },
      },
    },
  },
};
</script>

<style scoped>

</style>
