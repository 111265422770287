import { render, staticRenderFns } from "./VariantOtherActivities.vue?vue&type=template&id=73f79048&scoped=true&"
import script from "./VariantOtherActivities.vue?vue&type=script&lang=js&"
export * from "./VariantOtherActivities.vue?vue&type=script&lang=js&"
import style0 from "./VariantOtherActivities.vue?vue&type=style&index=0&id=73f79048&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73f79048",
  null
  
)

export default component.exports