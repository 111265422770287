<template>
  <div>
    <p>
      {{ badLinks.length }} bad link(s) found in the bot:
    </p>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in badLinks"
        :key="index"
      >
        {{ item }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
export default {
  name: 'LinkChecking',
  props: {
    insightDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    badLinks() {
      return Object.entries(this.insightDetails).filter((e) => !e[1]).map((e) => e[0]);
    },
  },
};
</script>
