<template>
  <div>
    <b-overlay
      rounded="sm"
      class="p-1"
      :show="usesWebhook && !hideOverlay"
    >
      <h3>Inactivity</h3>
      <b-card
        no-body
      >
        <p>
          Send inactivity message(s) when user does not respond.
        </p>
      </b-card>
      <hr>
      <h5>
        Messages to be sent when user is inactive
      </h5>
      <b-list-group>
        <!-- inactivity prompts have no unique id, so we cannot give a good key. -->
        <!-- eslint-disable-next-line vue/valid-v-for -->
        <b-list-group-item
          v-for="(ina, index) in inactivity"
          :key="index"
        >
          <b-container fluid class="px-0">
            <b-row>
              <b-col>
                <div>
                  <b style="font-weight: bold">Seconds: </b>{{ ina.time }}
                </div>
                <div>
                  <b style="font-weight: bold">Message: </b>{{ ina.msg }}
                </div>
              </b-col>
              <b-col md="auto">
                <b-btn
                  size="sm"
                  variant="outline-primary"
                  @click="setVars(index)"
                  @click.stop="$bvModal.show('inactivityModal')"
                >
                  <font-awesome-icon icon="edit" />
                </b-btn>
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  @click.stop="setRemoveInactivity(index)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </b-btn>
              </b-col>
            </b-row>
          </b-container>
        </b-list-group-item>
      </b-list-group>
      <b-btn
        class="mt-2"
        variant="primary"
        @click="clearVars"
        @click.stop="$bvModal.show('inactivityModal')"
      >
        Add inactivity message
      </b-btn>
      <hr>
      <b-form-checkbox
        id="inactiveCheckbox"
        v-model="useInactiveNode"
        :disabled="inactivity.length === undefined || inactivity.length < 1"
      >
        Go to
        <router-link :to="{ name: 'edit-node', params: { botId: getBotId, nodeId: 'inactive' } }">
          inactivity node
        </router-link>after last inactivity prompt.
      </b-form-checkbox>

      <b-form-group
        v-if="inactivity.length !== undefined && inactivity.length > 0"
        class="mt-2"
      >
        <b-form-radio
          id="inactivityFromStartCheckbox"
          v-model="inactivityFromStart"
          :disabled="inactivity.length === undefined || inactivity.length < 1"
          value="START_INACTIVITY_ON_CHAT_START"
        >
          Start inactivivity timer when the conversation starts
        </b-form-radio>
        <b-form-radio
          v-model="inactivityFromStart"
          :disabled="inactivity.length === undefined || inactivity.length < 1"
          value="START_INACTIVITY_ON_FIRST_USER_MESSAGE"
        >
          Start inactivity timer from the first user message
        </b-form-radio>
      </b-form-group>
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">
            Due to technical limitations of the platform(s) you have configured the bot for
            {{ getPlatforms }}, this feature is not available.
          </p>
          <b-button
            class="mt-1"
            variant="danger"
            @click="hideOverlay = true"
          >
            Show anyway
          </b-button>
        </div>
      </template>
    </b-overlay>

    <b-modal
      id="inactivityModal"
      ref="inactivityModal"
      :title="isEditing() ? 'Edit inactivity prompt' : 'Add inactivity prompt'"
      :ok-disabled="$v.tmpInactive.$invalid ? true : null"
      @ok="saveInactivity()"
    >
      <edit-inactivity
        :value="tmpInactive"
        @input="updateInactive"
      />
    </b-modal>
    <b-modal
      id="deleteInactivityMessageModal"
      title="Delete Inactivity Message"
      ok-variant="danger"
      ok-title="Delete"
      :auto-focus-button="'ok'"
      @ok="removeInactivity"
    >
      Are you certain you would like to delete the message?
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import EditInactivity from './EditInactivity.vue';

export default {
  name: 'InactivityPage',
  components: { EditInactivity },
  mixins: [validationMixin],
  data() {
    return {
      tmpInactive: {
        time: '',
        msg: '',
        responseApproved: false,
      },
      tmpInactiveID: -1,
      indexToDelete: -1,
      hideOverlay: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'getInactivity',
      'getUseInactiveNode',
      'getInactivityFromStart',
      'usesWebhook',
      'getPlatforms',
    ]),
    inactivity() {
      return this.getInactivity;
    },
    useInactiveNode: {
      get() {
        return this.getUseInactiveNode;
      },
      set(newVal) {
        this.setUseInactiveNode(newVal);
      },
    },
    inactivityFromStart: {
      get() {
        if (this.getInactivityFromStart) {
          return 'START_INACTIVITY_ON_CHAT_START';
        }
        return 'START_INACTIVITY_ON_FIRST_USER_MESSAGE';
      },
      set(newValue) {
        if (newValue === 'START_INACTIVITY_ON_CHAT_START') {
          this.setInactivityFromStart(true);
        } else if (newValue === 'START_INACTIVITY_ON_FIRST_USER_MESSAGE') {
          this.setInactivityFromStart(false);
        }
      },
    },
  },
  methods: {
    ...mapMutations('botManipulation/activeBot/config', [
      'setUseInactiveNode',
      'setInactivityFromStart',
    ]),
    ...mapActions('botManipulation', [
      'updateInactivity',
    ]),
    editInactiveNode() {
      const botId = this.$route.params.botId;
      this.$router.push({ name: 'edit-node', params: { botId, nodeId: 'inactive' } });
    },
    setVars(index) {
      this.inactiveID = index;
      this.tmpInactive.msg = this.inactivity[index].msg;
      this.tmpInactive.time = this.inactivity[index].time;
      this.tmpInactive.responseApproved = this.inactivity[index].responseApproved;
    },
    clearVars() {
      this.inactiveID = -1;
      this.tmpInactive.msg = '';
      this.tmpInactive.time = '';
      this.tmpInactive.responseApproved = false;
    },
    isEditing() {
      return this.inactiveID >= 0;
    },
    setApproved(value) {
      this.tmpInactive.responseApproved = !!value;
    },
    setInactiveMsg(value) {
      this.tmpInactive.msg = value;
      this.tmpInactive.responseApproved = false;
    },
    saveInactivity() {
      if (this.$v.tmpInactive.$invalid) {
        // Should not happen
        return;
      }
      const inactivityCopy = this.inactivity.slice(0);
      const newInactivity = {
        time: this.tmpInactive.time,
        msg: this.tmpInactive.msg,
        responseApproved: this.tmpInactive.responseApproved,
      };
      if (this.inactiveID >= 0) {
        inactivityCopy[this.inactiveID] = newInactivity;
      } else {
        inactivityCopy.push(newInactivity);
      }
      inactivityCopy.sort((a, b) => a.time - b.time);

      this.updateInactivity({ inactivity: inactivityCopy });
      this.tmpInactive.time = '';
      this.tmpInactive.msg = '';
    },
    removeInactivity() {
      const inactivityCopy = this.inactivity.slice(0);
      inactivityCopy.splice(this.indexToDelete, 1);
      this.updateInactivity({ inactivity: inactivityCopy });
      if (inactivityCopy.length < 1) {
        this.setUseInactiveNode(false);
      }
    },
    setRemoveInactivity(index) {
      this.indexToDelete = index;
      this.$bvModal.show('deleteInactivityMessageModal');
    },
    updateInactive(payload) {
      this.tmpInactive[payload.type] = payload.value;
      if (payload.type === 'msg') {
        this.tmpInactive.responseApproved = false;
      }
    },
  },
  validations: {
    tmpInactive: {
      msg: {
        required,
      },
      time: {
        required,
      },
    },
  },
};
</script>

<style scoped>

</style>
