import { render, staticRenderFns } from "./Metrics.vue?vue&type=template&id=c09e1b42&scoped=true&"
import script from "./Metrics.vue?vue&type=script&lang=js&"
export * from "./Metrics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c09e1b42",
  null
  
)

export default component.exports