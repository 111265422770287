<template>
  <b-card
    class="r-75"
    title="Manage Images"
  >
    <b-row class="mt-4">
      <b-col>
        <b-form-file
          v-model="image"
          class="img-input"
          accept=".jpg, .png, .gif"
          :state="Boolean(image)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        />
      </b-col>
      <b-col cols="2">
        <b-button
          class="upload-btn"
          variant="primary"
          block
          :disabled="!Boolean(image)"
          @click="uploadImage"
        >
          Upload Image
        </b-button>
      </b-col>
    </b-row>

    <b-table
      class="mt-2 mb-0"
      show-empty
      :items="items"
      :busy="isFetching"
      :fields="fields"
      empty-text="There are no images to show"
    >
      <template #cell(actions)="data">
        <b-button-group>
          <b-button
            v-b-tooltip.hover.noninteractive.viewport
            title="Copy image link"
            @click="copyImageLink(data.item.path)"
          >
            <font-awesome-icon icon="copy" />
          </b-button>
          <b-button
            v-b-tooltip.hover.noninteractive.viewport
            title="Open image in new tab"
            @click="openImage(data.item.path)"
          >
            <font-awesome-icon icon="external-link-alt" />
          </b-button>
          <b-button
            v-b-tooltip.hover.noninteractive.viewport
            title="Delete image"
            @click="deleteImage(data.item)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </b-button-group>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import copy from 'clipboard-copy';
import endpoints from '@/js/urls';

export default {
  name: 'ImagesPage',
  data: () => ({
    image: null,
    isFetching: false,
    fields: ['name', 'size', 'type', 'last_modified_time',
      {
        key: 'actions', label: 'Actions', tdClass: 'actions', thClass: 'actions',
      },
    ],
    images: [],
  }),
  computed: {
    items() {
      return this.images;
    },
  },
  mounted() {
    this.fetchImages();
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    async fetchImages() {
      this.images = [];
      try {
        const resp = await axios.get(endpoints.image, { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } });
        this.images = resp.data;
        this.isFetching = false;
      } catch (error) {
        this.showWarning({
          title: 'Failed to fetch images',
          text: error.message,
          variant: 'danger',
        });
        this.isFetching = false;
        throw error;
      }
    },
    async uploadImage() {
      const formData = new FormData();
      formData.append('image_file', this.image);
      try {
        await axios.post(
          endpoints.image,
          formData,
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
        );
        this.fetchImages();
      } catch (error) {
        const feedback = error?.response?.data?.error || error.message;
        this.showWarning({
          title: 'Failed to upload image',
          text: feedback,
          variant: 'danger',
        });
        throw error;
      }
    },
    async deleteImage(item) {
      if (await this.$bvModal.msgBoxConfirm('Are you sure you want to delete the image?', {
        title: 'Delete image',
        okTitle: 'Delete',
        okVariant: 'danger',
      })) {
        try {
          await axios.delete(endpoints.image, {
            params: { id: item.id },
            headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
          });
          this.fetchImages();
        } catch (error) {
          this.showWarning({
            title: 'Failed to delete image',
            text: error.message,
            variant: 'danger',
          });
          throw error;
        }
      }
    },
    copyImageLink(path) {
      copy(path);
    },
    openImage(path) {
      window.open(path, '_blank');
    },
  },
};
</script>
<style>
/* this is needed to make the button same height as the upload input */
.upload-btn{
  padding-top: 5px;
  padding-bottom: 4px;
}
.img-input{
  z-index: 1 !important;
}
</style>
