<template>
  <b-list-group-item>
    <b-row
      align-v="center"
      align-h="between"
      :style="{ cursor: 'pointer' }"
      @click="showDetails = !showDetails"
    >
      <b-col
        cols="11"
      >
        <h5 class="break-text mb-0">
          <b-link :to="link">
            {{ node.name }}
          </b-link>
        </h5>
      </b-col>
      <b-col
        cols="1"
        class="text-right"
      >
        <h5 class="mr-1 mb-0">
          <font-awesome-icon :icon="showDetails ? 'angle-up' : 'angle-down'" />
        </h5>
      </b-col>
    </b-row>

    <b-collapse
      v-model="showDetails"
      class="mt-3"
    >
      <div
        v-for="response in responses"
        :key="response.id"
      >
        <response-activity-text-input
          v-if="response.isNormalResponse"
          :uid="nodeId + response.id"
          :activity-id="response.id"
          :node-id="nodeId"
          :special="isSpecialNode(node)"
        />
        <compound-response-activity-inputs
          v-else
          :ref="response.id"
          :key="response.id"
          :uid="nodeId + response.id"
          :node-id="nodeId"
          :activity-id="response.id"
          :indent="0"
        />
        <hr v-if="response.index < response.numberOfResponses">
      </div>
      <template v-if="isSmartNode">
        <hr>
        <edit-multiple-choice-message :node-id="nodeId" />
        <edit-single-choice-message :node-id="nodeId" />
        <edit-other-option
          v-if="otherShow"
          :node-id="nodeId"
        />
        <edit-child-node-display-name
          v-if="nodeDefinesCustomDisplayNamesForChildren"
          :node-id="nodeId"
        />
        <approve-button
          v-model="smartResponseApproved"
          type="above texts"
        />
      </template>
    </b-collapse>
  </b-list-group-item>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import { RESPONSE, COMPOUND_RESPONSE } from '@/js/activity';
import ResponseActivityTextInput from '@/pages/EditNode/activity/ResponseActivityTextInput.vue';
import CompoundResponseActivityInputs from '@/pages/EditNode/activity/CompoundResponseActivityInputs.vue';
import EditChildNodeDisplayName from '@/pages/EditNode/SmartNode/EditChildNodeDisplayName.vue';
import EditMultipleChoiceMessage from '@/pages/EditNode/SmartNode/EditMultipleChoiceMessage.vue';
import EditSingleChoiceMessage from '@/pages/EditNode/SmartNode/EditSingleChoiceMessage.vue';
import EditOtherOption from '@/pages/EditNode/SmartNode/EditOtherOption.vue';
import { nodeTypes } from '@/js/constants';
import ApproveButton from '@/components/ApproveButton.vue';

export default {
  name: 'ResponsesForNode',
  components: {
    ApproveButton,
    CompoundResponseActivityInputs,
    EditChildNodeDisplayName,
    EditMultipleChoiceMessage,
    EditSingleChoiceMessage,
    EditOtherOption,
    ResponseActivityTextInput,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDetails: false,
    };
  },
  computed: {
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      node: 'nodeById',
      getSmartApproved: 'getResponseApproved',
    }), 'nodeId'),
    ...mapGetters('botManipulation/activeBot', ['isSpecialNode']),
    responses() {
      const responses = [];

      for (let i = 0; i < this.node.activityIds.length; i++) {
        const id = this.node.activityIds[i];
        const activity = this.node.activities[id];
        if (activity.type === RESPONSE || activity.type === COMPOUND_RESPONSE) {
          responses.push({
            id,
            index: i,
            isNormalResponse: activity.type === RESPONSE,
          });
        }
      }
      for (const response of responses) {
        response.numberOfResponses = responses.length;
      }
      return responses;
    },
    link() {
      const botId = this.$route.params.botId;
      return { name: 'edit-node', params: { botId, nodeId: this.node.id } };
    },
    nodeType() {
      const typeOfNode = this.node.options.nodeType;
      return typeOfNode || nodeTypes.SIMPLE;
    },
    isSmartNode() {
      return this.nodeType === nodeTypes.MULTIPLE_CHOICE;
    },
    otherShow() {
      const resp = this.node.options.otherShow;
      if (resp == null) {
        return true;
      }
      return resp;
    },
    nodeDefinesCustomDisplayNamesForChildren() {
      return Object.values(this.node.options.displayNames).length > 0;
    },
    smartResponseApproved: {
      get() {
        return this.getSmartApproved;
      },
      set(approved) {
        this.setSmartApproved({ approved });
      },
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', {
      setSmartApproved: 'setResponseApproved',
    }), { id: 'nodeId' }),
  },
};

</script>
