<template>
  <main
    class="h-100"
    role="main"
  >
    <template v-if="objectsLoading">
      <b-row class="h-100 align-items-center">
        <b-col class="text-center">
          <b-spinner
            style="width: 5rem; height: 5rem;"
          />
        </b-col>
      </b-row>
    </template>
    <router-view v-else />
  </main>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  name: 'AdminBase',
  props: {
    model: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState('administration', ['objectsLoading']),
  },
  watch: {
    model(newValue) {
      this.fetchObjects(newValue);
    },
  },
  created() {
    this.fetchObjects(this.model);
  },
  methods: {
    ...mapActions('administration', ['fetchObjects']),
  },
};

</script>

<style scoped>
</style>
