<template>
  <b-card
    class="r-75"
    body-class="p-3"
    title="Permissions"
  >
    <template v-if="loading">
      <b-spinner
        class="m-4"
        style="width: 4rem; height: 4rem;"
      />
    </template>
    <template v-else>
      <left-right-select
        :left-options="object.noperm"
        :right-options="object.perm"
        @move-to-left="onMoveToLeft"
        @move-to-right="onMoveToRight"
      />
    </template>
  </b-card>
</template>

<script>

import axios from 'axios';
import endpoints from '@/js/urls';
import LeftRightSelect from '@/components/LeftRightSelect.vue';

export default {
  name: 'AdminUsersSingle',
  components: { LeftRightSelect },
  async beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchObject(to.params.id);
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.fetchObject(to.params.id);
    next();
  },
  props: {
    model: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      object: {},
      loading: true,
    };
  },
  methods: {
    async fetchObject(id) {
      this.loading = true;
      const config = {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        params: { model: this.model },
      };
      const resp = await axios.get(endpoints.permission + id, config);
      this.object = resp.data;
      this.loading = false;
    },
    async changePermissionOfUsers(objIds, add) {
      const id = this.$route.params.id;
      await axios.put(
        endpoints.permission + id,
        { model: this.model, obj_ids: objIds, add },
        { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
      );
      this.fetchObject(id);
    },
    onMoveToLeft(val) {
      this.changePermissionOfUsers(val.items, false);
    },
    onMoveToRight(val) {
      this.changePermissionOfUsers(val.items, true);
    },
  },
};

</script>
