<template>
  <b-list-group-item
    :class="alwaysOpen ? 'border-0 p-0' : 'p-2'"
  >
    <b-container fluid>
      <b-row
        v-if="!alwaysOpen"
        align-v="center"
        class="cursor-pointer"
        align-h="between"
        @click="showMatch = !showMatch"
      >
        <b-col cols="*">
          <h4>
            Matching
            <tooltipped-text
              value="Requirements, examples and classifiers used with this node"
            />
            <span
              v-b-tooltip.hover
              title="Node has no matching configured"
            >
              <font-awesome-icon
                v-if="!isGreetNode && !hasMatchingConfigured"
                :icon="['far', 'circle']"
                class="ml-2"
                size="xs"
              />
            </span>
          </h4>
          <p
            v-if="!showCollapse"
            class="subheader"
          >
            {{ subHeaderText }}
          </p>
        </b-col>

        <b-col
          v-if="!alwaysOpen"
          cols="*"
        >
          <h4 class="mr-2">
            <span>
              <font-awesome-icon
                :icon="showMatch ? 'angle-up' : 'angle-down'"
                size="sm"
              />
            </span>
          </h4>
        </b-col>
      </b-row>
    </b-container>

    <b-collapse
      id="collapseMatching"
      v-model="showCollapse"
    >
      <b-card
        v-if="!isQANode"
        no-body
      >
        <b-alert
          :show="activeNode.preds.length > 1"
          variant="warning"
          class="p-2"
        >
          This node has multiple parents. Be careful when adjusting its matching because it may
          change the flow of conversations that you are not aware of!
        </b-alert>
        <b-tabs fill>
          <b-tab
            ref="nlu"
            title="Text matching"
          >
            <b-row
              class="mt-3"
              no-gutters
            >
              <b-col>
                <b-button-group>
                  <b-button
                    :variant="localUseClfToMatch ? 'secondary' : 'primary'"
                    @click="localUseClfToMatch = false"
                  >
                    Use examples
                  </b-button>
                  <b-button
                    :variant="localUseClfToMatch ? 'primary' : 'secondary'"
                    @click="localUseClfToMatch = true"
                  >
                    Use classifier
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
            <template v-if="localUseClfToMatch">
              <label class="font-weight-bold mt-2">
                Classifier
                <tooltipped-text
                  value="The classifier to use for matching on this node"
                />
              </label>
              <b-form-select
                v-model="localClassifierConfig.nluModelName"
                :options="nluModelNames"
                :state="!$v.localClassifierConfig.nluModelName.$invalid"
              />
              <label class="font-weight-bold">
                Label
                <tooltipped-text
                  value="The label of the classifier used for scoring this node"
                />
              </label>
              <b-form-select
                v-model="localClassifierConfig.nluLabel"
                :options="nluModelLabels"
                :state="!$v.localClassifierConfig.nluLabel.$invalid"
                placeholder="Select label"
              />
              <div class="mt-1 text-muted small">
                NLU models must be registered on the
                <router-link :to="{ name: 'classifiers' }">
                  Classifiers page
                </router-link>
                of the bot to be available here.
              </div>
            </template>
            <template v-else>
              <string-list
                fixed-input
                class="mt-3 mb-2 examples-list"
                :strings="nodeExamples"
                @change="examples => setNodeExamples({ examples })"
              />
            </template>
            <b-row v-if="showSaveButton">
              <b-col>
                <b-button
                  class="mt-2 font-weight-bold"
                  variant="outline-primary"
                  :disabled="localUseClfToMatch && invalidClassifierSetup"
                  @click="saveTextMatching"
                >
                  Click to save changes!
                </b-button>
                <small
                  v-if="localUseClfToMatch && invalidClassifierSetup"
                  class="text-danger d-block"
                >
                  Select examples or
                  valid classifier and label to save changes.
                </small>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab
            ref="advanced"
            title="Advanced"
          >
            <div class="my-2">
              <label class="font-weight-bold">
                Requirements
                <tooltipped-text
                  value="Conditions that must be met for this node to match."
                />
              </label>
              <string-list
                :strings="requirements"
                placeholder="Type BotScript here"
                class="text-monospace"
                :validate="true"
                :validations="['syntax']"
                :component="BotscriptValidation"
                @change="onRequirementsChange"
              />
              <div class="mt-1 text-muted small">
                For more information about how to define requirements, see the
                <router-link
                  :to="{ name: 'scripthelp' }"
                >
                  BotScript page
                </router-link>
              </div>
              <div
                v-if="!withinSubFlow && authenticationSubflowIsEnabled"
                class="my-3"
              >
                <hr>
                <label class="font-weight-bold">
                  Requires authentication
                  <tooltipped-text
                    v-if="!authenticationSubflowIsEnabled"
                    value="Authentication cannot be enabled on node
                    before authentication is enabled for bot"
                  />
                  <tooltipped-text
                    v-else
                    value="When enabled, user will be taken to authentication subflow if
                    user has not already authenticated"
                  />
                </label>
                <b-form-checkbox
                  v-model="requiresAuth"
                  :disabled="!authenticationSubflowIsEnabled"
                  switch
                />
                <div class="mt-1 text-muted small">
                  For Bot authentication, see <ConfigLink category="authentication" />
                </div>
              </div>
              <div
                v-if="requiresAuth"
                class="m-2"
              >
                <template v-if="!authFallbackNodeId">
                  <completion-input
                    class="d-inline-block"
                    placeholder="Choose fallback node"
                    :completions="nodeCompletions"
                    value=""
                    @input="fallbackNodeId => setAuthFallbackNodeId({ fallbackNodeId })"
                  />
                  <div class="d-inline-block">
                    <b-button
                      v-b-modal.create-auth-fallback-modal
                      variant="success"
                      style="margin-left:10px"
                      pill
                    >
                      <font-awesome-icon
                        icon="plus"
                      />
                      Create new fallback node
                    </b-button>
                  </div>
                </template>
                <div v-else>
                  <b-button
                    pill
                    size="md"
                    variant="primary"
                    @click="goToNode(authFallbackNodeId)"
                  >
                    {{ nodeById(authFallbackNodeId).name }}
                    <font-awesome-icon
                      :icon="['far', 'times-circle']"
                      size="lg"
                      @click.stop="() => setAuthFallbackNodeId({ fallbackNodeId: null })"
                    />
                  </b-button>
                </div>
              </div>
            </div>
            <div class="my-2">
              <label class="font-weight-bold">
                Keyword Matching
                <tooltipped-text
                  value="Keywords or phrases that force this node to be matched"
                />
              </label>
              <b-form-checkbox
                id="toggle-keyword-matching"
                v-model="useKeywordsToMatch"
                class="pb-2"
                switch
                size="sm"
              >
                Toggle keyword matching
              </b-form-checkbox>
              <b-overlay :show="!useKeywordsToMatch">
                <template #overlay>
                  <span />
                </template>
                <string-list
                  fixed-input
                  :strings="nodeKeywords"
                  placeholder="Add keyword.."
                  @change="keywords => setNodeKeywords({ keywords })"
                />
              </b-overlay>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
      <div v-if="isQANode">
        Matching for Q&A nodes are handled at the Q&A page
      </div>
      <add-node-modal
        title="Create authentication fallback node"
        modal-id="create-auth-fallback-modal"
        @nodeAdded="setAuthFallbackNodeId({ fallbackNodeId: $event })"
      />
    </b-collapse>
  </b-list-group-item>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import StringList from 'supwiz/components/StringList.vue';
import CompletionInput from 'supwiz/components/CompletionInput.vue';
import { validationMixin } from 'vuelidate';
import { requiredIf } from 'vuelidate/lib/validators';
import ConfigLink from '@/pages/BotConfig/ConfigLink.vue';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import { nodeTypes } from '@/js/constants';
import TooltippedText from '@/components/TooltippedText.vue';
import AddNodeModal from '@/pages/TreeView/AddNodeModal.vue';
import BotscriptValidation from '@/components/BotscriptValidation.vue';

export default {
  name: 'NodeEditMatch',
  components: {
    TooltippedText,
    StringList,
    CompletionInput,
    ConfigLink,
    AddNodeModal,
  },
  mixins: [validationMixin],
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localClassifierConfig: {
        nluModelName: null,
        nluLabel: null,
      },
      localUseClfToMatch: false,
      showMatch: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getActiveSubFlowId',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'getNLUModels',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    ...mapGetters('botManipulation/activeBot/config/auth', [
      'authenticationSubflowIsEnabled',
    ]),
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      activeNode: 'nodeById',
      nodeExamples: 'getNodeExamples',
      nodeKeywords: 'getNodeKeywords',
      authFallbackNodeId: 'getAuthFallbackNodeId',
      isGreetNode: 'isGreetNode',
      hasMatchingConfigured: 'hasMatchingConfigured',
      getUseClfToMatch: 'getUseClfToMatch',
      getUseKeywordsToMatch: 'getUseKeywordsToMatch',
      getNLUModelName: 'getNLUModelName',
      getNLULabel: 'getNLULabel',
      getRequiresAuth: 'getRequiresAuth',
      getMatchingWeight: 'getMatchingWeight',
    }), 'nodeId'),
    ...mapGetters('nlu/classifier', [
      'getGlobalNLUModels',
    ]),
    showCollapse: {
      get() {
        return this.alwaysOpen ? this.alwaysOpen : this.showMatch;
      },
      set(v) {
        this.showMatch = v;
      },
    },
    BotscriptValidation() {
      return BotscriptValidation;
    },
    showSaveButton() {
      if (this.localUseClfToMatch !== this.getUseClfToMatch) {
        return true;
      }
      if (this.localClassifierConfig.nluModelName !== this.getNLUModelName) {
        return true;
      }
      if (this.localClassifierConfig.nluLabel !== this.getNLULabel) {
        return true;
      }
      return false;
    },
    invalidClassifierSetup() {
      return this.$v.$invalid;
    },
    isQANode() {
      // QA: Question-Answer node
      return this.activeNode.options.nodeType === nodeTypes.QA;
    },
    requirements() {
      return this.activeNode.requirements;
    },
    exampleCount() {
      return this.activeNode.match.examples.length;
    },
    nluSet() {
      return this.getNLUModelName != null;
    },
    subHeaderText() {
      const reqName = this.requirements.length > 1 ? 'requirements' : 'requirement';
      const reqText = this.requirements.length > 0 ? `${this.requirements.length} ${reqName}, ` : '';
      const NLUText = this.nluSet ? `<${this.getNLUModelName} - ${this.NLULabelToName(this.getNLULabel)}>` : '';
      const exampleName = this.exampleCount > 1 ? 'examples' : 'example';
      const exampleText = !this.nluSet && this.exampleCount > 0
        ? `${this.exampleCount} ${exampleName}, ` : '';
      const authText = this.getRequiresAuth ? 'requires authentication' : '';
      let subHeader = reqText + exampleText + NLUText + authText;
      subHeader = subHeader.trim();
      if (subHeader.endsWith(',')) {
        subHeader = subHeader.slice(0, subHeader.length - 1);
      }
      return subHeader;
    },
    nodeCompletions() {
      if (!this.$store.state.botManipulation.activeBotSet) {
        return [];
      }
      const { nodes } = this.$store.state.botManipulation.activeBot;
      const nodeList = Object.values(nodes).map(({ id, name }) => ({
        key: id,
        value: name,
      }));
      return nodeList.concat({ key: 'fallback', value: this.nodeById('fallback').name });
    },
    requiresAuth: {
      get() {
        return this.getRequiresAuth;
      },
      set(requiresAuth) {
        this.setRequiresAuth({ requiresAuth });
      },
    },
    nluModelNames() {
      const names = this.getNLUModels.map((model) => model.name);
      names.unshift({ text: 'Select classifier', value: null });
      return names;
    },
    nluModelTmp() {
      return this.getNLUModels
        .filter((clf) => clf.name === this.localClassifierConfig.nluModelName)[0];
    },
    nluModelLabels() {
      if (!this.nluModelTmp) {
        return [];
      }
      const globalModel = this.getGlobalNLUModels[this.nluModelTmp.modelId];
      const clfNodeId = globalModel.nodeId;
      if (clfNodeId) {
        const labels = [];
        for (const childId of this.nluModelTmp.labels) {
          labels.push({ value: childId, text: this.NLULabelToName(childId) });
        }
        labels.unshift({
          value: null,
          text: 'Select a label',
        });
        return labels;
      }
      const labels = this.nluModelTmp.labels.slice();
      labels.unshift({
        value: null,
        text: 'Select a label',
      });
      return labels;
    },
    withinSubFlow() {
      const activeSubFlowId = this.getActiveSubFlowId;
      return activeSubFlowId !== undefined && activeSubFlowId !== null;
    },
    nluModelName() {
      return this.localClassifierConfig.nluModelName;
    },
    useKeywordsToMatch: {
      get() {
        return this.getUseKeywordsToMatch;
      },
      set(value) {
        this.setUseKeywordsToMatch({ useKeywordsToMatch: value });
      },
    },
  },
  watch: {
    nluModelName(newValue, oldValue) {
      if (oldValue && newValue !== oldValue) {
        this.localClassifierConfig.nluLabel = null;
      }
    },
    nodeId() {
      this.setLocalClassifierConfig();
    },
  },
  mounted() {
    this.localUseClfToMatch = this.getUseClfToMatch;
    this.setLocalClassifierConfig();
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', {
      setNodeExamples: 'setNodeExamplesWithHelper',
      setNodeKeywords: 'setNodeKeywordsWithHelper',
      setAuthFallbackNodeId: 'setAuthFallbackNodeId',
      setRequiresAuth: 'setRequiresAuth',
      setUseClfToMatch: 'setUseClfToMatch',
      setUseKeywordsToMatch: 'setUseKeywordsToMatch',
      setNLUModelName: 'setNLUModelName',
      setNLULabel: 'setNLULabel',
      updateRequirementsViaSpec: 'updateRequirementsViaSpec',
      setMatchingWeight: 'setMatchingWeight',
    }), { id: 'nodeId' }),
    onRequirementsChange(updateSpec) {
      this.updateRequirementsViaSpec({ updateSpec });
    },
    goToNode(nodeId) {
      this.$router.push({
        name: 'edit-node', params: { botId: this.$route.params.botId, nodeId },
      });
    },
    NLULabelToName(label) {
      try {
        return this.nodeById(label).name;
      } catch {
        return label;
      }
    },
    setLocalClassifierConfig() {
      this.localUseClfToMatch = this.getUseClfToMatch;
      this.localClassifierConfig.nluModelName = this.getNLUModelName;
      // We set this in $nextTick as the UI otherwise won't update reactively and it will look
      // like the label is not set if we switch from one node with NLU to another.
      this.$nextTick(() => {
        this.localClassifierConfig.nluLabel = this.getNLULabel;
      });
    },
    saveTextMatching() {
      // we allow the user to save with examples matching, even when the classifier setup is
      // incomplete. Then we just set the classifier to null
      if (this.localClassifierConfig.nluModelName && !this.localClassifierConfig.nluLabel) {
        this.localClassifierConfig.nluModelName = null;
      }
      this.setUseClfToMatch({ useClfToMatch: this.localUseClfToMatch });
      this.setNLUModelName({ nluModelName: this.localClassifierConfig.nluModelName });
      this.setNLULabel({ nluLabel: this.localClassifierConfig.nluLabel });
    },
  },
  validations() {
    return {
      localClassifierConfig: {
        nluModelName: {
          isSelected: requiredIf(() => this.localUseClfToMatch),
        },
        nluLabel: {
          isSelected: requiredIf(() => this.localUseClfToMatch),
        },
      },
    };
  },
};
</script>

<style scoped>
.subheader {
  font-size: smaller;
  margin: 2px;
}
::v-deep .nav-tabs > li > .nav-link{
  border-radius: 0;
}
.examples-list {
  max-height: 300px !important;
}

</style>
