<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <b-card class="r-75 border shadow" body-class="p-3">
          <b-img
            :src="require(`@/assets/supwiz-robot.png`)"
            fluid
            class="mx-auto my-0"
            style="max-width:90px; position: absolute; top: 0;"
            alt="Responsive image"
          />
          <b-row>
            <b-col style="margin-left: 100px;" class="d-color-dark">
              <h3 class="mb-1">
                Hi {{ displayName }}!
              </h3>
              <p>
                You are viewing bot dashboard
                ({{ dashboardTimeRange.startDateTime.toLocaleDateString() }}
                -- {{ dashboardTimeRange.endDateTime.toLocaleDateString() }}).
              </p>
            </b-col>
            <b-col cols="auto" class="my-auto pr-0">
              <b-button
                v-b-tooltip.hover.noninteractive.viewport="'Refresh data'"
                variant="link"
                size="lg"
                @click="refreshData"
              >
                <font-awesome-icon icon="arrows-rotate" />
              </b-button>
            </b-col>
            <b-col cols="auto" class="my-auto pl-0">
              <b-dropdown
                v-b-tooltip.hover.noninteractive.viewport="'Configuration'"
                size="lg"
                variant="link"
                right
                toggle-class="text-decoration-none"
                menu-class="p-2 dashboard-dropdown"
                no-caret
              >
                <template #button-content>
                  <font-awesome-icon icon="cog" />
                </template>
                <b-dropdown-text text-class="px-0 info-msg">
                  <font-awesome-icon icon="info-circle" />
                  The dashboard data is computed based on the previous seven-day period.
                </b-dropdown-text>
                <b-dropdown-divider />
                <b-dropdown-form form-class="p-0">
                  <b-form-group label="Variant" class="mb-0">
                    <b-form-select
                      v-model="selectedVariant"
                      :options="variantOptions"
                    />
                  </b-form-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-overlay :show="isFetchingKPIs || localIsFetching">
      <b-row class="d-flex">
        <b-col class="mb-3 pr-2" sm="6" lg="3">
          <DashboardKPICard
            class="d-flex flex-column h-100"
            title="Conversations"
            format-type="number"
            :value="getKPICardValue('num_chats', 'actual')"
            :change="getKPICardChange('num_chats')"
          />
        </b-col>
        <b-col class="mb-3 pl-2 pr-sm-3 pr-lg-2" sm="6" lg="3">
          <DashboardKPICard
            class="d-flex flex-column h-100"
            title="Automation rate"
            format-type="percent"
            :value="getKPICardValue('automation_rate', 'actual')"
            :change="getKPICardChange('automation_rate')"
          />
        </b-col>
        <b-col class="mb-3 pl-sm-3 pl-lg-2 pr-2" sm="6" lg="3">
          <DashboardKPICard
            class="d-flex flex-column h-100"
            title="Estimated cost savings"
            :currency="avgCostCurrency"
            format-type="currency"
            :value="getKPICardValue('estimated_cost_savings', 'actual')"
            :change="getKPICardChange('estimated_cost_savings')"
          />
        </b-col>
        <b-col class="mb-3 pl-2 pr-3" sm="6" lg="3">
          <DashboardKPICard
            class="d-flex flex-column h-100"
            title="Conversations with errors"
            format-type="number"
            revert-colors
            :value="getKPICardValue('convs_with_errors_count', 'actual')"
            :change="getKPICardChange('convs_with_errors_count')"
          />
        </b-col>
      </b-row>
    </b-overlay>
    <b-overlay :show="isLoading('flow_stats') || localIsFetching">
      <b-row class="d-flex">
        <b-col class="mb-3 pr-sm-3 pr-xl-2" sm="12" xl="4">
          <flows-summary
            title="Most visited flows"
            metric="most_visited_flows"
            :fields="getFields('most_visited_flows')"
          />
        </b-col>
        <b-col class="mb-3 px-sm-3 px-xl-2" sm="12" xl="4">
          <flows-summary
            :fields="getFields('most_successful_flows')"
            metric="most_successful_flows"
            title="Most successful flows"
          />
        </b-col>
        <b-col class="mb-3 pl-sm-3 pl-xl-2" sm="12" xl="4">
          <flows-summary
            title="Flows with most potential"
            metric="flows_with_most_potential"
            :fields="getFields('flows_with_most_potential')"
          />
        </b-col>
      </b-row>
    </b-overlay>
    <b-overlay :show="isLoadingHygieneOrProgress || localIsFetching">
      <b-row class="align-items-stretch">
        <b-col class="left-col mb-3 pr-3 pr-lg-2" sm="12" lg="6">
          <b-card class="r-75 h-100 border shadow" body-class="dashboard-card-content" style="overflow: hidden">
            <bot-hygiene ref="botHygiene" />
          </b-card>
        </b-col>
        <b-col class="right-col mb-3 pl-3 pl-lg-2" sm="12" lg="6">
          <div class="d-flex flex-column h-100">
            <b-card class="r-75 flex-grow-1 mb-3 border shadow" body-class="dashboard-card-content">
              <div class="dashboard-card-title text-truncate d-color-light">
                Weekly progress
              </div>
              <div>
                <template v-if="isExternalStatsEnabled()">
                  <div class="d-flex align-items-center justify-content-between mt-3">
                    <h6>Review conversations with negative feedback</h6>
                    <font-awesome-icon icon="external-link-alt" class="cursor-pointer" @click="viewTrainingData('labeled_negative_feedback_count')" />
                  </div>
                  <b-progress
                    v-b-tooltip.hover.noninteractive.viewport
                    :title="`Labeled ${getProgressValue('labeled_negative_feedback_count')} conversations (weekly goal: ${feedbackAndTransferWeeklyGoal})`"
                  >
                    <b-progress-bar
                      :value="Math.min(getProgressValue('labeled_negative_feedback_count'), feedbackAndTransferWeeklyGoal)"
                      :label="`${((Math.min(getProgressValue('labeled_negative_feedback_count'), feedbackAndTransferWeeklyGoal) / feedbackAndTransferWeeklyGoal) * 100).toFixed(0)}%`"
                      :max="feedbackAndTransferWeeklyGoal"
                    />
                    <b-progress />
                  </b-progress>
                </template>

                <div class="d-flex align-items-center justify-content-between mt-3">
                  <h6>Review conversations with transfers</h6>
                  <font-awesome-icon icon="external-link-alt" class="cursor-pointer" @click="viewTrainingData('labeled_transfer_count')" />
                </div>
                <b-progress
                  v-b-tooltip.hover.noninteractive.viewport
                  :title="`Labeled ${getProgressValue('labeled_transfer_count')} conversations (weekly goal: ${feedbackAndTransferWeeklyGoal})`"
                >
                  <b-progress-bar
                    :value="Math.min(getProgressValue('labeled_transfer_count'), feedbackAndTransferWeeklyGoal)"
                    :label="`${((Math.min(getProgressValue('labeled_transfer_count'), feedbackAndTransferWeeklyGoal) / feedbackAndTransferWeeklyGoal) * 100).toFixed(0)}%`"
                    :max="feedbackAndTransferWeeklyGoal"
                  />
                </b-progress>

                <!-- eslint-disable -->
                <!-- TODO: commented out because we're not sure if it's even a good idea to label this much.
                <div class="d-flex align-items-center justify-content-between mt-3">
                  <h6>Label conversations</h6>
                  <font-awesome-icon icon="external-link-alt" class="cursor-pointer" @click="viewTrainingData()" />
                </div>
                <b-progress v-b-tooltip.hover.noninteractive.viewport="`${getProgressValue('labeled_count')}/${labelWeeklyGoal}`">
                  <b-progress-bar
                    :value="getProgressValue('labeled_count')"
                    :label="`${((getProgressValue('labeled_count') / labelWeeklyGoal) * 100).toFixed(0)}%`"
                    :max="labelWeeklyGoal"
                  />
                </b-progress>
                -->
                <!-- eslint-enable -->
              </div>
            </b-card>
            <new-features />
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FlowsSummary from '@/pages/Dashboard/FlowsSummary.vue';
import BotHygiene from '@/components/BotHygiene.vue';
import DashboardKPICard from '@/pages/Dashboard/DashboardKPICard.vue';
import NewFeatures from '@/pages/Dashboard/NewFeatures.vue';
import { isExternalStatsEnabled } from '@/js/featureFlags';
import { getDashboardTimeRange } from '@/js/utils';

export default {
  name: 'Dashboard',
  components: {
    DashboardKPICard,
    FlowsSummary,
    BotHygiene,
    NewFeatures,
  },
  data() {
    return {
      labelWeeklyGoal: 100,
      feedbackAndTransferWeeklyGoal: 50,
      localIsFetching: true,
    };
  },
  computed: {
    ...mapGetters('botManipulation', ['getBotId']),
    ...mapGetters('auth', ['displayName']),
    ...mapGetters('statistics', [
      'getKPIDataInfo',
      'getKPICardValue',
      'isLoading',
      'statsSettings',
      'isLoaded',
    ]),
    ...mapGetters('statistics/filters', [
      'statsFilter',
    ]),
    ...mapGetters('chatlogs', [
      'availableDataOrigins',
      'availableVariants',
    ]),
    ...mapGetters('nodeInterpretations', [
      'optOutNodes',
    ]),
    ...mapGetters('health', ['metricsInProgress']),
    ...mapGetters('variants', ['getNameFromId']),
    dashboardTimeRange() {
      const val = getDashboardTimeRange();
      return val;
    },
    activeBot() {
      return this.$store.state.botManipulation.activeBot;
    },
    variantOptions() {
      const variants = (this.availableVariants || []).map((x) => (
        { value: x, text: this.getNameFromId(x) || `Deleted variant (${x})` }
      ));
      variants.unshift({ value: null, text: 'No variant (main bot)' });
      variants.unshift({ value: '', text: 'Any variant' });
      return variants;
    },
    selectedVariant: {
      get() {
        return this.statsFilter.selectedVariant;
      },
      set(newValue) {
        this.updateStatsFilter({ key: 'selectedVariant', newValue });
        this.fetchKPIData('dashboard');
        this.fetchLabelingData();
      },
    },
    isFetchingKPIs() {
      const fields = ['num_chats', 'automation_rate', 'estimated_cost_savings', 'error_chats'];
      const anyLoading = fields.map((e) => this.isLoading(e)).some((e) => e);
      return anyLoading;
    },
    isLoadingHygieneOrProgress() {
      const fields = ['labeled_transfer_count', 'labeled_count', 'labeled_negative_feedback_count', 'discover_features'];
      const anyLoading = fields.map((e) => this.isLoading(e)).some((e) => e);
      return !!anyLoading;
    },
    avgCostCurrency() {
      return this.statsSettings.avg_cost_currency;
    },
    hasComputedHygiene() {
      return this.getBotId in this.$store.state.health
             && !!Object.keys(this.$store.state.health[this.getBotId].metricResponses).length;
    },
    hasComputedDashboard() {
      // because dashboard data is computed in groups
      // we are checking each group (except discover features)
      // if all groups have been computed, return true
      return this.isLoaded('num_chats') && this.isLoaded('flow_stats')
      && this.isLoaded('labeled_transfer_count') && this.hasComputedHygiene;
    },
  },
  async created() {
    if (!Object.values(this.statsSettings).length) {
      this.fetchStatisticsSettings();
    }
  },
  async mounted() {
    this.fetchVariants();
    if (!this.availableDataOrigins?.length) {
      this.setLoadingOrigins(true);
      await this.fetchDataOrigins();
      this.setOrigins(this.availableDataOrigins);
      this.setLoadingOrigins(false);
    }
    if (!this.hasComputedDashboard) {
      await this.refreshData();
    }
    this.$nextTick(() => {
      this.localIsFetching = false;
    });
  },
  methods: {
    isExternalStatsEnabled,
    ...mapActions('statistics', [
      'fetchKPIData',
      'fetchProgressAndFeatures',
      'fetchStatisticsSettings',
      'fetchLabelingData',
    ]),
    ...mapMutations('statistics/filters', [
      'setLoadingOrigins',
      'setOrigins',
      'updateStatsFilter',
    ]),
    ...mapActions('chatlogs', [
      'fetchDataOrigins',
      'fetchVariants',
    ]),
    ...mapActions('health', [
      'fetchHealthConfig',
      'recomputeAllMetrics',
    ]),
    ...mapActions('userSettings', ['calculateDiscoverableFeatures']),
    async refreshData() {
      this.calculateDiscoverableFeatures();
      this.fetchKPIData('dashboard');
      this.fetchLabelingData();
      await this.fetchHealthConfig();
      if (!this.hasComputedHygiene) {
        this.recomputeAllMetrics();
      }
    },
    getFields(key) {
      const fields = [
        'name',
      ];
      if (key === 'most_visited_flows') {
        fields.push({ key: 'count', label: 'Count' });
        fields.push({ key: 'selfService', label: 'Self-service %' });
      } else if (key === 'most_successful_flows') {
        fields.push({ key: 'selfServiceCount', label: 'Self-service #' });
        fields.push({ key: 'selfService', label: 'Self-service %' });
      } else {
        fields.push({ key: 'transferredCount', label: 'Transfer #' });
        fields.push({ key: 'transferred', label: 'Transfer %' });
      }
      return fields;
    },
    getProgressValue(key) {
      if (typeof this.getKPIDataInfo(key) === 'number') {
        return this.getKPIDataInfo(key);
      }
      return 0;
    },
    fetchAllData() {
      this.fetchKPIData('dashboard');
      this.fetchProgressAndFeatures();
      this.$refs.botHygiene.recomputeAllMetrics();
    },
    getKPICardChange(field) {
      const actual = this.getKPICardValue(field, 'actual');
      const previous = this.getKPICardValue(field, 'previous');
      if (previous) {
        const percentageChange = previous !== 0 ? ((actual - previous) / previous) * 100 : 0;
        return percentageChange.toFixed(2);
      }
      return null;
    },
    viewTrainingData(type) {
      const pastDate = new Date();
      pastDate.setDate(new Date().getDate() - 7);
      const query = {
        endDate: new Date().toISOString(),
        startDate: pastDate.toISOString(),
      };
      if (type === 'labeled_negative_feedback_count') {
        query.rating = [1, 2];
      }
      if (type === 'labeled_transfer_count') {
        query.selfServedFilter = 'not self-served';
      }
      this.$router.push({
        name: 'training',
        query,
      });
    },
  },
};
</script>
<style>
.dashboard-card-content{
  padding: 0.8rem;
  padding-top: 0.6rem !important;
}
.dashboard-card-title{
  font-size: 1.2rem;
  font-weight: 400;
}
.d-color-light{
  color: #67748E !important;
}
.d-color-dark{
  color: #344767 !important;
}
.d-color-dark-all *{
  color: #344767 !important;
}
.d-bg-danger{
  background-color: #E74646 !important;
}
.d-bg-warning{
  background-color: #FFA500 !important;
}
.d-bg-info{
  background-color: #73BBC9 !important;
}
</style>
<style scoped>
::v-deep .dashboard-dropdown{
  min-width: 300px;
}
::v-deep .info-msg{
  font-size: 0.83rem;
}
</style>
