<template>
  <main
    role="main"
  >
    <template v-if="isLoading">
      <div class="text-center">
        <b-spinner v-if="isLoading" />
      </div>
    </template>
    <template v-else-if="chatlogData === null">
      An error occurred while loading the conversation log.
    </template>
    <b-container
      v-else
      fluid
      class="p-0"
    >
      <b-row
        align-v="stretch"
        no-gutters
      >
        <b-col>
          <b-card
            title="Conversation details"
            class="r-75 mr-2"
          >
            <b-container
              fluid
              class="p-0 m-0"
            >
              <b-row>
                <b-col class="font-weight-bold">
                  Start time:
                </b-col>
                <b-col cols="8">
                  <pretty-date-time :raw-time="new Date(chatlogData.chatStarted)" />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  End time:
                </b-col>
                <b-col cols="8">
                  <pretty-date-time :raw-time="new Date(chatlogData.chatEnded)" />
                </b-col>
              </b-row>
              <b-row v-if="chatlogData.externalId">
                <b-col class="font-weight-bold">
                  External ID:
                </b-col>
                <b-col cols="8">
                  {{ chatlogData.externalId }}
                </b-col>
              </b-row>
              <b-row v-if="variantName">
                <b-col class="font-weight-bold">
                  Variant:
                </b-col>
                <b-col cols="8">
                  {{ variantName }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  Language:
                </b-col>
                <b-col cols="8">
                  {{ chatlogData.language || "Not specified" }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  Number of messages:
                </b-col>
                <b-col cols="8">
                  {{ chatlogData.messagesInChat }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  Data origin:
                </b-col>
                <b-col cols="8">
                  {{ chatlogData.chatMeta.data_origin }}
                </b-col>
              </b-row>
              <b-row v-if="chatlogData.startUrl">
                <b-col class="font-weight-bold">
                  Start URL:
                </b-col>
                <b-col cols="8">
                  {{ chatlogData.startUrl }}
                </b-col>
              </b-row>
              <template v-if="chatSystemChatLink">
                <hr class="separator">
                <b-row
                  align-h="between"
                  class="mx-0 mt-3"
                >
                  <b-col cols="*">
                    <b-btn
                      variant="primary"
                      :href="chatSystemChatLink"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open chatsystem log
                    </b-btn>
                  </b-col>
                </b-row>
              </template>
            </b-container>
          </b-card>
        </b-col>
        <b-col v-if="chatlogData.internalMeta">
          <b-card
            title="Saved metadata"
            class="r-75 ml-2 h-100"
          >
            <b-container
              v-if="Object.keys(chatlogData.internalMeta).length > 0"
              fluid
              class="p-0 m-0"
            >
              <b-row
                v-for="field of Object.keys(chatlogData.internalMeta)"
                :key="field"
              >
                <b-col class="font-weight-bold">
                  {{ field }}:
                </b-col>
                <b-col cols="8">
                  {{ chatlogData.internalMeta[field] }}
                </b-col>
              </b-row>
            </b-container>
            <div
              v-else
              class="text-center font-italic w-100"
            >
              No saved metadata for chat
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card
            class="r-75 my-3"
            body-class="p-3"
          >
            <div class="d-flex justify-content-between">
              <b-card-title class="mb-0">
                Conversation Log
              </b-card-title>
              <div>
                <div
                  v-if="wasVoicebot"
                  class="d-inline-block mr-2"
                >
                  <b-form-checkbox
                    v-model="showSSML"
                  >
                    Show SSML
                  </b-form-checkbox>
                </div>
                <div
                  v-if="!simpleView"
                  class="d-inline-block"
                >
                  <b-form-checkbox
                    v-model="showActions"
                  >
                    Show integrations
                  </b-form-checkbox>
                </div>
                <div class="d-inline-block ml-2">
                  <voicebot-audio
                    v-if="!simpleView && hasAudio"
                    :chat-log-data="chatlogData"
                    button-text="Play recording"
                  />
                </div>
              </div>
            </div>
            <chat-details
              :bot-id="botId"
              :chat-events="chatlogData.logEvents"
              :chat-log-id="chatlogData.chatId"
              :chat-log-platform="chatlogData.chatMeta.platform"
              :show-actions="showActions"
              :show-ssml="showSSML"
              :simple-view="simpleView"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ChatDetails from '@/pages/ChatLogs/ChatDetails.vue';
import PrettyDateTime from '@/components/PrettyDateTime.vue';
import VoicebotAudio from '@/components/VoicebotAudio.vue';
import { chatSystemChatLink, chatlogTransformer } from '@/js/utils';

export default {
  name: 'ChatlogDetailsPage',
  components: {
    PrettyDateTime,
    ChatDetails,
    VoicebotAudio,
  },
  data() {
    return {
      isLoading: false,
      chatlogData: null,
      showActions: false,
      showSSML: false,
    };
  },
  computed: {
    ...mapGetters('variants', ['getNameFromId']),
    ...mapGetters('userSettings', ['isVoicebotPlatform']),
    botId() {
      return this.$route.params?.botId;
    },
    simpleView() {
      return !this.botId;
    },
    variantName() {
      if (!this.botId) {
        return '';
      }
      if (this.chatlogData.variantId === null) {
        return 'No variant (main bot)';
      }
      return this.getNameFromId(this.chatlogData.variantId) || `Deleted variant (${this.chatlogData.variantId})`;
    },
    chatSystemChatLink() {
      return chatSystemChatLink(this.chatlogData);
    },
    hasAudio() {
      if (this.chatlogData && Object.prototype.hasOwnProperty
        .call(this.chatlogData.chatMeta, 'recording_url')) {
        return this.chatlogData.chatMeta.recording_url != null;
      }
      return false;
    },
    wasVoicebot() {
      return this.isVoicebotPlatform(this.chatlogData.chatMeta.platform);
    },
  },
  mounted() {
    this.fetchChatlog();
  },
  methods: {
    ...mapActions('chatlogs', ['fetchSingleLog']),
    async fetchChatlog() {
      this.isLoading = true;
      const chatlog = await this.fetchSingleLog({
        botId: this.botId,
        chatId: this.$route.params.chatId,
        externalId: this.$route.params.externalId,
      });
      if (chatlog !== null) {
        this.chatlogData = chatlogTransformer(chatlog);
      }
      this.isLoading = false;
    },
  },
};
</script>
